import React, { useEffect, useRef, useState } from "react";
import { FormField, Button, ExplainParagraphLabel, FlexContainer, PageHeadingLabel, LottieLoadingIndicator, Toast } from "src/components";
import { Grid, Divider } from "@material-ui/core";
import styled, { css } from "styled-components";
import CheckIcon from "src/img/check@2x.png";
import DropdownIcon from "src/img/new/chevron-down.png";
import AddIcon from "src/img/new/add.svg";
import { PERK_TYPE, PROMO_CODE_TYPE, USER_GROUP, API_ERROR_FIELD } from "src/constants/perks";
import Dropzone from "react-dropzone";
import UsersModal from "./components/UsersModal";
import UsersIcon from "src/img/new/users.svg";
import { Text } from "src/components";
import ValidatedDateInput from "src/components/ValidatedDateInput";

const ColumnHeader = styled.h1`
    color: #612684;
    font-weight: 700;
    font-size: 1.563rem;
    margin: 0 0 1.25rem;
`;

const PerkTypeOption = styled.button`
    position: relative;
    width: 9.6rem;
    height: 2.9rem;
    border: 1px solid #e9ebef;
    border-radius: 12px;
    text-align: center;
    font-size: 0.875rem;
    font-family: Roboto, Helvetica, sans-serif;
    font-weight: 500;
    background-color: #fff;
    cursor: pointer;

    ${(props) =>
        props.active &&
        css`
            background-color: #000;
            color: #fff;
            font-weight: 500;`}
`;

const SelectedUsersContainer = styled.div`
    height: 2.9rem;
    border: 1px solid #e9ebef;
    border-radius: 0.938rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.938rem;
`;

const RoundedIconContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2rem;
    width: 2rem;
    background-color: #d6d6d6;
    border: 2px solid #fff;
    border-radius: 50%;
    box-shadow: 0px 3px 6px #00000029;
    margin-right: 10px;
    color: #6f6f6f;
`;

const FormButtonsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 1.5rem 3rem;
    gap: 1.25rem;
    align-items: center;
`;

const InputLabel = styled.label`
    font-family: Roboto, Hevetica, sans-serif;
    font-weight: 700;
    font-size: 1rem;
    color: #000;
`;

const InputDescription = styled.p`
    font-family: Roboto, Hevetica, sans-serif;
    font-weight: ${p => p.fontWeight || "normal"};
    font-size: ${p => p.size || "0.875rem"};
    color: ${p => p.color || "#8291b2"};
    margin: ${p => p.margin || "4px 0 8px"};
    text-align: ${p => p.align || "left"};
    margin-bottom: 1em;
`;

const TextInput = styled(FormField)`
    font-family: Roboto, Helvetica, sans-serif;
    height: 3.125rem;
    border: 1px solid #e9ebef;
    border-radius: 12px;
    text-align: left;
    font-weight: 400;
    font-size: 0.938rem;
    color: #000;
    margin: 0;

    ::placeholder {
        text-align: left;
        color: #808080;
        font-family: Roboto, Helvetica, sans-serif;
        font-size: 0.938rem;
    }
`;

const TextArea = styled.textarea`
    font-family: Roboto, Helvetica, sans-serif;
    color: #000;
    caret-color: #000;
    font-size: 0.938rem;
    font-weight: 400;
    border: 1px solid #e9ebef;
    border-radius: 12px;
    padding: 10px 20px;
    resize: none;
    height: 8.5rem;
    width: 90%;
    display: block;

    ::placeholder {
        text-align: left;
        color: #808080;
        font-family: Roboto, Helvetica, sans-serif;
        font-size: 0.938rem;
    }

    &:focus {
        border: 1px solid #000;
        outline: none;
    }
`;

const ImageContainer = styled(Dropzone)`
    height: 14.688rem;
    width: 100%;
    border: 1px solid #e9ebef;
    border-radius: 15px;
    margin: ${p => p.margin || "0"};
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
`;

const ImagePreview = styled.img`
    width: 100%;
    height: 100%;
    object-fit: contain;
`;

const PromoCodeType = styled.select`
    height: 3.125rem;
    width: 100%;
    padding: 0.3rem 1.1rem;
    border: 1px solid #e9ebef;
    border-radius: 12px;
    font-family: Roboto, Helvetica, sans-serif;
    font-weight: 400;
    font-size: 0.938rem;
    color: #000;
    background-image: url(${DropdownIcon});
    background-repeat: no-repeat;
    background-position-x: 97%;
    background-position-y: 50%;
    appearance: none;

    &:focus {
        border: 1px solid #000;
        outline: none;
    }
`;

const SpinnerContainer = styled.div`
    width: 2.188rem;
`;

const ErrorMessage = styled.div`
    font-size: 11.5px;
    color: #ff7171;
    font-weight: 500;
    line-height: 12.5px;
    margin: 4px 0 0 4px;
`;

const FormPage = ({
    user,
    handleFormVisible,
    handleChangePerkType,
    formData,
    handleInputChange,
    handleDateChange,
    handleCreateOrUpdateWithImages,
    handlePromoCodeChange,
    promoCodeType,
    updatingUuid,
    handleChangeUserGroup,
    isFormSaving,
    formErrors,
    handleGiftPerkRecipients
}) => {
    const [perkImage, setPerkImage] = useState(null);
    const [promoCodeImage, setPromoCodeImage] = useState(null);
    const perkImageDropzoneRef = useRef();
    const promoCodeImageDropzoneRef = useRef();
    const [showUsersModal, setShowUsersModal] = useState(false);
    const [editGiftPerkUserType, setEditGiftPerkUserType] = useState("");

    useEffect(() => {
        if (promoCodeType === PROMO_CODE_TYPE.TEXT) {
            setPromoCodeImage(null);
        }
    }, [promoCodeType]);

    const isGeneral = (type) => type === PERK_TYPE.GENERAL;

    const isGiftPerk = (type) => type === PERK_TYPE.GIFT;

    const GreenCheck = ({ active }) => active && <img src={CheckIcon} style={{ height: "24px", position: "absolute", top: "10px", right: "6px" }} />;

    const perkIsForEmployees = () => formData.allEmployeesInPerk && !formData.allClientsInPerk;

    const perkIsForClients = () => !formData.allEmployeesInPerk && formData.allClientsInPerk;

    const perkIsForEmployeesAndClients = () => formData.allEmployeesInPerk && formData.allClientsInPerk;

    const getErrorMessage = input => {
        const error = formErrors.find(error => error.input === input);
        return error ? `* ${error.message}` : null;
    };

    const handleDrop = (acceptedFiles, imageType) => {
        if (!acceptedFiles || acceptedFiles.length === 0) return;

        const file = acceptedFiles[0];

        const validImageTypes = ["image/jpeg", "image/png"];
        if (!validImageTypes.includes(file.type)) {
            Toast.error("Unsupported file type. Images may be JPEG or PNG format.");
            return;
        }

        const reader = new FileReader();
        reader.onload = () => {
            const result = reader.result;
            if (imageType === "perkImage") {
                setPerkImage(result);
            } else if (imageType === "promoCodeImage") {
                setPromoCodeImage(result);
            } else {
                console.warn("Unknown image type:", imageType);
            }
        };
        reader.readAsDataURL(file);
    };

    const handleShowUsersModal = () => {
        setShowUsersModal(true);
    };

    const handleCloseUsersModal = () => {
        setShowUsersModal(false);
    };

    const handleEditGiftPerkUsers = userType => {
        setEditGiftPerkUserType(userType);
        setShowUsersModal(true);
    };

    const giftPerkSelectedEmployeesCount = formData.selectedCompanyRecipients.filter(user => !user.isClient).length;

    const giftPerkSelectedClientsCount = formData.selectedCompanyRecipients.filter(user => user.isClient).length;

    return (
        <>
            <PageHeadingLabel>
                { updatingUuid ? "Edit Perk" : "Create New Perk" }
            </PageHeadingLabel>

            <ExplainParagraphLabel size="0.875rem" width="100%" maxWidth="44rem">
                Design and assign exclusive perks for all employees and clients, or target specific groups. You can also create <br />
                gift perks for individual users. Customize your perks to reward and engage your team with unique benefits.
            </ExplainParagraphLabel>

            <FlexContainer
                color="#fff"
                style={{
                    maxWidth: "84.688rem",
                    border: "1px solid #8291b2",
                    borderRadius: "7px",
                    marginTop: "1.4rem"
                }}
            >
                <Grid container style={{ padding: "2.5rem 3rem 3.5rem" }} spacing={10}>
                    <Grid item md={6}>
                        <ColumnHeader>
                            Perk Information
                        </ColumnHeader>

                        <Grid container direction="column" spacing={1}>
                            <Grid item style={{ marginBottom: "13px" }}>
                                <InputLabel>
                                    Select Perk Type
                                </InputLabel>
                                <InputDescription>
                                    Select wether this perk will be available to most users or gifted to specific users.
                                </InputDescription>
                                <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                                    <Grid item>
                                        <PerkTypeOption active={isGeneral(formData.type)} onClick={() => handleChangePerkType(PERK_TYPE.GENERAL)} disabled={isFormSaving}>
                                            General Perk
                                            <GreenCheck active={isGeneral(formData.type)} />
                                        </PerkTypeOption>
                                    </Grid>
                                    <Grid item>
                                        <PerkTypeOption active={isGiftPerk(formData.type)} onClick={() => handleChangePerkType(PERK_TYPE.GIFT)} disabled={isFormSaving}>
                                            Gift Perk
                                            <GreenCheck active={isGiftPerk(formData.type)} />
                                        </PerkTypeOption>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {formData.type === PERK_TYPE.GENERAL && (
                                <Grid item md={10}>
                                    <InputLabel>
                                        Select Perk User Group
                                    </InputLabel>
                                    <InputDescription>
                                        Select to display this perk to employees, clients or both user groups.
                                    </InputDescription>
                                    <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                                        <Grid item>
                                            <PerkTypeOption active={perkIsForEmployees()} onClick={() => handleChangeUserGroup(USER_GROUP.EMPLOYEES)} disabled={isFormSaving}>
                                                All Employees
                                                <GreenCheck active={perkIsForEmployees()} />
                                            </PerkTypeOption>
                                        </Grid>
                                        <Grid item>
                                            <PerkTypeOption active={perkIsForClients()} onClick={() => handleChangeUserGroup(USER_GROUP.CLIENTS)} disabled={isFormSaving}>
                                                All Clients
                                                <GreenCheck active={perkIsForClients()} />
                                            </PerkTypeOption>
                                        </Grid>
                                        <Grid item>
                                            <PerkTypeOption active={perkIsForEmployeesAndClients()} onClick={() => handleChangeUserGroup(USER_GROUP.BOTH)} disabled={isFormSaving}>
                                                Both
                                                <GreenCheck active={perkIsForEmployeesAndClients()} />
                                            </PerkTypeOption>
                                        </Grid>
                                    </Grid>
                                    <ErrorMessage>{ getErrorMessage(API_ERROR_FIELD.RECIPIENTS) ?? "" }</ErrorMessage>
                                </Grid>
                            )}

                            {formData.type === PERK_TYPE.GIFT && (
                                <Grid item md={9}>
                                    <InputLabel>
                                        Select Gift Perk Users
                                    </InputLabel>
                                    <InputDescription>
                                        Select the users you with to gift this perk to.
                                    </InputDescription>

                                    {!formData.selectedCompanyRecipients.length && (
                                        <Button
                                            disabled={isFormSaving}
                                            onClick={handleShowUsersModal}
                                            backgroundColor="#000"
                                            color="#fff"
                                            width="14.5rem"
                                            height="2.688rem"
                                            padding="0"
                                            style={{
                                                borderRadius: "12px",
                                                marginBottom: "7.5px",
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                position: "relative"
                                            }}
                                        >
                                            <p style={{ marginRight: "1.688rem" }}>Add People</p>
                                            <img src={AddIcon} style={{ height: "2.25rem", position: "absolute", right: "59px", top: "6px" }} />
                                        </Button>
                                    )}

                                    {formData.selectedCompanyRecipients.length > 0 && (
                                        <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                                            <Grid item md={6}>
                                                <SelectedUsersContainer>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <RoundedIconContainer>
                                                            <img src={UsersIcon} style={{ height: "16px", width: "16px" }} />
                                                        </RoundedIconContainer>

                                                        <Text
                                                            size="0.875rem"
                                                            weight="500"
                                                            color="#000"
                                                        >
                                                            { formData.allEmployeesInPerk ? "All" : giftPerkSelectedEmployeesCount } { formData.allEmployeesInPerk || giftPerkSelectedEmployeesCount > 1 ? "Employees" : "Employee" }
                                                        </Text>
                                                    </div>

                                                    <Text
                                                        size="0.875rem"
                                                        weight="500"
                                                        color="#006cff"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => handleEditGiftPerkUsers(USER_GROUP.EMPLOYEES)}
                                                    >
                                                        Edit
                                                    </Text>
                                                </SelectedUsersContainer>
                                            </Grid>

                                            <Grid item md={6}>
                                                <SelectedUsersContainer>
                                                    <div style={{ display: "flex", alignItems: "center" }}>
                                                        <RoundedIconContainer>
                                                            <img src={UsersIcon} style={{ height: "16px", width: "16px" }} />
                                                        </RoundedIconContainer>

                                                        <Text
                                                            size="0.875rem"
                                                            weight="500"
                                                            color="#000"
                                                        >
                                                            { formData.allClientsInPerk ? "All" : giftPerkSelectedClientsCount } { formData.allClientsInPerk || giftPerkSelectedClientsCount > 1 ? "Clients" : "Client" }
                                                        </Text>
                                                    </div>

                                                    <Text
                                                        size="0.875rem"
                                                        weight="500"
                                                        color="#006cff"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => handleEditGiftPerkUsers(USER_GROUP.CLIENTS)}
                                                    >
                                                        Edit
                                                    </Text>
                                                </SelectedUsersContainer>
                                            </Grid>
                                        </Grid>
                                    )}
                                    <ErrorMessage>{ getErrorMessage(API_ERROR_FIELD.RECIPIENTS) ?? "" }</ErrorMessage>
                                </Grid>
                            )}

                            <Grid item md={9}>
                                <InputLabel>
                                    Select Perk Validation Dates
                                </InputLabel>
                                <InputDescription>
                                    Choose the start and end dates to define how long this perk will be available.
                                </InputDescription>
                                <FlexContainer align="center" style={{ flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap", columnGap: "24px" }}>
                                    <div style={{ textAlign: "center", marginLeft: "0px", flex: "1 1" }}>
                                        <ValidatedDateInput
                                            placeholder="Select start date"
                                            onChange={momentObj => handleDateChange("startDate", momentObj)}
                                            errors={[]}
                                            value={formData.startDate}
                                            disabled={false}
                                            showErrors={false}
                                            size={"0.938rem"}
                                        />
                                    </div>
                                    <div style={{ textAlign: "center", marginLeft: "0px", flex: "1 1" }}>
                                        <ValidatedDateInput
                                            placeholder="Select end date"
                                            onChange={momentObj => handleDateChange("endDate", momentObj)}
                                            errors={[]}
                                            value={formData.endDate}
                                            disabled={false}
                                            showErrors={false}
                                            size={"0.938rem"}
                                        />
                                    </div>
                                </FlexContainer>
                                <ErrorMessage>{ getErrorMessage(API_ERROR_FIELD.DATES) ?? "" }</ErrorMessage>
                            </Grid>

                            <Grid item md={9}>
                                <InputLabel>
                                    Company Name Of Perk
                                </InputLabel>
                                <InputDescription>
                                    Enter the name of the retailer or provider offering this perk.
                                </InputDescription>
                                <TextInput
                                    name="companyName"
                                    value={formData.companyName}
                                    placeholder="e.g., “Ray-Ban”"
                                    onChange={handleInputChange}
                                    disabled={isFormSaving}
                                />
                                <ErrorMessage>{ getErrorMessage(API_ERROR_FIELD.COMPANY) ?? "" }</ErrorMessage>
                            </Grid>

                            <Grid item md={9}>
                                <InputLabel>
                                    Perk Heading
                                </InputLabel>
                                <InputDescription>
                                    Enter a clear and engaging title for your perk.
                                </InputDescription>
                                <TextInput
                                    name="title"
                                    value={formData.title}
                                    placeholder="e.g., “20% Off Your Next Purchase”"
                                    onChange={handleInputChange}
                                    disabled={isFormSaving}
                                />
                                <ErrorMessage>{ getErrorMessage(API_ERROR_FIELD.HEADING) ?? "" }</ErrorMessage>
                            </Grid>

                            <Grid item md={9}>
                                <InputLabel>
                                    Perk Description
                                </InputLabel>
                                <InputDescription>
                                    Provide a brief description of the perk, outlining the benefits and details for users.
                                </InputDescription>
                                <TextArea
                                    name="description"
                                    value={formData.description}
                                    placeholder="e.g., “Enjoy exclusive savings on all products this month”"
                                    onChange={handleInputChange}
                                    disabled={isFormSaving}
                                />
                                <ErrorMessage>{ getErrorMessage(API_ERROR_FIELD.DESCRIPTION) ?? "" }</ErrorMessage>
                            </Grid>

                            <Grid item md={9}>
                                <InputLabel>
                                    Perk Company Website
                                </InputLabel>
                                <InputDescription>
                                    Enter the website URL for the company offering the perk.
                                </InputDescription>
                                <TextInput
                                    name="website"
                                    value={formData.website}
                                    placeholder="e.g., www.companyname.com"
                                    onChange={handleInputChange}
                                    disabled={isFormSaving}
                                />
                                <ErrorMessage>{ getErrorMessage(API_ERROR_FIELD.WEBSITE) ?? "" }</ErrorMessage>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item md={6}>
                        <Grid container spacing={3}>
                            <Grid item md={8}>
                                <ColumnHeader>
                                    Upload Perk Image
                                </ColumnHeader>

                                <InputLabel>
                                    Upload Image
                                </InputLabel>
                                <InputDescription size="0.938rem" color="#612684" fontWeight="400">
                                    Upload the company’s logo or an image representing the perk.
                                </InputDescription>
                                <ImageContainer
                                    ref={perkImageDropzoneRef}
                                    onDrop={(file) => handleDrop(file, "perkImage")}
                                >
                                    { (perkImage || formData.imageUrl) && (
                                        <ImagePreview src={perkImage || formData.imageUrl} />
                                    )}
                                    <Button
                                        backgroundColor="#000"
                                        color="#fff"
                                        border="1px solid #000"
                                        size="0.813rem"
                                        style={{
                                            borderRadius: "7px",
                                            boxShadow: "none",
                                            position: perkImage || formData.imageUrl ? "absolute" : "static",
                                            bottom: perkImage || formData.imageUrl ? "25px" : undefined
                                        }}
                                        disabled={isFormSaving}
                                    >
                                        { perkImage || formData.imageUrl ? "Change Image" : "Browse File" }
                                    </Button>
                                    { (!perkImage && !formData.imageUrl) && (
                                        <InputDescription color="#808080" size="0.938rem" margin="15px 0 0" align="center">
                                            Supported formats: JPG, PNG. Max size: 5MB. <br />
                                            Aspect Ratio 16:9 - 1920px x 1080px
                                        </InputDescription>
                                    )}
                                </ImageContainer>
                                <ErrorMessage>{ getErrorMessage(API_ERROR_FIELD.IMAGE) ?? "" }</ErrorMessage>
                            </Grid>

                            <Grid item md={8}>
                                <ColumnHeader>
                                    Perk Code
                                </ColumnHeader>

                                <InputLabel>
                                    Enter Text Code Or Upload Image Asset
                                </InputLabel>
                                <InputDescription color="#612684" fontWeight="500">
                                    Select to provide a promo/discount code, or upload an image asset 
                                    such as a QR code or barcode for this perk.
                                </InputDescription>
                                <PromoCodeType
                                    name="promoCodeType"
                                    onChange={handlePromoCodeChange}
                                    disabled={isFormSaving}
                                    value={promoCodeType}
                                >
                                    <option value={PROMO_CODE_TYPE.TEXT}>Promo/Discount Code</option>
                                    <option value={PROMO_CODE_TYPE.IMAGE}>Image (eg: QR code/Barcode)</option>
                                </PromoCodeType>

                                {promoCodeType === PROMO_CODE_TYPE.TEXT && (
                                    <>
                                        <InputDescription margin="13px 0 10px">
                                            Enter you perk promo/discount code here.
                                        </InputDescription>
                                        <TextInput
                                            name="promoCode"
                                            value={formData.promoCode}
                                            placeholder="e.g., WELCOME10"
                                            onChange={handleInputChange}
                                            disabled={isFormSaving}
                                        />
                                    </>
                                )}

                                {promoCodeType === PROMO_CODE_TYPE.IMAGE && (
                                    <ImageContainer
                                        margin="35px 0 0"
                                        ref={promoCodeImageDropzoneRef}
                                        onDrop={(file) => handleDrop(file, "promoCodeImage")}
                                    >
                                        { (promoCodeImage || formData.promoCodeImageUrl) && (
                                            <ImagePreview src={promoCodeImage || formData.promoCodeImageUrl} />
                                        )}
                                        <Button
                                            backgroundColor="#000"
                                            color="#fff"
                                            border="1px solid #000"
                                            size="0.813rem"
                                            style={{
                                                borderRadius: "7px",
                                                boxShadow: "none",
                                                position: promoCodeImage || formData.promoCodeImageUrl ? "absolute" : "static",
                                                bottom: promoCodeImage || formData.promoCodeImageUrl ? "25px" : undefined
                                            }}
                                            disabled={isFormSaving}
                                        >
                                            { promoCodeImage ? "Change Image" : "Browse File" }
                                        </Button>
                                        { (!promoCodeImage && !formData.promoCodeImageUrl) && (
                                            <InputDescription color="#808080" size="0.813rem" margin="15px 0 0" align="center">
                                                Supported formats: JPG, PNG. Max size: 5MB. <br />
                                                Aspect Ratio 16:9 - 1920px x 1080px
                                            </InputDescription>
                                        )}
                                    </ImageContainer>
                                )}
                                <ErrorMessage>{ getErrorMessage(API_ERROR_FIELD.CODE_TYPE) ?? "" }</ErrorMessage>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Divider />

                <FormButtonsContainer>
                    {isFormSaving ?
                        <SpinnerContainer>
                            <LottieLoadingIndicator height="1.563rem" width="1.563rem" containerHeight="1.563rem" margin="0" />
                        </SpinnerContainer>
                        :
                        <>
                            <Button
                                width="8.75rem"
                                height="2.375rem"
                                size="0.875rem"
                                backgroundColor="#fff"
                                color="#000"
                                border="1px solid #000"
                                style={{ borderRadius: "7px", boxShadow: "none" }}
                                onClick={handleFormVisible}
                            >
                                Cancel
                            </Button>
                            <Button
                                width="8.75rem"
                                height="2.375rem"
                                size="0.875rem"
                                backgroundColor="#006cff"
                                color="#fff"
                                border="1px solid #006cff"
                                style={{ borderRadius: "7px", boxShadow: "none" }}
                                onClick={() => handleCreateOrUpdateWithImages(perkImage, promoCodeImage)}
                            >
                                Save
                            </Button>
                        </>
                    }
                </FormButtonsContainer>
            </FlexContainer>

            {showUsersModal && (
                <UsersModal
                    user={user}
                    showUsersModal={showUsersModal}
                    handleCloseUsersModal={handleCloseUsersModal}
                    handleGiftPerkRecipients={handleGiftPerkRecipients}
                    userType={editGiftPerkUserType}
                    selectedRecipients={formData.selectedCompanyRecipients}
                />
            )}
        </>
    );
};

export default FormPage;