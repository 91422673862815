import { useState, useEffect } from "react";
import axios from "axios";
import { Toast } from "src/components";
import { API_URL } from "src/scenes/App";

const useGetDigitalMemberCard = (token) => {
    const controller = new AbortController();
    const [digitalMembershipCards, setDigitalMembershipCards] = useState([]);
    const [isFetchingDigitalMemberCard, setIsFetchingDigitalMemberCard] = useState(false);

    const employeeCardInfo = {
        cardId: "12345678",
        fname: "Sally",
        lname: "Thomson",
        titleOrClientType: "Marketing Manager",
        memberSince: "31 Dec 2024"
    };

    const clientCardInfo = {
        cardId: "12345678",
        fname: "Harry",
        lname: "Anderson",
        titleOrClientType: "Member",
        memberSince: "31 Dec 2024"
    };

    const fetchDigitalMemberCard = async (silentLoading = false) => {

        if (!silentLoading) {
            setIsFetchingDigitalMemberCard(true);
        }

        try {
            let response;
            response = await axios.get(`${API_URL}/membershipCard/company/settings`, {
                signal: controller.signal,
                headers: {
                    Authorization: "Bearer " + token
                }
            });
            
            setDigitalMembershipCards(response.data);
            
        } catch (error) {
            Toast.error("Unable to fetch digital membership cards. Please try again later or contact support if the issue persists.");
        } finally {
            setIsFetchingDigitalMemberCard(false);
        }
    };

    useEffect(() => {
        fetchDigitalMemberCard(); 
    }, [token]);

    const getCardTemplateByUserType = (isTemplateForClientPreview) => {
        const templateByUserType = isTemplateForClientPreview ? digitalMembershipCards.chosenCardTemplateForClient : digitalMembershipCards.chosenCardTemplateForEmployee;
        const templateBackgroundColor = templateByUserType ? templateByUserType.customBackgroundColor : digitalMembershipCards.businessBackgroundColor;
        const templateTextColor = templateByUserType ? templateByUserType.textColor : digitalMembershipCards.cardTextColor;
        const templateHeroImage = templateByUserType ? templateByUserType.membershipCardTemplateImageUrl : undefined;
        const templateHeroUuid = templateByUserType ? templateByUserType.membershipCardTemplateUuid : null;
        
        return {
            logo: digitalMembershipCards.businessLogo,
            backgroundColor: templateBackgroundColor,
            textColor: templateTextColor,
            cardHeroImage: templateHeroImage,
            cardHeroUuid: templateHeroUuid
        };
    };

    return { 
        digitalMembershipCards, 
        getCardTemplateByUserType,
        isFetchingDigitalMemberCard, 
        fetchDigitalMemberCard,
        employeeCardInfo,
        clientCardInfo
    };
};

export default useGetDigitalMemberCard;