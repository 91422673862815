import React from "react";
import styled from "styled-components";
import { FlexContainer } from "src/components";
import Skeleton from "./Skeleton";

const TextDescription = styled.div`
    text-align: left;
    font-size: 15px;
    font-family: Roboto, Helvetica, sans-serif;
    font-weight: normal;
    color: #000000;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    display: -webkit-box;
    overflow: hidden;
`;

const Card = styled.div`
    width: 100%;
    height: 140px;
    overflow-y: hidden;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #8291B2;
    border-radius: 18px;
    object-fit: contain;
`;
    
const SkeletonContainer = styled.div`
    margin: .5rem 1rem;
`;

const NoticeboardCard = (props) => {
    const { content, loading, cardType, ...otherProps } = props;

    return (
        <>
        
            <Card {...otherProps}>
                {loading ?
                    <SkeletonContainer>
                        <Skeleton variant="Text" width="50px" height="33px" animation="wave" />
                    </SkeletonContainer>
                    : (cardType === "preview") ? props.children :
                        <FlexContainer
                            height="100%"
                            style={{ padding: "1em" }}>
                            <TextDescription>{content}</TextDescription>
                        </FlexContainer>
                }
            </Card>
        </>
    );
};
export default NoticeboardCard;