export const PERK_TYPE = {
    GENERAL: "general",
    GIFT: "gift"
};

export const PERK_STATUS = {
    ACTIVE: "active",
    SCHEDULED: "scheduled",
    EXPIRED: "expired",
};

export const DEFAULT_FORM_STATE = {
    imageUrl: null,
    type: PERK_TYPE.GENERAL,
    allEmployeesInPerk: false,
    allClientsInPerk: false,
    selectedCompanyRecipients: [],
    startDate: "",
    endDate: "",
    companyName: "",
    title: "",
    description: "",
    website: "",
    promoCode: "",
    promoCodeImageUrl: null
};

export const PROMO_CODE_TYPE = {
    TEXT: "text",
    IMAGE: "image"
};

export const USER_GROUP = {
    EMPLOYEES: "employees",
    CLIENTS: "clients",
    BOTH: "both"
};

export const API_ERROR_FIELD = {
    IMAGE: "image",
    RECIPIENTS: "selectedRecipients",
    DATES: "dates",
    COMPANY: "companyName",
    HEADING: "title",
    DESCRIPTION: "description",
    CODE_TYPE: "promoCodeType",
    WEBSITE: "website"
};