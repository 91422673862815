import React, { useState } from "react";
import CardHeroImageDropzone from "./CardHeroImageDropZone";
import { UPLOADED_CUSTOM_UUID } from "src/scenes/DigitalMemberCard/utils/const";

const UploadHeroImage = ({ setCardPreviewHeroImageData, selectedHeroUuid, assignHeroTemplate, deleteBusinessImg }) => {
    const [customUploadedHeroImage, setCustomUploadedHeroImage] = useState({
        uuid: UPLOADED_CUSTOM_UUID,
        imageUrl: null    
    });

    const handleUploadedCallback = (heroImageUrl) => {
        const customisedHeroImageDataForUpload = { 
            uuid: customUploadedHeroImage.uuid, imageUrl: heroImageUrl 
        };

        setCustomUploadedHeroImage(customisedHeroImageDataForUpload);
        assignHeroTemplate(customisedHeroImageDataForUpload);
        setCardPreviewHeroImageData(customisedHeroImageDataForUpload);
    };

    const handleRemoveUploaded = () => {
        if (selectedHeroUuid !== customUploadedHeroImage.uuid) {
            return;
        }
        
        const customisedHeroImageDataForUpload = { 
            uuid: customUploadedHeroImage.uuid, imageUrl: null 
        };

        setCustomUploadedHeroImage(customisedHeroImageDataForUpload);
        setCardPreviewHeroImageData(null);
        assignHeroTemplate(null);
    };

    return (
        <CardHeroImageDropzone 
            customUploadedHeroImage={customUploadedHeroImage}
            uploadedCallback={ (heroImageUrl) => handleUploadedCallback(heroImageUrl) } 
            removedUploadedCallback={() => handleRemoveUploaded() }
            selectedHeroUuid={selectedHeroUuid}
            customUploadedUuid={customUploadedHeroImage.uuid}
        />
    );
};

export default UploadHeroImage;