import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { FlexContainer, 
    mobile, desktop, tablet, ExplainParagraphLabel, PageHeadingLabel 
} from "src/components";
import { PageType } from "src/scenes/EmployeeList/EmployeeList";

const Card = styled.div`
    background-color: #FFF;
    color: #000;
    border: 1px solid #8291B2;
    max-width: 250px;
    height: auto;
    min-height: ${p => p.minHeight || "6.875rem"};
    border-radius: 12px;
    padding: 1.4375rem 1.5625rem 1.4375rem 1.8125rem;
    box-sizing: border-box;
    position: relative;

    ${tablet`
        margin-top: 1rem;
    `};

    ${mobile`
        margin-top: 1rem;
    `};

    ${desktop`
        margin-top: 0;
    `};
`;

const Label = styled.div`
    font-weight: 500;
    font-size: 0.875rem;
`;

const Number = styled.div`
    font-size: 2rem;
    font-weight: 700;
    margin: 0;
    text-align: left;
`;

const SelectInput = styled.select`
    height: 3.188rem !important;
    width: 19.7rem !important;

    &:focus {
        outline: none;
    }
`;

const FilterContainer = styled.div`
    display: flex;
    align-items: end;
`;

function MyUserListHeader(props) {
    const { fetching, isClient, existedUsers, employeeArray, totalUsers, onFilterChange } = props;
    const [licenseRemaining, setLicenseRemaining] = useState(totalUsers - existedUsers);
    const pageType = () => { 
        return isClient ? PageType.CLIENT : PageType.EMPLOYEE; 
    };

    useEffect(() => {
        setLicenseRemaining(totalUsers - existedUsers);
    }, [totalUsers, existedUsers]);

    return (
        <FlexContainer direction="row" justifyContent="space-between" marginBottom="1.625rem"
            style={{ maxWidth: "1512px" }}
        >
            <div style={{ marginRight: "6.1875rem" }}>
                <PageHeadingLabel>
                    My User Lists
                </PageHeadingLabel>
                <ExplainParagraphLabel width="100%" size="0.875rem" maxWidth="55rem">
                    Invite Employees, Clients or Support Professionals to your business app here. You can add users manually or 
                    use our template to upload a CSV spreadsheet. Users will receive a code via email or sms invite once sent. You 
                    can set certain permissions for individual users to best manage your app.
                </ExplainParagraphLabel>
            </div>

            { fetching === 0 &&
                <FlexContainer direction="row" margin="2rem 0 0.5rem"
                    width="49.375rem"
                    justifyContent="space-between"
                    gap="1em"
                >
                    <div className="row" style={{ margin: 0 }}>
                        <FilterContainer className="field">
                            <label>Filter:</label>

                            <SelectInput 
                                value={pageType()}
                                onChange={ (e) => onFilterChange(e) }
                            >
                                <option value={PageType.EMPLOYEE}>Employees ({ isClient ? existedUsers - employeeArray.length : employeeArray.length })</option>
                                <option value={PageType.CLIENT}>Clients ({ isClient ? employeeArray.length : existedUsers - employeeArray.length })</option>
                            </SelectInput>
                        </FilterContainer>
                    </div>
                    <Card>
                        <Label>Total Licenses Remaining</Label>
                        <Number>{ Math.max(0, licenseRemaining) }</Number>
                    </Card>
                </FlexContainer>
            }
        </FlexContainer>
    );
}

export default MyUserListHeader;