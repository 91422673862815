import { useEffect, useState, useMemo } from "react";
import { Toast } from "src/components";
import MatrixChat from "src/scenes/Chat/models/MatrixChat";

export const useChatMessage = (activeChatList, currentMatrixUserId) => {
    
    const [toggleDropdown, setToggleDropdown] = useState(false);
    const [profileModalIsShown, setProfileModalIsShown] = useState(false);
    const [profileModalShowsCurrentUser, setProfileModalShowsCurrentUser] = useState(false);
    const [profileModalUser, setProfileModalUser] = useState(null);
    const [profileModalUserLeadershipRoles, setProfileModalUserLeadershipRoles] = useState(null);
    const [encryptionComingSoonModalIsShown, setEncryptionComingSoonModalIsShown] = useState(false);

    const [openedMatrixChat, setOpenedMatrixChat] = useState(undefined);
    const [isFetchingRoomTimeline, setIsFetchingRoomTimeline] = useState(false);
    const [roomTimeline, setRoomTimeline] = useState([]);
    const [newMatrixEvent, setNewMatrixEvent] = useState(null);

    useEffect(() => {
        if (!activeChatList) {
            return;
        }

        if (activeChatList.length === 0) {
            setOpenedMatrixChat(null);
            return;
        }
        
        if (openedMatrixChat === null || openedMatrixChat === undefined) {
            //auto-open index 0 of Active chat.
            const firstActiveChat = activeChatList[0];
            console.warn("No chat opened, auto-selecting the first one...", firstActiveChat);
            setOpenedMatrixChat(firstActiveChat);
            return;
        }

        const isOpenedChatInActiveChat = activeChatList.find((matrixChat) => matrixChat.matrixRoomId === openedMatrixChat.matrixRoomId);
        if (!isOpenedChatInActiveChat) {
            //auto-open index 0 of Active chat triggered by onChange
            const firstActiveChat = activeChatList[0];
            setOpenedMatrixChat(firstActiveChat);
        }
        
        //do nothing
    }, [activeChatList]);

    const matrixRoomId = useMemo(() => openedMatrixChat?.matrixRoomId, [openedMatrixChat]);

    useEffect(() => {
        if (openedMatrixChat instanceof MatrixChat) {
            refreshRoomTimeline(openedMatrixChat);
        }
    }, [matrixRoomId]);

    useEffect(() => {
        if (newMatrixEvent === null) {
            return;
        }

        if (openedMatrixChat instanceof MatrixChat && openedMatrixChat.matrixRoomId === newMatrixEvent.getRoomId()) {
            refreshRoomTimeline(openedMatrixChat);
            setNewMatrixEvent(null);
            return;
        }

        console.warn("Opened Room and MatrixEvent Room doesn't match", openedMatrixChat?.matrixRoomId, newMatrixEvent.getRoomId());
    }, [newMatrixEvent]);

    const updateOpenedMatrixChatWithFormData = (apiResponseChat) => {
        if (matrixRoomId !== apiResponseChat.matrixRoomId) {
            return;
        }
        openedMatrixChat.updateFromApiResponseData(apiResponseChat);
        setOpenedMatrixChat(openedMatrixChat);
    };

    const upsertRoomTimeline = (matrixEvent) => {
        setNewMatrixEvent(matrixEvent);
    };

    const refreshRoomTimeline = async (matrixChat) => {
        setIsFetchingRoomTimeline(true);
        try {
            const events = await matrixChat.fetchMatrixRoomTimeline();
            setRoomTimeline(events);
        } catch (error) {
            console.error(error);
            Toast.error("Fetching messages encountered an error.");
        } finally {
            setIsFetchingRoomTimeline(false);
        }
    };

    const displayOtherUserProfileModal = () => {
        if (openedMatrixChat.isGroupChat) {
            return Toast.error("This feature is not available on group chats.");
        }
        const otherChatUser = openedMatrixChat.users.find(user => user.matrixUserId !== currentMatrixUserId);
        displayProfileModal(otherChatUser.employeeDetails, otherChatUser.leadershipRoles, false);
    };

    /**
     * @param {object} userToShow
     * @param {array} userLeadershipRoles
     * @param {boolean} isCurrentUser
     */
    const displayProfileModal = (userToShow, userLeadershipRoles, isCurrentUser) => {
        setProfileModalUser(userToShow);
        setProfileModalUserLeadershipRoles(userLeadershipRoles);
        setProfileModalIsShown(true);
        setProfileModalShowsCurrentUser(isCurrentUser);
    };

    const closeProfileModal = () => {
        setProfileModalIsShown(false);
    };

    return {
        openedMatrixChat,
        setOpenedMatrixChat,
        updateOpenedMatrixChatWithFormData,
        upsertRoomTimeline,
        isFetchingRoomTimeline, 
        roomTimeline, 
        toggleDropdown,
        setToggleDropdown,
        displayProfileModal,
        displayOtherUserProfileModal,
        closeProfileModal,
        profileModalIsShown,
        profileModalShowsCurrentUser,
        profileModalUser,
        profileModalUserLeadershipRoles,
        encryptionComingSoonModalIsShown,
        setEncryptionComingSoonModalIsShown
    };
};