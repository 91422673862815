import React from "react";
import DisplayConnectionIcon from "./components/DisplayConnectionIcon";
import { Container, FlexRow, SocialMediaButton } from "./AccountLinkingPage";
import { Text } from "src/components";

const SocialMediaRow = (props) => {
    const { logo, pageItem, isExpiredPage } = props;

    return (
        <Container>
            <FlexRow>
                <SocialMediaButton width="267px" backgroundColor="#fff" color="#000" border="1px solid #8291B2;">
                    <img src={logo} alt="Social Platform Icon" style={{ marginRight: "12px" }} />
                    {pageItem.pageName}
                </SocialMediaButton>
                { DisplayConnectionIcon(isExpiredPage) }
                {isExpiredPage && <Text size="14px" weight="500" color="black">&nbsp;Expired</Text>}
            </FlexRow>
        </Container>
    );
};

export default SocialMediaRow;
