
import styled from "styled-components";

const Initials = styled.div`
    font-family: ${p => p.family || "Roboto, Helvetica, sans-serif"};
    font-size: 16px;
    font-weight: 600;
    color: ${p => p.color || "black"};
    border-radius: 50%;
    background-color: ${p => p.backgroundColor || "white"};
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 20px;
    background-image: ${ p => p.profilePictureUrl ? `url(${p.profilePictureUrl})` : "none"};
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: ${ p => p.profilePictureUrl ? "52px" : "42px" }; 
    height: ${ p => p.profilePictureUrl ? "52px" : "42px" };
    border: ${ p => p.profilePictureUrl && "2px solid #FFFFFF" };

    @media (max-width: 1280px) {
        width: 42px;
        height: 42px;
        margin-left: 20px;
        margin-right: 0px;
    }
`;

export default Initials;
