import React from "react";
import { Text } from "src/components";

const NoAccountsLinked = () => {
    return (
        <div style={{ margin: "30px 0px 0px 0px" }}>
            <Text size="20px" weight="700" align="left" margin="0 0 20px 75px">No Accounts Linked</Text>
            <Text align="left" weight="400" size="16px" margin="0 40px 20px 75px">
                You haven’t linked any social accounts yet. Connect your Facebook, Instagram, or LinkedIn accounts to start sharing your Noticeboard posts directly to your social media platforms.
            </Text>    
        </div>
    );
};

export default NoAccountsLinked;

