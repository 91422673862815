import React, { useState, useRef, useEffect } from "react";
import { Button, FormField, Text, LottieLoadingIndicator, FlexContainer, Toast, Checkbox } from "src/components";
import { Dialog, Box, Grid, Divider } from "@material-ui/core";
import styled, { css } from "styled-components";
import AttachIcon from "src/img/new/attach-image.svg";
import "react-datepicker/dist/react-datepicker.css";
import Dropzone from "react-dropzone";
import { FILTER_NOTICE } from "src/scenes/Noticeboard/Noticeboard";
import { getAssetUrl } from "./NoticeboardContent";
import CheckMark from "src/img/check@2x.png";
import $ from "jquery";
import CalendarIcon from "src/img/survey/calendar.png";
import ImageIcon from "src/img/notice/image.svg";
import VideoIcon from "src/img/notice/video.svg";
import DocumentIcon from "src/img/notice/document.svg";
import RemoveIcon from "src/img/notice/remove-icon.svg";
import moment from "moment";
import NoticeModalSocialPlatform from "./NoticeModalSocialPlatformItems";
import { IsFeatureReady } from "src/constants/features";

const isFirefox = navigator.userAgent.toLowerCase().includes("firefox");

const StyledLabel = styled.label`
    font-family: Roboto, Helvetica, sans-serif;
    font-size: ${p => p.size || "1rem"};
    font-weight: 700;
    color: #000;
    display: inline-block;
    margin-bottom: 0.313rem;
`;

const StyledTitleInput = styled(FormField)`
    height: 3.125rem;
    border: 1px solid #8291b2;
    border-radius: 12px;
    text-align: left;
    font-family: Roboto, Helvetica, sans-serif;
    font-weight: 400;
    font-size: 1rem;
    color: #000;

    ::placeholder {
        text-align: left;
        color: #000;
        font-family: Roboto, Helvetica, sans-serif;
    }
`;

const StyledDateInput = styled(FormField)`
    background-size: 24px 24px; 
    background-repeat: no-repeat;
    background-position: right 22px center;
    ${p => (p.scheduleDateSetInputTypeAs === "text" || !isFirefox) && `background-image: url(${CalendarIcon})`}
`;

const TextArea = styled.textarea`
    font-family: Roboto, Helvetica, sans-serif;
    color: #000;
    font-size: 1rem;
    font-weight: 400;
    height: 15rem;
    width: 90%;
    border: 1px solid #8291b2;
    border-radius: 12px;
    padding: 10px 20px 10px 20px;
    margin: 0.313rem 0;
    resize: none;

    &:focus {
        outline: none;
        border: 1px solid #000;
    }

    ::placeholder {
        text-align: left;
        color: #000;
        font-family: Roboto, Helvetica, sans-serif;
        font-size: 0.875rem;
    }
`;

const RadioButton = styled.button`
    position: relative;
    width: 14rem;
    height: 3.125rem;
    border: 1px solid #8291b2;
    border-radius: 12px;
    text-align: center;
    font-size: 1rem;
    font-family: Roboto, Helvetica, sans-serif;
    font-weight: 400;
    background-color: #fff;
    cursor: pointer;

    ${(props) =>
        props.active &&
        css`
            background-color: #edf4ff;
            color: #000;
            font-weight: 700;
        `}
`;

const StyledDropzone = styled(Dropzone)`
    height: 12.938rem;
    border: 1px solid #8291b2;
    border-radius: 12px;
    margin: 0.313rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* Ensure image doesn't overflow the container */
`;

const StyledAttachmentDropzone = styled(StyledDropzone)`
    align-items: normal;
    min-height: 8rem;
    height: 100%;
    width: 100%;
    border: none;
`;

const ImagePreview = styled.img`
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain; /* Image will fit inside container without stretching */
`;

const FileInput = styled.input`
    display: none;
`;

const ErrorMessage = styled.span`
    font-size: 0.844rem;
    color: #ff7171;
`;

const Description = styled.div`
    font-weight: 400;
    font-size: 0.813rem;
    margin-bottom: 10px;
`;

const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;
`;

const SEND_TO = {
    EMPLOYEES: "employees",
    CLIENTS: "clients",
    EMPLOYEES_AND_CLIENTS: "employees_and_clients"
};

const ERROR_LIST = {
    SEND_TO: { "input": "visibleTo", "message": "*You must select 1 option." },
    SUBJECT: { "input": "subject", "message": "*Please enter your post subject here." },
    MESSAGE: { "input": "message", "message": "*Please enter your post message here." },
    SCHEDULE_DATE: { "input": "scheduleDate", "message": "*Please provide a post date or you can post it now" },
};

const NoticeModal = ({
    showNoticeModal,
    handleCloseModal,
    noticeData,
    filterOption,
    handleCreateOrUpdate,
    isFormLoading,
    setShowDeleteDialog,
    responseMessage,
    emptyResponseMessage,
    retrieveLinkedAccounts,
    linkedAccounts,
    linkedAccountsFetched,
    hasSocialMediaPostingRole
}) => {  
    const DEFAULT_ADD_NEW_FORM_STATE = {
        visibleToEmployees: filterOption === FILTER_NOTICE.EMPLOYEE,
        visibleToClients: filterOption === FILTER_NOTICE.CLIENT,
        subject: null,
        message: null,
        imageUrl: null,
        postType: "text",
        scheduleDate: "",
        isPostNow: false,
        isActive: false
    };

    const [formData, setFormData] = useState(noticeData === null ? DEFAULT_ADD_NEW_FORM_STATE : {
        subject: noticeData.subject,
        message: noticeData.message,
        imageUrl: getAssetUrl(noticeData),
        postType: noticeData.postType,
        scheduleDate: noticeData.scheduleDate !== null ? noticeData.scheduleDate : "",
        isPostNow: false,
        isActive: noticeData.isActive
    });

    const [errors, setErrors] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [mediaHasChanged, setMediaHasChanged] = useState(false); 
    const [scheduleDateSetInputTypeAs, setScheduleDateSetInputTypeAs] = useState(formData.scheduleDate === "" ? "text" : "datetime-local"); 
    const dropzoneRef = useRef();
    const dropzoneAttachmentRef = useRef();
    const [uploadedAttachmentFiles, setUploadedAttachmentFiles] = useState([]);
    const [deleteAttachmentFiles, setDeleteAttachmentFiles] = useState([]); 

    useEffect(() => {
        if (hasSocialMediaPostingRole && IsFeatureReady.accountLinking()) {
            retrieveLinkedAccounts();
        }
    }, []);
    
    const handleInputChange = (e, key) => {
        // Remove input's error message
        removeErrorMessage(key);
        const updatedNoticeData = { ...formData, [key]: e.target.value };
        setFormData(updatedNoticeData);
    };

    const handleFormSubmit = () => {
        emptyResponseMessage(); 
        if (hasFormErrors()) {
            return;
        }
        // create/update contact
        if (selectedImage && formData.imageUrl !== selectedImage) {
            const imageFile = dataURLtoFile(selectedImage, "contact-image");
            handleCreateOrUpdate(formData, imageFile, setErrors, uploadedAttachmentFiles, deleteAttachmentFiles);
        } else {
            handleCreateOrUpdate(formData, null, setErrors, uploadedAttachmentFiles, deleteAttachmentFiles);
        }
    };

    const dataURLtoFile = (dataUrl, filename) => {
        const arr = dataUrl.split(",");
        const mime = arr[0].match(/:(.*?);/)[1];
        const bStr = atob(arr[1]);
        let n = bStr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bStr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    };

    const handleVisibleTo = type => {
        // Remove input's error message
        removeErrorMessage(ERROR_LIST.SEND_TO.input);

        if (type === SEND_TO.EMPLOYEES) {
            setFormData({ ...formData, visibleToEmployees: true, visibleToClients: false });
        } else if (type === SEND_TO.CLIENTS) {
            setFormData({ ...formData, visibleToEmployees: false, visibleToClients: true });
        } else if (type === SEND_TO.EMPLOYEES_AND_CLIENTS) {
            setFormData({ ...formData, visibleToEmployees: true, visibleToClients: true });
        }
    };

    const hasFormErrors = () => {
        let newErrors = [];

        if (noticeData === null) {
            if (!formData.visibleToEmployees && !formData.visibleToClients) {
                newErrors = [...newErrors, ERROR_LIST.SEND_TO];
            }
        }
        if (!formData.subject) {
            newErrors = [...newErrors, ERROR_LIST.SUBJECT];
        }

        if (!formData.message) {
            newErrors = [...newErrors, ERROR_LIST.MESSAGE];
        }

        if (!formData.isActive) { /** Noticeboard notification already sent, don't reschedule so no need to validate*/
            if (!formData.scheduleDate && formData.isPostNow === false) {
                newErrors = [...newErrors, ERROR_LIST.SCHEDULE_DATE];
            }
        }

        setErrors(newErrors);
        return newErrors.length > 0;
    };

    const removeErrorMessage = key => {
        setErrors(prevErrors => prevErrors.filter(error => error.input !== key));
    };

    const hasErrorType = type => {
        return errors.find(error => error.input === type.input);
    };

    const handleDrop = acceptedFiles => {
        const validImageTypes = ["image/jpeg", "image/png"];
        const validVideoTypes = ["video/mp4", "video/webm", "video/ogg"]; // Add more as needed

        if (acceptedFiles && acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            const reader = new FileReader();
            reader.onload = () => {
                if (validImageTypes.includes(file.type)) {
                    setSelectedImage(reader.result);
                    setMediaHasChanged(true);
                    setFormData({ ...formData, postType: "image" });
                } else if (validVideoTypes.includes(file.type)) {
                    getVideoDimensions(reader);
                } else {
                    Toast.error("Unsupported file type. Images may be JPEG or PNG format, and videos may be MP4, WebM, or OGG format.");
                    return;
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const handleDropAttachments = acceptedFiles => {
        acceptedFiles.map(file => {
            // Check for existing filename with potential numbering
            const existingFile = uploadedAttachmentFiles.find(
                uploadedFile => uploadedFile.name === file.name
            );

            if (existingFile) {
                Toast.error("Similar filename found");
                return;
            }

            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                setUploadedAttachmentFiles(prevFiles => [
                    ...prevFiles, { 
                        name: file.name, 
                        type: file.type, 
                        preview: dataURLtoFile(reader.result, file.name)
                    }
                ]);
            };
        });
    };

    // get the video height and width
    // using jquery to avoid getting the height from the
    // video element on the page, which may not be rendered yet
    function getVideoDimensions(reader) {
        const dataUrl = reader.result;
        const videoId = "videoMain";
        const $videoEl = $("<video id=" + videoId + "></video>");
        $videoEl.attr("src", dataUrl);

        const videoTagRef = $videoEl[0];
        videoTagRef.addEventListener("loadedmetadata", function(e) {
            const width = videoTagRef.videoWidth;
            const height = videoTagRef.videoHeight;

            // if both are valid, proceed
            if (width > 0 && height > 0) {
                getAspectRatio(reader, width, height);
            } else {
                Toast.error("Cannot read video metadata.");
            }
        });
    }

    // get the aspect ratio
    // used as a callback from the get dimensions function
    const getAspectRatio = (reader, width, height) => {
        // define ratio constants
        const squareRatio = 1.0;
        const widescreenRatio = 16.0 / 9.0;

        // aspect ratio
        let aspectRatio = width / height;

        // check the aspect ratio
        if (closelyMatchesNumber(squareRatio, aspectRatio)) {
            // square
            setSelectedImage(reader.result);
            setMediaHasChanged(true);
            setFormData({ ...formData, postType: "video", aspectRatio: "square" });
        } else if (closelyMatchesNumber(widescreenRatio, aspectRatio)) {
            // widescreen
            setSelectedImage(reader.result);
            setMediaHasChanged(true);
            setFormData({ ...formData, postType: "video", aspectRatio: "widescreen" });
        } else {
            // not a supported aspect ratio
            // discard
            Toast.error("The uploaded video has an aspect ratio which is not supported");
            Toast.error("Please upload either 16:9 or 1:1 videos");
        }
    };
    
    // determine whether two numbers match
    // one another
    function closelyMatchesNumber(correctValue, numberToEvaluate) {
        const marginOfError = 0.01;

        let lowEnd = correctValue - marginOfError * numberToEvaluate;
        let highEnd = correctValue + marginOfError * numberToEvaluate;

        return numberToEvaluate >= lowEnd && numberToEvaluate <= highEnd;
    }

    const handleBrowseClick = () => {
        if (dropzoneRef.current) {
            dropzoneRef.current.open();
        }
    };

    const handleBrowseClickAttachment = (event) => {
        event.stopPropagation();

        if (dropzoneAttachmentRef.current) {
            dropzoneAttachmentRef.current.open();
        }
    };

    const handleRemoveAttachment = () => {
        setSelectedImage(null); 
        setFormData({ ...formData, postType: "text", imageUrl: null });
        setMediaHasChanged(true);
    };

    const handlePostNowChange = () => {
        const boolVal = !formData.isPostNow;

        if (boolVal) {
            setFormData({ ...formData, 
                isPostNow: boolVal,
                scheduleDate: ""
            });
        } else {
            setFormData({ ...formData, 
                isPostNow: boolVal
            });
        }
    };

    const onToggleSocialItem = (pageUuid) => {
        const currentSelected = formData.selectedSocialsToPostUuids || [];
        const updatedSelected = currentSelected.includes(pageUuid)
            ? currentSelected.filter(id => id !== pageUuid)
            : [...currentSelected, pageUuid];


        const updatedFormData = updatedSelected.length > 0
            ? { ...formData, selectedSocialsToPostUuids: updatedSelected }
            : formData;
        setFormData(updatedFormData);
    };

    const hasMedia = formData.imageUrl !== null || selectedImage !== null;
    const GreenCheck = ({ active }) => active && <img src={CheckMark} alt="check mark" style={{ height: "30px", position: "absolute", top: "10px", right: "5px" }} />;
    const isNewRecordOrScheduledPost = noticeData === null || !noticeData.isActive;
    const datePostedFormat = (dateToUse) => {
        return (
            <div>
                {moment(dateToUse).format("Do MMMM YYYY")} {moment(dateToUse).format("hh:mma")}
            </div>
        );
    };

    return (
        <Dialog
            open={showNoticeModal}
            maxWidth="md"
            scroll="body"
            PaperProps={{
                style: {
                    width: 980,
                    height: "auto",
                    borderRadius: 15,
                    overflow: "auto"
                }
            }}
        >
            <Box sx={{ padding: "1.563rem 3.125rem 0.625rem" }}>
                <Text size="2.313rem" weight="700" align="left" margin="0">
                    { noticeData !== null ? "Edit Post" : "New Post" }
                </Text>
            </Box>
            <Divider />
            <Box sx={{ padding: "1.25rem 3.125rem" }}>

                { noticeData === null &&
                    <>
                        <StyledLabel style={{ marginBottom: "0.625rem" }}>Send Post To</StyledLabel><Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={3} style={{ marginBottom: "0.625rem" }}>
                            <Grid item>
                                
                                <RadioButton active={formData.visibleToEmployees} onClick={() => noticeData === null && handleVisibleTo(SEND_TO.EMPLOYEES)}>
                                    Employees
                                    <GreenCheck active={formData.visibleToEmployees} />
                                </RadioButton>
                            </Grid>
                            <Grid item>
                                
                                <RadioButton active={formData.visibleToClients} onClick={() => noticeData === null && handleVisibleTo(SEND_TO.CLIENTS)}>
                                    Clients
                                    <GreenCheck active={formData.visibleToClients} />
                                </RadioButton>
                            </Grid>
                            <Grid item>
                                <RadioButton active={formData.visibleToEmployees && formData.visibleToClients} onClick={() => noticeData === null && handleVisibleTo(SEND_TO.EMPLOYEES_AND_CLIENTS)}>
                                    Employees & Clients
                                    <GreenCheck active={formData.visibleToEmployees && formData.visibleToClients} />
                                </RadioButton>
                            </Grid>
                            {hasErrorType(ERROR_LIST.SEND_TO) && <ErrorMessage style={{ marginLeft: "12px" }}>{ERROR_LIST.SEND_TO.message}</ErrorMessage>}
                        </Grid>
                    </>
                }
                <Grid container spacing={2} style={{ marginBottom: "0.625rem" }}>
                    <Grid item sm={12} md={12}>
                        <StyledLabel>Subject</StyledLabel>
                        <StyledTitleInput
                            value={formData.subject ?? ""}
                            placeholder="Enter your post subject here"
                            onChange={e => handleInputChange(e, "subject")}
                        />
                        {hasErrorType(ERROR_LIST.SUBJECT) && <ErrorMessage>{ERROR_LIST.SUBJECT.message}</ErrorMessage>}
                        {responseMessage.SUBJECT_INVALID.message.length > 0 && <ErrorMessage>{responseMessage.SUBJECT_INVALID.message}</ErrorMessage>}
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginBottom: "0.938rem" }}>
                    <Grid item sm={12} md={7}>
                        <StyledLabel>Message</StyledLabel>
                        <TextArea
                            value={formData.message ?? ""}
                            placeholder="Enter your post message here..."
                            onChange={e => handleInputChange(e, "message")}
                        />
                        {hasErrorType(ERROR_LIST.MESSAGE) && <ErrorMessage>{ERROR_LIST.MESSAGE.message}</ErrorMessage>}
                        {responseMessage.MESSAGE_INVALID.message.length > 0 && <ErrorMessage>{responseMessage.MESSAGE_INVALID.message}</ErrorMessage>}
                    </Grid>
                    <Grid item sm={12} md={5}>
                        <StyledLabel>{noticeData !== null && formData.postType !== "text" ? "Change" : "Attach" } Image/Video (Optional)</StyledLabel>
                        <StyledDropzone ref={dropzoneRef} onDrop={handleDrop}>
                            <PostTypeAssetPreview formData={formData} selectedImage={selectedImage} mediaHasChanged={mediaHasChanged} />
                        </StyledDropzone>
                        <FileInput type="file" accept="image/*,video/*" onChange={handleDrop} />
                        
                        <FlexContainer direction="row" justifyContent="space-between">
                            <Button
                                backgroundColor={hasMedia ? "#FFF" : "#000"}
                                border={hasMedia && "1px solid #000"}
                                color={hasMedia ? "#000" : "#FFF"}
                                width="9.35rem"
                                height="2.375rem"
                                padding="unset !important"
                                style={{ borderRadius: "7px", marginTop: "10px" }}
                                onClick={handleBrowseClick}
                            >
                                {hasMedia ? "Change Image/Video" : "Browse File"}
                            </Button>

                            {
                                hasMedia &&
                                <Button
                                    backgroundColor="#000"
                                    color="#fff"
                                    height="2.375rem"
                                    padding="unset !important"
                                    width="9.35rem"
                                    style={{ borderRadius: "7px", marginTop: "10px" }}
                                    onClick={handleRemoveAttachment}
                                >
                                    Remove Attachment
                                </Button>
                            }
                        </FlexContainer>
                    </Grid>
                </Grid>

                <Grid container>
                    <AttachmentDropzone 
                        noticeData={noticeData}
                        dropzoneAttachmentRef={dropzoneAttachmentRef}
                        handleDropAttachments={handleDropAttachments}
                        handleBrowseClickAttachment={handleBrowseClickAttachment}
                        uploadedAttachmentFiles={uploadedAttachmentFiles}
                        setUploadedAttachmentFiles={setUploadedAttachmentFiles}
                        setDeleteAttachmentFiles={setDeleteAttachmentFiles}
                    />
                </Grid>

                { !isNewRecordOrScheduledPost && 
                    <Grid item>
                        <StyledLabel>Posted Date:</StyledLabel>
                        <div>{datePostedFormat(formData.scheduleDate ? formData.scheduleDate : noticeData.date) }</div>
                    </Grid>
                }

                {/* due to how the entire form is written, this cant be stretched to full modal width */}
                { (IsFeatureReady.accountLinking() && hasSocialMediaPostingRole) &&
                    <>
                        <Divider width="100%" />
                        <Grid item style={{ marginTop: "22px" }}>
                            <StyledLabel size="20px">Post To Social Media</StyledLabel>
                        </Grid>

                        <Grid item sm={12} md={7}>
                            <Description>
                                You can also select to share this post on your linked social media accounts.
                            </Description>
                        </Grid>

                        <Grid container direction="row" spacing={1} style={{ marginTop: "16px", marginBottom: "44px" }}>
                            <Grid item style={{ width: "100%" }}>
                                <NoticeModalSocialPlatform
                                    linkedAccounts={linkedAccounts}
                                    linkedAccountsFetched={linkedAccountsFetched}
                                    onToggleSocialItem={onToggleSocialItem}
                                />
                            </Grid>
                        </Grid>
                    </>
                }

                { isNewRecordOrScheduledPost && 
                    <Grid item>
                        <StyledLabel>Schedule Post</StyledLabel>
                    </Grid>
                }

                { isNewRecordOrScheduledPost && 
                    <Grid container spacing={2}>
                        <Grid item sm={12} md={7}>
                            <StyledLabel size="0.9rem">Select Post Date</StyledLabel>
                            <Description>
                                Your post will be sent to your selected users and <br/>posted on your business noticeboard on this date.
                            </Description>

                            <StyledDateInput scheduleDateSetInputTypeAs={scheduleDateSetInputTypeAs} isHideDefaultCalendarIcon={true} borderRadius="12px" size="14px" padding="10px 20px" height="45px"
                                width="300px"
                                margin="0"
                                backgroundColor={formData.isPostNow && "#F0F0F0"}
                                color="#000" border="1px solid #E9EBEF"
                                align="left"
                                type={Object.keys(formData.scheduleDate).length > 0 ? "datetime-local" : scheduleDateSetInputTypeAs }
                                placeholder="Select date"
                                onChange={e => handleInputChange(e, "scheduleDate")}
                                onFocus={() => {setScheduleDateSetInputTypeAs("datetime-local"); } }
                                value={formData.scheduleDate}
                                max="9999-12-31"
                                showErrors={false} 
                            />
                            {hasErrorType(ERROR_LIST.SCHEDULE_DATE) && <ErrorMessage>{ERROR_LIST.SCHEDULE_DATE.message}</ErrorMessage>}
                            {responseMessage.SCHEDULE_INVALID.message.length > 0 && <ErrorMessage>{responseMessage.SCHEDULE_INVALID.message}</ErrorMessage>}
                        </Grid>
                        <Grid item sm={12} md={5}>
                            <StyledLabel size="0.9rem">Post Now</StyledLabel>
                            <Description>
                            Check this box if you wish to post this now to <br/>your business noticeboard.
                            </Description>
                            <CheckboxContainer>
                                <label>
                                    <Checkbox
                                        color="#36be24"
                                        height="32px"
                                        width="32px"
                                        padding="7px"
                                        radius="10px"
                                        value={true}
                                        checked={formData.isPostNow}
                                        onChange={ handlePostNowChange }
                                    />
                                </label>
                            </CheckboxContainer>
                            
                        </Grid>
                    </Grid>
                }
            </Box>
            <Divider />
            <Box sx={{ padding: "1.25rem 3.125rem 2.188rem" }}>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                    <div>
                        {noticeData !== null && !isFormLoading && (
                            <Button
                                style={{ borderRadius: "10px" }}
                                size="0.875rem"
                                backgroundColor="#ff5353"
                                color="#fff"
                                width="8.75rem"
                                onClick={() => [handleCloseModal(false), setShowDeleteDialog(true)] }
                            >
                            Delete Post
                            </Button>
                        )}
                    </div>
                    <div style={{ textAlign: "right" }}>
                        {isFormLoading ? <LottieLoadingIndicator height="1.563rem" width="1.563rem" containerHeight="0" /> : (
                            <>
                                <Button
                                    onClick={handleCloseModal}
                                    size="0.875rem"
                                    border="1px solid #000"
                                    color="#000"
                                    marginRight="15px"
                                    width="8.75rem"
                                    style={{ borderRadius: "7px", boxShadow: "none" }}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    size="0.875rem"
                                    backgroundColor="#006cff"
                                    color="#fff"
                                    border="1px solid #006cff"
                                    width="8.75rem"
                                    style={{ borderRadius: "7px", boxShadow: "none" }}
                                    onClick={() => handleFormSubmit()}
                                >
                                    Save
                                </Button>
                            </>
                        )}
                    </div>
                </div>
            </Box>
        </Dialog>
    );
};

const AttachItemContainer = styled.div`
    box-shadow: 0px 5px 5px #0000001A;
    border: 1px solid #8291B2;
    border-radius: 14px;
    width: 345px;
    padding: 8px 5px;
    display: flex;
    
`;

const FileIconImg = styled.img`
    margin-right: 20px;
    margin-left: 10px;
    max-width: 25px;
`;

const RemoveImg = styled.img`
    margin-left: auto;  /* Push remove icon to the right */
    height: 37px;
    position: relative;
    cursor: pointer;
`;
const AttachmentDropzone = (props) => {
    const { noticeData, dropzoneAttachmentRef, handleDropAttachments, handleBrowseClickAttachment, uploadedAttachmentFiles, setUploadedAttachmentFiles, setDeleteAttachmentFiles } = props;
    
    const [isDragging, setIsDragging] = useState(false);
    const handleDragEnter = () => setIsDragging(true);
    const handleDragLeave = () => setIsDragging(false);
    const handleDrop = (e) => {
        handleDropAttachments(e);
        setIsDragging(false);
    };

    function getFileType(fileName) {
        const extension = fileName.split(".").pop().toLowerCase();
        const mimeTypes = {
            "jpg": "image/jpeg",
            "jpeg": "image/jpeg",
            "png": "image/png",
            "gif": "image/gif",
            //"bmp": "image/bmp",

            // Video MIME types
            "mp4": "video/mp4", 
            //"mov": "video/quicktime",
            "webm": "video/webm",
            "ogg": "video/ogg",
            //"avi": "video/x-msvideo",
        };
        return mimeTypes[extension] || "unknown";
    }

    const existingFiles = () => {
        if (noticeData === null || !noticeData.attachments)
            return [];

        return noticeData.attachments
            .map(attachment => ({
                uuid: attachment.uuid,
                name: attachment.filename,
                type: getFileType(attachment.filename)
            }));
    };

    return (
        <>
            <StyledAttachmentDropzone
                ref={dropzoneAttachmentRef}
                onDrop={handleDrop}
                onDragEnter={handleDragEnter}
                onDragLeave={handleDragLeave}
                disableClick={true}
                style={{ backgroundColor: isDragging ? "#8291b26e" : "unset" }}
            >
                <Grid container spacing={2} style={{ marginBottom: "0.625rem" }}>
                    <Grid item sm={12} md={12}>
                        <StyledLabel>Attachments</StyledLabel>
                        
                        <div>
                            <AttachedFiles 
                                setUploadedAttachmentFiles={setUploadedAttachmentFiles} 
                                setDeleteAttachmentFiles={setDeleteAttachmentFiles} 
                                existingFiles={existingFiles} 
                                newFiles={uploadedAttachmentFiles} 
                            />
                        </div>

                        <div>
                            <Button
                                backgroundColor="#FFF"
                                border="1px solid #000"
                                color="#000"
                                width="9.35rem"
                                height="2.375rem"
                                padding="unset !important"
                                style={{ borderRadius: "7px", marginTop: "10px" }}
                                onClick={handleBrowseClickAttachment}
                            >
                                Attach File to Post
                            </Button>
                        </div>
                    </Grid>
                </Grid>
            </StyledAttachmentDropzone>
        </>
    );
};

const AttachedFiles = (props) => {
    const { setUploadedAttachmentFiles, setDeleteAttachmentFiles, existingFiles, newFiles } = props;

    const [modalExistingFiles, setModalExistingFiles] = useState(existingFiles());

    const fileIcon = (fileType) => {
        const validImageTypes = ["image/jpeg", "image/png", "image/gif"];
        const validVideoTypes = ["video/mp4", "video/webm", "video/ogg"]; // Add more as needed

        if (validImageTypes.includes(fileType)) {
            return ImageIcon;
        }

        if (validVideoTypes.includes(fileType)) {
            return VideoIcon;
        }

        return DocumentIcon;
    };

    const handleRemove = (fileNameToRemove, event) => {
        event.stopPropagation();
        setUploadedAttachmentFiles(prevFiles => prevFiles.filter((file) => file.name !== fileNameToRemove));
    };

    const handleRemoveExisting = (fileUuidToRemove, event) => {
        event.stopPropagation();
        // TODO: remove the attachment from the notice modal preview but not in the notice listing.
        setDeleteAttachmentFiles(prevFiles => [
            ...prevFiles, { 
                attachmentUuid: fileUuidToRemove,
            }
        ]);

        setModalExistingFiles(prevFiles => prevFiles.filter((file) => file.uuid !== fileUuidToRemove));
    };

    return (
        <>
            { modalExistingFiles.length > 0 && (
                <ul style={{ padding: 0, listStyleType: "none" }}>
                    {modalExistingFiles.map(existingFile => (
                        <li key={"key_" + existingFile.uuid} style={{ display: "inline-block", marginRight: "1em", marginBottom: "1em" }}>
                            <AttachItemContainer key={existingFile.name}>
                                <FileIconImg src={fileIcon(existingFile.type)} className="file-icon" alt="File Icon" />
                                <span className="text" style={{ flex: 1, alignContent: "center" }}>{existingFile.name}</span>
                                <RemoveImg onClick={(e) => handleRemoveExisting(existingFile.uuid, e)} src={RemoveIcon} alt="Remove" className="remove-icon" />
                            </AttachItemContainer>
                        </li>
                    ))}
                </ul>
            )
            }
            { newFiles.length > 0 && (
                <ul style={{ padding: 0, listStyleType: "none" }}>
                    {newFiles.map(file => (
                        <li key={"key_" + file.name} style={{ display: "inline-block", marginRight: "1em", marginBottom: "1em" }}>
                            <AttachItemContainer key={file.name}>
                                <FileIconImg src={fileIcon(file.type)} className="file-icon" alt="File Icon" />
                                <span className="text" style={{ flex: 1, alignContent: "center" }}>{file.name}</span>
                                <RemoveImg onClick={(e) => handleRemove(file.name, e)} src={RemoveIcon} alt="Remove" className="remove-icon" />
                            </AttachItemContainer>
                        </li>
                    ))}
                </ul>
            )
            }
        </>
    );
};

function PostTypeAssetPreview({ formData, selectedImage, mediaHasChanged }) {
    if (mediaHasChanged) {
        return (
            <>
                {
                    formData.postType === "text" &&
                    <ImagePreview src={AttachIcon} alt="Attach Icon" />
                }
                {
                    formData.postType === "image" &&
                    <ImagePreview src={selectedImage} alt="Image Thumbnail" />
                }
                
                { /* Render video content here */
                    formData.postType === "video" &&
                    <video controls style={{ height: "100%", width: "inherit", padding: "0px", objectFit: "contain" }}>
                        <source src={selectedImage} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                }
            </>
        );
    } else {
        return (
            <>
                {
                    formData.postType === "text" &&
                    <ImagePreview src={AttachIcon} alt="Attach Icon" />
                }
                {
                    formData.postType === "image" &&
                    <ImagePreview src={formData.imageUrl} alt="Attach Icon" />
                }
                
                { /* Render video content here */
                    formData.postType === "video" &&
                    <video controls style={{ height: "100%", width: "inherit", padding: "0px", objectFit: "contain" }}>
                        <source src={formData.imageUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                }
            </>
        );
    }
}

export default NoticeModal;