import React, { Component } from "react";
import axios from "axios";
import { API_URL } from "src/scenes/App";
import styled from "styled-components";
import DropZone from "react-dropzone";
import {
    Text, Button, FlexContainer, BackgroundContainer, Toast, BrowseFileButton, TextLabel
} from "src/components";

import ImageSlider from "../components/ImageSlider";
import { CustomiseContext } from "../CustomiseContext";
import GridBackground from "src/img/GridBackground.png";

const UploadContainer = styled.div`
`;

const UploadSize = {
    width: 391,
    height: 102,
    marginBottom: "1.5em",
    marginTop: "1.5em"
};

export const PhoneContainer = styled.div`
    align-items: start;
    display: flex;
    flex-shrink: 0;
    margin-top: 1rem;
    justify-content: center;
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 10px;
`;

class UploadLogo extends Component {
    state = {
        dropzoneActive: false,
        maxSize: 5242880, //5MB

        isUploading: false,
        uploadPercentage: 0,

        errorMessage: "",
    };

    static contextType = CustomiseContext;

    /**
     * File dropped into logo drop zone, validate file type and upload to S3
     * @param droppedFiles {Array<File>}
     */
    onDrop = (droppedFiles) => {
        const { token } = this.context;
        const fileTypeErrorMessage = "Your logo must be of the PNG or JPEG format, and less than 5MB in size.";
        // If the drop zone receives anything other than the accepted formats, or anything greater
        // than the max file size specified, the droppedFiles array will be empty.
        if (droppedFiles.length === 0) {
            this.setState({
                errorMessage: fileTypeErrorMessage
            });
            return;
        }

        // Take first file if multiple provided and use that
        let fileToUpload = droppedFiles[0];

        const validImageTypes = ["image/jpeg", "image/png"];
        if (!validImageTypes.includes(fileToUpload.type)) {
            this.setState({
                errorMessage: fileTypeErrorMessage
            });
            return;
        }
        
        let fileExtension = fileToUpload.name.split(".").pop();
        this.context.nextLogoUploading();
        this.setState({
            errorMessage: ""
        });

        axios.get(`${API_URL}/company/generateUrl/${fileExtension}`, {
            headers: {
                Authorization: "Bearer " + token
            }
        }).then(res => {
            const fullUrl = res.data.url;

            //set preview of the updated file to the props
            this.context.uploadFile("logoFile", droppedFiles.map(oneFile => ({
                ...oneFile,
                fullUrl: fullUrl,
                fileToUpload: fileToUpload,
                preview: URL.createObjectURL(fileToUpload)
            })));

            this.context.nextLogoAvailable(URL.createObjectURL(fileToUpload));
        }).catch(error => {
            return Toast.error(error.message);
        });
    };

    clickNext = () => {
        if (this.context.isNextClickable) {
            this.context.nextClick();
        } else {
            this.setState({
                errorMessage: "Please upload a company logo first"
            });
        }
    };

    render() {
        const { maxSize, isUploading, uploadPercentage, errorMessage, timestamp } = this.state;
        const { logoFile, assetLogoUrl, tempLogoUrl, companyName, currentScreen, currentForm, screenIds, colourPalette,
            leftScreen, rightScreen } = this.context;

        return (
            <>
                <FlexContainer mDirection="column" tDirection="column" direction="row">
                    <div style={{ flexGrow: 1 }}>
                        <TextLabel weight="700" size="1.875em" color="#2A2B2A" margin="2rem 0 0.5rem" flexGrow="1">
                            Step 1: Upload Your Logo
                        </TextLabel>

                        <FlexContainer style={{ flexShrink: 0 }}>
                            <Text size="1em" align="left">Start by uploading your business logo. Check the device preview to see where your logo will appear in the app.</Text>

                            <Text size="1.25em" align="left" weight="700">Upload your logo:</Text>

                            <DropZone
                                accept="image/png, image/jpeg"
                                maxSize={maxSize}
                                onDrop={this.onDrop.bind(this)}
                                style={UploadSize}
                            >
                                <UploadContainer>
                                    {isUploading ?
                                        <>
                                            {uploadPercentage === 100 ?
                                                <FlexContainer width="405px" height="102px" alignItems="center">
                                                    <BackgroundContainer width="405px" height="102px" image={GridBackground} style={{ border: "1px solid #000" }}>
                                                        <img style={{ maxWidth: "405px", maxHeight: "102px" }} src={logoFile[0].preview} alt={"Logo File Preview"} />
                                                    </BackgroundContainer>
                                                </FlexContainer>
                                                :
                                                <FlexContainer width="405px" height="102px">
                                                    <Text color="black">
                                                        Uploading - {uploadPercentage}%
                                                    </Text>
                                                    <div style={{ marginLeft: "50px" }}>
                                                        <svg width="391" height="10">
                                                            <rect width="391" height="10" fill="#566cad" rx="0" ry="0"></rect>
                                                            <rect width={3 * uploadPercentage} height="10" fill="#f9db5e" rx="0" ry="0"></rect>
                                                        </svg>
                                                    </div>
                                                </FlexContainer>
                                            }
                                        </>
                                        :
                                        <>
                                            {logoFile.length === 0 && assetLogoUrl === "" ?
                                                <img src={GridBackground} alt="UploadBackground" width="405px" style={{ border: "1px dashed #000" }} /> :
                                                <FlexContainer width="405px" height="102px" alignItems="center">
                                                    <BackgroundContainer width="405px" height="102px" image={GridBackground} style={{ border: "1px solid #000" }}>
                                                        {logoFile.length === 0 ?
                                                            <img style={{ maxWidth: "405px", maxHeight: "102px" }} src={assetLogoUrl} alt={"Asset Logo Url"} />
                                                            :
                                                            <img style={{ maxWidth: "405px", maxHeight: "102px" }} src={logoFile[0].preview} alt={"Logo File"} />
                                                        }
                                                    </BackgroundContainer>
                                                </FlexContainer>
                                            }
                                        </>
                                    }
                                </UploadContainer>
                            </DropZone>
                                
                            <Text align="left">
                                <BrowseFileButton onChange={(files) => this.onDrop(files)}>
                                    Browse File
                                </BrowseFileButton>
                            </Text>

                            <Text color="#8E4EB4" align="left" size="0.9375em">
                                <b>Image Format:</b><br/>
                                <b>PNG File</b> - Transparent Background
                            </Text>

                            <hr style={{ width: "100%", marginTop: "2em" }}/>

                            <Text align="left" size="1.0000em">
                                Click <b>Next </b> to proceed to <b>Step 2:</b> Colour Selection.
                            </Text>
                            
                            <ButtonsContainer>
                                <Button color="#000" backgroundColor="transparent" borderRadius="10px !important" border="1px solid black"
                                    width="112px" onClick={() => this.context.backToCustomise() }>
                                    Back
                                </Button>
                            
                                <Button color="#FFFFFF" backgroundColor="#000" borderRadius="10px !important" border="1px solid black"
                                    marginLeft="20px" width="112px" onClick={ this.clickNext } >
                                    Next
                                </Button>
                            
                            </ButtonsContainer>

                            <Text color="red">
                                {errorMessage}
                            </Text>
                        </FlexContainer>
                    </div>
                    <PhoneContainer>
                        <ImageSlider
                            companyName={companyName}
                            logoTopOffset={45}
                            phoneFrameTopOffset={30}
                            currentScreen={currentScreen}
                            currentForm={currentForm}
                            screenIds={screenIds}
                            leftScreen={leftScreen}
                            rightScreen={rightScreen}
                            colourPalette={colourPalette}
                            assetLogoUrl={tempLogoUrl ? tempLogoUrl : `${assetLogoUrl + "?" + timestamp}`} />
                    </PhoneContainer>
                </FlexContainer>
            </>
        );
    }
}

export default UploadLogo;