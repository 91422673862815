import React, { Component } from "react";
import styled from "styled-components";
import {
    Button, Text, FlexContainer, LottieLoadingIndicator, TextLabel
} from "src/components";

import { Screens } from "src/scenes/Customise";
import ColorPicker from "../components/ColorPicker";
import ImageSlider from "../components/ImageSlider";
import { CustomiseContext } from "../CustomiseContext";
import { PhoneContainer } from "src/scenes/Customise/forms/UploadLogo";

export const ColorButtonsContainer = styled.div`
    width: 80%;
    display: flex;
    flex-direction: row;
`;

const ButtonContainer = styled.div`
    width: 100px;
    margin-right: 42px;
    display: flex;
    flex-direction: column;
    align-items: center;
`;

export const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 10px;
`;

const BlackCircleDisplay = styled.div`
    width: 38px;
    height: 38px;
    background: #000000 0% 0% no-repeat padding-box;
    border: 1px solid #000000;
    border-radius: 20px;
    display: inline-block;
    margin-right: 20px;
`;

const WhiteCircleDisplay = styled.div`
    width: 38px;
    height: 38px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #000000;
    border-radius: 20px;
    display: inline-block;
`;
export function ScreenSwitch(param) {
    switch (param) {
    case Screens.MAIN:
        return "Quote Screen";
    case Screens.NOTICEBOARD:
        return "Noticeboard Screen";
    case Screens.PLAYLISTS:
        return "Playlists Screen";
    case Screens.GOALS:
        return "Goals Screen";
    case Screens.SPLASH:
        return "Loading Screen";
    case Screens.ARTICLES:
        return "Articles Screen";
    case Screens.BODY_AND_MIND:
        return "Body & Mind Screen";
    case Screens.SURVEY:
        return "Survey Screen";
    default:
        break;
    }
}

class SelectColor extends Component {
    static contextType = CustomiseContext;

    state = {
        timestamp: "",
        loading: false,
        errorMessage: "",
        backgroundColorSelected: this.context.isComplete ? this.context.backGroundColor : false,
        primaryColorSelected: this.context.isComplete ? this.context.primaryColor : false,
        secondaryColorSelected: this.context.isComplete ? this.context.secondaryColor : false,
    };

    componentDidMount() {
        //make sure logo image will be updated which the current page rendered
        let currentTime = new Date().getTime();
        this.setState({
            timestamp: currentTime,
            loading: false,
        });
    }

    handleBackgroundChange = (color) => {
        this.context.updateBackgroundColor(color);
        this.setBackgroundColorSelected(true);
    };

    handlePrimaryColorChange = (color) => {
        this.context.updatePrimaryColor(color);
        this.setPrimaryColorSelected(true);
    };

    handleSecondaryColor = (color) => {
        this.context.updateSecondaryColor(color);
        this.setSecondaryColorSelected(true);
    };

    setBackgroundColorSelected = (bool) => {
        this.setState({ backgroundColorSelected: bool });
    };

    setPrimaryColorSelected = (bool) => {
        this.setState({ primaryColorSelected: bool });
    };

    setSecondaryColorSelected = (bool) => {
        this.setState({ secondaryColorSelected: bool });
    };
    
    finish = () => {
        // Determine if all three colours have been set by the user yet.
        // The context colour objects are initialised as arrays for initial display, and then
        // become a string after being set by the user.
        if (!this.state.backgroundColorSelected ||
            !this.state.primaryColorSelected ||
            !this.state.secondaryColorSelected) {
            this.setState({
                errorMessage: "Please select all three colours first"
            });
        } else {
            //console.log("Finish")
            this.setState({ 
                loading: true,
                errorMessage: ""
            });
            this.context.nextClick();
        }
    };

    render() {
        const { timestamp, loading, errorMessage } = this.state;

        const { currentScreen, backGroundColor, secondaryColor, primaryColor, companyName, colourPalette,
            currentForm, screenIds, leftScreen, rightScreen, isComplete, assetLogoUrl, tempLogoUrl } = this.context;

        return (
            <>
                <FlexContainer mDirection="column" tDirection="column" direction="row">
                    <div style={{ flexGrow: 1 }}>
                        <TextLabel weight="700" size="1.875em" color="#2A2B2A" margin="2rem 0 0.5rem" flexGrow="1">
                            Step 2: Colour Selection
                        </TextLabel>

                        <FlexContainer style={{ flexShrink: 0 }}>

                            <Text size="1em" align="left">Select three colours that represent your business best. Refer to the device preview on the right for a visual representation and see your app come to life!</Text>

                            {/* Generating color pickers for different screens */}
                            <Text size="20px" color="#2A2B2A" margin="10px 0px 10px 0px" align="left" weight="700">
                                Select App Colours:
                            </Text>
                            <ColorButtonsContainer>
                                <ButtonContainer>
                                    <ColorPicker color={backGroundColor} isComplete={isComplete}
                                        handleColorChange={this.handleBackgroundChange} setSelectedColour={this.setBackgroundColorSelected} />
                                    <Text size="16px" color="black" align="left" weight="500">Background</Text>
                                </ButtonContainer>
                                <ButtonContainer>
                                    <ColorPicker color={primaryColor} isComplete={isComplete}
                                        handleColorChange={this.handlePrimaryColorChange} setSelectedColour={this.setPrimaryColorSelected} />
                                    <Text size="16px" color="black" weight="500">Primary</Text>
                                </ButtonContainer>
                                <ButtonContainer>
                                    <ColorPicker color={secondaryColor} isComplete={isComplete}
                                        handleColorChange={this.handleSecondaryColor} setSelectedColour={this.setSecondaryColorSelected} />
                                    <Text size="16px" color="black" align="left" weight="500">Secondary</Text>
                                </ButtonContainer>
                            </ColorButtonsContainer>

                            <FlexContainer direction="row" margin="1em 0">

                                <div style={{ color: "#8E4EB4", fontSize: "1em", width: "50%", marginRight: "2em", fontWeight: "400" }}>
                                    * Some parts of the app interface will remain white or black by default and can not be changed.
                                </div>

                                <div>
                                    <div style={{ fontSize: "1em", fontWeight: "500", marginBottom: "5px" }}>Default Colours</div>
                                    <BlackCircleDisplay></BlackCircleDisplay>
                                    <WhiteCircleDisplay></WhiteCircleDisplay>
                                </div>
                            </FlexContainer>
                            <hr style={{ width: "100%" }}/>

                            <Text align="left" size="1.0000em">
                                Click <b>Next </b> to proceed to <b>Step 3:</b> Custom App Icon
                            </Text>

                            <ButtonsContainer>
                                <Button color="#000" backgroundColor="transparent" borderRadius="10px !important" border="1px solid black"
                                    width="112px" onClick={() => this.context.goToStep1()}>
                                    Back
                                </Button>
                                { loading ?
                                    <FlexContainer width="130px" height="34px" >
                                        <LottieLoadingIndicator containerHeight="2rem" height="7px" width="7px" style={{ minHeight: "2em !important" }}/>
                                    </FlexContainer>
                                    :
                                    <Button color="#FFFFFF" backgroundColor="#000" borderRadius="10px !important" border="1px solid black"
                                        marginLeft="20px" width="112px" onClick={ this.finish } >
                                        Next
                                    </Button>
                                }
                            </ButtonsContainer>
                            <Text color="red">
                                {errorMessage}
                            </Text>
                        </FlexContainer>
                    </div>
                    <PhoneContainer>
                        <ImageSlider
                            companyName={companyName}
                            logoTopOffset={45}
                            phoneFrameTopOffset={30}
                            currentScreen={currentScreen}
                            currentForm={currentForm}
                            screenIds={screenIds}
                            leftScreen={leftScreen}
                            rightScreen={rightScreen}
                            colourPalette={colourPalette}
                            assetLogoUrl={tempLogoUrl ? tempLogoUrl : `${assetLogoUrl + "?" + timestamp}`} />
                    </PhoneContainer>
                </FlexContainer>
            </>
        );
    }
}

export default SelectColor;