import React, { useContext, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { API_URL } from "src/scenes/App";
import { Dialog } from "@material-ui/core/";
import { Header } from "./CreateChatModal";
import { Text, Button, Toast, FlexContainer, ErrorLabel, LottieLoadingIndicator } from "src/components";
import { UserContext } from "src/scenes/App/UserContext";
import { generateNameInitials } from "src/utils/helpers";
import PencilIcon from "src/img/new/chats/pencil.svg";
import PlusIcon from "src/img/new/chats/plus.svg";
import { UserKeys } from "src/constants/userDetails";

const Icon = styled.img`
    height: ${p => p.height};
    width: ${p => p.width};
    color: ${p => p.color};
    cursor: pointer;
`;

const CloseButton = styled(Button)`
    height: 2.375rem;
    width: 8.75rem;
    background: #FFF;
    border: 1px solid #000;
    color: #000;
    border-radius: 0.4375rem !important;
    font-size: 0.875rem;
    line-height: 1;
`;

const StyledInputLikeDiv = styled.div`
    border: 0.0625rem solid #DBE5ED;
    width: 100%;
    max-width: 14.25rem;
    line-height: 2.5rem;
    text-align: center;
    background: #FFFFFF;
    font-weight: 700;
    font-size: 1rem;
    border-radius: 0.4375rem;
    margin: 0 0 0.4375rem 0;
    :focus {
        outline: 0;
    }
`;

const ProfilePhoto = styled.div`
    position: relative;
    height: 7.875rem;
    width: 7.875rem;
    border-radius: 50%;
    border: 0.25rem solid #FFFFFF;
    box-shadow: 0 0.1875rem 0.375rem #00000029;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 3.125rem;
    font-weight: bold;
    color: #484848;
    background: #D6D6D6;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    margin: 0 0 2.5rem 0;
`;

const EditPhotoButton = styled.div`
    position: absolute;
    height: 1.9375rem;
    width: 1.9375rem;
    border-radius: 50%;
    background: #E0E0E0;
    border: 0.125rem solid #FFF;
    bottom: 0;
    transform: translateY(55%);
    box-shadow: 0 0.1875rem 0.375rem #00000029;
    cursor: pointer;
    & * {
        cursor: pointer;
    }
`;

const ClearPhotoButton = styled.div`
    position: absolute;
    height: 1.9375rem;
    width: 1.9375rem;
    border-radius: 50%;
    background: #FFF;
    box-shadow: 0 0.1875rem 0.375rem #00000029;
    top: 0;
    right: 0;
    transform: translate(30%, -40%);
    cursor: pointer;
`;

const LeadershipRoleSection = styled.div`
    display: flex;
    flex-direction: row;
    width: 80%;
    justify-content: center;
    flex-wrap: wrap;
`;

const LeadershipRoleItem = styled.div`
    border-radius: 0.4375rem !important;
    background-color: #e5fff6;
    color: #65a48f;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 2.5rem;
    padding: 0.5rem 1rem;
    margin: 0.5rem 0.5rem;
`;

const ProfileSettingsModal = (props) => {
    const { appFns } = useContext(UserContext);
    const { firstName, lastName, token, email, isClient, employmentPosition, department, profilePictureUrl } = props.userToShow;
    const { handleClose, profileModalUserLeadershipRoles } = props;
    
    const originalData = {
        profilePictureUrl: profilePictureUrl
    };

    const [formData, setFormData] = useState({ ...originalData });
    const [upload, setUpload] = useState({
        isUploading: false,
        timestamp: "",
        uploadPercentage: 0,
        fullUrl: "",
        fileToUpload: null,
        newImageUrl: null
    });
    const [errors, setErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const headers = { headers: { Authorization: "Bearer " + token } };

    const handleProfileUpdate = (e) => {
        const fileToUpload = e.target.files[0];
        const validImageTypes = ["image/jpeg", "image/png"];
        if (!validImageTypes.includes(fileToUpload.type)) {
            Toast.error("Unsupported file type. Images may be JPEG or PNG format.");
            return;
        }
        
        setIsLoading(true);
        setErrors([]);

        let fileExtension = fileToUpload.name.split(".").pop();
        axios.get(`${API_URL}/chats/profile/generatePhotoUrl/${fileExtension}`, headers)
            .then(res => {
                const fullUrl = res.data.url;
                const storedUrl = fullUrl.substring(0, fullUrl.indexOf("?"));

                setFormData({
                    ...formData,
                    profilePictureUrl: storedUrl
                });

                setUpload({
                    ...setUpload,
                    fullUrl: fullUrl,
                    fileToUpload: fileToUpload,
                    // creating a url here to give a preview of the selected image
                    // since image wont be uploaded until form submission
                    newImageUrl: URL.createObjectURL(fileToUpload)
                });
            })
            .catch(err => {
                const message = typeof(err) === "string" ? err : "Unable to generate a pre-signed URL for your photo.";
                setErrors(message);
                return Toast.error(message);
            }).finally(() => {
                setIsLoading(false);
            });
    };

    const handleFormSubmit = async () => {
        setErrors([]);
        if (!props.isCurrentUser) {
            return Toast.error("You cannot update the profile of another user.");
        }
        setIsLoading(true);
        uploadFileAndSaveProfile().catch(() => {
            const message = "Failed to upload image to the server.";

            setErrors(message);
            Toast.error(message);
        });
    };

    const uploadFileAndSaveProfile = async () => {
        try {
            if (upload.fileToUpload !== null) {
                const parameters = {
                    headers: { "Content-Type": upload.fileToUpload.type },
                    onUploadProgress: progressEvent => {
                        let uploadPercent = (progressEvent.loaded / progressEvent.total) * 100;
    
                        setUpload({
                            isUploading: true,
                            uploadPercentage: uploadPercent.toFixed(2),
                        });
                    }
                };
                await axios.put(upload.fullUrl, upload.fileToUpload, parameters);
            }   
            

            const response = await axios.put(
                `${API_URL}/chats/profile/updateEmployeeProfilePicture`, formData, headers
            );
            appFns.updateUserContextProperties({
                [UserKeys.PROFILE_PICTURE_URL]: response.data.profilePictureUrl
            });

            Toast.success("Profile successfully updated.");
            setUpload({
                ...upload,
                newImageUrl: null,
                fileToUpload: null,
                fullUrl: ""
            });
            // clean up the blob url created after selecting an image to upload
            URL.revokeObjectURL(upload.newImageUrl);
        } catch (err) {
            const message = typeof(err) === "string" ? err : "Cannot update name.";
            Toast.error(message);
        } finally {
            setIsLoading(false);
            handleClose();
        }
    };

    const handleClearProfilePicture = () => {
        setFormData({
            ...formData,
            profilePictureUrl: ""
        });
    };

    /**
     * @returns {string} - a valid CSS background-image value; "none" or "url(...)"
     */
    const renderUserImage = () => {
        return upload.newImageUrl !== null ? `url(${upload.newImageUrl})`
            : !formData.profilePictureUrl ? "none" : `url(${formData.profilePictureUrl})`;
    };

    const renderErrors = () => errors.map((err, index) => <ErrorLabel key={index}>{ err }</ErrorLabel>);
    const hasProfileChanged = () => {
        if (originalData.profilePictureUrl !== formData.profilePictureUrl)
            return true;

        return false;
    };

    return (
        <React.Fragment>
            <Dialog open={true} onClose={handleClose}
                maxWidth="md"
                scroll="body"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                PaperProps={{
                    style: {
                        width: "95%",
                        maxWidth: "59.375rem",
                        height: "53.0625rem",
                        borderRadius: "1.25rem",
                        overflow: "auto",
                        background: "#F9FAFC",
                        paddingBottom: "2em"
                    },
                }}
            >
                <Header>
                    <Text color="#4B5155" size="1.125rem" weight="700">
                        { props.isCurrentUser ? "Profile Settings" : "User Profile" }
                    </Text>
                    <CloseButton onClick={handleClose}>Close</CloseButton>
                </Header>
                <div style={{ 
                    display: "flex", 
                    flexDirection: "column", alignItems: "center"
                }}>
                    <Text margin="2.3125rem 0 1.25rem 0" weight="700" size="1.25rem">
                        { props.isCurrentUser ? "My " : "" }Profile
                    </Text>
                    <ProfilePhoto
                        style={{
                            backgroundImage: renderUserImage()
                        }}
                    >
                        { !formData.profilePictureUrl ? generateNameInitials(firstName, lastName) : "" }
                        { props.isCurrentUser &&
                            <React.Fragment>
                                {renderUserImage() !== "none" && <ClearPhotoButton className="flex-centered-content"
                                    onClick={() => handleClearProfilePicture()}
                                >
                                    <Icon width="1.1rem" height="1.1rem" src={PlusIcon} style={{ transform: "rotate(45deg)" }} />
                                </ClearPhotoButton>}
                                <EditPhotoButton className="flex-centered-content">
                                    <input type="file" style={{ opacity: 0, position: "absolute" }} 
                                        onChange={e => handleProfileUpdate(e)}
                                    />
                                    <Icon width="1.1rem" height="1.1rem" src={PencilIcon} />
                                </EditPhotoButton>
                            </React.Fragment>
                        }
                        
                    </ProfilePhoto>
                    { upload.isUploading &&
                        <FlexContainer>
                            <Text>Uploading - { upload.uploadPercentage }%</Text>
                        </FlexContainer>
                    }

                    <StyledInputLikeDiv>{firstName}</StyledInputLikeDiv>
                    <StyledInputLikeDiv>{lastName}</StyledInputLikeDiv>
                    
                    <Text margin="1.875rem 0 0 0" size="1rem" weight="700">Email</Text>
                    <Text margin="0.625rem 0 1.125rem 0" size="1rem" height="1.3125rem">{ email }</Text>

                    { !isClient &&
                        <React.Fragment>
                            {employmentPosition && 
                                <>
                                    <Text margin="0" size="1rem" weight="700">Job Title</Text>
                                    <Text margin="0.625rem 0 1.125rem 0" size="1rem" height="1.3125rem">{ employmentPosition }</Text>
                                </>
                            }

                            {department && 
                                <>
                                    <Text margin="0" size="1rem" weight="700">Department / Group</Text>
                                    <Text margin="0.625rem 0 3.875rem 0" size="1rem" height="1.3125rem">{ department }</Text>
                                </>
                            }
                        </React.Fragment>
                    }

                    { profileModalUserLeadershipRoles && profileModalUserLeadershipRoles.length > 0 && 
                        <>
                            <Text margin="1em 0 0 0" size="1rem" weight="700">Leadership Role</Text>
                            <LeadershipRoleSection>
                                { profileModalUserLeadershipRoles.map((lrItem) => 
                                    <LeadershipRoleItem key={lrItem.uuid} >
                                        { lrItem.name }
                                    </LeadershipRoleItem>
                                )}
                            </LeadershipRoleSection>
                        </>
                    }

                    <Text margin="1em 0 0 0" size="1rem" weight="700">Email</Text>
                    <Text margin="0 0 1em 0" size="1rem" height="1.3125rem">{ email }</Text>

                    { !isClient &&
                        <React.Fragment>
                            {employmentPosition && 
                                <>
                                    <Text margin="0" size="1rem" weight="700">Job Title</Text>
                                    <Text margin="0 0 1em 0" size="1rem" height="1.3125rem">{ employmentPosition }</Text>
                                </>
                            }

                            {department && 
                                <>
                                    <Text margin="0" size="1rem" weight="700">Department / Group</Text>
                                    <Text margin="0 0 1em 0" size="1rem" height="1.3125rem">{ department }</Text>
                                </>
                            }
                        </React.Fragment>
                    }

                    { errors.length ? renderErrors() : "" }
                    { isLoading ? <LottieLoadingIndicator /> :
                        props.isCurrentUser &&
                        <Button backgroundColor="#006CFF" color="#FFF"
                            marginTop="1.3125rem"
                            width="8.75rem"
                            borderRadius="0.4375rem !important"
                            style={{ opacity: !hasProfileChanged() && "0.4" }}
                            onClick={() => hasProfileChanged() ? handleFormSubmit() : null }
                        >
                            Save Changes
                        </Button>
                    }
                </div>
            </Dialog>
        </React.Fragment>
    );
};

export default ProfileSettingsModal;