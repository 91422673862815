import React, { useState } from "react";
import { SocialMediaButton } from "../AccountLinkingPage";
import FacebookIcon from "src/img/noticeboard/facebook.svg";

const FacebookLogin = ({ onSuccess }) => {
    const [loading, setLoading] = useState(false);

    const handleFacebookLogin = () => {
        if (!window.FB) {
            console.error("Facebook SDK is still loading...");
        }
        setLoading(true);
        window.FB.login(
            function(response) {
                if (response.authResponse) {
                    const authResponse = response.authResponse; // short lived access token

                    setLoading(false);
                    onSuccess(authResponse);
                } else {
                    setLoading(false);
                    console.error("Facebook login failed. Please try again.");
                }
            },
            { scope: "pages_manage_posts,pages_read_engagement" } // Ensure permissions are requested
        );
    };

    return (
        <SocialMediaButton onClick={handleFacebookLogin}>
            <img src={FacebookIcon} alt="Facebook Icon" style={{ marginRight: "12px" }}/>
            {loading ? "Loading..." : "Connect Facebook"}
        </SocialMediaButton>
    );
};

export default FacebookLogin;
