import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import {
    FlexContainer, Text, Button, LottieLoadingIndicator, Toast
} from "src/components";
import styled from "styled-components";
import axios from "axios";
import { API_URL } from "src/scenes/App";

const StyledButton = styled(Button)`
    border: 1px solid #2e3036;
    color: white;
    padding: 10px 30px 10px;
    border-radius: 20px; 
    background-color: ${ p => p.backgroundColor || "#2e3036"} ;
`;

const CategoryBox = styled.div`
    :nth-child(even) {
        background-color: white;
    }
    :nth-child(odd) {
        background-color: #f3f3f4;
    }
`;

const Item = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  position: relative;
`;

const RadioButtonLabel = styled.label`
  position: absolute;
  top: 30%;
  left: 4px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: white;
  border: 1px solid #2D3037;
`;
const RadioButton = styled.input`
  opacity: 0;
  z-index: 1;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  margin-right: 15px;
  &:hover ~ ${RadioButtonLabel} {
    background: #bebebe;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      margin: 4px;
      background: #eeeeee;
    }
  }
  &:checked + ${RadioButtonLabel} {
    background: #FFFFFF;
    border: 1px solid #2D3037;
    &::after {
      content: "";
      display: block;
      border-radius: 50%;
      width: 12px;
      height: 12px;
      margin: 4px;
      box-shadow: 1px 3px 3px 1px rgba(0, 0, 0, 0.1);
      background: #51CB1D;
    }
  }
`;

class QuoteCategorySelection extends Component {
    constructor(props) {
        super(props);
        this.state = {
            category: "",
            categoryId: null,
            hasSelectedCategory: false,
            quoteCategoryInfo: [],
            loading: true,
            categoryToIdMap: {},
            idToCategoryMap: {},
            quoteCategories: []
        };
    }

    componentDidMount() {
        axios.get(`${API_URL}/company/quoteTypes`, {
            headers: {
                Authorization: "Bearer " + this.props.user.token
            }
        })
            .then(response => {
                // handle success
                const { quoteTypes, quoteTypeId } = response.data;
                this.setQuoteCategories(quoteTypes);

                // If the company has already chosen a category, set that value in the state
                if (quoteTypeId !== null) {
                    const category = this.state.idToCategoryMap[quoteTypeId];
                    this.setState({
                        quoteCategoryInfo: quoteTypes,
                        category: category,
                        categoryId: quoteTypeId,
                        hasSelectedCategory: true,
                        loading: false
                    });
                } else {
                    const category = this.state.idToCategoryMap[quoteTypeId];
                    this.setState({
                        quoteCategoryInfo: quoteTypes,
                        category: category,
                        categoryId: quoteTypeId,
                        loading: false
                    });
                }
            })
            .catch(error => {
                // handle error
                return Toast.error(error.message);
            });
    }

    setQuoteCategories = (quoteTypes) => {
        const quoteCategories = [];
        const categoryToIdMap = {};
        const idToCategoryMap = {};
        
        // Option used to clear any previously chosen preference
        categoryToIdMap["Me Quotes"] = null;
        idToCategoryMap["null"] = "Me Quotes";
        quoteCategories.push("Me Quotes");
        
        for (let i = 0; i < quoteTypes.length; i++) {
            const type = quoteTypes[i]["quoteType"];
            const id = quoteTypes[i]["id"]; 
            categoryToIdMap[type] = id;
            idToCategoryMap[id] = type;
            quoteCategories.push(type);
        }

        this.setState({
            categoryToIdMap: categoryToIdMap,
            quoteCategories: quoteCategories,
            idToCategoryMap: idToCategoryMap
        });
    };
    
    redirectToClientInvite = () => {
        const { history } = this.props;
        history.push("/invite");
    }; 

    setCategory = (e) => {
        const { value } = e.target;
        const categoryId = this.state.categoryToIdMap[value];
        this.setState({
            category: value,
            categoryId: categoryId,
            hasSelectedCategory: true
        });
    };

    saveSelection = () => {
        const categoryId = this.state.categoryId;
        this.setState({
            loading: true
        });
        // Make a post request to save the selected category for this company
        axios.put(`${API_URL}/company/setQuoteType`, {
            quoteTypeId: categoryId,
        }, {
            headers: {
                Authorization: "Bearer " + this.props.user.token
            }
        })
            .then(res => {
                this.setState({
                    loading: false
                });
                Toast.error("Quote Category has been selected successfully");
                this.redirectToClientInvite();
            })
            .catch(error => {
                return Toast.error(error.message);
            }); 
    };
    
    render() {
        const { quoteCategories, loading, hasSelectedCategory } = this.state;

        if (loading) {
            return <LottieLoadingIndicator />;
        }

        return (
            <FlexContainer justifyContent="center">
                <Text size="60px" color="#23262D" >
                    Quote Category Selection
                </Text>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <div style={{ width: "490px" }}>
                        <Text color="black" height="116px" style={{ marginTop: "0px" }} >
                            In this section you can pick a specific category of quotes that align with your business. Choose a category that best represents
                            your clients so they can relate, take action and reach their targets!
                        </Text>
                    </div>
                </div>
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <CategoryBox style={{ overflow: "scroll", height: "200px", width: "400px" }}>
                        { quoteCategories.map((category, index) => {
                            return ( 
                                <CategoryBox style={{ height: "50px" }} key={index}>
                                    <Item style={{ marginLeft: "20px" }}>
                                        <RadioButton type="radio" name="category" value={category} onChange={this.setCategory} checked={category === this.state.category} />
                                        <RadioButtonLabel />
                                        <Text color="black" style={{ marginLeft: "15px" }}>{category} </Text>
                                    </Item>                
                                </CategoryBox>
                            );
                        })}
                    </CategoryBox>
                </div>
                <FlexContainer justifyContent="center" direction="row" width="auto" style={{ marginTop: "60px" }} >
                    <StyledButton style={{ fontSize: "14px", marginRight: "10px", color: "black", backgroundColor: "transparent" }} onClick={this.redirectToClientInvite} >
                        Back
                    </StyledButton>
                    <StyledButton style={{ fontSize: "14px" }} onClick={this.saveSelection} disabled={!hasSelectedCategory} >
                        Save Selection
                    </StyledButton>
                </FlexContainer>
            </FlexContainer>
        );
    }
}

export default withRouter(QuoteCategorySelection);