import React, { useContext, useEffect, useRef, useState } from "react";
import { API_URL } from "src/scenes/App";
import axios from "axios";
import Select, { components } from "react-select";
import {
    Toast, FlexContainer, FormField, ErrorText,
    Text, Form, LottieLoadingIndicator
} from "src/components";

import {
    CancelButton, SaveButton,
    FormBackground, FormSectionHeader, LoadingIndicatorButton
} from "../components/Utils";
import useAds from "./hooks/useAds";
import { AdContext } from "../AdContext";
import { AdFormContext } from "./AdFormContext";
import { UserContext } from "src/scenes/App/UserContext";

import { ErrorList, ErrorStrings, PLACEMENT_OPTIONS } from "../components/Enums";
import { UserKeys } from "src/constants/userDetails";
import { AUDIENCE, FILTER_BY } from "../Advertising";

import Skeleton from "src/scenes/Statistics/components/Skeleton";
import ValidatedDateInput from "src/components/ValidatedDateInput";
import { customStyles, customErrorStyles } from "./components/DropdownCustomStyles";
import AdPrimaryImageUpload from "./components/AdPrimaryImageUpload";
import AdVerticalImageUpload from "./components/AdVerticalImageUpload";
import ImageSlider from "./components/ImageSlider";
import { FormAssetSection, FormGrid, FormLeftSection, FormRightBottomSection, FormRightSection, FormRightTopSection, PhoneContainer, TextArea } from "./components/StyledAdComponents";

// Form Components Fields
const HeadlineField = (props) => {
    const { headline, inputHeadline, inputErrors } = props;

    const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
    const headlineError = getErrorStrings(ErrorList.EMPTY_HEADLINE, ErrorList.HEADLINE_LIMIT);

    return (
        <>
            <Text size="1rem" align="left" weight="700">
                Headline
            </Text>
            <Text size="0.938rem" color="#612684" align="left">
                Make it catchy and relevant (up to 60 characters).
            </Text>
            <FormField 
                borderRadius="12px" 
                size="0.938rem" 
                padding="10px 20px" 
                height="50px"
                placeholderColor="#808080" 
                border="1px solid #E9EBEF"
                placeholderAlignment="left"
                width="100%"
                type="text"
                style={{ textAlign: "left", margin: "0px" }}
                placeholder="Enter your ad headline here"
                onChange={(event) => { inputHeadline(event.target.value); }}
                errors={headlineError}
                value={headline}
                showErrors={headlineError.length > 0}
            >
            </FormField>
        </>
    );
};

const ButtonTextField = (props) => {
    const { buttonText, inputButtonText, inputErrors } = props;

    const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
    const buttonTextError = getErrorStrings(ErrorList.EMPTY_BUTTON_TEXT, ErrorList.BUTTON_TEXT_LIMIT);

    return (
        <>
            <Text size="1rem" align="left" weight="700">
                Button Text
            </Text>
            <Text size="0.938rem" color="#612684" align="left">
                Make it catchy and relevant (up to 25 characters).
            </Text>
            <FormField 
                borderRadius="12px" 
                size="0.938rem" 
                padding="10px 20px" 
                height="50px"
                placeholderColor="#808080" 
                border="1px solid #E9EBEF"
                placeholderAlignment="left"
                width="100%"
                type="text"
                style={{ textAlign: "left", margin: "0px" }}
                placeholder='Enter button text (e.g., "Learn More")'
                onChange={(event) => { inputButtonText(event.target.value); }}
                errors={buttonTextError}
                value={buttonText}
                showErrors={buttonTextError.length > 0}
            >
            </FormField>
        </>
    );
};

const ButtonLinkField = (props) => {
    const { buttonLink, inputButtonLink, inputErrors } = props;

    const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
    const buttonLinkError = getErrorStrings(ErrorList.EMPTY_BUTTON_LINK);

    return (
        <>
            <Text size="1rem" align="left" weight="700">
                Button Link
            </Text>
            <Text size="0.938rem" color="#612684" align="left">
                The link where users will be directed when they click the button
            </Text>
            <FormField 
                borderRadius="12px" 
                size="0.938rem" 
                padding="10px 20px" 
                height="50px"
                placeholderColor="#808080" 
                border="1px solid #E9EBEF"
                placeholderAlignment="left"
                width="100%"
                type="text"
                style={{ textAlign: "left", margin: "0px" }}
                placeholder="Enter the URL for your button link"
                onChange={(event) => { inputButtonLink(event.target.value); }}
                errors={buttonLinkError}
                value={buttonLink}
                showErrors={buttonLinkError.length > 0}
            >
            </FormField>
        </>
    );
};

const AdDurationFields = (props) => {
    const { startsAt, endsAt, inputStartDate, inputFinishDate, inputErrors } = props;
    const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);

    const surveyStartDateError = getErrorStrings(ErrorList.EMPTY_START_DATE);
    const surveyEndDateError = getErrorStrings(ErrorList.EMPTY_END_DATE);

    const startDateInvalid = getErrorStrings(ErrorList.DATE_START_DATE_INVALID);
    const finishDateInvalid = getErrorStrings(ErrorList.DATE_END_DATE_INVALID);

    const dateStartFormatInvalid = getErrorStrings(ErrorList.DATE_START_FORMAT_INVALID);
    const dateFinishFormatInvalid = getErrorStrings(ErrorList.DATE_END_FORMAT_INVALID);

    const dateRangeInvalid = getErrorStrings(ErrorList.DATE_RANGE_INVALID);

    const hasAnySurveyDurationErrors = (surveyStartDateError.length > 0 || surveyEndDateError.length > 0 || startDateInvalid.length > 0 || finishDateInvalid.length > 0 || dateStartFormatInvalid.length > 0 || dateFinishFormatInvalid.length > 0 || dateRangeInvalid.length > 0);
    const today = new Date();
    return (
        <>
            <FlexContainer align="center" style={{ flexDirection: "row", justifyContent: "space-between", flexWrap: "wrap", columnGap: "24px" }}>
                <div style={{ textAlign: "center", marginLeft: "0px", flex: "1 1" }}>
                    <Text size="1rem" align="left" weight="700">
                        Start Date
                    </Text>
                    <ValidatedDateInput
                        placeholder="Select start date"
                        onChange={(event) => { inputStartDate(event); }}
                        errors={surveyStartDateError}
                        value={startsAt}
                        disabled={false}
                        minDate={today}
                        showErrors={hasAnySurveyDurationErrors}
                        size={"0.938rem"}
                        fontWeight={"400"}
                    />
                </div>
                <div style={{ textAlign: "center", marginLeft: "0px", flex: "1 1" }}>
                    <Text size="1rem" align="left" weight="700">
                        End Date
                    </Text>
                    <ValidatedDateInput
                        placeholder="Select end date"
                        onChange={(event) => { inputFinishDate(event); }}
                        errors={surveyEndDateError}
                        value={endsAt}
                        disabled={false}
                        minDate={startsAt}
                        showErrors={hasAnySurveyDurationErrors}
                        size="0.938rem"
                        fontWeight="400"
                    />
                </div>
            </FlexContainer>
            <Text color="#612684" align="left" size="0.938rem" margin="11px 0 18px 0">
                Choose when your ad campaign will begin and end.
            </Text>

            {hasAnySurveyDurationErrors &&
                <ErrorText errorMargin="0px">
                    {startDateInvalid.length > 0 ? startDateInvalid : null}
                    {finishDateInvalid.length > 0 ? finishDateInvalid : null}
                    {dateStartFormatInvalid.length > 0 ? dateStartFormatInvalid : null}
                    {dateFinishFormatInvalid.length > 0 ? dateFinishFormatInvalid : null}
                    {dateRangeInvalid.length > 0 ? dateRangeInvalid : null}
                </ErrorText>
            }
        </>
    );
};

const AudienceSelectionField = (props) => {
    const { inputErrors, selectAudience, loading, audience } = props;
    const AUDIENCE_OPTIONS = [
        { value: "Employees", label: "Employees" },
        { value: "Clients", label: "Clients" },
        { value: "Employees & Clients", label: "Employees & Clients" }
    ];
    
    const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
    const audienceError = getErrorStrings(ErrorList.EMPTY_AUDIENCE);

    return (
        <>
            <Text align="left" size="1rem" weight="700">
                Audience Selection
            </Text>
            <Text size="0.938rem" color="#612684" align="left">
                You can target employees, clients, or both.
            </Text>
            <FlexContainer>
                { loading ?
                    <Skeleton height="50px" width="100%" animation="wave" />
                    :
                    <Select
                        isSearchable={false}
                        className="surveyTypeDropdown"
                        defaultValue={AUDIENCE_OPTIONS[0].value}
                        placeholder={"Choose who can see this ad"}
                        value={ AUDIENCE_OPTIONS.find(obj => obj.value === audience) }
                        options={ AUDIENCE_OPTIONS }
                        onChange={(choice) => selectAudience(choice.value)}
                        styles={audienceError.length > 0 ? customErrorStyles : customStyles}
                    />
                }
                <ErrorText style={{ padding: "10px 20px", margin: "0px" }}>
                    {audienceError}
                </ErrorText>
            </FlexContainer>
        </>
    );
};

const CheckboxOption = (props) => {
    return (
        <components.Option {...props}>
            <label style={{ marginLeft: "10px" }}>{props.label}</label>
            <input
                type="checkbox"
                checked={props.isSelected}
                onChange={() => null} // React-Select handles this
                style={{ transform: "scale(1.5)", cursor: "pointer" }}
            />
        </components.Option>
    );
};

const PlacementSelectionField = (props) => {
    const { inputErrors, selectPlacement, loading, placement } = props;

    const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
    const placementError = getErrorStrings(ErrorList.EMPTY_PLACEMENT);

    return (
        <>
            <Text align="left" size="1rem" weight="700">
                Ad Placement
            </Text>
            <Text size="0.938rem" color="#612684" align="left">
                Choose where your ad will appear in the app.
            </Text>
            <FlexContainer>
                { loading ?
                    <Skeleton height="50px" width="100%" animation="wave" />
                    :
                    <Select
                        isSearchable={false}
                        isMulti
                        className="surveyTypeDropdown"
                        placeholder={"Where to display"}
                        value={ placement }
                        options={ PLACEMENT_OPTIONS }
                        onChange={(choice) => selectPlacement(choice) }
                        styles={placementError.length > 0 ? customErrorStyles : customStyles}
                        components={{ Option: CheckboxOption }}
                    />
                }
                <ErrorText style={{ padding: "10px 20px", margin: "0px" }}>
                    {placementError}
                </ErrorText>
            </FlexContainer>
        </>
    );
};

const AdDescriptionField = (props) => {
    const { description, inputDescription, inputErrors } = props;
    const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
    const descriptionError = getErrorStrings(ErrorList.EMPTY_DESCRIPTION, ErrorList.DESCRIPTION_LIMIT);

    return (
        <>
            <Text size="1rem" align="left" weight="700">
                Ad Description
            </Text>
            <Text size="0.938rem" color="#612684" align="left">
                Provide more details about your ad.
            </Text>
            <TextArea
                placeholder="Enter your ad description here"
                onChange={(event) => { inputDescription(event.target.value); }}
                errors={descriptionError}
                value={description}
                isHighlighted={descriptionError.length > 0}
                fontSize="0.938rem"
            />
            <ErrorText style={{ padding: "10px 20px", margin: "0px" }}>
                {descriptionError}
            </ErrorText>
        </>
    );
};

const AdvertisingForm = (props) => {
    const { adToEdit, fetchCompanyAds, handleHideAdForm, setFilterOption, setAudienceOption } = props;
    const currentUser = useContext(UserContext);
    const {
        requestS3PresignedUrl,
        uploadToS3,
        dataURLtoFile,
        validateAd,
        getLabelForPreview
    } = useAds(currentUser.token);
    const controllerRef = useRef(new AbortController());
    const { companyColors, companyColorsReady } = useContext(AdContext);
    const [inputErrors, setInputErrors] = useState([]);
    
    const [headline, setHeadline] = useState("");
    const [description, setDescription] = useState("");
    const [buttonText, setButtonText] = useState("");
    const [buttonLink, setButtonLink] = useState("");
    const [audience, setAudience] = useState("");
    const [placement, setPlacement] = useState("");
    const [startsAt, setStartsAt] = useState("");
    const [endsAt, setEndsAt] = useState("");
    const [formPrimaryAsset, setFormPrimaryAsset] = useState(null);
    const [formPrimaryAssetChanged, setFormPrimaryAssetChanged] = useState(false);
    const [formPrimaryAssetType, setFormPrimaryAssetType] = useState("");
    const [formVerticalAsset, setFormVerticalAsset] = useState(null);
    const [formVerticalHasAsset, setFormVerticalHasAsset] = useState(false);
    const [formVerticalAssetType, setFormVerticalAssetType] = useState("");
    const [isFormSending, setIsFormSending] = useState(false);

    const checkFileTypeOnLoad = (fileLink) => {
        if (fileLink.includes("image")) {
            return "image";
        }
        if (fileLink.includes("video")) {
            return "video";
        }
        return "";
    };

    useEffect(() => {
        if (adToEdit !== null) {
            setHeadline(adToEdit.headline);
            setDescription(adToEdit.description);
            setButtonText(adToEdit.buttonText);
            setButtonLink(adToEdit.buttonLink);
            setAudience(adToEdit.audience);
            const selectedPlacementOptions = PLACEMENT_OPTIONS.filter(option => 
                adToEdit.placement.includes(parseInt(option.value))
            );
            setPlacement(selectedPlacementOptions);
            setStartsAt(adToEdit.startsAt);
            setEndsAt(adToEdit.endsAt);
            setFormPrimaryAsset(adToEdit.primaryAsset.assetUrl);
            setFormPrimaryAssetType(checkFileTypeOnLoad(adToEdit.primaryAsset.assetType));
            if (adToEdit.verticalAsset) {
                setFormVerticalAsset(adToEdit.verticalAsset.assetUrl);
                setFormVerticalAssetType(checkFileTypeOnLoad(adToEdit.verticalAsset.assetType));
            }
        }
    }, [adToEdit]);

    const inputStartDate = (selectedDate) => {
        setStartsAt(selectedDate);
    };

    const inputFinishDate = (selectedDate) => {
        setEndsAt(selectedDate);
    };

    const handleFormSubmission = () => {
        adToEdit ? handleFormSubmissionLogic("edit") : handleFormSubmissionLogic("create");
    };

    const handleFormSubmissionLogic = async (type) => {
        const formatter = Intl.DateTimeFormat("fr-CA", { year: "numeric", month: "2-digit", day: "2-digit" });
        const formattedStartDate = startsAt ? formatter.format(new Date(startsAt)) : "";
        const formattedEndDate = endsAt ? formatter.format(new Date(endsAt)) : "";
        const formattedPlacementValue = placement ? JSON.stringify(placement.map(item => Number(item.value))) : [];
        const isEditingEntry = type === "edit";

        let formRequest = {
            headline,
            description,
            audience,
            buttonText,
            buttonLink,
            placement: formattedPlacementValue,
            startsAt: `${formattedStartDate} 00:00:00`,
            endsAt: `${formattedEndDate} 23:59:59`,
        };

        let adStatusType = FILTER_BY.ACTIVE_ADS;
        if (startsAt > new Date) {
            adStatusType = FILTER_BY.SCHEDULED_ADS;
        }

        if (controllerRef.current) {
            controllerRef.current.abort();
        }

        setIsFormSending(true);
        controllerRef.current = new AbortController();

        const errors = validateAd(formRequest, formPrimaryAsset);
    
        if (errors.length > 0) {
            setInputErrors(errors);
            setIsFormSending(false);
            return;
        }
    
        const processAssetUpload = async (selectedFile, assetType, controllerRef) => {
            const fileToUpload = dataURLtoFile(selectedFile, `ad-${assetType}-asset`);
            const fileType = fileToUpload.type.replace(/^(image|video)\//, "");

            const presignedUrl = await requestS3PresignedUrl(fileType, controllerRef);
            const uploadedS3Url = await uploadToS3(presignedUrl, fileToUpload, controllerRef);
            return uploadedS3Url;
        };
    
        if (formPrimaryAssetChanged) {
            formRequest.primaryAssetUrl = await processAssetUpload(formPrimaryAsset, "primary", controllerRef);
        } else {
            if (formPrimaryAsset) {
                formRequest.primaryAssetUrl = formPrimaryAsset;
            } else {
                Toast.error(ErrorStrings[ErrorList.EMPTY_PRIMARY_ASSET]);
                setIsFormSending(false);
                return;
            }
        }

        if (formVerticalHasAsset) {
            formRequest.verticalAssetUrl = await processAssetUpload(formVerticalAsset, "vertical", controllerRef);
        } else {
            formRequest.verticalAssetUrl = formVerticalAsset;
        }


        const apiUrl = isEditingEntry ? `${API_URL}/advertising/${adToEdit.uuid}` : `${API_URL}/advertising`;
        const apiMethod = isEditingEntry ? axios.put : axios.post;

        apiMethod(apiUrl, formRequest, {
            headers: { Authorization: `Bearer ${currentUser[UserKeys.TOKEN]}` },
            signal: controllerRef.current.signal,
        })
            .then(() => {
                Toast.success(`Ad ${isEditingEntry ? "updated" : "created"} successfully`);
                handleHideAdForm();
                setIsFormSending(false);
                setFilterOption(adStatusType);
                const urlEncodedAudience = (Object.values(AUDIENCE).find(i => i === audience.replace("&", "%26")));
                setAudienceOption(urlEncodedAudience);
                fetchCompanyAds(adStatusType, urlEncodedAudience);
            })
            .catch((error) => {
                Toast.error(error);
                setIsFormSending(false);
            });
    };

    return (
        <FormBackground>
            <Form width="inherit" onSubmit={(e) => { e.preventDefault(); }} style={{ padding: "2.5rem 0" }} >
                <FormGrid>
                    <FormLeftSection>
                        <FormSectionHeader style={{ fontWeight: "600" }}>
                            Ad Details
                        </FormSectionHeader>
                        <HeadlineField headline={headline} inputHeadline={setHeadline} inputErrors={inputErrors} />
                        <AdDescriptionField description={description} inputDescription={setDescription} inputErrors={inputErrors} />
                        <ButtonTextField buttonText={buttonText} inputButtonText={setButtonText} inputErrors={inputErrors} />
                        <ButtonLinkField buttonLink={buttonLink} inputButtonLink={setButtonLink} inputErrors={inputErrors} />
                        <AudienceSelectionField selectAudience={setAudience} inputErrors={inputErrors} audience={audience} />
                        <PlacementSelectionField selectPlacement={setPlacement} inputErrors={inputErrors} placement={placement} />
                        <AdDurationFields inputStartDate={inputStartDate} inputFinishDate={inputFinishDate} inputErrors={inputErrors} startsAt={startsAt} endsAt={endsAt} isEditForm={ adToEdit !== null } />
                    </FormLeftSection>
                    <FormRightSection>
                        <FormRightTopSection>
                            <FormSectionHeader style={{ fontWeight: "600" }}>
                                Upload Image/Video
                            </FormSectionHeader>
                            <FormAssetSection>
                                <AdPrimaryImageUpload
                                    asset={formPrimaryAsset}
                                    setFormPrimaryAsset={setFormPrimaryAsset}
                                    setImageChanged={setFormPrimaryAssetChanged}
                                    setFormPrimaryAssetType={setFormPrimaryAssetType}
                                    inputErrors={inputErrors}
                                    assetType={formPrimaryAssetType}
                                />
                                <AdVerticalImageUpload
                                    asset={formVerticalAsset}
                                    setFormVerticalAssetType={setFormVerticalAssetType}
                                    setFormVerticalAsset={setFormVerticalAsset}
                                    setImageChanged={setFormVerticalHasAsset}
                                    assetType={formVerticalAssetType}
                                />
                            </FormAssetSection>
                        </FormRightTopSection>
                        <FormRightBottomSection>
                            <div>
                                <FormSectionHeader style={{ fontWeight: "600" }}>
                                    Preview Ad
                                </FormSectionHeader>
                            </div>
                            <AdFormContext.Provider value={{
                                headlineContext: headline,
                                descriptionContext: description,
                                buttonTextContext: buttonText,
                                formPrimaryAssetContext: formPrimaryAsset,
                                formPrimaryAssetChangedContext: formPrimaryAssetChanged,
                                formPrimaryAssetTypeContext: formPrimaryAssetType,
                                formVerticalAssetContext: formVerticalAsset,
                                formVerticalAssetTypeContext: formVerticalAssetType,
                                formVerticalHasAssetContext: formVerticalHasAsset,
                                isEditForm: adToEdit !== null,
                                companyColors: companyColors
                            }}>
                                {
                                    !companyColorsReady ? <Skeleton width="45%" height="500px" animation="wave" /> :
                                        <PhoneContainer>
                                            <ImageSlider
                                                companyName={currentUser.companyName}
                                                logoTopOffset={45}
                                                phoneFrameTopOffset={30}
                                                assetLogoUrl={currentUser.companyLogoUrl}
                                                getLabelForPreview={getLabelForPreview}
                                                companyColors={companyColors}
                                            />
                                        </PhoneContainer>
                                }
                            </AdFormContext.Provider>
                        </FormRightBottomSection>
                    </FormRightSection>
                </FormGrid>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", padding: "0 3.75rem" }}>
                    {
                        isFormSending ?
                            <LoadingIndicatorButton height="38px">
                                <LottieLoadingIndicator height="2rem" width="2rem" margin="5px" containerHeight="2rem" />
                            </LoadingIndicatorButton>
                            :
                            <>
                                <CancelButton borderRadius="7px" onClick={(e) => handleHideAdForm() }>Cancel</CancelButton>
                                <SaveButton border="unset !important" color="white" backgroundColor="#006CFF" borderRadius="7px" onClick={(e) => { handleFormSubmission(); }}>Save</SaveButton>
                            </>
                    }
                </div>
            </Form>
        </FormBackground>
    );
};

export default AdvertisingForm;