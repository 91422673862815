import { useContext, useEffect, useState } from "react";
import axios from "axios";
import * as sdk from "matrix-js-sdk";
import { API_URL } from "src/scenes/App";
import { UserContext } from "src/scenes/App/UserContext";
import bootstrapOlm from "../../Olmbootstrapper";
import { Buffer } from "buffer";
import { LocalStorageCryptoStore, ClientEvent, RoomEvent, EventStatus } from "matrix-js-sdk";
import { Toast } from "src/components";
import { KnownMembership } from "matrix-js-sdk/lib/@types/membership";
import { ADMIN_MATRIX_ID } from "src/constants/chat";
import { IsFeatureReady } from "src/constants/features";
import MatrixChat from "src/scenes/Chat/models/MatrixChat";

window.Buffer = Buffer;

export const useMatrixClient = (headers) => {
    const userContext = useContext(UserContext);
    const [matrixClient, setMatrixClient] = useState(null);
    const [isInitialSyncComplete, setIsInitialSyncComplete] = useState(false);

    useEffect(() => {

        let createdMatrixClient = null;

        const initializeMatrixClient = async () => {
            try {
                const storage = new LocalStorageCryptoStore("matrix_storage");
                const session = await registerClient(headers);
                const client = createClient(
                    API_URL,
                    userContext.token,
                    session.userId, 
                    session.deviceId,
                    storage,
                );
                createdMatrixClient = client;

                if (IsFeatureReady.chatEncryption()) {
                    await initializeOlm();
                    await client.initCrypto(); /** client.initRustCrypto() is failing on fresh seed. */
                    /** TODO: initRustCrypto() should be implemented before releasing to production */

                    client.setGlobalErrorOnUnknownDevices(false);
                }
                
                await client.startClient({ 
                    initialSyncLimit: 10, 
                    disablePresence: false //FUTURE TODO: make this to true, if sync.ts -> opts.disablepresence has a way to be false. start matrix client as offline, usePresenceListener::useEffect() will update it later
                });
                
                setMatrixClient(client);
                await initialSync(client);
            } catch (error) {
                console.error(error);
                return Toast.error("Failed to initialize Matrix client.");
            }
        };

        initializeMatrixClient();

        return () => {
            if (createdMatrixClient) {
                console.log("Cleaning up Matrix client");
                createdMatrixClient.stopClient();
            } else {
                console.log("Matrix client is not set, no cleanup needed");
            }
        };
    }, []);

    const initialSync = async (client) => {
        client.once(ClientEvent.Sync, (state, prevState, res) => {
            if (state === "PREPARED") {
                setIsInitialSyncComplete(true);

                if (!isInitialSyncComplete) {
                    //console.clear();
                }
            }
            else {
                process.exit(1);
            }
        });
    };

    const runListener = (client, removeRoomInMatrixChatList, upsertRoomInMatrixChatList, upsertRoomTimeline, placedRoomToIndexZeroOfMatrixChatList, openedMatrixChat, setOpenedMatrixChat) => {
        try {

            /**
             * Fires whenever a new Room is added. This will fire when you are invited to a
             * room, as well as when you join a room. <strong>This event is experimental and
             * may change.</strong>
             * @param room - The newly created, fully populated room.
             */
            client.on("Room", function(room) {
                const roomId = room.roomId;
                const latestRoomEvent = room.getLastLiveEvent();
                console.warn("A new Room is added: ", roomId);
                addToMatrixChatList(latestRoomEvent);
            });

            /**
             * Fires whenever a Room is removed. This will fire when you forget a room.
             * <strong>This event is experimental and may change.</strong>
             * @param roomId - The deleted room ID.
             */
            client.on("deleteRoom", function(roomId) {
                console.warn("A `deleteRoom` event triggered for this roomId: ", roomId);
                removeRoomInMatrixChatList(roomId);
                closeChatMessageContainerForDeletedRoom(roomId);
            });
            
            client.on("Room.timeline", (event, room) => {
                /**
                 * Room.timeline event order when initializing a room
                 * 1. m.room.create
                 * 2. m.room.member
                 * 3. m.room.power_levels
                 * 4. m.room.join_rules
                 * 5. m.room.history_visibility
                 * 6. m.room.encryption
                 */

                console.warn("[Room.timeline][" + event.getType() + "]");
                /*
                if (event.getType() === "m.room.encryption") {
                    const algorithm = event.getContent().algorithm;
                    console.warn(`Room ${room.roomId} is encrypted using ${algorithm}`);
                    // Encryption has been enabled (or the room is already encrypted)
                    //updateMatrixChatListForThisRoom(room.roomId);
                } else if (event.getType() === "m.room.member") {
                    const membership = event.getContent().membership;
                    const userId = event.getStateKey();
                    if (event.isEncrypted()) { // Check if room is encrypted before assuming it is.
                        console.warn(`User ${userId} ${membership}ed encrypted room ${room.roomId}. Key exchange will occur automatically.`);
                    }
                } */
            });

            const addToMatrixChatList = (addMatrixEvent) => {
                console.warn(`[m.room.member] ${addMatrixEvent.getStateKey()} joining ${addMatrixEvent.getRoomId()} in matrixChatList`);
                upsertRoomInMatrixChatList(addMatrixEvent.getRoomId());
            };

            /**
             * 
             * triggered from 
             * useDeleteChat::handleChatDeletion() -> MatrixChat.deleteMatrixRoom() @param {} leaveMatrixEvent 
             * @returns 
             */
            const removeToMatrixChatList = (leaveMatrixEvent) => {
                console.warn(`[m.room.member] ${leaveMatrixEvent.getStateKey()} leaving ${leaveMatrixEvent.getRoomId()} in matrixChatList`, matrixClient.getUserId());
                if ([matrixClient.getUserId(), ADMIN_MATRIX_ID].includes(leaveMatrixEvent.getStateKey())) {
                    closeChatMessageContainerForDeletedRoom(leaveMatrixEvent.getRoomId());
                    removeRoomInMatrixChatList(leaveMatrixEvent.getRoomId());
                    return;
                } 
            };

            const updateRoomDetailsAndChatMessageTimeline = async (event) => {
                await client.decryptEventIfNeeded(event);
                upsertRoomInMatrixChatList(event.getRoomId());
                upsertRoomTimeline(event);
                placedRoomToIndexZeroOfMatrixChatList(event.getRoomId());
            };

            const closeChatMessageContainerForDeletedRoom = (roomId) => {
                if (openedMatrixChat instanceof MatrixChat && openedMatrixChat.matrixRoomId === roomId) {
                    setOpenedMatrixChat(null);
                }
            };

            client.on("event", (event) => {
                console.log("[Event][" + event.getType() + "]");

                if (event.getType() === "m.room.encrypted") {
                    // This is an encrypted message
                    console.warn("Encrypted message received:", event);
                    updateRoomDetailsAndChatMessageTimeline(event);
                    return;

                } else if (event.getType() === "m.room.message") {
                    updateRoomDetailsAndChatMessageTimeline(event);
                    return;

                } else if (event.getType() === "m.room.member") {
                    const membership = event.getContent()?.membership;
                    if (membership === KnownMembership.Join) {
                        //addToMatrixChatList(event) moved in client.on("Room")
                        return;
                    } else if (membership === KnownMembership.Leave) {
                        removeToMatrixChatList(event);
                        return;
                    }

                    return;
                } else if (event.getType() === "m.presence") {
                    //see usePresenceListener::presenceHandler()
                    return;
                }
            });

            client.on(RoomEvent.LocalEchoUpdated,
                /**
                 * a confirmation that the message have been delivered to the server
                 * ChatMessages will replace the local echo with this new event.
                 * @param {MatrixEvent} event
                 * @param {Room} room
                 * @param {string} oldEventId
                 */
                (event, room, oldEventId, oldStatus) => {
                    console.log("[Room.localEchoUpdated][" + event.status + "]");
                    
                    if (event.status === EventStatus.SENDING) {
                        updateRoomDetailsAndChatMessageTimeline(event);
                    }
                });
        } catch (err) {
            console.error("Error in useMatrixClient > runListener", err);
        }
    };

    return { 
        matrixClient,
        isInitialSyncComplete,
        runListener
    };
};

const registerClient = async (headers) => {
    const response = await axios.get(`${API_URL}/chats/registration`, headers);
    return response.data.session;
};

/**
 * @returns {import("matrix-js-sdk/lib/client").MatrixClient}
 */
const createClient = (baseUrl, token, userId, deviceId, storage) => {
    return sdk.createClient({
        baseUrl: baseUrl,
        accessToken: token,
        userId: userId,
        deviceId: deviceId,
        storage,
        cryptoCallbacks: {
            setup: true,
        }
    });
};

const initializeOlm = async () => {
    return bootstrapOlm().then(() => console.log("Olm initialized."))
        .catch(err => console.error("Olm failed to initialize."));
};