import React from "react";
import styled from "styled-components";
import ReactDOM from "react-dom";
import { 
    LottieLoadingIndicator
} from "src/components";

const MODAL_CONTAINER_ID = "modal-container";

const Body = styled.div`
    height: 26rem;
    width: 48rem;
    max-height: 75vh;
    max-width: 90vw;
    pointer-events: auto;       
    
    display: grid;
    grid-template-columns: 12% 76% 12%;
    grid-template-rows: 22% 48% 30%;
    
    border-radius: 2.5rem;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 10px 30px #00000029;
    
    margin: auto;
`;

const DismissButton = styled.div`     
    pointer-events: none; 
   
    background: #2D3037 0% 0% no-repeat padding-box;
    height: 2rem;
    width: .5rem;
    border-radius: .2rem;
    transform: translate(1.25rem, .5rem) rotateZ(50grad);
    
    :after {
        position: absolute;
        background: #2D3037 0% 0% no-repeat padding-box;
        content: "";
        top: .75rem;
        left: -.75rem;
        height: .5rem;
        width: 2rem;
        border-radius: .2rem;
    }
`;

const DismissButtonContainer = styled.div`    
    grid-column: 3;
    grid-row: 1;
    
    pointer-events: auto;
    cursor: pointer; 

    height: 3rem;
    width: 3rem;
    margin: auto;
`;

const ModalContainer = styled.div`
    position: fixed;
    display: flex;
    flex-direction: row;
    justify-content: center; 
    z-index: 996;  
    width: 100%;
    height: 100%;  
    pointer-events: none; 
`;

const Container = styled.div`
    position: fixed;
    top: 0; 
    left: 0; 
    width: 100vw;
    height: 100vh;
    z-index: 994;
    
    animation-timing-function: ease-out;
    animation-name: fade-in;
    animation-duration: .1s;
    animation-fill-mode: forwards;
    
    @keyframes fade-in {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`;

const Overlay = styled.div`
    position: fixed;
    top: 0; 
    left: 0; 
    background-color: rgba(0,0,0,.5);
    z-index: 995;  
    width: 100%;
    height: 100%;  
    pointer-events: auto;
    cursor: ${ p => (p.dismissable && "pointer") || "normal" };
`;

const ActionButtonContainer = styled.div`
    grid-column: 2;
    grid-row: 3;

    margin: auto;
`;

const ActionButton = styled.div`
    display: inline-block;
    padding: 0 1rem;
    min-width: 9rem;
    height: 2.5rem;
    
    background: #2D3037 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 1.25rem;
    
    text-align: center;
    font: Regular 1rem Roboto, Helvetica, sans-serif;
    letter-spacing: 0.35px;
    line-height: 2.5rem;
    color: #FFFFFF;
    
    pointer-events: auto;
    cursor: pointer;  
    user-select: none;
    background-color: ${ p => p.backgroundColor || "#51315D"};
`;

const CancelButton = styled.div`
    display: inline-block;
    padding: 0 1rem;
    min-width: 9rem;
    height: 2.5rem;
    box-shadow: 0px 3px 6px #00000029; 
    text-align: center;
    font: Regular 1rem Roboto, Helvetica, sans-serif;
    letter-spacing: 0.35px;
    line-height: 2.5rem;
    pointer-events: auto;
    cursor: pointer;  
    user-select: none;

    color: ${ p => p.color || "#FFFFFF"};

    background: #2D3037 0% 0% no-repeat padding-box;
    background-color: ${ p => p.backgroundColor || "#51315D"};

    border-radius: 1.25rem;
    border:  ${ p => p.border || "1px solid #000000;"};
    margin-right: 10px;
`;

const Header = styled.div`
    grid-column: 2;
    grid-row: 1;
    
    margin: auto auto 0 auto;   
    
    text-align: center;
    font: 500 2rem Roboto, Helvetica, sans-serif;
    letter-spacing: 0.75px;
    color: #23262D;
`;

const TextBody = styled.div`
    grid-column: 2;
    grid-row: 2;
    
    text-align: center; 
    font: 1rem Roboto, Helvetica, sans-serif;
    letter-spacing: 0;
    color: #000000;
    
    padding-top: 1rem;
    margin: auto auto;
`;

const createModal = (title, message, callback, actionName, easyDismiss, modalLoading) => {
    const modalContainer = document.createElement("div");

    ConfirmModal.instance.appendChild(modalContainer);

    const dismissEvent = () => {
        modalContainer.parentNode.removeChild(modalContainer);
    };

    const modalBody = (loading) => {
        return (
            <Body>
                <DismissButtonContainer onClick={dismissEvent}>
                    <DismissButton/>
                </DismissButtonContainer>
                <Header>
                    {title}
                </Header>
                <TextBody>
                    {message}
                </TextBody>

                {
                    loading ?
                        <LottieLoadingIndicator height="20px" width="20px"/>
                        :
                        <ActionButtonContainer>
                            <CancelButton backgroundColor="#FFFFFF" color="#000" onClick={dismissEvent}>
                        Cancel
                            </CancelButton>
                            <ActionButton backgroundColor="#ff6464" onClick={callback}>
                                {actionName}
                            </ActionButton>
                        </ActionButtonContainer>
                }
                
            </Body>
        );
    };

    ReactDOM.render(
        <Container>
            <Overlay onClick={easyDismiss ? dismissEvent : null} dismissable={easyDismiss}/>
            <ModalContainer>
                { modalBody(modalLoading) }
            </ModalContainer>
        </Container>
        ,
        modalContainer
    );
};

class ConfirmModal {
    static instance;

    static init() {
        let exists = document.getElementById(MODAL_CONTAINER_ID);

        if (exists) {
            ConfirmModal.instance = exists;
            ConfirmModal.instance.id = MODAL_CONTAINER_ID;
            return;
        }

        ConfirmModal.instance = document.createElement("div");
        ConfirmModal.instance.id = MODAL_CONTAINER_ID;
        document.body.appendChild(ConfirmModal.instance);
    }

    /** There can only every be one modal active at a time. */
    static open(title = "", message = "", callback = null, actionName = null, easyDismiss = true) {
        if (!ConfirmModal.instance || !document.getElementById(MODAL_CONTAINER_ID)) {
            ConfirmModal.init();

            console.log("ConfirmModal.init();", ConfirmModal.instance, !document.getElementById(MODAL_CONTAINER_ID));
        }

        createModal(title, message, callback, actionName, easyDismiss);
    }

    /** Allows for programmatic closing of a modal. */
    static close() {
        if (!ConfirmModal.instance || !document.getElementById(MODAL_CONTAINER_ID)) {
            console.warn("There is no modal to close.");
            return;
        }

        let modal = document.getElementById(MODAL_CONTAINER_ID);
        if (modal) {
            modal.parentNode.removeChild(modal);
        }
    }
}

export default ConfirmModal;
