import React, { Component } from "react";
import { FlexContainer, Text, PageContainer, ExplainParagraphLabel, PageHeadingLabel } from "src/components";
import { SALES_AND_PROMOTION_HEADING } from "./SalesAndPromotionHeader";

class Information extends Component {
    render() {
        return (
            <PageContainer>

                <PageHeadingLabel>
                    { SALES_AND_PROMOTION_HEADING }
                </PageHeadingLabel>
                <div style={{ marginBottom: "3em" }}>
                    <ExplainParagraphLabel style={{ fontSize: "0.8750em", width: "50vw" }}>
                    Sales & Promotions allows you to provide short term access to your Me Business app to clients. You must 
                    purchase trial licenses to add this type of client to this list. Client Trial Licenses are different to your main 
                    business users as they are short term licenses and provide a limited period of 
                    access to your Me Business app.
                    </ExplainParagraphLabel>
                </div>
                    
                <FlexContainer style={{ maxWidth: "1400px", backgroundColor: "#FFF", borderRadius: "12px", padding: "20px 40px 40px", width: "60vw" }}>
                    
                    <Text size="1.25em" align="left" weight="700" margin="1em 0">
                        How to Gain Access to Sales & Promotions:
                    </Text>

                    <Text size="0.8750em" align="left" margin="0 0 1em 0">
                        This feature is currently disabled. If you would like to leverage the Sales & Promotions 
                        feature and start offering trial licenses to your clients, we invite you to contact our 
                        support team at ‘Me Business.’ Our support team will promptly review your request and 
                        provide you with the necessary steps to enable Sales & Promotions for your account.
                    </Text>

                    <a href="https://www.memotivationapp.com/contact" target="_blank" rel="noopener noreferrer" style={{ width: "130px", borderRadius: "10px", color: "#FFF", fontSize: "0.9375em", fontWeight: "500", padding: "10px 0px", textDecoration: "none", textAlign: "center", backgroundColor: "#4956FC", margin: "1em 0em" }}>
                        Contact Us
                    </a>
                </FlexContainer>
            </PageContainer>
        );
    }
}

export default Information;