import React, { useState, useContext } from "react";
import { FlexContainer, LottieLoadingIndicator } from "src/components";
import DigitalCompanyCardContext from "src/scenes/DigitalMemberCard/DigitalCompanyCardContext";
import { PanelContainer, LeftPanel, RightPanel, CustomizeButton, Heading, Description, FormLabel, FormDescription } from "src/scenes/DigitalMemberCard/components/StyledComponents";
import EmployeeAppleCard from "src/scenes/DigitalMemberCard/components/EmployeeAppleCard";
import EmployeeGoogleCard from "src/scenes/DigitalMemberCard/components/EmployeeGoogleCard";
import SelectMembershipCardColor from "src/scenes/DigitalMemberCard/components/SelectMembershipCardColor";
import SelectArtWorkImageAndUpload from "src/scenes/DigitalMemberCard/components/SelectArtWorkImageAndUpload";
import useSaveCustomisation from "src/scenes/DigitalMemberCard/hooks/useSaveCustomisation";

const CustomiseUserTypeCard = ({ isClient }) => {
    const pageLabel = !isClient ? "Customise Your Employee Card" : "Customise Your Client Card";
    const userType = !isClient ? "employee" : "client";
    const userTypes = userType + "s";

    const { 
        user,
        renderDisplayCards,
        digitalMembershipCards,
        getCardTemplateByUserType,
        fetchDigitalMemberCard,
        useGetHeroImages
    } = useContext(DigitalCompanyCardContext);
    
    const { 
        businessHeroImages, 
        setBusinessHeroImages,
        meHeroImages,
        isFetchingHeroImages,
        fetchHeroImages
    } = useGetHeroImages;
    
    const { saveCustomCardSettings, isSavingCustomisation } = useSaveCustomisation(user.token);

    const cardTemplateInformation = getCardTemplateByUserType(isClient);
    const customCardColor = cardTemplateInformation.backgroundColor;
    const [cardPreviewBackgroundColor, setCardPreviewBackgroundColor] = useState(customCardColor);
    const [cardPreviewHeroImageData, setCardPreviewHeroImageData] = useState(cardTemplateInformation.cardHeroImage);
    const [cardPreviewTextColor, setCardPreviewTextColor] = useState(cardTemplateInformation.textColor);
    
    const handleSave = async () => {
        const formData = {
            customCardColor: cardPreviewBackgroundColor,
            customCardHeroData: cardPreviewHeroImageData,
            forClient: isClient
        };

        await saveCustomCardSettings(formData);
        await Promise.all([fetchDigitalMemberCard(), fetchHeroImages()]); 
        renderDisplayCards();
    };
    
    return (
        <PanelComponent isFetchingHeroImages={isFetchingHeroImages}>
            <LeftPanel>
                <div className="spacing-x spacing-y borderBottom">
                    <div className="maxWidth" >

                        <div style={{ marginBottom: "20px" }}>
                            <Heading>{pageLabel}</Heading>
                            <Description>
                                Create a unique digital ID for your {userTypes} with your<br />
                                company branding.
                            </Description>
                        </div>

                        <div>
                            <FormLabel>Default Template</FormLabel>
                            <FormDescription>
                                This template uses your company’s logo and background colour for a clean, 
                                professional look. Easily customise further or use as is for your {userType} cards.
                            </FormDescription>
                        </div>
                        <br />

                        <div>
                            <SelectMembershipCardColor 
                                businessLogo={digitalMembershipCards.businessLogo} 
                                businessBackgroundColor={digitalMembershipCards.businessBackgroundColor} 
                                customBackgroundColor={cardTemplateInformation.backgroundColor}
                                cardPreviewBackgroundColor={cardPreviewBackgroundColor}
                                setCardPreviewBackgroundColor={setCardPreviewBackgroundColor}
                                setCardPreviewTextColor={setCardPreviewTextColor}
                            />
                        </div>
                    </div>
                </div>

                <div className="maxWidth-680">
                    <div className="spacing-x spacing-y" style={{ paddingBottom: "unset" }}>
                        <FormLabel>Choose Your Card Hero Image (Optional)</FormLabel>
                        <FormDescription>Upload your custom thumbnail or hero image to display on your Apple or Google <br/>Wallet member card pass.</FormDescription>
                    </div>

                    <SelectArtWorkImageAndUpload 
                        cardHeroUuid={cardTemplateInformation.cardHeroUuid}
                        businessHeroImages={businessHeroImages} 
                        setBusinessHeroImages={setBusinessHeroImages} 
                        meHeroImages={meHeroImages} 
                        isFetchingHeroImages={isFetchingHeroImages} 
                        setCardPreviewHeroImageData={setCardPreviewHeroImageData} 
                    />
                </div>
            </LeftPanel>
            <RightPanel>
                <div className="spacing-x spacing-y">
                    <Heading>Card Preview</Heading>
                    <Description>
                        See a real-time preview of your customised {userType} <br/>
                        card with your selected design, logo, and details.
                    </Description>
                    <br />
                    <div>
                        <FormLabel fontSize="14px">Preview</FormLabel>
                        <br />
                    </div>

                    <FlexContainer alignItems="center" mDirection="column" tDirection="column" direction="column" width="100%" gap="10px">
                        <EmployeeAppleCard 
                            isTemplateForClientPreview={isClient} 
                            cardPreviewBackgroundColor={cardPreviewBackgroundColor}
                            cardPreviewHeroImage={cardPreviewHeroImageData ? cardPreviewHeroImageData.imageUrl : null} 
                            cardPreviewTextColor={cardPreviewTextColor}
                        />
                        <div style={{ fontSize: "0.875rem" }}>Apple Wallet</div>
                        <br/>
                        <EmployeeGoogleCard 
                            isTemplateForClientPreview={isClient} 
                            cardPreviewBackgroundColor={cardPreviewBackgroundColor}
                            cardPreviewHeroImage={cardPreviewHeroImageData ? cardPreviewHeroImageData.imageUrl : null} 
                            cardPreviewTextColor={cardPreviewTextColor}
                        />
                        <div style={{ fontSize: "0.875rem" }}>Google Wallet</div>
                        <br/>
                    </FlexContainer>
                    <FlexContainer mDirection="row" tDirection="row" direction="row" width="100%" justifyContent="center" gap="20px" marginTop="3em">
                        <CustomizeButton onClick={renderDisplayCards} disabled={isSavingCustomisation}>Cancel</CustomizeButton>
                        <CustomizeButton color="#fff" backgroundColor="#000" onClick={handleSave} disabled={isSavingCustomisation} >{isSavingCustomisation ? "Saving..." : "Save"}</CustomizeButton>
                    </FlexContainer>
                </div>
            </RightPanel>
        </PanelComponent>
    );
}; 

const PanelComponent = ({ isFetchingHeroImages, children }) => {
    const isFetching = (isFetchingHeroImages === true || isFetchingHeroImages === null);
    return (
        <PanelContainer>
            { isFetching && <LottieLoadingIndicator />}
            { !isFetching && children }
        </PanelContainer>
    );
};

export default CustomiseUserTypeCard;