import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { FlexContainer } from "src/components";
import { FormLabel } from "src/scenes/DigitalMemberCard/components/StyledComponents";
import DigitalCompanyCardContext from "src/scenes/DigitalMemberCard/DigitalCompanyCardContext";
import useDeleteMembershipCard from "src/scenes/DigitalMemberCard/hooks/useDeleteMembershipCard";
import UploadHeroImage from "./UploadHeroImage";
import greenCheckImg from "src/img/check@2x.png";
import removeHeroImg from "src/img/remove-hero-image.png";

const HeroContainer = styled.div`
    width: 131px;
    height: 82px;
    border: 2px solid #E0E0E0;
    
    border-radius: 9px;
    flex-shrink: 0;
    box-shadow: 0px 3px 6px #00000029;
    background-image: url(${ p => p.backgroundImg});
    background-position: center center;
    background-size: cover;
    border: ${p => p.isSelected && "2px solid #36BE24"};
    cursor: pointer;
    opacity: ${p => p.isDeleting && "0.2"}; 
    box-shadow: 0px 3px 6px #00000029;
`;

const NoImageHeroContainer = styled(HeroContainer)`
    border-width: 2px;
    border-style: dashed;
    border-color: ${ p => p.isSelected ? "#36BE24" : "#000000" };
    background-image: none;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 0.875rem;
`;

const GreenCheckImgContainer = styled.span`
    position: absolute;
    left: 42%;
    bottom: 10px;
    & img {
        width: 25px;
        position: absolute;
    }
`;

const RemoveImgIconContainer = styled.span`
    position: absolute;
    left: 90%;
    top: -8px;
    & img {
        width: 28px;
        position: absolute;
        cursor: pointer;
    }
`;

const SelectArtWorkImageAndUpload = ({ cardHeroUuid, businessHeroImages, setBusinessHeroImages, meHeroImages, setCardPreviewHeroImageData }) => {
    const { 
        digitalMembershipCards,
        fetchDigitalMemberCard
    } = useContext(DigitalCompanyCardContext);

    const businessName = digitalMembershipCards.businessName;
    
    const [selectedHeroUuid, setSelectedHeroUuid] = useState(cardHeroUuid);
    const [displayedHeroImages, setDisplayedHeroImages] = useState(businessHeroImages);
    
    useEffect(() => {
        setDisplayedHeroImages(businessHeroImages);
    }, [businessHeroImages]);

    const assignHeroTemplate = (heroData) => {
        setSelectedHeroUuid(heroData ? heroData.uuid : null);
        setCardPreviewHeroImageData(heroData ? heroData : null);
    };

    const onHeroImageDeleted = (heroData) => {
        setBusinessHeroImages(preBusinessImages => {
            return preBusinessImages.filter(businessImage => businessImage.uuid !== heroData.uuid);
        });

        if (selectedHeroUuid === heroData.uuid) {
            assignHeroTemplate(null);
        }

        /** force silent-re-fetch because the hero image might not be is existing anymroe */
        fetchDigitalMemberCard(true); /** no await */
    };

    return (
        <>
            <div>
                <UploadHeroImage 
                    setCardPreviewHeroImageData={setCardPreviewHeroImageData}
                    selectedHeroUuid={selectedHeroUuid}
                    assignHeroTemplate={(heroData) => assignHeroTemplate(heroData) }
                    onHeroImageDeleted={(heroData) => onHeroImageDeleted(heroData) } 
                />
            </div>
            <div className="spacing-x">
                <FormLabel>{businessName} Images </FormLabel>
                <FlexContainer mDirection="row" tDirection="row" direction="row" alignItems="center" gap="20px" wrap="wrap">
                    <div style={{ position: "relative" }}>
                        <NoImageHeroContainer isSelected={selectedHeroUuid === null} onClick={ () => assignHeroTemplate(null) }>   
                            No Image 
                        </NoImageHeroContainer>
                        { selectedHeroUuid === null && <GreenCheckImgContainer> <img src={greenCheckImg} alt="Selected Template Icon" /></GreenCheckImgContainer> } 
                    </div>
                    { 
                        displayedHeroImages.map(businessHeroData => {
                            return (
                                <HeroImageClick 
                                    isBusinessImages={true}
                                    key={"hero-image-thumbnail-" + businessHeroData.uuid}
                                    selectedHeroUuid={selectedHeroUuid}
                                    heroData={businessHeroData}
                                    assignHeroTemplate={(heroData) => assignHeroTemplate(heroData) }
                                    onHeroImageDeleted={(heroData) => onHeroImageDeleted(heroData) }
                                />
                            );
                        })
                    }
                </FlexContainer>
            </div>
            <div className="spacing-x spacing-y">
                <FormLabel>Me Business Images </FormLabel>
                <FlexContainer mDirection="row" tDirection="row" direction="row" alignItems="center" gap="20px" wrap="wrap">
                    { 
                        meHeroImages.map(meHeroData => {
                            return (
                                <HeroImageClick 
                                    isBusinessImages={false}
                                    key={"hero-image-thumbnail-" + meHeroData.uuid}
                                    selectedHeroUuid={selectedHeroUuid}
                                    heroData={meHeroData}
                                    assignHeroTemplate={(heroData) => assignHeroTemplate(heroData) }
                                    onHeroImageDeleted={null}
                                />
                            );
                        })
                    }
                </FlexContainer>
            </div>
        </>
    );
};

const HeroImageClick = ({ selectedHeroUuid, heroData, assignHeroTemplate, isBusinessImages, onHeroImageDeleted }) => {
    const { 
        user,
    } = useContext(DigitalCompanyCardContext);
    const { deleteHeroImage, isDeletingMembershipCard } = useDeleteMembershipCard(user.token);

    const isSelected = selectedHeroUuid === heroData.uuid;

    const handleClick = () => {
        if (isDeletingMembershipCard) {
            return;
        }

        assignHeroTemplate(heroData);
    };

    const handleRemoveHeroImg = async () => {
        if (!isBusinessImages || isDeletingMembershipCard) {
            return;
        }

        if (!confirm("Remove selected hero image?")) {
            return;
        }

        try 
        {
            await deleteHeroImage(heroData.uuid);
            onHeroImageDeleted(heroData);
        } catch (error) {
            console.error("Error on deleting hero image: ", error);
        }
    };

    return (
        <div style={{ position: "relative" }}>
            { isBusinessImages && <RemoveImgIconContainer> <img src={removeHeroImg} alt="Remove Template Icon" onClick={handleRemoveHeroImg} /></RemoveImgIconContainer> } 
            <HeroContainer onClick={handleClick} backgroundImg={heroData.thumbnailUrl} isSelected={isSelected} isDeleting={isDeletingMembershipCard} />
            { isSelected && <GreenCheckImgContainer> <img src={greenCheckImg} alt="Selected Template Icon" /></GreenCheckImgContainer> } 
        </div>
    );
};

export default SelectArtWorkImageAndUpload;