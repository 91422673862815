import axios from "axios";
import { API_URL } from "src/scenes/App";
import React, { Component } from "react";
import { SetupPasswordContainerContext } from "src/components/SetupPasswordContainerContext";
import WellDone from "./components/WellDone";
import styled from "styled-components";
import {
    Form, FormField, LottieLoadingIndicator, Text, FlexContainer, Button
} from "src/components";

const SubmitButton = styled(Button)`
    background: #000;
    color: #FFF;
    width: 140px;
    padding: 10px 30px 10px;
`;

const SetupPasswordError = {
    EMPTY_PASSWORD: 1,
    EMPTY_CONFIRM_PASSWORD: 2,
    DIFF_PASS: 3, 
    MIN_LENGTH: 4
};

const ErrorStrings = {
    [SetupPasswordError.EMPTY_PASSWORD]: "* Please enter a password",
    [SetupPasswordError.EMPTY_CONFIRM_PASSWORD]: "* Please re-enter the same password",
    [SetupPasswordError.DIFF_PASS]: "* Please make sure passwords match",
    [SetupPasswordError.MIN_LENGTH]: "* Please enter a password at least eight characters long",
};

class SetupPassword extends Component {
    state = {
        isShowWellDone: false,
    };

    static contextType = SetupPasswordContainerContext;

    showWellDone = (isShow) => {
        this.setState({ 
            isShowWellDone: isShow
        });
    };

    render() {
        const { accessCode, businessName } = this.context;

        if (this.state.isShowWellDone)
            return <WellDone />;

        return (
            <FlexContainer style={{ margin: "auto 0" }} family="Roboto, Helvetica, sans-serif">
                <FlexContainer alignItems="center" padding="2rem 1rem" height="100%">
                    
                    <Text color="#FFF" size="40px" weight="500">
                            Welcome to the Me Business App!
                    </Text>
                        
                    <Text color="#FFF" size="20px" weight="500">
                        {accessCode}
                    </Text>

                    <Text color="#FFF" size="16px" weight="400" family="Roboto, Helvetica, sans-serif" width="25rem">
                            You have received an invitation from {businessName} to use the Me Business App. To gain access, kindly use your access code and create a password.
                    </Text>

                    <Password showWellDone={this.showWellDone} />
                </FlexContainer>
            </FlexContainer>
        ); 
    }
}

class Password extends Component {
    static contextType = SetupPasswordContainerContext;

    state = {
        password: "",
        confirmPassword: "",
        inputErrors: [],
        errorMessage: "",
        loading: false
    };

    submitSetupPassword = (e) => {
        const { password, confirmPassword } = this.state;

        e.preventDefault();
        let errorArray = [];

        this.setState({
            inputErrors: [],
            errorMessage: ""
        });

        if (password.trim() === "") {
            errorArray.push(SetupPasswordError.EMPTY_PASSWORD);
        } else if (password.length < 8) {
            errorArray.push(SetupPasswordError.MIN_LENGTH);
        }
        else if (confirmPassword.trim() === "") {
            errorArray.push(SetupPasswordError.EMPTY_CONFIRM_PASSWORD);
        } else if (password !== confirmPassword) {
            errorArray.push(SetupPasswordError.DIFF_PASS);
        }

        if (errorArray.length !== 0) {   
            this.setState({
                inputErrors: errorArray,
                errorMessage: ""
            });
        } else {
            this.requestSubmitPassword();
        }
    };

    requestSubmitPassword = () => {
        const { password } = this.state;
        const { shortTermToken } = this.context;
        const { showWellDone } = this.props;

        this.setState({
            loading: true
        });

        axios.put(`${API_URL}/setupPassword/${shortTermToken}`, {
            password: password,
        })
            .then(response => {
                this.setState({ loading: false });
                showWellDone(true);
            })
            .catch(error => {
                this.setState({
                    errorMessage: error,
                    loading: false
                });
            });
    };

    render() {
        const { inputErrors, errorMessage, loading } = this.state;
        
        const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);

        const passwordErrors = getErrorStrings(SetupPasswordError.EMPTY_PASSWORD, SetupPasswordError.MIN_LENGTH);
        const confirmErrors = getErrorStrings(SetupPasswordError.EMPTY_CONFIRM_PASSWORD, SetupPasswordError.DIFF_PASS);
        
        return (
            <FlexContainer alignItems="center">
                <Form width="20em" style={{ textAlign: "center" }} paddingBottom="0">
                    {errorMessage.length > 0 && <><Text color="red"><strong>{errorMessage}</strong></Text><br/></>}
                    <Text size="20px" weight="500" color="white">
                        Create a password
                    </Text>
                    
                    <FormField
                        placeholder="Enter password"
                        placeholderWeight="500"
                        placeholderAlignment="center"
                        align="center"
                        type="password"
                        onChange={(e) => this.setState({ password: e.target.value }) }
                        errors={passwordErrors}
                        width="80%"
                        showErrors={true}
                        errorTextHeight="unset"
                        errorTextAlign="center"
                    />
                    <FormField
                        placeholder="Re-enter password"
                        placeholderWeight="500"
                        placeholderAlignment="center"
                        align="center"
                        type="password"
                        onChange={(e) => this.setState({ confirmPassword: e.target.value }) }
                        errors={confirmErrors}
                        width="80%"
                        showErrors={true}
                        errorTextHeight="unset"
                        errorTextAlign="center"
                    />

                    <FlexContainer width="100%" height="6rem" justifyContent="center" alignItems="center">
                        { loading ?
                            <LottieLoadingIndicator height="7px" width="7px"/>
                            :
                            <SubmitButton onClick={this.submitSetupPassword}> Done </SubmitButton>
                        }
                    </FlexContainer>
                   
                </Form>
            </FlexContainer>
        );
    }
}

export default SetupPassword;