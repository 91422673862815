import React from "react";
import styled from "styled-components";
import { Select, MenuItem } from "@material-ui/core/";
import UnreadCount from "src/scenes/Chat/components/children/UnreadCount";
import { makeStyles } from "@material-ui/core/styles";
import { UserKeys } from "src/constants/userDetails";
import { GROUP_CHAT, ONE_ON_ONE_CHAT, USER_TYPE_CLIENTS, USER_TYPE_EMPLOYEES } from "src/constants/chat";
import { CREATE_GROUP_CHAT, MESSAGE_CLIENTS, MESSAGE_EMPLOYEES, isChatActionAllowed } from "src/constants/permissions";

import UserSingleIcon from "src/img/new/new-1-on-1-chat.svg";
import UserGroupIcon from "src/img/new/new-group-chat.svg";
import IconButton from "src/scenes/Chat/components/IconButton";

import { 
    StyledText, CreateChatButtons,
} from "src/scenes/Chat/components/styled/chatListStyled";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const useStyles = makeStyles(() => ({
    outlined: {
        "&:focus": {
            outline: "none",
        },
        "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
        },
    },
    listItemSecondary: {
        color: "#000"
    }
}));

const StyledMenuItem = styled(MenuItem)`
    [class*='UnreadCount'] {
        position: absolute;
        right: 15px;
    }
`;


const ActiveChatListSelect = styled(Select)`
    .MuiSelect-selectMenu{
        display: flex;
    }

    [class*='UnreadCount'] {
        position: absolute;
        right: 30px;
    }
`;

const selectStyle = {
    width: 227,
    height: 40,
    fontSize: "0.9rem",
    fontFamily: "Roboto",
    fontWeight: "600",
    color: "#4B5155",
    border: "1px solid #DBE5ED",
    boxShadow: "0px 3px 6px #00000029",
    borderRadius: 7,
    "&:focus": {
        backgroundColor: "transparent"
    }
};

const ActiveChats = (props) => {
    const classes = useStyles();
    const {
        currentUser,
        showCreateChatModalOrShowLeadershipEmployees,
        selectedChatListTypeOrLeadershipUuid,
        handleChatListSelectChange,
        activeChatsTotalUnread,
        leadershipRoles,
        activeChatListDetails,
        isFetchingActiveChats
    } = props;

    const handleSelectChange = (event) => {
        handleChatListSelectChange(event.target.value);
    };

    const showCreateOneToOneChatButton = () => {
        const userPermissions = currentUser[UserKeys.PERMISSIONS];

        if (selectedChatListTypeOrLeadershipUuid === USER_TYPE_EMPLOYEES) {
            return isChatActionAllowed(MESSAGE_EMPLOYEES, userPermissions);
        } else if (selectedChatListTypeOrLeadershipUuid === USER_TYPE_CLIENTS) {
            return isChatActionAllowed(MESSAGE_CLIENTS, userPermissions);
        } else {
            return true; // Always allowed to reach out to leaders
        }
    };
    
    const showCreateGroupChatButton = () => {
        const userPermissions = currentUser[UserKeys.PERMISSIONS];
        const userLeadershipRoles = currentUser[UserKeys.LEADERSHIP_ROLES];

        if (selectedChatListTypeOrLeadershipUuid === USER_TYPE_EMPLOYEES) {
            return isChatActionAllowed(MESSAGE_EMPLOYEES, userPermissions);
        } else if (selectedChatListTypeOrLeadershipUuid === USER_TYPE_CLIENTS) {
            return isChatActionAllowed(MESSAGE_CLIENTS, userPermissions);
        } else {
            //leadershipRole

            // Check if selectedChatListTypeOrLeadershipUuid exists in userLeadershipRoles
            return userLeadershipRoles.some(userLeadershipRole =>
                selectedChatListTypeOrLeadershipUuid === userLeadershipRole.uuid
            );
        }
    };

    const getUnreadCount = (selectedChatListTypeOrLeadershipUuid) => {

        const isNonLeadership = [USER_TYPE_CLIENTS, USER_TYPE_EMPLOYEES].includes(selectedChatListTypeOrLeadershipUuid);
        try 
        {
            if (isNonLeadership) {
                const nonLeadershipType = activeChatsTotalUnread.find(chatList => chatList.label === selectedChatListTypeOrLeadershipUuid);
                return nonLeadershipType ? nonLeadershipType.totalUnreadCount : 0;
            }

            //leadership
            const findLeadershipRole = leadershipRoles.find(role => role.uuid === selectedChatListTypeOrLeadershipUuid);
            if (!findLeadershipRole) {
                console.warn(`Leadership Role: ${selectedChatListTypeOrLeadershipUuid} not found when trying to get the total unread count.`, leadershipRoles, selectedChatListTypeOrLeadershipUuid);
                return 0;
            }
            
            const leadershipList = activeChatsTotalUnread.find(chatList => chatList.label === findLeadershipRole.uuid);
            return leadershipList ? leadershipList.totalUnreadCount : 0;
        } catch (e) {
            console.warn("Error in getting total unread count of Chat list.", e, selectedChatListTypeOrLeadershipUuid, activeChatListDetails);
            return 0;
        }
    };

    const employeeUnreadCount = getUnreadCount(USER_TYPE_EMPLOYEES);
    const clientUnreadCount = getUnreadCount(USER_TYPE_CLIENTS);

    const renderActiveChatList = () => {

        if (activeChatListDetails.length === 0) {
            return <StyledMenuItem disabled value="No list available">
                <em>None</em>
            </StyledMenuItem>;
        }

        const employeeChatListDetails = activeChatListDetails.find(chatList => chatList.label === USER_TYPE_EMPLOYEES);
        const clientChatListDetails = activeChatListDetails.find(chatList => chatList.label === USER_TYPE_CLIENTS);
         
        const showEmployees = currentUser[UserKeys.PERMISSIONS].includes(MESSAGE_EMPLOYEES) 
                || (employeeChatListDetails && employeeChatListDetails.data.chats.length > 0) 
                || !currentUser.isClient;

        const showClients = currentUser[UserKeys.PERMISSIONS].includes(MESSAGE_CLIENTS) 
                || (clientChatListDetails && clientChatListDetails.data.chats.length > 0)
                || currentUser.isClient;

        let prepareListItems = [];
        
        //employee
        if (showEmployees) {
            const employeeDropdownOption = <StyledMenuItem key={USER_TYPE_EMPLOYEES} value={USER_TYPE_EMPLOYEES}>Employees { employeeUnreadCount > 0 && <UnreadCount unreadCount={employeeUnreadCount} />}</StyledMenuItem>;
            prepareListItems.push(employeeDropdownOption);
        }

        //client
        if (showClients) {
            const clientDropdownOption = <StyledMenuItem key={USER_TYPE_CLIENTS} value={USER_TYPE_CLIENTS}>Clients { clientUnreadCount > 0 && <UnreadCount unreadCount={clientUnreadCount} />}</StyledMenuItem>;
            prepareListItems.push(clientDropdownOption);
        } 

        //leadership
        const leadershipActiveChats = activeChatListDetails.filter((activeChat) => ![USER_TYPE_CLIENTS, USER_TYPE_EMPLOYEES].includes(activeChat.label));
        
        const visibleLeadersOptions = leadershipActiveChats.map((activeChat) => {
            const role = leadershipRoles.find(leadershipRole => leadershipRole.uuid === activeChat.label);
            if (!role) {
                console.warn("Unable to create list item for role: ", activeChat.label);
                return false;
            }

            const isVisibleToThisUser = role.visibleLeaderCount > 0 || role.currentUserIsLeader;
            const leadershipRoleUnreadCount = getUnreadCount(role.uuid);
            const menuContent = <>{ role.name } {leadershipRoleUnreadCount > 0 && <UnreadCount unreadCount={leadershipRoleUnreadCount} />}</>;
            
            if (isVisibleToThisUser) {
                return <StyledMenuItem 
                    key={role.uuid} 
                    value={role.uuid} 
                    name={role.name}> { menuContent }
                </StyledMenuItem>;
            }
        });

        prepareListItems.push(...visibleLeadersOptions);
        return prepareListItems.filter(Boolean);
    };

    return (
        <>
            <StyledText size="1rem" margin="1.4375rem 0 0.8125rem 0" weight="600">Active Chats</StyledText>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                { !isFetchingActiveChats ? 
                    <>
                        <ActiveChatListSelect value={selectedChatListTypeOrLeadershipUuid || ""} variant="outlined"
                            style={selectStyle}
                            onChange={handleSelectChange}
                            classes={{ outlined: classes.outlined }}
                            SelectDisplayProps={{
                                style: {
                                    background: "transparent",
                                },
                            }}
                        >
                            { renderActiveChatList() }
                        </ActiveChatListSelect>
                        
                        <CreateChatButtons key={"create-group-chat"} className="flex-centered-content"
                            onClick={() => showCreateChatModalOrShowLeadershipEmployees(GROUP_CHAT, selectedChatListTypeOrLeadershipUuid)}
                            isAllowed={isChatActionAllowed(CREATE_GROUP_CHAT, currentUser[UserKeys.PERMISSIONS]) && showCreateGroupChatButton()}
                        >
                            <IconButton color="#fff" src={UserGroupIcon}></IconButton>
                        </CreateChatButtons>
                        
                        <CreateChatButtons key={"create-one-on-one-chat"} className="flex-centered-content"
                            onClick={() => showCreateChatModalOrShowLeadershipEmployees(ONE_ON_ONE_CHAT, selectedChatListTypeOrLeadershipUuid)}
                            isAllowed={showCreateOneToOneChatButton()}
                        >
                            <IconButton color="#fff" src={UserSingleIcon}></IconButton>
                        </CreateChatButtons>
                    </>
                    : 
                    <>
                        <Skeleton style={{ height: selectStyle.height, width: selectStyle.width }} /> 
                    </>
                }
            </div>
        </>
    );
};

export default ActiveChats; 