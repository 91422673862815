import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import styled from "styled-components";
import { Button, LottieLoadingIndicator, Text } from "src/components";

// Images
import LinkedInIcon from "src/img/noticeboard/linkedin.svg";
import FacebookIcon from "src/img/noticeboard/facebook.svg";
import InstagramIcon from "src/img/noticeboard/instagram.svg";

import AccountsLinked from "./AccountsLinked";

// custom components
import FacebookLogin from "./components/FacebookLoginButton";
import useAccountLinks from "./hooks/useAccountLinks";
import Skeleton from "../Skeleton";
import { AccountLinkingContext } from "./AccountLinkingContext";
import { IsFeatureReady } from "src/constants/features";

const UpperControls = styled.div`
    position: relative;
    width: 100%;
    max-width: 94.5rem;
    background: #fff;
    height: auto;
    min-height: 5.625rem;
    display: flex;
    align-items: center;
    border-radius: 7px 7px 0 0;
    border: 1px solid #8291B2;
    border-bottom: none;
    box-sizing: border-box;
    padding: 1em;
`;

const CloseButton = styled(Button)`
    border-radius: 10px !important;
    border: ${p => p.border || "none"};
    height: 39px;
    width: 124px;
    color: ${p => p.color || "#fff"};
    background-color: ${p => p.backgroundColor || "#006cff"};
    box-sizing: border-box;
    font-size: 0.875rem;
    font-weight: 500;
    padding: ${p => p.padding};
    display: flex;
    align-items: center;
`;

export const SocialMediaButton = styled(Button)`
    border-radius: 10px !important;
    border: ${p => p.border || "none"};
    height: ${p => p.height || "51px"};
    width: ${p => p.width || "250px"};
    color: ${p => p.color || "#fff"};
    background-color: ${p => p.backgroundColor || "#006cff"};
    box-sizing: border-box;
    box-shadow: 0px 3px 6px #00000029;
    font-size: 0.875rem;
    font-weight: 500;
    padding: ${p => p.padding};
    display: flex;
    align-items: center;
    &:disabled {
        cursor: not-allowed;
        opacity: 80%;
    }
`;

export const Container = styled.div`
    margin: 20px 26px 20px 18px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
`;

export const FlexRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
`;

const LoadingContainer = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const LinkedAccountContainer = ({ isLoading, children }) => {
    return (
        <>
            { isLoading && <Skeleton width="250px" height="51px" animation="wave" /> }
            { !isLoading && children }
        </>
    );
};

const AccountLinkedContainer = ({ isLoading, children }) => {
    if (isLoading) {
        return (
            <LoadingContainer>
                <LottieLoadingIndicator containerHeight="2rem" />
            </LoadingContainer> 
        );
    } else {
        return children;
    }
};

const DisplayConnectFacebook = ({ totalFacebookAccounts, saveFacebookDetails }) => {
    return (
        <>
            {
                totalFacebookAccounts < 4 ?
                    <FacebookLogin onSuccess={(data) => saveFacebookDetails(data)}/>
                    : 
                    <SocialMediaButton disabled="true" width="280px">
                        <img src={FacebookIcon} alt="Facebook Icon" style={{ marginRight: "12px" }}/>
                        Maximum accounts reached
                    </SocialMediaButton>
            }
        </>
    );
};

const DisplayConnectInstagram = ({ totalInstagramAccounts }) => {
    return (
        <>
            {
                totalInstagramAccounts < 4 ?
                    <SocialMediaButton backgroundColor="#EE1C8E">
                        <img src={InstagramIcon} alt="Instagram Icon" style={{ marginRight: "12px" }}/>
                        Connect Instagram
                    </SocialMediaButton>
                    : 
                    <SocialMediaButton backgroundColor="#EE1C8E" disabled="true" width="280px">
                        <img src={InstagramIcon} alt="Instagram Icon" style={{ marginRight: "12px" }}/>
                        Maximum accounts reached
                    </SocialMediaButton>
            }
        </>
    );
};

const DisplayConnectLinkedIn = ({ totalLinkedInAccounts }) => {
    return (
        <>
            {
                totalLinkedInAccounts < 4 ?
                    <SocialMediaButton backgroundColor="#0A69C5">
                        <img src={LinkedInIcon} alt="LinkedIn Icon" style={{ marginRight: "12px" }}/>
                        Connect Linkedin
                    </SocialMediaButton>
                    : 
                    <SocialMediaButton backgroundColor="#0A69C5" disabled="true" width="280px">
                        <img src={LinkedInIcon} alt="LinkedIn Icon" style={{ marginRight: "12px" }}/>
                        Maximum accounts reached
                    </SocialMediaButton>
            }
        </>
    );
};

const AccountLinkingPage = ({
    user,
    toggleShowAccountLinkingPage,
}) => {
    const { 
        retrieveLinkedAccounts,
        saveFacebookDetails,
        revokeSocialMediaAccount,
        reconnectSocialMediaPlatform,
        linkedAccounts,
        linkedAccountsFetched,
        showAccountsLinked,
    } = useAccountLinks(user.token);

    const [totalFacebookAccounts, setTotalFacebookAccounts] = useState(0);
    const [totalInstagramAccounts, setTotalInstagramAccounts] = useState(0);
    const [totalLinkedinAccounts, setTotalLinkedinAccounts] = useState(0);

    const [hasLinkedFacebookAccounts, setHasLinkedFacebookAccounts] = useState(false);
    const [hasLinkedInstagramAccounts, setHasLinkedInstagramAccounts] = useState(false);
    const [hasLinkedLinkedinAccounts, setHasLinkedLinkedinAccounts] = useState(false);

    useEffect(() => {
        retrieveLinkedAccounts();
    }, []);
    
    useEffect(() => {
        setTotalFacebookAccounts((linkedAccounts.Facebook && linkedAccounts.Facebook.length) || 0);
        setTotalInstagramAccounts((linkedAccounts.Instagram && linkedAccounts.Instagram.length) || 0);
        setTotalLinkedinAccounts((linkedAccounts.LinkedIn && linkedAccounts.LinkedIn.length) || 0);
        setHasLinkedFacebookAccounts(linkedAccounts.Facebook && linkedAccounts.Facebook.length > 0);
        setHasLinkedInstagramAccounts(linkedAccounts.Instagram && linkedAccounts.Instagram.length > 0);
        setHasLinkedLinkedinAccounts(linkedAccounts.LinkedIn && linkedAccounts.LinkedIn.length > 0);
    }, [linkedAccounts]);

    return (
        <>
            <UpperControls> {/** already has 1em padding = 16px */}
                <Grid item xs={6}>
                    <Grid container style={{ paddingLeft: "49px" }} direction="row" justifyContent="flex-start" alignItems="center">
                        <Text size="30px" align="left" weight="700" margin="0">
                            Get Started with Social Account Linking
                        </Text>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                        <CloseButton backgroundColor="#FFF" border="1px solid #000" color="#000" style={{ marginRight: "1rem", width: "12.5rem", display: "block" }} onClick={() => toggleShowAccountLinkingPage()}>Close</CloseButton>
                    </Grid>
                </Grid>
            </UpperControls>
            <AccountLinkingContext.Provider value={{
                totalFacebookAccounts: totalFacebookAccounts,
                totalInstagramAccounts: totalInstagramAccounts,
                totalLinkedinAccounts: totalLinkedinAccounts,
                hasLinkedFacebookAccounts: hasLinkedFacebookAccounts,
                hasLinkedInstagramAccounts: hasLinkedInstagramAccounts,
                hasLinkedLinkedinAccounts: hasLinkedLinkedinAccounts,
                showAccountsLinked: showAccountsLinked,
            }}>
                <Grid container direction="row" style={{ maxWidth: "94.5rem", minHeight: "50rem", border: "1px solid #8291B2", backgroundColor: "#FFF", marginBottom: "2rem" }}>
                    <Grid item xs={12} sm={6} justifyContent="flex-start" style={{ borderRight: "1px solid #8291B2" }}> {/* Left Side */}
                        <div style={{ margin: "30px 60px 30px 65px" }}>
                            <Text size="20px" weight="700" margin="0 0 17px 0" align="left">Link Your Social Accounts with Ease</Text>
                            <Text size="16px" margin="0" align="left" padding="0 6.875rem 0 0" >Connect your Facebook, Instagram, and LinkedIn accounts to seamlessly post to your Me Business Noticeboard and social media platforms at the same time.</Text>

                            <Text size="20px" weight="700" margin="43px 0 17px 0" align="left">Select Accounts to Link:</Text>

                            <LinkedAccountContainer isLoading={!linkedAccountsFetched}>
                                <DisplayConnectFacebook
                                    totalFacebookAccounts={totalFacebookAccounts}
                                    saveFacebookDetails={saveFacebookDetails}
                                />
                            </LinkedAccountContainer>

                            <Text size="16px" align="left" weight="400" padding="0 6.875rem 0 0" margin="12px 0 30px 0">
                                Link your Facebook account to share updates directly to your business page alongside your Me Noticeboard posts.
                            </Text>

                            { IsFeatureReady.instagramLinking() &&
                                <>
                                    <LinkedAccountContainer isLoading={!linkedAccountsFetched}>
                                        <DisplayConnectInstagram
                                            totalInstagramAccounts={totalInstagramAccounts}
                                        />
                                    </LinkedAccountContainer>

                                    <Text size="16px" align="left" weight="400" padding="0 6.875rem 0 0" margin="12px 0 30px 0">
                                        Connect your Instagram account to post engaging updates and visuals to your followers while updating your Noticeboard.
                                    </Text>
                                </>
                            }

                            { IsFeatureReady.linkedInLinking() &&
                                <>
                                    <LinkedAccountContainer isLoading={!linkedAccountsFetched}> 
                                        <DisplayConnectLinkedIn
                                            totalLinkedInAccounts={totalLinkedinAccounts}
                                        />
                                    </LinkedAccountContainer>

                                    <Text size="16px" align="left" weight="400" padding="0 6.875rem 0 0" margin="12px 0 30px 0">
                                        Link your LinkedIn account to share professional updates seamlessly with your network and Noticeboard audience.
                                    </Text>
                                </>
                            }
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} > {/* right side */}
                        <AccountLinkedContainer
                            isLoading={!linkedAccountsFetched}
                        >
                            <AccountsLinked
                                linkedAccounts={linkedAccounts}
                                linkedAccountsFetched={linkedAccountsFetched}
                                revokeSocialMediaAccount={revokeSocialMediaAccount}
                                reconnectSocialMediaPlatform={reconnectSocialMediaPlatform}
                            />
                        </AccountLinkedContainer>
                    </Grid>
                </Grid>
            </AccountLinkingContext.Provider>
        </>
    );
};

export default AccountLinkingPage;
