import React, { Component } from "react";
import { FlexContainer, PageContainer, ExplainParagraphLabel, PageHeadingLabel, LottieLoadingIndicator } from "src/components";
import { SALES_AND_PROMOTION_HEADING } from "./SalesAndPromotionHeader";

class LoadingPage extends Component {
    render() {
        return (
            <PageContainer>

                <PageHeadingLabel>
                    { SALES_AND_PROMOTION_HEADING }
                </PageHeadingLabel>

                <div style={{ marginBottom: "3em" }}>
                    <ExplainParagraphLabel style={{ fontSize: "0.8750em", width: "50vw" }}></ExplainParagraphLabel>
                </div>
                    
                <FlexContainer
                    justifyContent="center"
                    alignItems="center"
                    color="#fff"
                    style={{
                        overflowX: "auto",
                        maxWidth: "94.5rem",
                        minHeight: "40rem",
                        border: "1px solid #8291B2",
                        borderRadius: "7px"
                    }}
                >
                    <LottieLoadingIndicator />
                </FlexContainer>
            </PageContainer>
        );
    }
}

export default LoadingPage;