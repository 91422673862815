import React, { useState } from "react";
import styled from "styled-components";
import { hexToRgba } from "src/utils/colors";
import MemberCardColorPicker, { ColorCircle } from "src/scenes/DigitalMemberCard/components/MemberCardColorPicker";
import { FlexContainer } from "src/components";

const ImageContainer = styled.div`
    height: 31px;
    width: 85%;
    display: flex; 
    justify-content: center; 
    align-items: center;

    > img {
        max-width: 100%;
        max-height: 100%;
    }
`;

const SelectMembershipCardColor = ({ businessLogo, businessBackgroundColor, customBackgroundColor, cardPreviewBackgroundColor, setCardPreviewBackgroundColor, setCardPreviewTextColor }) => {

    const [isDefaultBackgroundColorSelected, setIsDefaultBackgroundColorSelected] = useState(undefined);
    const [logoBackgroundColor, setLogoBackgroundColor] = useState(cardPreviewBackgroundColor);
    
    const handleDefaultBackgroundColorSwitch = () => {
        handleDynamicBackgroundColorSwitch(businessBackgroundColor);
    };

    const handleDynamicBackgroundColorSwitch = (backgroundColorToShow) => {
        const isDefaultColor = businessBackgroundColor === backgroundColorToShow;
        setIsDefaultBackgroundColorSelected(isDefaultColor);
        setLogoBackgroundColor(backgroundColorToShow);
        setCardPreviewBackgroundColor(backgroundColorToShow);
        setCardPreviewTextColor(isLightColor(backgroundColorToShow) ? "#000000" : "#FFFFFF");
    };

    /**
     * @param {string} hexColor
     * @returns {boolean}
     */
    const isLightColor = (hexColor) => {
        
        const [r, g, b] = hexToRgba(hexColor);
      
        const luminance = 0.299 * r + 0.587 * g + 0.114 * b;
      
        return luminance > 0.55; // threshold
    };

    return (
        <FlexContainer mDirection="row-reverse" tDirection="row-reverse" direction="row-reverse" width="100%" justifyContent="center" gap="10px">
            { /* `row-reverse` because <SelectColor> need to render first for <DisplayLogoWithBackgroundColor> to adapt */}
            <SelectColor 
                businessBackgroundColor={businessBackgroundColor}
                customBackgroundColor={customBackgroundColor}
                isDefaultBackgroundColorSelected={isDefaultBackgroundColorSelected}
                handleDefaultBackgroundColorSwitch={handleDefaultBackgroundColorSwitch}
                handleDynamicBackgroundColorSwitch={handleDynamicBackgroundColorSwitch}
            />
            <DisplayLogoWithBackgroundColor
                logoBackgroundColor={logoBackgroundColor}
                businessLogo={businessLogo}
            />
        </FlexContainer>
    );
};

const SelectColor = ({ businessBackgroundColor, customBackgroundColor, isDefaultBackgroundColorSelected, handleDefaultBackgroundColorSwitch, handleDynamicBackgroundColorSwitch }) => {
    const [hexColor, setHexColorChange] = useState(isDefaultBackgroundColorSelected ? businessBackgroundColor : customBackgroundColor);
    
    const onColorPickerChange = (hexColorValue) => {
        setHexColorChange(hexColorValue);
        handleDynamicBackgroundColorSwitch(hexColorValue);
    };
    
    return (
        <FlexContainer mDirection="row" tDirection="row" direction="row" alignItems="center" gap="8px">
            <ColorCircle onClick={() => handleDefaultBackgroundColorSwitch()}
                showAddColourImg={false}
                color={businessBackgroundColor}
                border={ isDefaultBackgroundColorSelected ? "1px solid #36BE24" : undefined}
                showGreenCheck={isDefaultBackgroundColorSelected}
            />
            { 
                (hexColor !== businessBackgroundColor) &&
                <ColorCircle onClick={ () => handleDynamicBackgroundColorSwitch(hexColor)}
                    showAddColourImg={false}
                    color={hexColor}
                    border={isDefaultBackgroundColorSelected ? undefined : "1px solid #36BE24"}
                    showGreenCheck={!isDefaultBackgroundColorSelected}
                />
            }

            <MemberCardColorPicker hexColor={hexColor} onColorPickerChange={onColorPickerChange} /> 
            <div style={{ display: "inline-block" }}>Background&nbsp;Colour</div>
        </FlexContainer>
    );
};

const DisplayLogoWithBackgroundColor = ({ businessLogo, logoBackgroundColor }) => {

    return (
        <FlexContainer mDirection="row" tDirection="row" direction="row" width="100%" alignItems="center" gap="10px" style={{ padding: "0px 10px" }}>
            <div style={{ backgroundColor: logoBackgroundColor, borderRadius: "10px", width: "145px", height: "49px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                <ImageContainer>
                    <img src={businessLogo} alt="Business Logo" />
                </ImageContainer>
            </div>

            <div>
                Logo
            </div>
        </FlexContainer>
    );
};

export default SelectMembershipCardColor;