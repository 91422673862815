import React, { useContext } from "react";
import styled from "styled-components";
import { Button, FlexContainer } from "src/components";
import DigitalCompanyCardContext from "src/scenes/DigitalMemberCard/DigitalCompanyCardContext";
import EmployeeAppleCard from "./EmployeeAppleCard";
import EmployeeGoogleCard from "./EmployeeGoogleCard";

const H2 = styled.h2`
    color: #612684;
    font-size: 1.563rem;
    font-weight: 700;
    margin: 0;
    flex-grow: 1;
`;

const CardDescription = styled.div`
    color: #808080;
    font-size: 0.875rem;
    max-width: 400px;
    flex-grow: 1;
    font-weight: 400px
`;

const H3 = styled.h3`
    color: #000000;
    font-size: 0.875rem;
    font-weight: 400px;
`;

const CardSpacer = styled.div`
    flex-grow: 1;
    margin-top: 50px;
    min-height: 450px;
`;

const Divider = styled.div`
    border-right: 1px solid #8291B2;
`;

const DisplayCompanyCards = () => {
    const { 
        renderCustomiseEmployee,
        renderCustomiseClient
    } = useContext(DigitalCompanyCardContext);

    return (
        <>
            <FlexContainer flexGrow="1" mDirection="row" tDirection="row" direction="row" width="100%" justifyContent="space-evenly" gap="10px">
                
                <FlexContainer padding="4em 0em" style={{ alignItems: "center", textAlign: "center" }}>
                    <H2>Employee Card Preview</H2>
                    <CardDescription>View and manage your custom digital employee cards, featuring personalised IDs and company branding.</CardDescription>
                    <CardSpacer>
                        <FlexContainer alignItems="flex-start" mDirection="column" tDirection="column" direction="row" width="100%" justifyContent="space-evenly" gap="10px">
                            <div>
                                <H3>Apple Wallet</H3>
                                <EmployeeAppleCard isTemplateForClientPreview={false} />
                            </div>
                            <div>
                                <H3>Google Wallet</H3>
                                <EmployeeGoogleCard isTemplateForClientPreview={false} />
                            </div>
                        </FlexContainer>
                    </CardSpacer>

                    <div><CustomizeButton onClick={ renderCustomiseEmployee } /></div>
                </FlexContainer>
                
                <Divider />

                <FlexContainer padding="4em 0em" style={{ alignItems: "center", textAlign: "center" }}>
                    <H2>Client Card Preview</H2>
                    <CardDescription>Review and customise your client digital cards, displaying unique IDs with your company logo and colours.</CardDescription>
                    <CardSpacer>
                        <FlexContainer alignItems="flex-start" mDirection="column" tDirection="column" direction="row" width="100%" justifyContent="space-evenly" gap="10px">
                            <div>
                                <H3>Apple Wallet</H3>
                                <EmployeeAppleCard isTemplateForClientPreview={true} />
                            </div>
                            <div>
                                <H3>Google Wallet</H3>
                                <EmployeeGoogleCard isTemplateForClientPreview={true} />
                            </div>
                        </FlexContainer>
                    </CardSpacer>

                    <div><CustomizeButton onClick={ renderCustomiseClient } /></div>
                </FlexContainer>
            </FlexContainer>
        </>
    );
}; 

const BlackButton = styled(Button)`
    background: #000000 0% 0% no-repeat padding-box;
    padding: 10px 38px 10px 38px !important;
    border-radius: 10px !important;
    color: #fff;
    font-size: 0.875rem;
    text-align: center;
    margin-top: 2em;
`;

const CustomizeButton = ({ onClick }) => {
    return (
        <BlackButton onClick={() => onClick()} >
            Customise
        </BlackButton>
    );
};

export default DisplayCompanyCards;