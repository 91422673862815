export const UserKeys = {
    UUID: "uuid",
    TOKEN: "token",
    FIRST_NAME: "firstName",
    LAST_NAME: "lastName",
    PROFILE_PICTURE_URL: "profilePictureUrl",
    DEPARTMENT: "department",
    EMPLOYMENT_POSITION: "employmentPosition",
    EMAIL: "email",
    ACCESS_CODE: "accessCode",
    PERMISSIONS: "permissions",
    PHONE_NUMBER: "phoneNumber",
    BIRTH_DATE: "birthDate",
    IS_ACTIVE: "isActive",
    IS_CLIENT: "isClient",
    LEADERSHIP_ROLES: "leadershipRoles",
    COMPANY_NAME: "companyName",
    COMPANY_ABN: "abn",
    IS_CUSTOMIZE_COMPLETE: "isCustomizeComplete",
    COUNTRY_CODE: "countryCode",
    ENABLED_CLIENT_LICENSE: "enabledClientLicense",
    ENABLED_BUSINESS_DIRECTORY: "enabledBusinessDirectory",
    COMPANY_LOGO_URL: "companyLogoUrl",
    MATRIX_USER_ID: "matrixUserId",
    CHAT_PRESENCE_OVERRIDE: "chatPresenceOverride"
};