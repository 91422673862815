import React, { useState } from "react";
import MoreMenu from "src/img/SettingsMoreMenu.svg";
import axios from "axios";
import { API_URL } from "src/scenes/App";
import { Toast } from "src/components";
function MoreMenuContainerBilling({ invoice, token, indexOfShownMenu, setIndexOfShownMenu, thisIndex }) {
    const [isDownloading, setIsDownloading] = useState(false);
    const isActive = indexOfShownMenu === thisIndex;

    const handleClick = () => {
        if (thisIndex === indexOfShownMenu) {
            setIndexOfShownMenu(null);
            return false;
        }

        setIndexOfShownMenu(thisIndex);
    };

    const handleDownloadInvoice = () => {
        download();
    };

    const handleContactSupport = () => {
        window.open("https://www.memotivationapp.com/contact", "_blank");
    };
    
    const download = async () => {
        if (isDownloading)
            return false;
        
        setIsDownloading(true);
        
        try {
            // Fetch PDF object from API
            let response = await axios.get(`${API_URL}/company/invoice/${invoice.uuid}/pdf`, {
                headers: {
                    Authorization: "Bearer " + token
                },
                responseType: "blob"
            });

            // Open the document in a new tab
            const type = response.headers["Content-Type"];
            const fileName = `Me Business App Tax Invoice - ${invoice.invoiceNo}.pdf`;
            const blob = new Blob([response.data], { type: type, encoding: "UTF-8" });
            const link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = fileName;
            link.click();
        } catch (error) {
            Toast.error("Unable to download invoice");
        } finally {
            setIsDownloading(false);
        }
    };

    return (
        <>
            <img src={MoreMenu} alt={MoreMenu} onClick={handleClick} style={{ cursor: "pointer" }} />

            {isActive &&
            <div style={{ position: "relative" }}>
                <div style={{ width: "220px", border: "1px solid #E9EBEF", borderRadius: "8px", padding: "1em", fontSize: "14px", fontWeight: "500", backgroundColor: "#FFF", position: "absolute", top: "-2px", right: "20px" }}>
                    <div style={{ paddingBottom: "0.5em", cursor: "pointer" }} onClick={handleDownloadInvoice}>{!isDownloading ? "Download Invoice" : "Downloading..."}</div>
                    <div style={{ paddingTop: "0.5em", borderTop: "1px solid #E9EBEF", cursor: "pointer" }} onClick={handleContactSupport}>Contact Support</div>
                </div>
            </div>
            }
        </>
    );
}

export default MoreMenuContainerBilling;