import React, { useContext } from "react";
import styled from "styled-components";
import moment from "moment";
import { Grid } from "@material-ui/core";

import { Text } from "src/components";
import { SocialMediaButton, Container, FlexRow } from "./AccountLinkingPage";
import { AccountLinkingContext } from "./AccountLinkingContext";

import SocialMediaRow from "./AccountLinkingSocialMediaRow";
import NoAccountsLinked from "./NoAccountsLinked";

// Images
import FacebookIcon from "src/img/noticeboard/facebook.svg";
import FacebookColoredIcon from "src/img/noticeboard/facebook_colored.svg";
import LinkedInIcon from "src/img/noticeboard/linkedin.svg";
import LinkedInColoredIcon from "src/img/noticeboard/linkedin_colored.svg";
import InstagramIcon from "src/img/noticeboard/instagram.svg";
import { IsFeatureReady } from "src/constants/features";

const SocialMediaDivider = styled.div`
    width: 100%;
    height: 30px;
    background-color: #8291B2;
    border: 1px solid #8291B2;
    text-align: left;
    align-content: center;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
`;

const SocialMediaPageCard = styled.div`
    width: auto;
    background: #F4F5F8 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #8291B2;
    border-radius: 7px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin: 20px;
`;

const ConnectedPagesSection = (props) => {
    const {
        linkedItem,
        platformMainIcon,
        platformItemsIcon,
        isAccountAccessExpired,
    } = props;
    const pages = linkedItem.pages.flat();
    const isExpired = isAccountAccessExpired(linkedItem.pageAdmin.expiryDate);
    return (
        <Grid item xs={12} md={6}> {/** Left side, profile name and pages */}
            <Container>
                <FlexRow>
                    <SocialMediaButton width="267px">
                        <img src={platformMainIcon} alt="Platform Icon" style={{ marginRight: "12px" }} />
                        {linkedItem.pageAdmin?.accountName}
                    </SocialMediaButton>
                </FlexRow>
            </Container>
            {
                pages.map((pageItem) => {
                    return (
                        <SocialMediaRow
                            pageItem={pageItem}
                            logo={platformItemsIcon}
                            isExpiredPage={isExpired}
                        />
                    );
                })
            }
        </Grid>
    );
};

const BusinessPortalDetailsAndActionsSection = (props) => {
    const { 
        linkedItem,
        isAccountAccessExpired,
        onButtonTypeAction
    } = props;
    const isExpired = isAccountAccessExpired(linkedItem.pageAdmin.expiryDate);

    const buttonColor = isExpired ? "#fff" : "#000";
    const textColor = isExpired ? "#000" : "#fff";
    const border = isExpired ? "1px solid #000000" : "";
    const label = isExpired ? "Reconnect" : "Disconnect";
    return (
        <Grid item xs={12} md={6}> 
            <Grid 
                container
                direction="column"
                justifyContent="flex-end"
                alignItems="flex-end"
                style={{ padding: "0px 1.438rem 20px 0px" }}
            >
                <Grid item>
                    <Text size="0.938rem" weight="500" align="right">
                        Added by {linkedItem.pageAdmin.employeeName}
                    </Text>
                </Grid>
                <Grid item>
                    <SocialMediaButton
                        width="125px"
                        height="39px"
                        border={border}
                        backgroundColor={buttonColor}
                        color={textColor}
                        onClick={() => onButtonTypeAction(linkedItem.pageAdmin.uuid, linkedItem.pageAdmin.socialMediaType)}
                    >
                        {label}
                    </SocialMediaButton>
                </Grid>
            </Grid>
        </Grid>
    );
};

const DisplayLinkedItems = ({
    linkedItems,
    platformItemsIcon,
    platformMainIcon,
    revokeSocialMediaAccount,
    reconnectSocialMediaPlatform,
    platformType
}) => {
    const today = moment().startOf("day");
    const isAccountAccessExpired = (expiryDate) => expiryDate && moment(expiryDate).isBefore(today);
    return (
        <>
            {
                linkedItems.map((linkedItem) => {
                    return (
                        <SocialMediaPageCard>
                            <Grid container width="100%">
                                {/** Left side, profile name and pages */}
                                <ConnectedPagesSection
                                    linkedItem={linkedItem}
                                    platformMainIcon={platformMainIcon}
                                    platformItemsIcon={platformItemsIcon}
                                    isAccountAccessExpired={isAccountAccessExpired}
                                />
                                {/** Right side, business portal user name action button */}
                                <BusinessPortalDetailsAndActionsSection
                                    linkedItem={linkedItem}
                                    isAccountAccessExpired={isAccountAccessExpired}
                                    onButtonTypeAction={isAccountAccessExpired(linkedItem.pageAdmin.expiryDate) ? reconnectSocialMediaPlatform : revokeSocialMediaAccount}
                                />
                            </Grid>
                        </SocialMediaPageCard>
                    );
                })
            }
        </>
    );
};

const AccountsLinked = (props) => {
    const { 
        linkedAccounts,
        revokeSocialMediaAccount,
        reconnectSocialMediaPlatform
    } = props;


    const {
        showAccountsLinked,
        totalFacebookAccounts,
        totalInstagramAccounts,
        totalLinkedinAccounts,
        hasLinkedFacebookAccounts,
        hasLinkedInstagramAccounts,
        hasLinkedLinkedinAccounts,
    } = useContext(AccountLinkingContext);

    if (!hasLinkedFacebookAccounts &&
        !hasLinkedInstagramAccounts &&
        !hasLinkedLinkedinAccounts &&
        showAccountsLinked) {
        return (
            <NoAccountsLinked />
        );
    }

    return (
        <div style={{ margin: "30px 0px 0px 0px" }}>
            <Text size="20px" weight="700" align="left" margin="0 0 20px 75px">Connected Accounts:</Text>
            <SocialMediaDivider>
                <Text size="15px" weight="500" align="left" margin="auto 0 auto 75px" color="#fff">Facebook Accounts</Text>
                <Text size="15px" weight="500" align="right" margin="auto 26px auto 0" color="#fff">{totalFacebookAccounts}</Text>
            </SocialMediaDivider>
            { hasLinkedFacebookAccounts && 
                <DisplayLinkedItems
                    linkedItems={linkedAccounts?.Facebook}
                    platformMainIcon={FacebookIcon}
                    platformItemsIcon={FacebookColoredIcon}
                    color="#1877F2"
                    revokeSocialMediaAccount={revokeSocialMediaAccount}
                    reconnectSocialMediaPlatform={reconnectSocialMediaPlatform}
                />
            }
            { !hasLinkedFacebookAccounts && <NoAccountsLinked/> }

            { IsFeatureReady.instagramLinking() &&
                <>
                    <SocialMediaDivider>
                        <Text size="15px" weight="500" align="left" margin="auto 0 auto 75px" color="#fff">Instagram Accounts</Text>
                        <Text size="15px" weight="500" align="right" margin="auto 26px auto 0" color="#fff">{totalInstagramAccounts}</Text>
                    </SocialMediaDivider>
                    { hasLinkedInstagramAccounts && 
                        <DisplayLinkedItems
                            linkedItems={linkedAccounts?.Instagram}
                            platformMainIcon={InstagramIcon}
                            platformItemsIcon={InstagramIcon}
                            color="#EE1C8E"
                        />
                    }
                    { !hasLinkedInstagramAccounts && <NoAccountsLinked/> }
                </>
            }

            { IsFeatureReady.linkedInLinking() && 
                <>
                    <SocialMediaDivider>
                        <Text size="15px" weight="500" align="left" margin="auto 0 auto 75px" color="#fff">Linkedin  Accounts</Text>
                        <Text size="15px" weight="500" align="right" margin="auto 26px auto 0" color="#fff">{totalLinkedinAccounts}</Text>
                    </SocialMediaDivider>
                    { hasLinkedLinkedinAccounts && 
                        <DisplayLinkedItems
                            linkedItems={linkedAccounts?.LinkedIn}
                            platformMainIcon={LinkedInIcon}
                            platformItemsIcon={LinkedInColoredIcon}
                        />
                    }
                    { !hasLinkedLinkedinAccounts && <NoAccountsLinked/> }
                </>
            }
        </div>
    );
};

export default AccountsLinked;

