import React, { useState, useEffect, useContext, useRef } from "react";
import styled from "styled-components";
import { Box, Dialog, Switch, withStyles } from "@material-ui/core/";
import {
    FlexContainer, Text, FormField,
    Button, LottieLoadingIndicator,
} from "src/components";
import SearchIcon from "src/img/new/search-gray.svg";
import { EnumUserGroup, FormType } from "../../components/Enums";
import { UserContext } from "src/scenes/App/UserContext";
import { UserKeys } from "src/constants/userDetails";
import axios from "axios";
import { API_URL } from "src/scenes/App";
import UserGroup from "src/img/new/users.svg";
import CloseIcon from "src/img/CloseButton@2x.png";
import PollsUsersList from "./PollsComponents/UsersList";
import { CancelButton, SaveButton } from "../../components/Utils";
import Skeleton from "src/scenes/Statistics/components/Skeleton";

export const Header = styled(FlexContainer)`
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: #F4F5F8;
    height: 5.125rem;
    padding: 0 2.375rem 0 1.6875rem;
    border-bottom: 1px solid #DBE5ED;
    border-radius: 1.25rem 1.25rem 0 0;
`;

export const SearchInput = styled(FormField)`
    position: relative;
    background-image: url(${SearchIcon});
    background-repeat: no-repeat;
    background-position: calc(100% - 0.9375rem) center;
    border: 1px solid #DBE5ED;
    width: 100%;
    max-width: 53.9375rem;
    height: 2.5rem;
    font-size: 0.875rem;
    text-align: left;
    padding: 0 0.9375rem 0 0.9375rem;
    border-radius: 0.5rem !important;
    margin: ${p => p.margin};

    ::placeholder {
        text-align: left;
        color: #AFBBC6;
        font-size: 0.875rem;
    }
`;

export const StyledButton = styled(Button)`
    width: 11.5rem;
    height: 3.4375rem;
    border-radius: 0.625rem !important;
    font-size: 1rem;
    font-weight: 700;
    box-sizing: border-box;
    background-color: ${p => p.backgroundColor || ""};
    color: ${p => p.color || ""};
`;

const InputSelector = styled.select`
    background: #FFF;
    border: 1px solid #DBE5ED !important;
    text-decoration: none;
    border-radius: 7px;
    padding: auto 23px;
    height: 38px;
    width: 200px;
    font-size: 18px;
    font-weight: 700;
    &:focus-visible {
        outline: unset !important;
        border: 1px solid #DBE5ED !important;
    }
`;

const SelectedPeopleIcon = styled.div`
    width: 44px;
    height: 44px;
    background: #D6D6D6 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 2px solid #FFFFFF;
    opacity: 1;
    border-radius: 50%;
    display: flex;
`;

const SelectedPeopleItem = styled.div`
    width: 242px;
    height: 59px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 5px #0000001A;
    border: 1px solid #E0E0E0;
    border-radius: 15px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 14px;
    padding-right: 14px;
`;

const SearchBar = styled.input`
    background-image: url(${SearchIcon});
    background-repeat: no-repeat;
    background-position: right 1rem center;
    border: 1px solid #DBE5ED;
    height: 2.5rem;
    font-family: SofiaPro;
    font-size: 0.875rem;
    border-radius: 0.5rem;
    background-color: #FFF;
    outline: none;
    width: 100%;
    text-align: left;
    padding: 0 1.25rem;
    box-sizing: border-box;

    ::placeholder {
        color: #AFBBC6;
        text-align: left;
        font-family: Roboto;
        font-weight: 500;
        font-size: 0.875rem;
    }
`;

const AllUserToggler = styled.div`
    width: 234px;
    height: 40px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #DBE5ED;
    border-radius: 8px;
    opacity: 1;
    display: flex;
    justify-content: space-between;
    padding: 0 16px;
    margin-left: 8px;
`;

const ToggleAllUsersSwitch = withStyles((theme) => ({
    root: {
        width: 41,
        height: 25,
        padding: 0,
        margin: theme.spacing(1),
    },
    switchBase: {
        padding: 1,
        "&$checked": {
            transform: "translateX(16px)",
            color: theme.palette.common.white,
            "& + $track": {
                backgroundColor: "#1A61FF",
                opacity: 1,
                border: "1px solid #1A61FF"
            },
        },
        "&$focusVisible $thumb": {
            color: "#52d869",
            border: "6px solid #fff",
        },
    },
    thumb: {
        width: 20,
        height: 20,
        border: `1px solid ${theme.palette.grey[400]}`
    },
    track: {
        borderRadius: 26 / 2,
        border: `1px solid ${theme.palette.grey[400]}`,
        backgroundColor: theme.palette.grey[50],
        height: 22,
        opacity: 1,
        transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

const VisibilityModal = (props) => {
    const currentUser = useContext(UserContext);
    const controllerRef = useRef(new AbortController());

    const {
        togglePollVisibilityModal,
        showPollVisibilityModal,
        handleSave,
        pollParticipantUuids,
        clientsInPoll,
        employeesInPoll,
        employeesCountInPoll,
        clientsCountInPoll,
        modalTitle,
        formType,
        userGroupType
    } = props;
    const [selectedUserGroupType, setSelectedUserGroupType] = useState(EnumUserGroup.EMPLOYEES); // this identifies the values of the dropdown option, NOT the poll/survey usergroup type
    const [usersList, setUsersList] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [usersToDisplay, setUsersToDisplay] = useState([]);
    const [allEmployeesSelected, setAllEmployeesSelected] = useState(employeesInPoll || false);
    const [employeesCountSelected, setEmployeesCountSelected] = useState(employeesCountInPoll || 0);
    const [allClientsSelected, setAllClientsSelected] = useState(clientsInPoll || false);
    const [clientsCountSelected, setClientsCountSelected] = useState(clientsCountInPoll || 0);
    const [loadingFetchUsers, setLoadingFetchUsers] = useState(false);

    const [showEmployeesBadge, setShowEmployeesBadge] = useState(false);
    const [showClientsBadge, setShowClientsBadge] = useState(false);

    useEffect(() => {
        fetchUsersList(EnumUserGroup.EMPLOYEES);
    }, []);

    useEffect(() => {
        if (showPollVisibilityModal) {
            verifyUserGroupValues(formType, userGroupType);
        }
    }, [showPollVisibilityModal]);

    useEffect(() => {
        if (usersList) {
            if (pollParticipantUuids && selectedUsers.length === 0) {
                const preselectedUsers = usersList.filter(user => pollParticipantUuids.includes(user.uuid));
                setSelectedUsers(preselectedUsers);
            }
        }
    }, [usersList]);

    const fetchUsersList = async (selectedUserGroupType) => {
        if (controllerRef.current) {
            controllerRef.current.abort();
        }
        setLoadingFetchUsers(true);

        controllerRef.current = new AbortController();

        axios.get(`${API_URL}/survey/company/getAllUsers`, {
            headers: { Authorization: `Bearer ${currentUser[UserKeys.TOKEN]}` },
            signal: controllerRef.current.signal
        }).then(response => {
            const requestingClients = selectedUserGroupType === EnumUserGroup.CLIENTS;
            const usersOfType = response.data.companyEmployees.filter(user => user.isClient === requestingClients);
            const usersInExpectedFormat = usersOfType.map(user => ({
                uuid: user.employeeUuid,
                ...user
            }));
            setUsersList(usersInExpectedFormat);
            setUsersToDisplay(usersInExpectedFormat);
            setLoadingFetchUsers(false);
        }).catch(error => {
            if (axios.isCancel(error)) {
                console.error(error.message || error.response.data.error);
            }
            setLoadingFetchUsers(false);
        });
    };

    const retrieveUpdatedUsersList = async (fetchUserType) => {
        setSelectedUserGroupType(fetchUserType);
        fetchUsersList(fetchUserType);
    };

    const handleSearch = (e) => {
        const searchKey = e.target.value;
        if (searchKey.length) {
            const filteredUsers = usersList.filter(user =>
                user.firstName.toLowerCase().includes(searchKey.toLowerCase())
                || user.lastName.toLowerCase().includes(searchKey.toLowerCase())
            );
            setUsersToDisplay(filteredUsers);
        } else {
            setUsersToDisplay(usersList);
        }
    };

    const setAllSelectedByUserType = (isChecked, setAllSelectedByUser, setShowBadgeByUser) => {
        let selectedUsersTemp = [...selectedUsers];
        
        setAllSelectedByUser(isChecked);
        if (!isChecked) { // toggle is off
            const removeSelectedUsersFromList = selectedUsersTemp.filter((value) => 
                !usersList.some((user) => user.uuid === value.uuid));
            setSelectedUsers(removeSelectedUsersFromList);
            setShowBadgeByUser(false);
        } else {
            selectedUsersTemp = [...new Set([...selectedUsersTemp, ...usersList.map((i) => ({
                uuid: i.uuid,
                isClient: i.isClient
            }))])];
            setSelectedUsers(selectedUsersTemp);
            setShowBadgeByUser(true);
        }
    };

    const handleAllSwitch = (e) => {
        if (selectedUserGroupType === EnumUserGroup.CLIENTS) {
            setAllSelectedByUserType(e.target.checked, setAllClientsSelected, setShowClientsBadge);
        } else {
            setAllSelectedByUserType(e.target.checked, setAllEmployeesSelected, setShowEmployeesBadge);
        }
    };

    const removeSelectedPeopleItems = (userType) => {
        let selectedUsersTemp = [...selectedUsers];
        const isClient = userType === EnumUserGroup.CLIENTS;
        const removeSelectedUsersFromList = selectedUsersTemp.filter((value) => 
            value.isClient !== isClient
        );
        setSelectedUsers(removeSelectedUsersFromList);

        if (isClient) {
            setAllClientsSelected(false);
            setShowClientsBadge(false);
        } else {
            setAllEmployeesSelected(false);
            setShowEmployeesBadge(false);
        }
    };

    const handleSelectUser = (user) => {
        let tempUserSelectedList = [...selectedUsers];
        if (tempUserSelectedList.find((item) => item.uuid === user.uuid)) { // removes selected user from selected list
            tempUserSelectedList = tempUserSelectedList.filter((item) => item.uuid !== user.uuid);
            if (allEmployeesSelected || allClientsSelected) {
                if (selectedUserGroupType === EnumUserGroup.CLIENTS) {
                    setAllClientsSelected(false);
                } else {
                    setAllEmployeesSelected(false);
                }
            }
        } else { // adds selected user from selected list
            tempUserSelectedList.push({ uuid: user.uuid, isClient: user.isClient });
            if (!allEmployeesSelected || !allClientsSelected) {
                const tempTalliedUsersUuid = countUUIDsInUsers(usersList, tempUserSelectedList);
                if (selectedUserGroupType === EnumUserGroup.CLIENTS) {
                    if (usersList.length === tempTalliedUsersUuid) {
                        setAllClientsSelected(true);
                    }
                } else {
                    if (usersList.length === tempTalliedUsersUuid) {
                        setAllEmployeesSelected(true);
                    }
                }
            }
        }
        const talliedUsersUuid = countUUIDsInUsers(usersList, tempUserSelectedList);
        if (selectedUserGroupType === EnumUserGroup.CLIENTS) {
            setClientsCountSelected(talliedUsersUuid);
            setShowClientsBadge(talliedUsersUuid !== 0);
        } else {
            setEmployeesCountSelected(talliedUsersUuid);
            setShowEmployeesBadge(talliedUsersUuid !== 0);
        }

        setSelectedUsers(tempUserSelectedList);
    };

    const countUUIDsInUsers = (userList, selectedUsers) => {
        return userList.filter((userItem) => selectedUsers.some((selectedUser) => selectedUser.uuid === userItem.uuid)).length;
    };

    const verifyUserGroupValues = (formType, userGroupType) => {
        if (formType === FormType.SURVEYS) {
            if (userGroupType === EnumUserGroup.EMPLOYEES || userGroupType === EnumUserGroup.CLIENTS) {
                if (userGroupType === EnumUserGroup.EMPLOYEES) {
                    setShowEmployeesBadge(true);
                    setAllEmployeesSelected(true);
                }
                if (userGroupType === EnumUserGroup.CLIENTS) {
                    setShowClientsBadge(true);
                    setAllClientsSelected(true);
                }
            }
            if (userGroupType === EnumUserGroup.SELECTED_USERS) {
                if (employeesCountInPoll != 0) {
                    setShowEmployeesBadge(true);
                    setEmployeesCountSelected(employeesCountInPoll);
                }
                if (clientsCountInPoll != 0) {
                    setShowClientsBadge(true);
                    setClientsCountSelected(clientsCountInPoll);
                }
            }
            if (userGroupType === EnumUserGroup.ALL_USERS) {
                setShowEmployeesBadge(true);
                setAllEmployeesSelected(true);
                setShowClientsBadge(true);
                setAllClientsSelected(true);
            }
        }
        if (formType === FormType.POLLS) {
            if (userGroupType.allEmployees && userGroupType.allClients) {
                // identify if polls value for allEmployeesInPoll & allClientsInPoll is true
                const employeeValue = userGroupType.allEmployees.split(" ");
                if (employeeValue[0] === "All") {
                    setShowEmployeesBadge(true);
                    setAllEmployeesSelected(true);
                } else {
                    setShowEmployeesBadge(true);
                    setAllEmployeesSelected(false);
                    setEmployeesCountSelected((Number(employeeValue[0])));
                }
                const clientsValue = userGroupType.allClients.split(" ");
                if (clientsValue[0] === "All") {
                    setShowClientsBadge(true);
                    setAllClientsSelected(true);
                } else {
                    setShowClientsBadge(true);
                    setAllClientsSelected(false);
                    setClientsCountSelected((Number(clientsValue[0])));
                }
            }
            if (userGroupType.allEmployees && !userGroupType.allClients) {
                // identify if polls value for allEmployeesInPoll is true & allClientsInPoll is false
                const employeeValue = userGroupType.allEmployees.split(" ");
                if (employeeValue[0] === "All") {
                    setShowEmployeesBadge(true);
                    setAllEmployeesSelected(true);
                } else {
                    setShowEmployeesBadge(true);
                    setAllEmployeesSelected(false);
                    setEmployeesCountSelected((Number(employeeValue[0])));
                }
            }
            if (userGroupType.allClients && !userGroupType.allEmployees) {
                // identify if polls value for allEmployeesInPoll is false & allClientsInPoll is true
                const clientsValue = userGroupType.allClients.split();
                if (clientsValue[0] === "All") {
                    setShowClientsBadge(true);
                    setAllClientsSelected(true);
                } else {
                    setShowClientsBadge(true);
                    setAllClientsSelected(false);
                    setClientsCountSelected((Number(clientsValue[0])));
                }
            }
        }
    };

    const mapUsersToUuids = (selectedUsers) => {
        return selectedUsers.map(user => user.uuid);
    };

    return (
        <React.Fragment>
            <Dialog open={showPollVisibilityModal} onClose={togglePollVisibilityModal}
                maxWidth="md"
                scroll="body"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                PaperProps={{
                    style: {
                        width: "90%",
                        height: "fit-content",
                        maxHeight: "900px",
                        borderRadius: "1.25rem",
                        overflow: "auto",
                        background: "#F9FAFC"
                    },
                }}
                className="scroll-design"
            >
                <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
                    <Header>
                        <Text color="#4B5155" size="18px" weight="700"> {modalTitle}</Text>
                        <div className="row" style={{ marginTop: "auto", marginBottom: "auto" }}>
                            <div className="field" style={{ display: "flex" }}>
                                <InputSelector style={{ height: "38px", width: "200px", paddingLeft: "23px", fontSize: "14px" }} value={selectedUserGroupType} onChange={(e) => retrieveUpdatedUsersList(e.target.value)} >
                                    <option value={EnumUserGroup.EMPLOYEES} style={{ fontSize: "1rem" }}>
                                        Employees
                                    </option>
                                    <option value={EnumUserGroup.CLIENTS} style={{ fontSize: "1rem" }}>
                                        Clients
                                    </option>
                                </InputSelector>
                            </div>
                        </div>
                    </Header>
                    <Box sx={{ flex: "1" }}>
                        <FlexContainer style={{ height: "100%", }}>
                            <div style={{ background: "#FFFFFF", height: "95px", display: "flex", justifyContent: "left", padding: "0 50px", flexDirection: "row" }}>
                                <Text color="#4B5155" size="16px" margin="auto 0" >Selected People:</Text>
                                {showEmployeesBadge &&
                                    <div style={{ marginTop: "auto", marginBottom: "auto", paddingLeft: "26px", verticalAlign: "middle" }}>
                                        <SelectedPeopleItem>
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                <SelectedPeopleIcon>
                                                    <img src={UserGroup} alt="peopleIcon" height="20px" style={{ margin: "auto" }} />
                                                </SelectedPeopleIcon>
                                                <Text size="16px" weight="500" style={{ marginLeft: "0.5rem", marginBlockStart: "unset", marginBlockEnd: "unset", display: "flex", alignItems: "center" }} >
                                                    {allEmployeesSelected ? "All" : employeesCountSelected} Employees
                                                </Text>
                                            </div>
                                            <img src={CloseIcon} alt="plusIcon" style={{ height: "28px", width: "28px", marginTop: "auto", marginBottom: "auto", cursor: "pointer" }} onClick={() => removeSelectedPeopleItems(EnumUserGroup.EMPLOYEES)} />
                                        </SelectedPeopleItem>
                                    </div>
                                }
                                {showClientsBadge &&
                                    <div style={{ marginTop: "auto", marginBottom: "auto", paddingLeft: "17px", verticalAlign: "middle" }}>
                                        <SelectedPeopleItem>
                                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                                                <SelectedPeopleIcon>
                                                    <img src={UserGroup} alt="peopleIcon" height="20px" style={{ margin: "auto" }} />
                                                </SelectedPeopleIcon>
                                                <Text size="16px" weight="500" style={{ marginLeft: "0.5rem", marginBlockStart: "unset", marginBlockEnd: "unset", display: "flex", alignItems: "center" }} >
                                                    {allClientsSelected ? "All" : clientsCountSelected} Clients
                                                </Text>
                                            </div>
                                            <img src={CloseIcon} alt="plusIcon" style={{ height: "28px", width: "28px", marginTop: "auto", marginBottom: "auto", cursor: "pointer" }} onClick={() => removeSelectedPeopleItems(EnumUserGroup.CLIENTS)} />
                                        </SelectedPeopleItem>
                                    </div>
                                }
                            </div>
                            <FlexContainer
                                direction="column"
                                mDirection="column"
                                tDirection="column"
                                marginTop="26px"
                                marginLeft="50px"
                                marginRight="50px"
                                marginBottom="30px"
                                height="100%"
                            >
                                <FlexContainer direction="row" mDirection="row" tDirection="row" justifyContent="space-between" width="100%">
                                    <Text size="16px" color="#4B5155" weight="700">Select {selectedUserGroupType}</Text>
                                    {loadingFetchUsers ?
                                        <div style={{ margin: "auto 0" }}>
                                            <Skeleton variant="text" width="150px" height="22px" animation="wave" />
                                        </div>
                                        :
                                        <Text size="16px" color="#4B5155" weight="700">
                                            {countUUIDsInUsers(usersList, selectedUsers) ?? 0} {selectedUserGroupType} Selected
                                        </Text>
                                    }
                                </FlexContainer>
                                <FlexContainer direction="row" mDirection="row" tDirection="row" justifyContent="space-between" width="100%" marginBottom="20px">
                                    <SearchBar placeholder={`Search ${selectedUserGroupType.toLowerCase()}`} onChange={(e) => handleSearch(e)} />
                                    <AllUserToggler>
                                        <span style={{ margin: "auto 0" }}>All {selectedUserGroupType}</span>
                                        <ToggleAllUsersSwitch onChange={handleAllSwitch} checked={selectedUserGroupType === EnumUserGroup.CLIENTS ? allClientsSelected : allEmployeesSelected} />
                                    </AllUserToggler>
                                </FlexContainer>
                                <FlexContainer direction="row" mDirection="row" tDirection="row" justifyContent="flex-end" style={{ flexGrow: 1 }}>
                                    {loadingFetchUsers &&
                                        <LottieLoadingIndicator />
                                    }
                                    {!loadingFetchUsers &&
                                        <PollsUsersList
                                            usersToDisplay={usersToDisplay}
                                            selectedUserGroupType={selectedUserGroupType}
                                            handleSelectUser={handleSelectUser}
                                            selectedUsers={selectedUsers}
                                        />
                                    }
                                </FlexContainer>
                                <FlexContainer direction="row" mDirection="row" tDirection="row" justifyContent="flex-end" marginTop="28px" >
                                    <CancelButton borderRadius="7px" onClick={(e) => togglePollVisibilityModal() }>Cancel</CancelButton>
                                    <SaveButton border="unset !important" color="white" backgroundColor="#006CFF" borderRadius="7px" onClick={(e) => handleSave(allEmployeesSelected, allClientsSelected, mapUsersToUuids(selectedUsers), employeesCountSelected, clientsCountSelected) }>Save</SaveButton>
                                </FlexContainer>
                            </FlexContainer>
                        </FlexContainer>
                    </Box>
                </div>
            </Dialog>
        </React.Fragment>
    );
};

export default VisibilityModal;
