import React, { useState } from "react";
import styled from "styled-components";
import {
    Text, Button,
    FlexContainer,
    Toast,
    ErrorLabel
} from "src/components";
import DropZone from "react-dropzone";
import { UserKeys } from "src/constants/userDetails";
import axios from "axios";
import { API_URL } from "src/scenes/App";

import greenCheckImg from "src/img/check@2x.png";
import removeHeroImg from "src/img/remove-hero-image.png";

const UploadContainer = styled.div`
    padding-top: 1.5em;
    padding-bottom: 1.5em;
`;
const PreviewContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 20px;
`;

const StyledDropZone = styled(DropZone)`
    cursor: pointer;
    position: relative;
`;

const BrowseFileContainer = styled.div`
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #004165;
    border-radius: 15px;
    width: 182px;
    height: 115px;
    flex-shrink: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    background-image: url(${ p => p.backgroundImg});
    background-position: center center;
    background-size: cover;
    border: ${p => p.isSelected && "1px solid #36BE24"};
    cursor: pointer;
`;  

const GreenCheckImgContainer = styled.span`
    position: absolute;
    left: 42%;
    bottom: 10px;
    & img {
        width: 25px;
        position: absolute;
    }
`;

const RemoveImgIconContainer = styled.span`
    position: absolute;
    left: 90%;
    top: -8px;
    
    & img {
        width: 28px;
        position: absolute;
        cursor: pointer;
    }
`;

const maxSize = 5242880; //5MB

const CardHeroImageDropzone = ({ customUploadedHeroImage, uploadedCallback, removedUploadedCallback, selectedHeroUuid, customUploadedUuid }) => {
    const token = localStorage.getItem(UserKeys.TOKEN);
    const [iconFile, setIconFile] = useState([]);
    const [isUploading, setIsUploading] = useState(false);
    const [uploadPercentage, setUploadPercentage] = useState(0);
    const [errorMessage, setErrorMessage] = useState("");

    const uploadFile = (droppedFiles) => {
        const droppedFilesWithPreview = droppedFiles.map(oneFile => {
            const fileWithPreview = {
                ...oneFile,
                preview: URL.createObjectURL(oneFile)
            };
            return fileWithPreview;
        });

        setIconFile(droppedFilesWithPreview);
    };

    const reselectUploadedImage = (event) => {
        event.stopPropagation();
        uploadedCallback(customUploadedHeroImage.imageUrl);
    };

    const removeFile = (event) => {
        event && event.stopPropagation();

        setIconFile([]);
        setIsUploading(false);
        setErrorMessage("");

        removedUploadedCallback();
    };

    const getFileuploadHeaderParameter = (fileToUpload) => {
        //set parameters applied in S3 updating
        return {
            headers: {
                "Content-Type": fileToUpload.type
            },

            //set current updating percentage to S3 of the file and used to display on screen
            onUploadProgress: progressEvent => {
                handleUploadProgress(progressEvent);
            }
        };
    };

    const handleUploadProgress = (progressEvent) => {
        const uploadPercent = (progressEvent.loaded / progressEvent.total) * 100;
        //get current time and add it to the end of logo url in order to guarantee the file can be
        //auto refreshed when a new logo is updated due to the updated logo's url is the same
        
        setIsUploading(true);
        setUploadPercentage(uploadPercent);
    };

    const onHeroImageUploaded = (assetIconUrl) => {
        const currentTime = new Date().getTime().toString();
        const suffixTimestamp = "?" + currentTime; //trick for the image to not load from cache
        const assetUrlWithTimeStamp = assetIconUrl + suffixTimestamp;
        uploadedCallback(assetUrlWithTimeStamp);
    };

    /**
     * File dropped into logo drop zone, validate file type and upload to S3
     * @param droppedFiles {Array<File>}
     */
    const onDrop = (droppedFiles) => {
        removeFile();

        // If the drop zone receives anything other than the accepted formats, or anything greater
        // than the max file size specified, the droppedFiles array will be empty.
        if (droppedFiles.length === 0) {
            setErrorMessage("Unsupported file type. Images may be JPEG or PNG format, and less than 5MB in size.");
            return;
        }

        // Take first file if multiple provided and use that
        let fileToUpload = droppedFiles[0];
        let fileExtension = fileToUpload.name.split(".").pop();
        setErrorMessage("");
        setIsUploading(true);
        setUploadPercentage(0);
        axios.get(`${API_URL}/membershipCard/company/generateImageUrl/${fileExtension}`, {
            headers: {
                Authorization: "Bearer " + token
            }
        }).then(res => {

            //set preview of the updated file to the props
            uploadFile(droppedFiles);

            //send updated file to the pre-signed url
            const fullUrl = res.data.url;
            
            const parameter = getFileuploadHeaderParameter(fileToUpload);
            return axios.put(fullUrl, fileToUpload, parameter)
                .then(result => {
                    const storedUrl = fullUrl.substring(0, fullUrl.indexOf("?"));
                    onHeroImageUploaded(storedUrl);
                });
        }).catch(error => {
            return Toast.error(error.message);
        }).finally(() => {
            setIsUploading(false);
        });
    };

    const isSelected = selectedHeroUuid === customUploadedUuid;
    return (
        <>
            <div>
                <UploadContainer className="spacing-x">
                    {isUploading ?
                        <>
                            <PreviewContainer>
                                <FlexContainer width="405px" height="102px">
                                    <Text color="black">
                                        Uploading - {uploadPercentage}%
                                    </Text>
                                    <div style={{ marginLeft: "50px" }}>
                                        <svg width="391" height="10">
                                            <rect width="391" height="10" fill="#566cad" rx="0" ry="0"></rect>
                                            <rect width={`${uploadPercentage}%`} height="10" fill="#f9db5e" rx="0" ry="0"></rect>
                                        </svg>
                                    </div>
                                </FlexContainer>
                            </PreviewContainer>
                        </>
                        :
                        <PreviewContainer>
                            <StyledDropZone
                                accept="image/png, image/jpeg"
                                maxSize={maxSize}
                                onDrop={onDrop.bind(this)}
                            >
                                { iconFile.length > 0 && <RemoveImgIconContainer> <img src={removeHeroImg} alt="Remove uploaded hero image" onClick={(event) => removeFile(event)} /></RemoveImgIconContainer> } 
                                
                                <BrowseFileContainer backgroundImg={iconFile.length > 0 && iconFile[0].preview} isSelected={isSelected} onClick={(event) => iconFile.length > 0 && reselectUploadedImage(event) } >
                                    { iconFile.length === 0 && <Button size="14px" color="#FFF" backgroundColor="#000" padding="10px 30px 10px" borderRadius="7px" style={{ boxShadow: "0px 3px 6px #00000029" }}>Browse File</Button> }
                                </BrowseFileContainer>
                                { isSelected && <GreenCheckImgContainer> <img src={greenCheckImg} alt="Selected Template Icon" /></GreenCheckImgContainer> } 
                            </StyledDropZone>
                            <ImageFormat />

                        </PreviewContainer>
                    }
                    
                </UploadContainer>
            </div>
            <ErrorLabel className="spacing-x" margin="0 0 1em 0">{errorMessage}</ErrorLabel>
        </>
    );
};

const ImageFormat = () => {
    return (
        <Text align="left" color="#8E4EB4" size="0.9375em" margin="0 0 0 10px">
            <b>Artwork Size Guide: </b><br/>
            For best results, ensure your image follows the 
            recommended dimensions: <br/><br/>

            Dimensions: 1032x336 px. (3:1 aspect ratio) <br/>
            File Format: PNG or JPEG
        </Text>
    );
};

export default CardHeroImageDropzone;


