import React, { useContext } from "react";
import styled from "styled-components";
import { FlexContainer, Text, Button, PageHeadingLabel, LottieLoadingIndicator } from "src/components";
import { SubscriptionContext } from "../SubscriptionContext";
import Icon from "../../../img/new/purchase_more_users.svg";
import { UserContext } from "src/scenes/App/UserContext";
import * as PERMISSIONS from "src/constants/permissions";
import { withRouter } from "react-router-dom";

const BDCard2 = styled.div`
    background-color: ${ p => p.backgroundColor || "#fff" };
    color: ${p => p.color || "#000" };
    margin: ${p => p.margin};
    border: ${p => p.border || 0 };
    width: ${p => p.width};
    height: ${p => p.height || "6.875rem"};
    border-radius: ${p => p.borderRadius || "12px"};
    padding: ${p => p.padding || "1.4375rem 1.8125rem"};
    box-sizing: border-box;
    position: relative;
`;

const Label = styled.div`
    font-weight: 500;
    font-size: 0.875rem;
    color: ${p => p.color || "#000"}
`;

const Number = styled.div`
    font-size: 2rem;
    font-weight: 700;
    color: ${p => p.color || "#000"}
    margin: 0;
    text-align: left;
`;

const ButtonIcon = styled.img`
    width: 23px;
    height: 19px;
    color: #fff;
    margin-right: 6px;
`;

const SubscriptionGroupExpiryBox = styled.div`
    width: 100%;
    max-width: 47.8125rem;
    min-height: 3.8125rem;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    color: #FF5353;
    font-size: 0.875rem;
    font-weight: 500;
    padding: 0.625rem 1.8125rem;
    background: #fff;
    border-radius: 12px;
    margin-bottom: 1.4rem;
`;

const BusinessDashboard = ({ ...props }) => {
    const {
        companyName,
        totalNumber,
        employees,
        clients,
        trialClientUsers,
        isSalesAndPromotionsEnabled,
        isSubscriptionExpired,
        nextClick, // Increments currentForm from Forms.ACTIVE to Forms.PURCHASE which will render <Plan />
        loadingCount, 
    } = useContext(SubscriptionContext);
    const { permissions } = useContext(UserContext);

    const hasBillingPermission = PERMISSIONS.hasPermissions([PERMISSIONS.BILLING], permissions);
    const hasUserManagementPermission = PERMISSIONS.hasPermissions([PERMISSIONS.USER_LISTS], permissions);

    const licenses = [
        { label: "Total Licenses Purchased", number: totalNumber },
        { label: "Total Licenses Used", number: employees + clients },
        { label: "Total Licenses Remaining", number: totalNumber - (employees + clients) },
    ];

    let userCards = [
        { label: "Employees", number: employees, background: "#EAF3FF", border: "#006CFF", color: "#006CFF" },
        { label: "Clients", number: clients, background: "#EAF3FF", border: "#006CFF", color: "#006CFF" },
        // { label: 'Admins', number: '10', background: '#EFE7FF', border: '#633FE4', color: '#633FE4' },
        // { label: 'Support Professional', number: '10', background: '#E6FFF8', border: '#26A26B', color: '#26A26B' },
    ];

    if (isSalesAndPromotionsEnabled) {
        userCards.push({ label: "Trial Client Users", number: trialClientUsers, background: "#EAF3FF", border: "#006CFF", color: "#006CFF" });
    }

    const licensesToRender = licenses.map((license, index) =>
        <BDCard2 key={index} margin="0 1.6875rem 1.625rem 0" width="14.8125rem">
            <Label>{ license.label }</Label>
            <Number>{ Math.max(0, license.number) }</Number>
        </BDCard2>
    );

    const userCardsToRender = userCards.map((user, index) =>
        <BDCard2 key={index} className="bd-cards"
            color={ user.color } backgroundColor={ user.background }
            border={`1px solid ${ user.border }`}
            margin="0 1.875rem 1.625rem 0"
            width="13.125rem"
        >
            <Label color={ user.color }>{ user.label }</Label>
            <Number color={ user.color }>{ user.number }</Number>
        </BDCard2>
    );

    const onClickIsExpiredHandler = () => {
        props.history.push("/setting/subscriptions");
    };

    return (
        <>
            <PageHeadingLabel>
                Business Dashboard
            </PageHeadingLabel>
            <Text color="#000" weight="700" align="left" margin="35px 0 13px 0">Business Name</Text>
            <BDCard2
                margin="0 0 1.5rem 0"
                width="528px"
                height="39px"
                padding="0 29px"
                style={{ display: "flex", alignItems: "center" }}
            >
                <Label>{ companyName.length > 50 ? companyName.substring(0, 49) + "..." : companyName }</Label>
            </BDCard2>
            <LoadingContainer loadingCount={loadingCount}>
                { hasBillingPermission &&
                    <>
                        <Text color="#000" weight="700" align="left" margin="0 0 13px 0">License Overview</Text>
                        <FlexContainer direction="row" justifyContent="start" padding="0">
                            { licensesToRender }
                        </FlexContainer>
                        <Button backgroundColor={ !isSubscriptionExpired ? "#006CFF" : "#bfbfbf" }
                            color="#fff"
                            height="39px" width="270px"
                            marginTop="0"
                            marginBottom="0.5em"
                            style={{ 
                                display: "flex", boxShadow: "none", borderRadius: "10px",
                                cursor: !isSubscriptionExpired ? "pointer" : "default",
                                pointerEvents: !isSubscriptionExpired ? "auto" : "none"
                            }}
                            onClick={nextClick}
                        >
                            <ButtonIcon src={Icon}></ButtonIcon>
                            <span style={{ fontFamily: "Roboto, Helvetica, sans-serif", fontWeight: "500", fontSize: "14px" }}>Purchase More User Licenses</span>
                        </Button>
                        <Text align="left" color="#808080">
                            You can purchase more user licenses to add to your account. Use licenses to extend business app invitations <br />to users. Each amount of licenses purchased will create a new subscription for those licenses. Manage your <br /> subscriptions in Account Settings &gt; Subscriptions.
                        </Text>

                        { isSubscriptionExpired &&
                            <>
                                <Text align="left" color="#000" weight="700">Subscriptions</Text>
                                <SubscriptionGroupExpiryBox>
                                    <div style={{ maxWidth: "34.375rem", width: "100%" }}>
                                        Your subscription has expired. Please make a payment immediately to prevent cancellation. Go to Account Settings &gt; Subscriptions to view.
                                    </div>
                                    <Button color="#FF5353" border="1px solid #FF5353" 
                                        width="6.4375rem" height="2rem"
                                        borderRadius="10px" size="0.875rem" padding="0"
                                        style={{ lineHeight: "50%", borderRadius: 10, fontWeight: 700 }}
                                        onClick={ onClickIsExpiredHandler }
                                    >
                                        View
                                    </Button>
                                </SubscriptionGroupExpiryBox>
                            </>
                        }
                    </>
                }

                { hasUserManagementPermission &&
                    <>
                        <Text align="left" color="#000" weight="700">User Breakdown </Text>
                        <FlexContainer direction="row">
                            { userCardsToRender }
                        </FlexContainer>
                    </>
                }
                { (!hasBillingPermission && !hasUserManagementPermission) &&
                    <BDCard2 width="42.625rem" height="9rem" style={{ padding: "1.9375rem 1.8125rem" }}>
                        <Text size="1.25rem" weight="700" align="left" margin="0 0 0.8125rem 0">Permissions to Access Required</Text>
                        <Text align="left" margin="0">
                            Please use the navigation links on the side to explore the Me Business Portal.<br />
                            If you need to access a feature that is restricted, contact your account administrator.
                        </Text>
                    </BDCard2>
                }
            </LoadingContainer>
        </>
    );
};

const LoadingContainer = ({ loadingCount, children }) => {
    const isFetching = loadingCount > 0;

    return (
        <>
            { isFetching && <LottieLoadingIndicator />}
            { !isFetching && children }
        </>
    );
};
export default withRouter(BusinessDashboard);