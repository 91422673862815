import React from "react";
import styled from "styled-components";
import { Switch, withStyles } from "@material-ui/core";
import { SocialMediaPlatform } from "./AccountLinking/utils/Enums";

/** Social Media Platform Icons */
import FacebookIcon from "src/img/noticeboard/facebook_colored.svg";
import LinkedInIcon from "src/img/noticeboard/linkedin_colored.svg";
import InstagramIcon from "src/img/noticeboard/instagram.svg";

const SocialItem = styled.div`
    width: 315px;
    height: 51px;
    border: 1px solid #8291B2;
    border-radius: 12px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    margin-right: 54px;
`;
const InnerBox = styled.div`
    width: initial;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 16px;
    min-width: 0;
`;

const PageNameWrapper = styled.div`
  flex: 1;
  min-width: 0;
  overflow: hidden;
`;

const PageNameText = styled.span`
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;


const TogglePageSwitch = withStyles((theme) => ({
    root: {
        width: 51,
        height: 31,
        padding: 0,
        marginTop: "auto",
        marginBottom: "auto",
    },
    switchBase: {
        padding: 1,
        "&$checked": {
            transform: "translateX(19px)",
            color: "#E8F5E9",
            "& + $track": {
                backgroundColor: "#4CAF50",
                opacity: 1,
                border: "none",
            },
        },
        "&$focusVisible $thumb": {
            color: "#4CAF50",
            border: "6px solid #fff",
        },
    },
    thumb: {
        width: 29,
        height: 29,
    },
    track: {
        borderRadius: 29 / 2,
        backgroundColor: "#C4C4C4",
        opacity: 1,
        transition: theme.transitions.create(["background-color", "border"]),
    },
    checked: {},
    focusVisible: {},
}))(({ classes, ...props }) => {
    return (
        <Switch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            {...props}
        />
    );
});

const identifyPlatformLogo = (platformType) => {
    switch (platformType) {
        case SocialMediaPlatform.FACEBOOK:
            return FacebookIcon;
        case SocialMediaPlatform.INSTAGRAM:
            return InstagramIcon;
        case SocialMediaPlatform.LINKEDIN:
            return LinkedInIcon;
        default:
            console.error("Unsupported platform provided.");
            return null;
    }
};

function LinkedSocials(props) {
    const { pageName, pageUuid, pagePlatformType, onToggle } = props;
    const logo = identifyPlatformLogo(pagePlatformType);
    return (
        <SocialItem>
            <InnerBox>
                <img src={logo} alt={`${pagePlatformType} logo`} style={{ marginRight: "12px" }} />
                <PageNameWrapper>
                    <PageNameText>{ pageName }</PageNameText>
                </PageNameWrapper>
                <TogglePageSwitch onChange={() => onToggle(pageUuid)} />
            </InnerBox>
        </SocialItem>
    );
}

export default LinkedSocials;