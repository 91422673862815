export const ErrorList = {
    UNKNOWN: 0,
    EMPTY_HEADLINE: 1,
    EMPTY_DESCRIPTION: 2,
    EMPTY_BUTTON_TEXT: 3,
    EMPTY_BUTTON_LINK: 4,
    EMPTY_START_DATE: 5,
    EMPTY_END_DATE: 6,
    EMPTY_AUDIENCE: 7,
    EMPTY_PLACEMENT: 8,
    DATE_START_DATE_INVALID: 9,
    DATE_END_DATE_INVALID: 10,
    DATE_START_FORMAT_INVALID: 11,
    DATE_END_FORMAT_INVALID: 12,
    DATE_RANGE_INVALID: 13,
    EMPTY_PRIMARY_ASSET: 14,
    HEADLINE_LIMIT: 15,
    DESCRIPTION_LIMIT: 16,
    BUTTON_TEXT_LIMIT: 17
};

export const returnErrorMessages = {
    [ErrorList.DATE_START_DATE_INVALID]: "Please provide a day in the future for start date",
    [ErrorList.DATE_END_DATE_INVALID]: "Please provide a day in the future for finish date",
    [ErrorList.DATE_RANGE_INVALID]: "Please provide that start date is not greater finish date"
};

export const INPUT_FIELD_VALDATIONS = {
    HEADLINE_LENGTH: 60,
    DESCRIPTION_LENGTH: 2_000,
    BUTTON_TEXT_LENGTH: 25
};

export const ErrorStrings = {
    [ErrorList.UNKNOWN]: "* Error unknown. Please contact support@memotivationapp.com",
    [ErrorList.EMPTY_HEADLINE]: "* Please enter a headline ",
    [ErrorList.EMPTY_DESCRIPTION]: "* Please enter ad description ",
    [ErrorList.EMPTY_BUTTON_TEXT]: "* Please enter a button text ",
    [ErrorList.EMPTY_BUTTON_LINK]: "* Please enter a button link ",
    [ErrorList.EMPTY_AUDIENCE]: "* Please select an audience ",
    [ErrorList.EMPTY_PLACEMENT]: "* Please select ad placement ",
    [ErrorList.EMPTY_END_DATE]: "* Please enter end date ",
    [ErrorList.EMPTY_START_DATE]: "* Please enter start date ",
    [ErrorList.DATE_START_DATE_INVALID]: "* " + returnErrorMessages[ErrorList.DATE_START_DATE_INVALID],
    [ErrorList.DATE_END_DATE_INVALID]: "* " + returnErrorMessages[ErrorList.DATE_END_DATE_INVALID],
    [ErrorList.DATE_RANGE_INVALID]: "* Please provide that end date is ahead from start date",
    [ErrorList.EMPTY_PRIMARY_ASSET]: "* Please provide appropriate Primary Asset",
    [ErrorList.HEADLINE_LIMIT]: "* Please provide a headline below " + INPUT_FIELD_VALDATIONS.HEADLINE_LENGTH + " characters.",
    [ErrorList.DESCRIPTION_LIMIT]: "* Please provide a description below " + INPUT_FIELD_VALDATIONS.DESCRIPTION_LENGTH + " characters.",
    [ErrorList.BUTTON_TEXT_LIMIT]: "* Please provide a button text below " + INPUT_FIELD_VALDATIONS.BUTTON_TEXT_LENGTH + " characters."
};

export const Screens = {
    QUOTES: 0, //Quote
    FULLSCREEN_QUOTE: 1,
    FULLSCREEN_VERTICAL_QUOTE: 2,
    NOTICEBOARD: 3,
};

export const AnimationColors = {
    BACKGROUND: "#90B5FA",
    PRIMARY: "#2C349B",
    SECONDARY: "#346E73"
};

export const PLACEMENT_OPTIONS = [
    { value: "1", label: "Quotes" },
    { value: "2", label: "Noticeboard" },
];

export const ALLOWED_FILE_TYPES = {
    IMAGE: ["jpeg", "jpg", "png"],
    VIDEO: ["mp4"]
};

export const validImageTypes = ["image/jpeg", "image/jpg", "image/png"];
export const validVideoTypes = ["video/mp4"];