import React, { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { FlexContainer, Text, FormField } from "src/components";
import UserAvatar from "../UserAvatar";

import { ChatContext } from "src/scenes/Chat/ChatContext";
import { FORM_TYPE_EDIT, USER_TYPE_EMPLOYEES, FORM_TYPE_CREATE } from "src/constants/chat";
import { SearchInput } from "../CreateChatModal";
import { capitalizeString, trimStringWithEllipsis, isUserOnline } from "src/utils/helpers";

import PlusIcon from "src/img/new/chats/add.svg";
import RemoveIcon from "src/img/new/chats/close.svg";

const Container = styled.div`
    position: relative;
    width: 90%;
    display: flex;
    flex-direction: column;
    margin: ${p => p.margin || "0"}
`;

export const Card = styled.div`
    position: relative;
    background: #FFFFFF;
    border: 1px solid #DBE5ED;
    border-radius: 0.5rem;
    height: 26.8125rem;
    width: 100%;
    box-sizing: border-box;
    overflow-y: auto;

    ::-webkit-scrollbar {
        width: 0.375rem;
        height: 0.5rem;
        border-radius: 0.375rem;
        border-right: none;
        border-left: none;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #DBE5ED;
        border-radius: 0.375rem;
    }

    ::-webkit-scrollbar-track {
        margin-left: 1.875rem;
    }

    ::-webkit-scrollbar-track-piece:start {
        border-radius: 0.375rem;
    }
`;

export const StyledInput = styled(FormField)`
    position: relative;
    background-repeat: no-repeat;
    background-position: calc(100% - 0.9375rem) center;
    border: 1px solid #DBE5ED;
    width: 100%;
    max-width: 53.9375rem;
    height: 2.5rem;
    font-size: 0.875rem;
    text-align: left;
    padding: 0 0.9375rem 0 0.9375rem;
    border-radius: 0.5rem !important;
    margin: 0 0 1.5rem 0;

    ::placeholder {
        text-align: left;
        color: #AFBBC6;
        font-size: 0.875rem;
    }
`;

const Upper = styled.div`
    margin: 0 0 0.875rem 0;
    height: 4.6875rem;
    width: 100%;
`;

const ListItem = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.625rem 1.4375rem;
    box-sizing: border-box;
    border-bottom: ${p => p.borderBottom};
`;

const AddOrRemoveIcon = styled.img`
    height: 1.8125rem;
    width: 1.8125rem;
    cursor: pointer;
    margin-right: 1.3125rem;
`;

const FlexRow = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 2rem;
`;

const Group = (props) => {
    const { 
        selectedUserListType, availableUsersList, searchFilteredUserList, handleSearch, handleSelectedEmployeesToChat,
        handleSetGroupChatName, formType, hasLeadershipRoles
    } = props;

    const {
        matrixClient,
        currentMatrixUserId,
        useChatMessage: ctxUseChatMessage
    } = useContext(ChatContext);

    const {
        openedMatrixChat
    } = ctxUseChatMessage;

    const [chatName, setChatName] = useState(formType === FORM_TYPE_EDIT ? openedMatrixChat.name : "");
    const [selectedUuids, setSelectedUuids] = useState(formType === FORM_TYPE_EDIT ?
        openedMatrixChat.users.filter(user => user.currentMember).map(user => user.employeeUuid) :
        availableUsersList.filter(user => user.matrixUserId === currentMatrixUserId).map(user => user.uuid));

    const isEmployee = selectedUserListType === USER_TYPE_EMPLOYEES;

    const isFirstRender = useRef(true);

    useEffect(() => {
        if (!isFirstRender.current) {
            handleSelectedEmployeesToChat(selectedUuids);
        } else {
            isFirstRender.current = false;
        }                     
    }, [selectedUuids]);

    useEffect(() => handleSetGroupChatName(chatName), [chatName]);

    const handleUserSelection = (uuid) => {
        const uuidIndex = selectedUuids.indexOf(uuid);
        const updatedUuids = [...selectedUuids];
        uuidIndex === -1 ? updatedUuids.push(uuid) : updatedUuids.splice(uuidIndex, 1);

        setSelectedUuids(updatedUuids);
    };

    const noUsersToDisplay = () => {
        return (
            <div style={{ padding: "15px" }}>
                No users to display.
            </div>
        );
    };

    const renderUnselectedUsersList = () => {

        const availableUsers = searchFilteredUserList.filter(user => !selectedUuids.includes(user.uuid));
        const usersLength = availableUsers.length;
        if (usersLength === 0) {
            return noUsersToDisplay();
        }

        return availableUsers.map((user, index) => {
            const userFullName = trimStringWithEllipsis(capitalizeString(user.firstName) + " " + capitalizeString(user.lastName));

            return (
                <ListItem key={user.uuid}
                    borderBottom={index + 1 === usersLength ? "none" : "1px solid #DBE5ED"}
                >
                    <div style={{ position: "relavite", display: "flex", alignItems: "center" }}>
                        <UserAvatar width="3.125rem" height="3.125rem" hideInfo={true}
                            photo={user.profilePictureUrl}
                            background="#D6D6D6"
                            firstName={user.firstName}
                            lastName={user.lastName}
                            statusBorder="1px solid #fff"
                            hideStatus={true}
                            withShadow={true}
                            pixelBorder="1px"
                            showPresenceStatus={true}
                            isOnline={ isUserOnline(matrixClient, user.matrixUserId) }
                        />
                        <Text margin="0" color="#7B8793" size="1rem" weight="500">
                            { userFullName }
                        </Text>
                    </div>
                    { !selectedUuids.includes(user.uuid) && <AddOrRemoveIcon src={PlusIcon} alt="add" onClick={() => handleUserSelection(user.uuid)} /> }
                </ListItem>
            );
        });
    };

    const renderSelectedUsersList = () => {
        return selectedUuids.map((uuid, index) => {

            const user = availableUsersList.find(item => item.uuid === uuid);
            if (!user) {
                return "";
            }

            const userFullName = trimStringWithEllipsis(capitalizeString(user.firstName) + " " + capitalizeString(user.lastName)); 
                
            return (
                <ListItem key={index}>
                    <FlexContainer direction="row" alignItems="center">
                        <UserAvatar width="3.125rem" height="3.125rem" hideInfo={true}
                            photo={user.profilePictureUrl}
                            background="#D6D6D6"
                            firstName={user.firstName}
                            lastName={user.lastName}
                            statusBorder="1px solid #fff"
                            hideStatus={true}
                            withShadow={true}
                            pixelBorder="1px"
                            showPresenceStatus={true}
                            isOnline={ isUserOnline(matrixClient, user.matrixUserId) }
                        />
                        <Text margin="0" color="#7B8793" size="1rem" weight="500">
                            { userFullName }
                        </Text>
                    </FlexContainer>
                    { user.matrixUserId !== currentMatrixUserId &&
                        <AddOrRemoveIcon src={RemoveIcon} alt="add" onClick={() => handleUserSelection(user.uuid)} />
                    }
                </ListItem>
            );
        });
    };

    return (
        <React.Fragment>
            <Text align="left" color="#4B5155" weight="700" size="1rem" margin="0 0 0.8125rem 0">
                { !hasLeadershipRoles && "Group Name (Optional)" }
            </Text>
            <StyledInput value={chatName} onChange={e => setChatName(e.target.value)} placeholder="Create a group name (optional)" />
            <FlexRow>
                <Container margin="0 1rem 0 0" >
                    <Upper>
                        <Text align="left" color="#4B5155" weight="700" size="1rem" margin="0 0 0.8125rem 0">
                            Select { isEmployee ? "Employees" : "Clients" }
                        </Text>
                        <SearchInput onChange={(e) => handleSearch(e.target.value)}
                            placeholder="Search People"
                            margin="0"
                        />
                    </Upper>
                    <Card>
                        { renderUnselectedUsersList() }
                    </Card>
                </Container>
                <Container margin="0 0 0 0" >
                    <Upper>
                        <Text margin="0 0 0.8125rem 0" size="1rem">&nbsp;</Text>
                        <Text align="left" color="#4B5155" size="1rem" weight="700" margin="0" height="2.5rem" display="flex" flexDirection="column" justifyContent="center">
                            { formType === FORM_TYPE_CREATE ? "" : "Edit" } Group Members
                        </Text>
                    </Upper>
                    <Card>
                        { selectedUuids.length ? renderSelectedUsersList() : "" }
                    </Card>
                </Container>
            </FlexRow>
        </React.Fragment>
    );
};

export default Group;