import React from "react";
import styled from "styled-components";
import { STANDARD, ROLE_STYLES, LEADERSHIP_ROLE_STYLE } from "src/constants/roles";

const Button = styled.button`
    background: ${ p => p.background };
    color: ${ p => p.color };
    width: 170px !important;
    max-height: 39px;
    cursor: none;
    pointer-events: none;
    display: ${ p => p.isFromModal && "block" };
    font-size: ${ p => p.fontSize && p.fontSize + " !important" };
    
`;

const LeadershipButton = styled(Button)`
    background: ${LEADERSHIP_ROLE_STYLE.background};
    color: ${LEADERSHIP_ROLE_STYLE.color};
    font-size: ${ p => p.fontSize && p.fontSize + " !important" };
`;

const Permission = (props) => {
    const roleStyles = ROLE_STYLES;
    const hasLeadershipRole = props.hasLeadershipRole;
    const fontSize = props.fontSize;
    const isFromModal = props.isFromModal; //rendering From UserPermissionModal
    const handleStyles = (key) => {
        return props.role ? roleStyles[props.role][key] : roleStyles[0][key];
    };

    const renderBtnText = (name) => props.role === STANDARD ? `${name} (${ props.isClient ? "Client" : "Employee" })` : name;


    return (
        <>
            <Button 
                className="employee-list-table-btn" 
                background={handleStyles("background")} 
                color={handleStyles("color")} 
                hasLeadershipRole={hasLeadershipRole}
                isFromModal={isFromModal}
                fontSize={fontSize}
            >
                { renderBtnText(handleStyles("name")) }
            </Button>
            {hasLeadershipRole && <LeadershipButton className="employee-list-table-btn" background={handleStyles("background")} color={handleStyles("color")} fontSize={fontSize}>
                {LEADERSHIP_ROLE_STYLE.name}
            </LeadershipButton>}
        </>
    );
};

export default Permission;