import React from "react";
import styled from "styled-components";

import CorrectPng from "src/img/survey/correct.png";
import ExpiredIcon from "src/img/noticeboard/expired.svg";

const CheckIcon = styled.img`
    display: flex;
    height: 34px;
    margin-left: 10px;
    justify-content: center;
    align-content: center;
    filter: hue-rotate(32.2deg) saturate(0.82) brightness(1.04);
`;

const CrossIcon = styled.img`
    display: flex;
    height: 34px;
    margin-left: 10px;
    justify-content: center;
    align-content: center;
`;

const DisplayConnectionIcon = (isExpiredPage) => {
    return isExpiredPage ?
        <CrossIcon src={ExpiredIcon} alt="wrong" />
        : <CheckIcon src={CorrectPng} alt="check" />;
};


export default DisplayConnectionIcon;