import React, { useEffect, useState } from "react";
import { API_URL } from "src/scenes/App";
import axios from "axios";
import styled from "styled-components";
import { Box, Dialog } from "@material-ui/core/";
import {
    Button, FormField, ErrorLabel, Toast, LottieLoadingIndicator
} from "src/components";
import MoreAboutPermissions from "./MoreAboutPermissions";
import LeadershipRoles from "./Leadership/LeadershipRoles";
import { LeadershipContext } from "./Leadership/LeadershipContext";
import LeadershipRoleModal from "./Leadership/LeadershipRoleModal";
import RolesForm from "./RolesForm";
import { ROLE_STYLES, STANDARD, MULTIPLE_PERMISSIONS, ACCOUNT_OWNER } from "src/constants/roles";
import { FULL_ACCESS_BUSINESS_CHAT, MESSAGE_CLIENTS, MESSAGE_EMPLOYEES, CREATE_GROUP_CHAT } from "src/constants/permissions";
import { useGetLeadershipRoles } from "src/scenes/Chat/hooks";
import {
    useSetEmployeeLeadershipRoles,
    useSaveAssignedLeadershipRoles,
    useSavePermissions
} from "../hooks";

const FormButtons = styled(Button)`
    border-radius: 7px !important;
    box-shadow: none;
    width: 100%;
    max-width: 8.75rem;
`;

function UserRoleAndPermissionsForm(props) {
    const { showDialog, onFormSubmissionSuccessful, handleClose, userLeadershipRoles, token, hasLeadershipRole } = props;
    const [ isDataFetchFinished, setIsDataFetchFinished ] = useState(false);
    const [ roles, setRoles ] = useState([]);
    const [ permissions, setPermissions ] = useState(null);
    const [ currentSelectedRole, setCurrentSelectedRole ] = useState(props.userRole);
    const [ currentSelectedPermissions, setCurrentSelectedPermissions ] = useState(props.userPermissions);
    const [ disableInput, setDisableInput ] = useState(false);
    const [ error, setError ] = useState(null);
    const { leadershipRoles, retrieveLeadershipRoles } = useGetLeadershipRoles();
    const [ leadershipModal, setLeadershipModal ] = useState({
        show: false,
        isCreate: true,
        formData: {}
    });

    const { isSavingPermissions, savePermissions } = useSavePermissions();
    const {
        hasLeadershipRolesChanged,
        selectedLeadershipRoles,
        setSelectedLeadershipRoles,
        assignLeadershipRoles,
    } = useSetEmployeeLeadershipRoles();
    const {
        isSavingAssignedLeadership,
        saveAssignedLeadershipRoles
    } = useSaveAssignedLeadershipRoles();

    const headers = { headers: { Authorization: "Bearer " + token } };

    const userRoleStylesForInput = ROLE_STYLES;

    useEffect(() => {
        if (userLeadershipRoles) {
            setSelectedLeadershipRoles(userLeadershipRoles);
        }
    }, [userLeadershipRoles]);

    useEffect(() => {
        // This will check whether the 'checked' permissions matches those of any role's default permissions;
        // if it does not match, the role should automatically become "Multiple Permissions"
        let roleToSet = MULTIPLE_PERMISSIONS;
        const selected = [...currentSelectedPermissions];
        selected.sort((a, b) => a - b);

        for (let i = 0; i < roles.length; i++) {
            const arr2 = roles[i].permissionIds.sort((a, b) => a - b);
            if (selected.length === arr2.length) {
                const status = selected.every((v, i) => v === arr2[i]);
                if (status) {
                    roleToSet = roles[i].id;
                    break;
                }
            }
        }

        setCurrentSelectedRole(roleToSet);
    }, [currentSelectedPermissions, roles]);

    const apisGet = async (url) => {
        try {
            const response = await axios.get(`${API_URL}/${url}`, headers);
            return [ response.data, null ];
        }
        catch (error) {
            return [ null, error ];
        }
    };

    const getRolesAndDefaultPermissions = async () => {
        const [ data, error ] = await apisGet("permissions/roles/get");
        if (!error) {
            setRoles(data.roles);
        }
        else {
            setError(error);
        }
    };

    const getPermissions = async (isClient) => {
        const apiGetPermissionForWebDashboard = !isClient ? apisGet("permissions/employee") : apisGet("permissions/client");
        const [ data, error ] = await apiGetPermissionForWebDashboard;
        if (!error) {
            setPermissions(data.permissions);
            renderForm();
            toggleDisableOnButtons();
        }
        else {
            return Toast.error(error.message);
        }
    };

    const renderForm = () => {
        setIsDataFetchFinished(true);
    };

    const clickRole = (roleId) => {
        const item = roles.find(role => role.id === roleId);
        setCurrentSelectedPermissions(item.permissionIds);
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        setDisableInput(true);
        toggleDisableOnButtons();
        try {
            if (props.userRole !== ACCOUNT_OWNER) {
                await savePermissions(props.userUuid, currentSelectedPermissions, headers);
            }
            if (hasLeadershipRolesChanged) {
                const filtered = removeLeadershipRolesWithBothFalseVisible();
                await saveAssignedLeadershipRoles(props.userUuid, filtered, headers);
            }

            onFormSubmissionSuccessful();
        } catch (error) {
            return setError(error);
        } finally {
            setDisableInput(false);
            toggleDisableOnButtons();
        }
    };

    const toggleDisableOnButtons = () => {
        const buttons = document.querySelectorAll(".form-btns");
        Array.from(buttons).forEach(button => {
            button.classList.toggle("disabled");
        });
    };

    /**
     * disable checkbox for remaining permission when FULL_ACCESS_BUSINESS_CHAT is selected */
    const disableChatPermission = (permissionId) => {
        if (disableInput) {
            return true;
        }
    
        const permissionsThatCanBeDisabled = [MESSAGE_CLIENTS, MESSAGE_EMPLOYEES, CREATE_GROUP_CHAT];
        if (permissionsThatCanBeDisabled.includes(permissionId)) {
            if (currentSelectedPermissions.includes(FULL_ACCESS_BUSINESS_CHAT)) {
                return true;
            }
        }

        return false;
    };

    const setPermissionIsChecked = (permissionId, isChecked) => {
        if (!isChecked && currentSelectedPermissions.includes(permissionId)) {
            setCurrentSelectedPermissions(
                currentSelectedPermissions.filter(id => id !== permissionId)
            );
        }
        if (isChecked && !currentSelectedPermissions.includes(permissionId)) {
            setCurrentSelectedPermissions(
                [...currentSelectedPermissions, permissionId]
            );
        }
    };

    const getRoleInputStyles = (key) => userRoleStylesForInput[props.userRole][key];
    const renderRoleInputText = (name) => props.userRole === STANDARD ? `${name} (${ props.isClient ? "Client" : "Employee" })` : name;
    const isUserRole = (roleId) => currentSelectedRole === roleId;

    useEffect(() => {
        // this will abort ongoing async calls when component unmounts
        const controller = new AbortController();
        const { signal } = controller;
        retrieveLeadershipRoles(signal);
        getRolesAndDefaultPermissions(signal);
        getPermissions(props.isClient, signal);

        return () => controller.abort();
    }, []);

    const createLeadershipRole = (formData) => {
        setIsDataFetchFinished(false);
        axios.post(`${API_URL}/chats/leadership/roles`, formData, headers)
            .then(res => {
                Toast.success("Leadership role has been created.");
                retrieveLeadershipRoles();
            })
            .catch(err => {
                let errorMessage = "Failed to create leadership role.";
                if (err.response !== undefined) {
                    errorMessage = err.response.data.error;
                }
                else if (err.message) {
                    errorMessage = err.message;
                }

                Toast.error(errorMessage);
            })
            .finally(() => {
                setLeadershipModal({
                    show: false,
                    isCreate: true,
                    formData: {}
                });
                setIsDataFetchFinished(true);
            });
    };

    const updateLeadershipRole = (formData) => {
        setIsDataFetchFinished(false);
        axios.put(`${API_URL}/chats/leadership/roles/${formData.uuid}`, formData, headers)
            .then(res => {
                Toast.success("Leadership role has been updated.");
                retrieveLeadershipRoles();
            })
            .catch(err => {
                let errorMessage = "Failed to update leadership role.";
                if (err.response !== undefined) {
                    errorMessage = err.response.data.error;
                }
                else if (err.message) {
                    errorMessage = err.message;
                }

                Toast.error(errorMessage);
            })
            .finally(() => {
                setLeadershipModal({
                    show: false,
                    isCreate: true,
                    formData: {}
                });
                setIsDataFetchFinished(true);
            });
    };

    const deleteLeadershipRole = (formData) => {
        setIsDataFetchFinished(false);
        axios.delete(`${API_URL}/chats/leadership/roles/${formData.uuid}`, headers)
            .then(res => {
                Toast.success("Leadership role has been deleted.");
                retrieveLeadershipRoles();
            })
            .catch(err => {
                let errorMessage = "Failed to delete leadership role.";
                if (err.response !== undefined) {
                    errorMessage = err.response.data.error;
                }
                else if (err.message) {
                    errorMessage = err.message;
                }

                Toast.error(errorMessage);
            })
            .finally(() => {
                setLeadershipModal({
                    show: false,
                    isCreate: true,
                    formData: {}
                });
                setIsDataFetchFinished(true);
            });
    };

    const removeLeadershipRolesWithBothFalseVisible = () => {
        /**
         * removing items to allow the API to soft delete 
         * the API will compare this formData's leadership roles against the employee/client's leadership roles
         * any existing leadership roles of the user that is missing from this form will be soft deleted
         */
        return selectedLeadershipRoles.filter(role => role.isVisibleToClients || role.isVisibleToEmployees);
    };

    const toggleLeadershipModal = (show, isCreate, role = {}) => {
        setLeadershipModal(prevState => ({
            ...prevState,
            show: show,
            isCreate: isCreate,
            formData: role
        }));
    };
    
    return (
        <React.Fragment>
            <Dialog open={showDialog} onClose={handleClose}
                fullWidth={false}
                maxWidth={false}
                scroll="body"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                PaperProps={{ // the dialog content is automatically wrapped in a 'Paper' class - see dev tools elements tab
                    style: {
                        borderRadius: 15,
                        maxWidth: "56.875rem",
                        width: "100%"
                    },
                }}
            >
                <Box className="user-permission-modal" sx={{ padding: "2.5625rem 1.875rem 2.5625rem 3.75rem", borderRadius: "1.5625rem" }}>
                    <div style={{ paddingRight: 30 }}>
                        <div className="heading">User Permission</div>
                        <div className="flex-row">
                            <div className="subheading" style={{ fontSize: "0.875rem", fontWeight: "400", marginRight: 75 }}>
                                User permissions are rules that control what actions a user can perform on<br /> your business app. They help protect your privacy and security by giving <br />you control over what information or feature a user can access.
                            </div>
                            <MoreAboutPermissions />
                        </div>
                        <hr />
                        <div className="item-row">
                            <div className="row-label">User Information</div>
                            <div style={{ display: "grid", gridTemplateColumns: "repeat(3, 1fr)", gridColumnGap: "1.1em" }}>
                                <div className="field">
                                    <label>Name</label>
                                    <FormField className="input-fields font-roboto" value={props.userName} onChange={() => ""} />
                                </div>
                                <div className="field">
                                    <label>Email</label>
                                    <FormField style={{ paddingRight: 20 }} className="input-fields font-roboto" value={props.userEmail} onChange={() => ""} />
                                </div>
                                <div className="field">
                                    <label>User Type</label>
                                    <FormField className="input-fields font-roboto"
                                        value={renderRoleInputText(getRoleInputStyles("name"))} 
                                        onChange={() => ""} 
                                        style={{
                                            color: getRoleInputStyles("color"),
                                            background: getRoleInputStyles("background"),
                                            border: `1px solid ${getRoleInputStyles("color")}`,
                                            fontWeight: 500
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    { !isDataFetchFinished ? <LottieLoadingIndicator /> : 
                        <RolesForm 
                            isClient={props.isClient}
                            roles={roles} 
                            userRole={props.userRole}
                            isUserRole={isUserRole} 
                            clickRole={clickRole} 
                            disableInput={disableInput} 
                            currentSelectedRole={currentSelectedRole}
                            permissions={permissions}
                            currentSelectedPermissions={currentSelectedPermissions}
                            setPermissionIsChecked={setPermissionIsChecked}
                            disableChatPermission={disableChatPermission}     
                            hasLeadershipRole={hasLeadershipRole}
                        />
                    }

                    <>
                        <hr style={{ margin: "1.875rem 30px 1.875rem 0" }}/>
                        <LeadershipContext.Provider
                            value={{
                                isDataFetchFinished: isDataFetchFinished,
                                toggleLeadershipModal: toggleLeadershipModal,
                                createLeadershipRole: createLeadershipRole,
                                updateLeadershipRole: updateLeadershipRole,
                                deleteLeadershipRole: deleteLeadershipRole,
                                leadershipRoles: leadershipRoles,
                                isFormCreate: leadershipModal.isCreate,
                                leadershipRoleToEdit: leadershipModal.formData,
                                selectedLeadershipRoles: selectedLeadershipRoles,
                                assignLeadershipRoles: assignLeadershipRoles
                            }}
                        >
                            <LeadershipRoles />
                            { leadershipModal.show &&
                                <LeadershipRoleModal
                                    showDialog={leadershipModal.show}
                                    handleClose={() => toggleLeadershipModal(false, false)}
                                />
                            }
                        </LeadershipContext.Provider>
                    </>
                    
                    { error !== null && <ErrorLabel>{ error }</ErrorLabel> }
                    <Box sx={{ textAlign: "right", margin: "1.25rem 1.875rem 0 0" }}>
                        <hr style={{ marginBottom: 30 }} />
                        <FormButtons color="#000"
                            border="1px solid #000"
                            marginRight="0.9375rem"
                            onClick={handleClose}
                            className="form-btns disabled"
                        >
                            Cancel
                        </FormButtons>
                        <FormButtons color="#FFF"
                            backgroundColor="#006CFF"
                            border="1px solid #006CFF"
                            type="submit"
                            onClick={handleFormSubmit}
                            className="form-btns disabled"
                        >
                            { isSavingPermissions || isSavingAssignedLeadership ? "Loading..." : "Save" }
                        </FormButtons>
                    </Box>
                </Box>
            </Dialog>
        </React.Fragment>
    );
}

export default UserRoleAndPermissionsForm;
