import React, { useContext } from "react";
import styled from "styled-components";
import { List, ListItem } from "@material-ui/core";
import { Text } from "src/components";
import ListAvatar from "src/scenes/Chat/components/ListAvatar";
import ListText from "src/scenes/Chat/components/children/ListText";
import { UserContext } from "src/scenes/App/UserContext";
import UnreadCount from "src/scenes/Chat/components/children/UnreadCount";
import { ChatListScrollableContainer } from "src/scenes/Chat/components/styled/chatListStyled";
import { ChatContext } from "src/scenes/Chat/ChatContext";
import { isUserOnline } from "src/utils/helpers";

export const StyledText = styled(Text)`
    font-weight: ${ p => p.weight || 400 };
    color: ${ p => p.color || "#000000" };
    text-align: left;
    font-size: ${ p => p.size };
    margin: ${ p => p.margin || "1px" };
    text-align: ${ p => p.align || "left"};
`;

const ListOfChats = (props) => {
    const {
        matrixClient,
        currentMatrixUserId,
        useChatMessage: ctxUseChatMessage,
        //TODO: usePresenceListener: ctxUsePresenceListener,
        useMatrixChatList: ctxUseMatrixChatList,
    } = useContext(ChatContext);

    const { openedMatrixChat, setOpenedMatrixChat } = ctxUseChatMessage;
    //TODO: const { onlineUsersMatrixId } = ctxUsePresenceListener;
    const { upsertRoomInMatrixChatList } = ctxUseMatrixChatList;
    const { searchedChatList, setLookingForLeadershipToChat } = props;
    
    const currentUser = useContext(UserContext);

    const renderClassName = (uuid) => {
        if (openedMatrixChat && openedMatrixChat.uuid === uuid) {
            return "active-chat";
        }
        return "inactive-chat cursor-pointer";
    };

    const runExternalCallbacks = (chat) => {
        setOpenedMatrixChat(chat);
        setLookingForLeadershipToChat(null);
    };

    const beforeOpeningChat = async (chat) => {
        runExternalCallbacks(chat);

        //run this silently
        if (chat.matrixRoomUnreadNotificationCount !== 0) {
            const matrixClient = chat.matrixClient;
            const room = chat.matrixClient.getRoom(chat.matrixRoomId);
            const lastEvent = room.getLastLiveEvent();
            await matrixClient.sendReadReceipt(lastEvent);
            upsertRoomInMatrixChatList(chat.matrixRoomId);
        }
    };

    const renderChatsList = (givenSearchedChatsList) => {
        return givenSearchedChatsList.map(chat => {
            const { isGroupChat, users, name } = chat;
            const { matrixRoomLoading, matrixRoomLatestEvent, matrixRoomRecentMessage, matrixRoomUnreadNotificationCount } = chat;

            const otherChatUser = users.find(user => user.matrixUserId !== currentMatrixUserId) ?? null;
            const isOtherUserOnline = otherChatUser ? isUserOnline(matrixClient, otherChatUser.matrixUserId) : false;

            return (
                <ListItem custom-attribute-key={chat.uuid} key={chat.uuid}
                    className={renderClassName(chat.uuid)}
                    style={{ boxSizing: "border-box", padding: "0.625rem", borderRadius: "0.625rem", display: "flex" }}
                    onClick={() => beforeOpeningChat(chat)}
                >
                    <ListAvatar
                        currentUser={currentUser}
                        users={users}
                        isGroupChat={isGroupChat}
                        currentMatrixUserId={currentMatrixUserId}
                        isOnline={ isOtherUserOnline }
                        showPresenceStatus={true} //TODO: use with `onlineUsersMatrixId` or use `{ isUserOnline } from "src/utils/helpers";`
                    />

                    <>
                        <ListText matrixRoomLoading={matrixRoomLoading} chatName={name} latestEvent={matrixRoomLatestEvent} recentMessage={matrixRoomRecentMessage} showTimestamp={matrixRoomUnreadNotificationCount === 0} />
                        <div>{ matrixRoomUnreadNotificationCount > 0 && <UnreadCount unreadCount={matrixRoomUnreadNotificationCount} />}</div>
                    </> 
                           
                </ListItem>
            );
        });
    };

    return (
        <React.Fragment>
            <>
                { searchedChatList.length > 0 
                    ?
                    <ChatListScrollableContainer>
                        <List>{ renderChatsList(searchedChatList) }</List>
                    </ChatListScrollableContainer>
                    :
                    <div className="flex-centered-content" style={{ height: "100%" }}>
                        <StyledText align="center" margin="1.25rem 0">You have no available chats.</StyledText>
                    </div>
                }
            </>
        </React.Fragment>
    );
};

export default ListOfChats;