import React from "react";
import styled from "styled-components";
import { ListItemAvatar } from "@material-ui/core";
import UserAvatar from "./UserAvatar";
import { generateNameInitials } from "src/utils/helpers";

const GroupChatAvatarsContainer = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-right: 0.4375rem;
`;

const GroupChatAvatar = styled.div`
    position: relative;
    border-radius: 50%;
    background: ${p => p.photo ? `url(${p.photo})` : p.isAUser ? "#D6D6D6" : "#FFFFFF"};
    background-size: cover;
    color: #000000;
    font-weight: 700;
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    box-shadow: 0px 3px 6px #00000029;
    border: ${p => p.isAUser ? "1px solid #FFF" : "1px solid #D6D6D6"};
    font-size: 0.625rem;
    width: 1.5625rem;
    height: 1.5625rem;
    margin: ${p => p.margin};
    object-fit: cover;
`;

const ListAvatar = (props) => {
    const { currentUser, users, isGroupChat, currentMatrixUserId, isOnline, presenceStatusBGColor } = props;

    const renderGroupChatAvatars = (activeUsers) => {
        let lastAvatar = { firstName: "", lastName: "", profilePictureUrl: "" };
        const activeUsersCount = activeUsers.length;
        /**
         * remove the current user, so it won't show as an avatar
         * and show the number of users in the chat (including the current user)
         * minus the 2 users displayed */
        if (activeUsersCount > 3) {
            const spliced = activeUsers.filter(user => user.matrixUserId !== currentMatrixUserId).slice(0, 3);
            activeUsers = spliced;
            lastAvatar.firstName = "+";
            lastAvatar.lastName = (activeUsersCount - 2).toString();
        }
        else if (activeUsersCount === 3) {
            lastAvatar.firstName = activeUsers[2].employeeDetails.firstName;
            lastAvatar.lastName = activeUsers[2].employeeDetails.lastName;
            lastAvatar.profilePictureUrl = activeUsers[2].employeeDetails.profilePictureUrl;
        }
        /** less than 3 users will show a blank to empty avatar */

        const generateAvatar = (user, isAUser) => (
            <GroupChatAvatar margin="0 2px 0 0" isAUser={isAUser}>
                { user.profilePictureUrl ?
                    <img src={user.profilePictureUrl} alt="profile"
                        style={{ borderRadius: "50%",
                            border: "1px solid #FFF",
                            width: "inherit", height: "inherit",
                            boxSizing: "border-box",
                            boxShadow: "0px 3px 6px #00000029",
                            objectFit: "cover"
                        }}
                    />
                    : generateNameInitials(
                        user.firstName, 
                        user.lastName
                    )
                }
            </GroupChatAvatar>
        );
        
        return (
            <React.Fragment>
                { generateAvatar(activeUsers[0].employeeDetails, true) }
                <div style={{ display: "flex" }}>
                    { generateAvatar(activeUsers[1].employeeDetails, true) }
                    { generateAvatar(lastAvatar, (activeUsersCount <= 3))}
                </div>
            </React.Fragment>
        );
    };

    const activeUsers = users.filter(user => user.currentMember);
    const otherChatUsers = activeUsers.filter(user => user.matrixUserId !== currentMatrixUserId);
    // empty array means that the logged-in user is the only one left in the chat
    const otherUser = otherChatUsers.length ? otherChatUsers[0].employeeDetails : currentUser;
    return (
        <ListItemAvatar>
            { !isGroupChat || activeUsers.length < 3 ?
                <UserAvatar
                    width="3.125rem"
                    height="3.125rem"
                    margin="0 0.6875rem 0 0"
                    hideInfo={true}
                    photo={otherUser.profilePictureUrl}
                    firstName={otherUser.firstName}
                    lastName={otherUser.lastName}
                    statusBorder="3px solid #fff"
                    withShadow={true}
                    borderPixel="1px"
                    isOnline={isOnline}
                    presenceStatusBGColor={presenceStatusBGColor}
                    showPresenceStatus={true}
                />
                :
                <GroupChatAvatarsContainer>
                    { renderGroupChatAvatars(activeUsers) }
                </GroupChatAvatarsContainer>
            }
        </ListItemAvatar>
    );
};

export default ListAvatar;