import { useEffect, useState } from "react";
import MatrixChat from "src/scenes/Chat/models/MatrixChat";
import { USER_TYPE_EMPLOYEES, USER_TYPE_CLIENTS } from "src/constants/chat";

export const useMatrixChatList = (chatList, selectedChatListTypeOrLeadershipUuid, matrixClient, populateActiveChatListDetails, removeRoomInActiveChatListDetails) => {

    const [matrixChats, setMatrixChats] = useState(null);
    const [matrixChatList, setMatrixChatList] = useState(null);
    const [activeChatList, setActiveChatList] = useState(null);

    const newMatrixChatFromApiData = (data) => {
        return new MatrixChat(data, matrixClient, setMatrixChats);
    };

    const addNewRoomToMatrixChats = (singleMatrixChat) => {
        singleMatrixChat.getMyRoomLiveDetails();
        setMatrixChats([singleMatrixChat]);
    };

    const insertChatCreateResponseToMatrixChats = (chatCreateResponse) => {
        const singleMatrixChat = newMatrixChatFromApiData(chatCreateResponse.chat);
        addNewRoomToMatrixChats(singleMatrixChat);
        
        return singleMatrixChat;
    };

    useEffect(() => {

        if (!matrixClient) {
            return;
        }

        /**
         * useEffect `chatList` -> convertChatResponseIntoMatrixChat() is used when initial loading of the chats, 
         * and updating the existing chatList from a MatrixEvent,
         * the `chatList` is set to change in useActiveChats::useEffect[activeChatListDetails] from running useGetChats::populateActiveChatListDetails()
         */
        const convertChatResponseIntoMatrixChat = async (existingChats) => {

            const newChats = []; 
            const _matrixChatList = matrixChatList ?? [];

            existingChats.map(data => {
                const newMatrixChat = newMatrixChatFromApiData(data);
                const indexToUpdate = _matrixChatList.findIndex((matrixChatInstance) => matrixChatInstance.matrixRoomId === newMatrixChat.matrixRoomId);
                newMatrixChat.getMyRoomLiveDetails();
                if (indexToUpdate === -1) {
                    newChats.unshift(newMatrixChat);
                }
            });

            setMatrixChats(newChats);
        };

        if (chatList) {
            const existingChats = chatList ?? [];
            convertChatResponseIntoMatrixChat(existingChats);
        }
    }, [chatList, matrixClient]);

    /**
     * For dev: setState calls within a loop are queued and executed 
     * as a single update at the end of the loop, 
     * resulting in useEffect only seeing the final state.
     */
    useEffect(() => {
        if (!matrixChats) {
            return;
        }

        updateMatrixChatListForThisRooms(matrixChats);
        setMatrixChats(null); //make it null after updating MatrixChatList
    }, [matrixChats]);

    
    useEffect(() => {
        const updateListOfChats = async () => {
            if (!selectedChatListTypeOrLeadershipUuid || !matrixChatList) {
                return;
            }

            const activeRooms = getRoomsForThisActiveChatList(matrixChatList);
            setActiveChatList(activeRooms ?? []);
        };

        updateListOfChats();
    }, [selectedChatListTypeOrLeadershipUuid, matrixChatList]);

    const updateMatrixChatListForThisRooms = (matrixChats) => {

        const matrixListCopy = matrixChatList ?? [];

        setMatrixChatList(prevMatrixChatList => {
            const _prevMatrixChatList = [...(prevMatrixChatList || [])];
            matrixChats.forEach((matrixChat, index) => {
                const indexToUpdate = _prevMatrixChatList.findIndex((matrixChatInstance) => matrixChatInstance.matrixRoomId === matrixChat.matrixRoomId);
                if (indexToUpdate !== -1) {
                    _prevMatrixChatList[indexToUpdate] = matrixChat;
                } else {
                    console.warn("Room not found, roomId not in the MatrixChatList.", matrixChat.matrixRoomId, matrixListCopy);
                    _prevMatrixChatList.unshift(matrixChat);
                }
            });

            return _prevMatrixChatList;
        });
    };

    const removeRoomInMatrixChatList = async (roomId) => {
        setMatrixChatList(prevMatrixChatList => {
            const indexToRemove = prevMatrixChatList.findIndex((matrixChatInstance) => matrixChatInstance.matrixRoomId === roomId);
            
            if (indexToRemove !== -1) {
                prevMatrixChatList.splice(indexToRemove, 1); // Remove element from found index
            }
            return prevMatrixChatList;
        });

        removeRoomInActiveChatListDetails(roomId); //from useActiveChat.js
    };

    const upsertRoomInMatrixChatList = async (roomId) => {
        const existingMatrixChat = matrixChatList.find((matrixChatInstance) => matrixChatInstance.matrixRoomId === roomId);

        if (!existingMatrixChat) {
            await populateActiveChatListDetails(true);
            return;
        }

        existingMatrixChat.getMyRoomLiveDetails();
    };

    const updateResponseDataInMatrixChatList = apiResponseData => {

        const listedMatrixChat = matrixChatList.find(matrixChat => matrixChat.matrixRoomId === apiResponseData.matrixRoomId);
        if (!listedMatrixChat) {
            return;
        }

        listedMatrixChat.updateFromApiResponseData(apiResponseData);
        updateMatrixChatListForThisRooms([listedMatrixChat]);
    };

    /**
     * after storing the MatrixChatList,
     * filter the MatrixChats based on Active Chats
     */
    const getRoomsForThisActiveChatList = (updatedMatrixChatList) => {
        const filteredMatrixChatList = [];
        updatedMatrixChatList.forEach(matrixChatInstance => {
            //leadershipRole
            if (matrixChatInstance.leadershipRoleUuid) {
                if (selectedChatListTypeOrLeadershipUuid === matrixChatInstance.leadershipRoleUuid) {
                    filteredMatrixChatList.push(matrixChatInstance);
                }
            } else {
                if (selectedChatListTypeOrLeadershipUuid === USER_TYPE_EMPLOYEES && !matrixChatInstance.isClient) {
                    filteredMatrixChatList.push(matrixChatInstance);
                } else if (selectedChatListTypeOrLeadershipUuid === USER_TYPE_CLIENTS && matrixChatInstance.isClient) {
                    filteredMatrixChatList.push(matrixChatInstance);
                }
            }
        });

        return filteredMatrixChatList;
    };
    
    const placedRoomToIndexZeroOfMatrixChatList = (roomId) => {
        
        setMatrixChatList(prevMatrixChatList => {
            const matrixUnshifter = [...prevMatrixChatList];
            const indexToMove = prevMatrixChatList.findIndex(matrixChatInstance => matrixChatInstance.matrixRoomId === roomId);
            
            if (indexToMove === -1) {
                console.warn(`roomId: ${roomId} not found in MatrixChatList when trying to unshift.`);
                return matrixUnshifter;
            }

            const matrixInstanceToMove = matrixUnshifter.splice(indexToMove, 1)[0]; // Remove element from found index
            matrixUnshifter.unshift(matrixInstanceToMove); // Add element to the beginning (index 0)
            return matrixUnshifter;
        });
    };

    return {
        matrixChatList,
        activeChatList,
        updateResponseDataInMatrixChatList,
        getRoomsForThisActiveChatList,
        removeRoomInMatrixChatList,
        upsertRoomInMatrixChatList,
        insertChatCreateResponseToMatrixChats,
        placedRoomToIndexZeroOfMatrixChatList
    };
};