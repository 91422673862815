import React, { useRef } from "react";
import { FormField, Button, ExplainParagraphLabel, FlexContainer, PageHeadingLabel, LottieLoadingIndicator, Toast } from "src/components";
import { Grid, Divider } from "@material-ui/core";
import styled, { css } from "styled-components";
import CheckIcon from "src/img/check@2x.png";
import { USER_GROUP, API_ERROR_FIELD } from "src/constants/donations";
import Dropzone from "react-dropzone";

const ColumnHeader = styled.h1`
    color: #612684;
    font-weight: 700;
    font-size: 1.563rem;
    margin: 0 0 1.25rem;
`;

const DonationTypeOption = styled.button`
    position: relative;
    width: 9.6rem;
    height: 2.9rem;
    border: 1px solid #e9ebef;
    border-radius: 12px;
    text-align: center;
    font-size: 0.875rem;
    font-family: Roboto, Helvetica, sans-serif;
    font-weight: 700;
    background-color: #fff;
    cursor: pointer;

    ${(props) =>
        props.active &&
        css`
            background-color: #000;
            color: #fff;
            font-weight: 500;`}
`;

const FormButtonsContainer = styled.div`
    display: flex;
    justify-content: flex-end;
    padding: 1.5rem 3rem;
    gap: 1.25rem;
`;

const InputLabel = styled.label`
    font-family: Roboto, Hevetica, sans-serif;
    font-weight: 700;
    font-size: 1rem;
    color: #000;
`;

const InputDescription = styled.p`
    font-family: Roboto, Hevetica, sans-serif;
    font-weight: ${p => p.fontWeight || "normal"};
    font-size: ${p => p.size || "0.875rem"};
    color: ${p => p.color || "#8291b2"};
    margin: ${p => p.margin || "7px 0 10px"};
    text-align: ${p => p.align || "left"};
`;

const TextInput = styled(FormField)`
    font-family: Roboto, Helvetica, sans-serif;
    height: 3.125rem;
    border: 1px solid #e9ebef;
    border-radius: 12px;
    text-align: left;
    font-weight: 400;
    font-size: 0.938rem;
    color: #000;
    margin: 0;

    ::placeholder {
        text-align: left;
        color: #808080;
        font-family: Roboto, Helvetica, sans-serif;
        font-size: 0.938rem;
    }
`;

const TextArea = styled.textarea`
    font-family: Roboto, Helvetica, sans-serif;
    color: #000;
    font-size: 0.938rem;
    font-weight: 400;
    border: 1px solid #e9ebef;
    border-radius: 12px;
    padding: 10px 20px;
    resize: none;
    height: 8.5rem;
    width: 90%;

    ::placeholder {
        text-align: left;
        color: #808080;
        font-family: Roboto, Helvetica, sans-serif;
        font-size: 0.938rem;
    }

    &:focus {
        border: 1px solid #000;
        outline: none;
    }
`;

const ErrorMessage = styled.div`
    font-size: 11.5px;
    color: #ff7171;
    font-weight: 500;
    line-height: 12.5px;
    margin: 4px 0 0 4px;
`;

const StyledDropzone = styled(Dropzone)`
    height: 12.938rem;
    border-radius: 15px;
    border: 1px solid #e9ebef;
    margin: 0.313rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    overflow: hidden; /* Ensure image doesn't overflow the container */
`;

const ImageContainer = styled.div`
    height: 14.688rem;
    width: 100%;
    
    
    margin: ${p => p.margin || "0"};
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
`;

const ImagePreview = styled.img`
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
    object-fit: contain; /* Image will fit inside container without stretching */
`;

const FileInput = styled.input`
    display: none;
`;

const FormPage = ({
    handleFormVisible,
    formData,
    updatingUuid,
    isFormSubmitting,
    formErrors,
    handleInputChange,
    handleChangeUserGroup,
    handleFormSubmit,
    setSelectedImage,
    selectedImage
}) => {

    const GreenCheck = ({ active }) => active && <img src={CheckIcon} style={{ height: "24px", position: "absolute", top: "10px", right: "6px" }} />;

    const forEmployeesUserGroup = (formData) => formData.userGroupType === USER_GROUP.EMPLOYEES;
    const forClientsUserGroup = (formData) => formData.userGroupType === USER_GROUP.CLIENTS;
    const forEmployeesAndClientsUserGroup = (formData) => formData.userGroupType === USER_GROUP.BOTH;
    const getErrorMessage = input => {
        const error = formErrors.find(error => error.input === input);
        return error ? `* ${error.message}` : null;
    };

    const dropzoneRef = useRef();
    const handleBrowseClick = () => {
        if (dropzoneRef.current) {
            dropzoneRef.current.open();
        }
    };

    const handleDrop = acceptedFiles => {
        const validImageTypes = ["image/jpeg", "image/png"];
       
        if (acceptedFiles && acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                if (validImageTypes.includes(file.type)) {
                    setSelectedImage(prev => {
                        return { 
                            ...prev, 
                            readerResult: reader.result,
                            hasBeenUploaded: false,
                            s3Url: null
                        };
                    });
                } else {
                    Toast.error("Unsupported file type. Images may be JPEG or PNG format.");
                    return;
                }
            };
        }
    };

    return (
        <>
            <PageHeadingLabel>
                { updatingUuid ? "Edit Donation Organisation" : "Create New Donation Organisation" }
            </PageHeadingLabel>

            <ExplainParagraphLabel size="0.875rem" width="100%" maxWidth="44rem">
                Add a donation opportunity by uploading an image, providing details about the cause, and linking to the 
                donation page. Engage users in supporting important causes through your business.
            </ExplainParagraphLabel>

            <FlexContainer
                color="#fff"
                style={{
                    maxWidth: "84.688rem",
                    border: "1px solid #8291b2",
                    borderRadius: "7px",
                    marginTop: "1.4rem"
                }}
            >
                <Grid container style={{ padding: "2.5rem 3rem 3.5rem" }}>
                    <Grid item md={8} lg={6}>
                        <ColumnHeader>
                            Donation Information
                        </ColumnHeader>

                        <Grid container direction="column" spacing={2}>

                            <Grid item md={10}>
                                <InputLabel>
                                    Select Donation User Group
                                </InputLabel>
                                <InputDescription>
                                    Select to display this donation to employees, clients or both user groups.
                                </InputDescription>
                                <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={1}>
                                    <Grid item>
                                        <DonationTypeOption active={forEmployeesUserGroup(formData)} onClick={() => handleChangeUserGroup(USER_GROUP.EMPLOYEES)}>
                                            All Employees
                                            <GreenCheck active={forEmployeesUserGroup(formData)} />
                                        </DonationTypeOption>
                                    </Grid>
                                    <Grid item>
                                        <DonationTypeOption active={forClientsUserGroup(formData)} onClick={() => handleChangeUserGroup(USER_GROUP.CLIENTS)}>
                                            All Clients
                                            <GreenCheck active={forClientsUserGroup(formData)} />
                                        </DonationTypeOption>
                                    </Grid>
                                    <Grid item>
                                        <DonationTypeOption active={forEmployeesAndClientsUserGroup(formData)} onClick={() => handleChangeUserGroup(USER_GROUP.BOTH)}>
                                            Both
                                            <GreenCheck active={forEmployeesAndClientsUserGroup(formData)} />
                                        </DonationTypeOption>
                                    </Grid>
                                </Grid>
                                <ErrorMessage>{ getErrorMessage(API_ERROR_FIELD.USER_GROUP_TYPE) ?? "" }</ErrorMessage>
                            </Grid>
                            <Grid item md={10} lg={8}>
                                <InputLabel>
                                    Name Of Organisation
                                </InputLabel>
                                <InputDescription>
                                    Enter the name of the donation organisation.
                                </InputDescription>
                                <TextInput
                                    name="organisationName"
                                    value={formData.organisationName ?? ""}
                                    placeholder="e.g., “Red Cross“"
                                    onChange={handleInputChange}
                                    disabled={isFormSubmitting}
                                />
                                <ErrorMessage>{ getErrorMessage(API_ERROR_FIELD.ORGANISATION_NAME) ?? "" }</ErrorMessage>
                            </Grid>

                            <Grid item md={10} lg={8}>
                                <InputLabel>
                                    Headline
                                </InputLabel>
                                <InputDescription>
                                    Enter a clear and engaging headline for your donation.
                                </InputDescription>
                                <TextInput
                                    name="headline"
                                    value={formData.headline ?? ""}
                                    placeholder="e.g., “Support the Red Cross: Help Those in Need”"
                                    onChange={handleInputChange}
                                    disabled={isFormSubmitting}
                                />
                                <ErrorMessage>{ getErrorMessage(API_ERROR_FIELD.HEADLINE) ?? "" }</ErrorMessage>
                            </Grid>

                            <Grid item md={10} lg={8}>
                                <InputLabel>
                                    Donation Description
                                </InputLabel>
                                <InputDescription>
                                    Provide a brief description of the donation, outlining the benefits and details for users.
                                </InputDescription>
                                <TextArea
                                    name="description"
                                    value={formData.description ?? ""}
                                    placeholder="e.g., “Join us in supporting the Red Cross, an organisation dedicated to providing emergency assistance, disaster relief, and education. Your contribution can make a difference in the lives of those affected by crises.”"
                                    onChange={handleInputChange}
                                    disabled={isFormSubmitting}
                                />
                                <ErrorMessage>{ getErrorMessage(API_ERROR_FIELD.DESCRIPTION) ?? "" }</ErrorMessage>
                            </Grid>

                            <Grid item md={10} lg={8}>
                                <InputLabel>
                                    Donation Website Page
                                </InputLabel>
                                <InputDescription>
                                    Enter the website URL for the organisation&apos;s donation page.
                                </InputDescription>
                                <TextInput
                                    name="donationPageUrl"
                                    value={formData.donationPageUrl ?? ""}
                                    placeholder="e.g., www.companyname.com"
                                    onChange={handleInputChange}
                                    disabled={isFormSubmitting}
                                />
                                <ErrorMessage>{ getErrorMessage(API_ERROR_FIELD.PAGE_URL) ?? "" }</ErrorMessage>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item md={4} lg={6}>
                        <Grid container spacing={3}>
                            <Grid item md={12} lg={8}>
                                <ColumnHeader>
                                    Upload Donation Image
                                </ColumnHeader>

                                <InputLabel>
                                    Upload Image
                                </InputLabel>
                                <InputDescription color="#612684">
                                    Upload the organisations logo or an image representing the donation.
                                </InputDescription>
                                
                                <StyledDropzone ref={dropzoneRef} onDrop={handleDrop}>
                                    <ImageContainer>
                                        {
                                            selectedImage.readerResult || formData.imageUrl ? (
                                                <ImagePreview src={selectedImage.readerResult || formData.imageUrl} alt="Donation Image" />
                                            ) : (
                                                <>
                                                    <UploadButtonInfo handleBrowseClick={() => {/** leave this blank as Dropzone already have event */}}/>
                                                </>
                                            )
                                        }
                               
                                        <FileInput type="file" accept="image/*" onChange={handleDrop} />
                                    </ImageContainer>
                                </StyledDropzone>    
                                { (selectedImage.readerResult || formData.imageUrl) && <UploadButtonInfo handleBrowseClick={handleBrowseClick} /> }
                                <ErrorMessage style={{ textAlign: "center" }}>{ getErrorMessage(API_ERROR_FIELD.IMAGE) ?? "" }</ErrorMessage>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Divider />

                <FormButtonsContainer>
                    <Button
                        width="8.75rem"
                        height="2.375rem"
                        size="0.875rem"
                        backgroundColor="#fff"
                        color="#000"
                        border="1px solid #000"
                        style={{ borderRadius: "7px", boxShadow: "none", lineHeight: "1rem" }}
                        onClick={handleFormVisible}
                    >
                        Cancel
                    </Button>
                    <SaveButton isFormSubmitting={isFormSubmitting} handleFormSubmit={handleFormSubmit} />
                    
                </FormButtonsContainer>
            </FlexContainer>
        </>
    );
};

const SaveButton = ({ handleFormSubmit, isFormSubmitting }) => {
    return (
        isFormSubmitting ? 
            <div>
                <LottieLoadingIndicator height="40px" width="40px" containerHeight="40px" margin="0px" />
            </div> 
            :
            <Button
                width="8.75rem"
                height="2.375rem"
                size="0.875rem"
                backgroundColor="#006cff"
                color="#fff"
                border="1px solid #006cff"
                style={{ borderRadius: "7px", boxShadow: "none", lineHeight: "1rem" }}
                onClick={handleFormSubmit}
                disabled={isFormSubmitting}
            >
            Save
            </Button>
    );
};

const UploadButtonInfo = ({ handleBrowseClick }) => {
    return (
        <>
            <div style={{ textAlign: "center" }}>
                <Button
                    backgroundColor="#000"
                    color="#fff"
                    border="1px solid #000"
                    size="0.813rem"
                    style={{ borderRadius: "7px", boxShadow: "none" }}
                    onClick={handleBrowseClick}
                >
                    Browse File
                </Button>
            </div>
            <InputDescription color="#808080" size="0.938rem" margin="15px 0 0" align="center">
                Supported formats: JPG, PNG. Max size: 5MB. <br />
                Aspect Ratio 16:9 - 1920px x 1080px
            </InputDescription>
        </>
    );
};
export default FormPage;