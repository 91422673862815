import React, { useState } from "react";
import { withRouter } from "react-router-dom";
import { PageContainer, FlexContainer, PageHeadingLabel, ExplainParagraphLabel, LottieLoadingIndicator } from "src/components";
import { HeaderTwoColumns, DisclaimerContainer } from "./components/StyledComponents";
import useGetDigitalMemberCard from "./hooks/useGetDigitalMemberCard";
import useGetHeroImages from "src/scenes/DigitalMemberCard/hooks/useGetHeroImages";
import DigitalCompanyCardContext from "./DigitalCompanyCardContext";
import DisplayCompanyCards from "./components/DisplayCompanyCards";
import CustomiseUserTypeCard from "./components/CustomiseUserTypeCard";
import { DIGITAL_MEMBER_CARD_PAGES } from "./utils/enum";

const DigitalMemberCard = ({ user }) => {
    const [renderPage, setRenderPage] = useState(DIGITAL_MEMBER_CARD_PAGES.DISPLAY_COMPANY_CARD);
    const { digitalMembershipCards, getCardTemplateByUserType, isFetchingDigitalMemberCard, employeeCardInfo, clientCardInfo, fetchDigitalMemberCard } = useGetDigitalMemberCard(user.token);
    const { 
        businessHeroImages, 
        setBusinessHeroImages,
        meHeroImages,
        isFetchingHeroImages,
        fetchHeroImages
    } = useGetHeroImages(user.token);
    
    const PageHeader = () => (
        <>
            <HeaderTwoColumns className="row" style={{ alignItems: "flex-start" }}>
                <div>
                    <PageHeadingLabel>
                        Digital Membership Cards
                    </PageHeadingLabel>
                    
                    <ExplainParagraphLabel size="0.875rem" width="100%" maxWidth="44rem">
                        Create unique digital member cards for your employees and clients, displaying your company 
                        logo, background colour, and their personal details. These cards serve as digital IDs, perfect for 
                        event access or offering discounts, and can be added to Apple or Google Wallets for easy access.
                    </ExplainParagraphLabel>
                </div>
                <DisclaimerContainer>
                    <div className="container">
                        <div className="title">Member Card Preview Disclaimer</div>
                        <div className="desc">
                            The information displayed on employee and client card previews 
                            is for example purposes only. You can test adding your contact 
                            using the QR code on either the employee or client card preview.
                        </div>
                    </div>
                </DisclaimerContainer>
            </HeaderTwoColumns>
        </>
    );

    const PageBodyContainer = ({ children, isFetchingDigitalMemberCard }) => {
        return (
            <PageContainer>
                <PageHeader />

                <FlexContainer
                    justifyContent={ isFetchingDigitalMemberCard && "center" }
                    alignItems="center"
                    color="#fff"
                    style={{
                        overflowX: "auto",
                        maxWidth: "94.5rem",
                        minHeight: "44.188rem",
                        border: "1px solid #8291B2",
                        borderRadius: "7px"
                    }}
                >
                    { isFetchingDigitalMemberCard && <LottieLoadingIndicator /> }
                    { !isFetchingDigitalMemberCard && children }
                </FlexContainer>
            </PageContainer>
        );
    };

    return (
        <PageBodyContainer isFetchingDigitalMemberCard={isFetchingDigitalMemberCard}>
            <DigitalCompanyCardContext.Provider value={{ 
                user: user,
                renderDisplayCards: () => setRenderPage(DIGITAL_MEMBER_CARD_PAGES.DISPLAY_COMPANY_CARD),
                renderCustomiseEmployee: () => setRenderPage(DIGITAL_MEMBER_CARD_PAGES.CUSTOMISE_EMPLOYEE_CARD),
                renderCustomiseClient: () => setRenderPage(DIGITAL_MEMBER_CARD_PAGES.CUSTOMISE_CLIENT_CARD),
                getCardTemplateByUserType: getCardTemplateByUserType,
                employeeCardInfo: employeeCardInfo,
                clientCardInfo: clientCardInfo,
                digitalMembershipCards: digitalMembershipCards,
                fetchDigitalMemberCard: fetchDigitalMemberCard,
                useGetHeroImages: {
                    businessHeroImages, 
                    setBusinessHeroImages,
                    meHeroImages,
                    isFetchingHeroImages,
                    fetchHeroImages
                }
            }}>
                { renderPage === DIGITAL_MEMBER_CARD_PAGES.DISPLAY_COMPANY_CARD && <DisplayCompanyCards /> }
                { renderPage === DIGITAL_MEMBER_CARD_PAGES.CUSTOMISE_EMPLOYEE_CARD && <CustomiseUserTypeCard isClient={false} /> }
                { renderPage === DIGITAL_MEMBER_CARD_PAGES.CUSTOMISE_CLIENT_CARD && <CustomiseUserTypeCard isClient={true} /> }
            </DigitalCompanyCardContext.Provider>
        </PageBodyContainer>
    );
};

export default withRouter(DigitalMemberCard);