import React, { Component } from "react";
import { API_URL } from "src/scenes/App";
import axios from "axios";
import styled from "styled-components";
import {
    LottieLoadingIndicator, ErrorText, Text, Form, Button, FlexContainer, ExplainParagraphLabel,
    BankCountry, AccountHolderName, BankName, AccountNumber, SwiftCode, BsbOrIban,
    InvoiceOrStatementEmail, StreetAddress, StreetAddress2nd, Toast,
    City, State, AddressCountry, PostcodeOrZip, ErrorList, ContainerGrid 
} from "src/components";

import { AUSTRALIA } from "src/constants/subscriptions";

const ActionButtonContainer = styled.div`
    margin: 1rem 0px;
`;

const GridContainer = styled.div`
    display: grid;
    grid-template-columns: 40% 40% 20%;
    column-gap: 5%;
`;

const ActionButton = styled(Button)`
    color: ${ p => (p.color) || "#000" };
    background-color: ${ p => (p.backgroundColor) || "#fff" };
    margin: 5px 5px;
    font-size:14px;
    padding: ${ p => (p.padding) || "9px 45px 9px !important" };
    border: ${ p => (p.border) || "2px solid #000" };
    border-radius: 10px !important;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
`;

const RenewalAndEarningsContainer = styled.div`
    display: flex;
    flex-direction: column;
    max-width: 26.8125rem;
    width: 100%;
    font-size: 0.875rem;
    font-weight: 500;
    color: #8551AE;
    margin: 1rem 0 2rem 0;
`;

const SubHeadingLabel = styled.div`
    font-family: Roboto, Helvetica, sans-serif;
    font-weight: ${ p => p.weight || "normal" };
    font-size: ${ p => p.size || "1em" };
    color: ${ p => p.color || "black" };
    text-align: ${ p => p.align || "start" };
    padding: ${ p => p.padding || "0" };
    margin: ${ p => p.margin || "0" };
    width: ${ p => p.width };
    height: ${ p => p.height };
    flex-grow: ${ p => p.flexGrow };
    max-width: ${ p => p.maxWidth };
`;

class ClientLicenseBankDetailsForm extends Component {
    state = {
        bankAccountDetails: {
            country: "",
            accountHolderName: "",
            bankName: "",
            accountNumber: "",
            swiftCode: "",
            bsbOrIban: "",
            invoiceOrStatementEmail: "",
            streetAddress: "",
            streetAddress2: "",
            city: "",
            state: "",
            addressCountry: "",
            postcodeOrZip: "",
        },
        message: "",
        inputErrors: [],
        loadingSubmit: false,
        isLoading: false,
        isSwiftCodeDisabled: null,
        fetching: 0,
    };

    componentDidMount() {
        this.setState({
            bankAccountDetails: this.props.bankAccountDetails,
            isSwiftCodeDisabled: this.props.bankAccountDetails.country === AUSTRALIA,
        });
    }

    getErrors() {
        let bankAccountDetails = this.state.bankAccountDetails;
        let errors = [];

        if (bankAccountDetails.country === "" || bankAccountDetails.country === null) {
            errors.push(ErrorList.EMPTY_COUNTRY);
        }

        if (bankAccountDetails.accountHolderName === "" || bankAccountDetails.accountHolderName === null) {
            errors.push(ErrorList.EMPTY_ACCOUNT_HOLDER_NAME);
        }

        if (bankAccountDetails.bankName === "" || bankAccountDetails.bankName === null) {
            errors.push(ErrorList.EMPTY_BANK_NAME);
        }

        if (bankAccountDetails.accountNumber === "" || bankAccountDetails.accountNumber === null) {
            errors.push(ErrorList.EMPTY_ACCOUNT_NUMBER);
        } else if (bankAccountDetails.country === AUSTRALIA && !(bankAccountDetails.accountNumber.length >= 7 && bankAccountDetails.accountNumber.length <= 12)) {
            errors.push(ErrorList.AUS_INVALID_ACCOUNT_NUMBER);
        } 

        if (bankAccountDetails.country !== AUSTRALIA) {
            if (bankAccountDetails.swiftCode === "" || bankAccountDetails.swiftCode === null) {
                errors.push(ErrorList.EMPTY_SWIFT_CODE);
            }
        }

        if (bankAccountDetails.bsbOrIban === "" || bankAccountDetails.bsbOrIban === null) {
            errors.push(ErrorList.EMPTY_BSB_OR_IBAN);
        } else if (bankAccountDetails.country === AUSTRALIA && bankAccountDetails.bsbOrIban.length !== 6) {
            errors.push(ErrorList.AUS_BSB_LENGTH);
        }
      
        if (bankAccountDetails.invoiceOrStatementEmail === "" || bankAccountDetails.invoiceOrStatementEmail === null) {
            errors.push(ErrorList.EMPTY_INVOICE_OR_STATEMENT_EMAIL);
        }
        else {
            /* regex to validate email */
            if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(bankAccountDetails.invoiceOrStatementEmail) === false) {
                errors.push(ErrorList.EMAIL_INVALID);
            }
        }

        /** ADDRESS */
        if (bankAccountDetails.streetAddress === "" || bankAccountDetails.streetAddress === null) {
            errors.push(ErrorList.EMPTY_STREET_ADDRESS);
        }

        if (bankAccountDetails.city === "" || bankAccountDetails.city === null) {
            errors.push(ErrorList.EMPTY_CITY);
        }

        if (bankAccountDetails.state === "" || bankAccountDetails.state === null) {
            errors.push(ErrorList.EMPTY_STATE);
        }

        if (bankAccountDetails.addressCountry === "" || bankAccountDetails.addressCountry === null) {
            errors.push(ErrorList.EMPTY_ADDRESS_COUNTRY);
        }

        if (bankAccountDetails.postcodeOrZip === "" || bankAccountDetails.postcodeOrZip === null) {
            errors.push(ErrorList.EMPTY_POST_CODE_OR_ZIP);
        }

        return errors;
    }

    submitEarningBankAccountDetailsForm = () => {
        this.setState({
            loadingSubmit: true,
            inputErrors: [],
            responseErrors: "",
        });

        const bankAccountDetailsData = this.state.bankAccountDetails;

        let errors = this.getErrors();

        if (errors.length > 0) {
            this.setState({
                loadingSubmit: false,
                inputErrors: errors,
                responseErrors: "Please check the fields again for any errors. The form cannot be submitted until all fields are valid.",
            });
        }
        else {
            this.postClient(bankAccountDetailsData);
        }
    };

    postClient = (bankAccountDetailsData) => {
        const { setIsShowGetStarted } = this.props;

        axios.post(`${API_URL}/company/upsertBankAccountDetails`, {
            countryCode: bankAccountDetailsData.country,
            accountHolderName: bankAccountDetailsData.accountHolderName,
            bankName: bankAccountDetailsData.bankName,
            accountNumber: bankAccountDetailsData.accountNumber,
            swiftCode: bankAccountDetailsData.swiftCode,
            bsbOrIban: bankAccountDetailsData.bsbOrIban,
            email: bankAccountDetailsData.invoiceOrStatementEmail,

            street: bankAccountDetailsData.streetAddress,
            street2: bankAccountDetailsData.streetAddress2,
            city: bankAccountDetailsData.city,
            state: bankAccountDetailsData.state,
            countryCodeAddress: bankAccountDetailsData.addressCountry,
            postcodeOrZip: bankAccountDetailsData.postcodeOrZip,
        },
        {
            headers: {
                Authorization: "Bearer " + this.props.token
            }
        }).then(res => {
            Toast.info("Your bank account details have been updated.");
            setIsShowGetStarted(false);
        }).catch(message => {
            this.setState({
                responseErrors: message,
                loadingSubmit: false,
            });
        });
    };

    cancel = () => {
        this.props.setIsShowGetStarted(false); 
    };

    inputBankAccountDetailsValue = (event, propertyName) => {
        const propertyNameValue = event.target.value;
        
        this.setState(prevState => ({
            bankAccountDetails: { // object that we want to update
                ...prevState.bankAccountDetails, // keep all other key-value pairs
                [propertyName]: propertyNameValue // update the value of specific key
            }
        }));

        //Disable Swift Code Field when Bank Account Info.Country is AU
        if (propertyName === "country") {
            this.setState(prevState => ({
                bankAccountDetails: {         
                    ...prevState.bankAccountDetails,   
                    "swiftCode": propertyNameValue === AUSTRALIA ? "" : prevState.bankAccountDetails.swiftCode 
                },
                isSwiftCodeDisabled: propertyNameValue === AUSTRALIA
            }));
        }
    };

    render() {
        const { inputErrors, responseErrors, bankAccountDetails, loadingSubmit, isLoading, isSwiftCodeDisabled } = this.state;

        if (isLoading) {
            return (
                <FlexContainer height="60vh" flexGrow="1" alignItems="center" justifyContent="center">
                    <LottieLoadingIndicator />
                </FlexContainer>
            );
        }
        else {
            return (
                <>
                    <div style={{ marginBottom: "1rem" }}>
                        <SubHeadingLabel weight="700" size="1.5625rem" color="#11141A" margin="2rem 0 0.5rem" flexGrow="1">Getting Started: Earnings Bank Account Details</SubHeadingLabel>
                        <ExplainParagraphLabel width="100%" size="0.875rem" maxWidth="38rem">
                            Please kindly provide the bank account information and invoice statement details below, 
                            to enable us to deposit your earnings to the designated account and email you statements.
                        </ExplainParagraphLabel>
                    </div>
                    
                    <Form width="100%" onSubmit={(e) => {e.preventDefault(); }} >
                        { 
                            responseErrors && <ErrorText margin="0">
                                { responseErrors}  
                            </ErrorText>
                        }

                        <GridContainer>
                            <div>
                                <Text size="1.25em" color="#612684" weight="700" align="left" margin="1">
                                    Bank Account Information
                                </Text>

                                <ContainerGrid>
                                    <BankCountry bankAccountDetails={bankAccountDetails} inputBankAccountDetailsValue={this.inputBankAccountDetailsValue} inputErrors={inputErrors} placeholderDisabled={true} />
                                    <AccountHolderName bankAccountDetails={bankAccountDetails} inputBankAccountDetailsValue={this.inputBankAccountDetailsValue} inputErrors={inputErrors} placeholderDisabled={true} />
                                    <BankName bankAccountDetails={bankAccountDetails} inputBankAccountDetailsValue={this.inputBankAccountDetailsValue} inputErrors={inputErrors} placeholderDisabled={true} />
                                    <AccountNumber bankAccountDetails={bankAccountDetails} inputBankAccountDetailsValue={this.inputBankAccountDetailsValue} inputErrors={inputErrors} placeholderDisabled={true} />
                                    <SwiftCode bankAccountDetails={bankAccountDetails} inputBankAccountDetailsValue={this.inputBankAccountDetailsValue} inputErrors={inputErrors} isSwiftCodeDisabled={isSwiftCodeDisabled} placeholderDisabled={true}/>
                                    <BsbOrIban bankAccountDetails={bankAccountDetails} inputBankAccountDetailsValue={this.inputBankAccountDetailsValue} inputErrors={inputErrors} placeholderDisabled={true} />
                                </ContainerGrid>
                                
                                <InvoiceOrStatementEmail bankAccountDetails={bankAccountDetails} inputBankAccountDetailsValue={this.inputBankAccountDetailsValue} inputErrors={inputErrors} placeholderDisabled={true} />
                                
                            </div>
                            <div>
                                <Text size="1.25em" color="#612684" weight="700" align="left" margin="1">
                                    Address
                                </Text>
                                <ContainerGrid>
                                    <StreetAddress bankAccountDetails={bankAccountDetails} inputBankAccountDetailsValue={this.inputBankAccountDetailsValue} inputErrors={inputErrors} placeholderDisabled={true} />
                                    <StreetAddress2nd bankAccountDetails={bankAccountDetails} inputBankAccountDetailsValue={this.inputBankAccountDetailsValue} inputErrors={inputErrors} placeholderDisabled={true} />
                                    <City bankAccountDetails={bankAccountDetails} inputBankAccountDetailsValue={this.inputBankAccountDetailsValue} inputErrors={inputErrors} placeholderDisabled={true} />
                                    <State bankAccountDetails={bankAccountDetails} inputBankAccountDetailsValue={this.inputBankAccountDetailsValue} inputErrors={inputErrors} placeholderDisabled={true} />
                                    <AddressCountry bankAccountDetails={bankAccountDetails} inputBankAccountDetailsValue={this.inputBankAccountDetailsValue} inputErrors={inputErrors} placeholderDisabled={true}/>
                                    <PostcodeOrZip bankAccountDetails={bankAccountDetails} inputBankAccountDetailsValue={this.inputBankAccountDetailsValue} inputErrors={inputErrors} placeholderDisabled={true} />
                                </ContainerGrid>

                                <RenewalAndEarningsContainer>
                                *You can update your bank account details at anytime by in Account Settings &gt; Renewals & Earnings.
                                </RenewalAndEarningsContainer>

                                <hr/>

                                <ActionButtonContainer>
                                    {
                                        loadingSubmit 
                                            ?
                                            <div style={{ width: "100%" }}>
                                                <LottieLoadingIndicator containerHeight="3em" height="3em" width="3em" />
                                            </div>
                                            :
                                            <div>
                                                <div style={{ textAlign: "right" }}>
                                                    <ActionButton style={{ marginLeft: "auto" }} backgroundColor="#FFFFFF" color="#000" onClick={this.cancel}>
                                                    Cancel
                                                    </ActionButton>
                                                    <ActionButton color="#FFF" backgroundColor="#000000 " onClick={this.submitEarningBankAccountDetailsForm}>
                                                    Save
                                                    </ActionButton>
                                                </div>
                                            </div>
                                    }
                                </ActionButtonContainer>
                            </div>
                        </GridContainer>

                    </Form>
                </>
            );
        }    
    }
}

export default ClientLicenseBankDetailsForm;
