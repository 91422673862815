import React from "react";
import { Checkbox, Text } from "src/components";
import Skeleton from "src/scenes/Statistics/components/Skeleton";
import styled from "styled-components";
import { SurveyStatusType } from "src/constants/survey";

const CheckboxContainer = styled.div`
    display: flex;
    align-items: center;
`;


const HiddenCheckbox = styled.input.attrs({ type: "checkbox" })`
    border: 0;
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
`;

const DisabledStyledCheckbox = styled.div`
    background-clip: content-box;
    background-color: ${props => (props.checked ? props.color || "#85b336" : "white")};
    border: ${p => p.border || "1px solid #2D3037"};
    box-sizing: border-box;
    height: ${p => p.height || "20px"};
    padding: ${p => p.padding || "5px"};
    border-radius: ${ p => p.radius || "0"};
    transition: all 150ms;
    width: ${p => p.width || "20px"};
    cursor: not-allowed;

    ${HiddenCheckbox}:focus + & {
    box-shadow: 0 0 0 3px pink;
    }
`;

export const SurveySubmissionAnonymousField = (props) => {
    const { setSurveyAnonymous, surveyIsAnonymous, surveyStatus, isEditSurveyForm, loading } = props;

    return (
        <>
            <Text align="left" size="16px" weight="700">
                Make Survey Submission Anonymous (Optional)
            </Text>
            <Text align="left" size="14px" color="#000000" maxWidth="540px" wordBreak="break-word">
            By checking this box, all survey responses will be collected anonymously,
            allowing users to share their thoughts freely without any personal identifiers.
            Once the survey becomes active, this setting cannot be changed,
            so make sure to decide before proceeding to promote candid and unbiased feedback.
            </Text>
            { loading ?
                <Skeleton animation="wave" width="32px" height="32px" />
                :
                <CheckboxContainer>
                    <label>
                        { surveyStatus !== SurveyStatusType.SCHEDULED && isEditSurveyForm &&
                                <DisabledStyledCheckbox
                                    border="1px solid #8291B2"
                                    color={surveyIsAnonymous ? "#8291B2" : "#36be24" }
                                    height="32px"
                                    width="32px"
                                    padding="7px"
                                    radius="10px"
                                    value={true}
                                    checked={surveyIsAnonymous}
                                />
                        }
                        {((surveyStatus === SurveyStatusType.SCHEDULED && isEditSurveyForm) || !isEditSurveyForm) &&
                            <Checkbox
                                color="#36be24"
                                height="39px"
                                width="39px"
                                padding="7px"
                                radius="10px"
                                value={true}
                                checked={surveyIsAnonymous}
                                onChange={ (e) => setSurveyAnonymous(e.target.checked) }
                                border="2px solid #000000"
                            />
                        }
                    </label>
                </CheckboxContainer>
            }
        </>
    );
};
