import React, { useContext, useState } from "react";
import { API_URL } from "src/scenes/App";
import axios from "axios";
import {
    FlexContainer, Toast, CardHeadingLabel, ExplainParagraphLabel
} from "src/components";
import { isValidPhoneNumber } from "react-phone-number-input";
import { 
    BusinessName, Abn, Website, FirstName, LastName, DateOfBirth, 
    Mobile, Country, ContainerGrid, EditButton, SaveButton, 
    ErrorList, ErrorStrings
} from "../components/MyBusinessFields";

import { SettingContext } from "../SettingContext";
import { UserContext } from "src/scenes/App/UserContext";
import { UserKeys } from "src/constants/userDetails";
import moment from "moment";
import styled from "styled-components";
import { desktop } from "src/components/Media";

const COUNTRY_ERROR_MESSAGE = "Unable to confirm your country. Please select the correct country or contact support@memotivationapp.com.";

const MyBusinessButton = styled(FlexContainer)`
    ${desktop`
        margin-top: -3.5em;
    `};
`;

function MyBusiness() {
    const userContext = useContext(UserContext);
    const { 
        isAccountOwner, 
        hasCompany, 
        countryCallingCode,
        website,
        setWebsite
    } = useContext(SettingContext);
    
    const birthDateFormatted = userContext[UserKeys.BIRTH_DATE] ? moment(userContext[UserKeys.BIRTH_DATE]).format("YYYY-MM-DD") : "";
    const defaultFormData = { // this is use when trying to `Cancel` changes from the Form.
        companyName: userContext[UserKeys.COMPANY_NAME], //optional if `hasCompany`,
        country: userContext[UserKeys.COUNTRY_CODE],
        abn: userContext[UserKeys.COMPANY_ABN], //optional based on country
        website: website, 
        firstName: userContext[UserKeys.FIRST_NAME],
        lastName: userContext[UserKeys.LAST_NAME],
        birthDate: birthDateFormatted,
        phoneNumber: userContext[UserKeys.PHONE_NUMBER] ? userContext[UserKeys.PHONE_NUMBER] : "", //optional
    };

    const [formData, setFormData] = useState(defaultFormData);
    const [isFormEditable, setIsFormEditable] = useState(false);
    const [inputErrors, setInputErrors] = useState([]);

    /**
     * checks in the api if selected country matches user's ip address
     * @param {string} country 
     * @param {string} token
     */
    const validateCountry = (country, token) => {
        return new Promise((resolve, reject) => {
            axios.post(`${API_URL}/company/validateIpAndGivenCountry`, { countryCode: country }, 
                { headers: { Authorization: "Bearer " + token } }  
            ).then(() => {
                resolve();
            })
                .catch((catchValidateCountry) => {
                    reject({ validateCountry: catchValidateCountry });
                });
        });
    };

    const axiosPutBusinessSettings = (emitEditableEventHandler) => {
        return new Promise((resolve, reject) => {
            axios.put(`${API_URL}/company/businessSettings`, formData, {
                headers: {
                    Authorization: "Bearer " + userContext.token
                }
            }).then(() => {
                emitEditableEventHandler(false);

                if (isAccountOwner) { 
                    const userState = {
                        [UserKeys.COMPANY_NAME]: formData.companyName,
                        [UserKeys.COMPANY_ABN]: formData.abn,

                        [UserKeys.FIRST_NAME]: formData.firstName,
                        [UserKeys.LAST_NAME]: formData.lastName,
                        [UserKeys.BIRTH_DATE]: formData.birthDate,
                        [UserKeys.PHONE_NUMBER]: formData.phoneNumber
                    };

                    //update the UserContext for the Left Navigation to be updated
                    userContext.appFns.updateUserContextProperties(userState);
                }
                
                Toast.info("My Business details have been updated.");
                resolve();
            }).catch(catchAxiosPutBusinessSettings => {
                reject({ axiosPutBusinessSettings: catchAxiosPutBusinessSettings });
            });
        });
    };

    const updateCompanyBusinessSettings = async (setFormLoading, emitEditableEventHandler) => {
        setFormLoading(true);

        try {
            await validateCountry(formData.country, userContext.token);
            await axiosPutBusinessSettings(emitEditableEventHandler);
            setWebsite(formData.website); //Note: website is from Setting.state not from UserContext
        } catch (error) {
            //from await validateCountry
            if (error.validateCountry && error.validateCountry.message === COUNTRY_ERROR_MESSAGE) {
                ErrorStrings[ErrorList.COUNTRY_INVALID] = COUNTRY_ERROR_MESSAGE;
                setInputErrors([ErrorList.COUNTRY_INVALID]);
            }

            //from await axiosPutBusinessSettings
            if (error.axiosPutBusinessSettings) {
                let errors = [];
                let dataErrorFields = error.axiosPutBusinessSettings;
                
                Object.keys(dataErrorFields).forEach(fieldKey => {
                    const responseMessage = "* " + dataErrorFields[fieldKey];
                    switch (fieldKey) {
                    case "companyNameInvalid":
                        ErrorStrings[ErrorList.BUSINESS_NAME_INVALID] = responseMessage;
                        errors.push(ErrorList.BUSINESS_NAME_INVALID);
                        return;

                    case "abnInvalid":
                        ErrorStrings[ErrorList.ABN_INVALID] = responseMessage;
                        errors.push(ErrorList.ABN_INVALID);
                        return;

                    case "websiteInvalid":
                        ErrorStrings[ErrorList.WEBSITE_INVALID] = responseMessage;
                        errors.push(ErrorList.WEBSITE_INVALID);
                        return;

                    case "firstNameInvalid":
                        ErrorStrings[ErrorList.FIRST_NAME_INVALID] = responseMessage;
                        errors.push(ErrorList.FIRST_NAME_INVALID);
                        return;

                    case "lastNameInvalid":
                        ErrorStrings[ErrorList.LAST_NAME_INVALID] = responseMessage;
                        errors.push(ErrorList.LAST_NAME_INVALID);
                        return;

                    case "birthDateInvalid":
                        ErrorStrings[ErrorList.DATE_OF_BIRTH_INVALID] = responseMessage;
                        errors.push(ErrorList.DATE_OF_BIRTH_INVALID);
                        return;

                    case "phoneNumberInvalid":
                        ErrorStrings[ErrorList.MOBILE_INVALID] = responseMessage;
                        errors.push(ErrorList.MOBILE_INVALID);
                        return;
                                
                    default:
                        //NO ERROR TO CATCH
                        return;
                    }
                });

                setInputErrors(errors);
            }
        } finally {
            setFormLoading(false);
        }
    };

    return (
        <FlexContainer maxWidth="55em">
            <div>
                <CardHeadingLabel>
                    My Business
                </CardHeadingLabel>
                <ExplainParagraphLabel style={{ fontSize: "14px" }}>
                    {!isFormEditable && "View and update your account details, profile and more."}
                </ExplainParagraphLabel>
            </div>
            <MyBusinessForm 
                setIsFormEditable={setIsFormEditable}
                defaultFormData={defaultFormData}
                formData={formData} 
                setFormData={setFormData}
                updateCompanyBusinessSettings={updateCompanyBusinessSettings} 
                inputErrors={inputErrors}
                setInputErrors={setInputErrors}
                hasCompany={hasCompany}
                countryCallingCode={countryCallingCode}
            />

        </FlexContainer>
        
    );
}

function MyBusinessForm({ defaultFormData, formData, setFormData, updateCompanyBusinessSettings, inputErrors, setInputErrors, hasCompany, setIsFormEditable, countryCallingCode }) {
    const userContext = useContext(UserContext);
    const [formLoading, setFormLoading] = useState(false);
    const [isEditable, setIsEditable] = useState(false);

    const cancelHandler = () => { //bring back onload data.
        setInputErrors([]);
        setFormData(defaultFormData);
        emitEditableEventHandler(false);
    };

    const emitEditableEventHandler = (bool) => {
        setIsEditable(bool);
        setIsFormEditable(bool);
    };

    // prevents console warns when one of the input values are null
    const filterInputValue = (value) => [null].includes(value) ? "" : value;

    const handleInputChange = (event, key) => {
        const filteredValue = filterInputValue(event.target.value);

        let updatedFormData = { ...formData, [key]: filteredValue };

        //empty abn field for non-AU countries
        if (key === "country" && filteredValue !== "AU") {
            updatedFormData = { ...updatedFormData, abn: "" };
        }

        setFormData(updatedFormData);
    };

    const handlePhoneNumber = (phone) => {
        if (phone !== "" && !phone.startsWith("+")) {
            phone = "+" + phone;
        }

        const updatedFormData = { ...formData, phoneNumber: phone };
        setFormData(updatedFormData);
    };

    const handleFormSubmit = () => {
        if (formLoading) {
            return;
        }

        setInputErrors([]);
        const errors = getErrors();

        if (errors.length > 0) {
            setInputErrors(errors);
            setFormLoading(false);
            return;
        }

        updateCompanyBusinessSettings(setFormLoading, emitEditableEventHandler);
    };

    const getErrors = () => {
        let errors = [];
        
        if (hasCompany()) {
            if (formData.companyName === "" || formData.companyName === null) {
                errors.push(ErrorList.EMPTY_BUSINESS_NAME);
            }

            if (formData.country === "" || formData.country === null) {
                errors.push(ErrorList.EMPTY_COUNTRY);
            }
        }

        if (formData.firstName === "" || formData.firstName === null) {
            errors.push(ErrorList.EMPTY_FIRST_NAME);
        }

        if (formData.lastName === "" || formData.lastName === null) {
            errors.push(ErrorList.EMPTY_LAST_NAME);
        }


        if (!validatePhoneNumber(formData.phoneNumber)) {
            errors.push(ErrorList.MOBILE_FORMAT);
        }

        return errors;
    };

    const validatePhoneNumber = (phoneNumber) => {
        if (!phoneNumber) {
            return true;
        } else {
            let phoneStr = (phoneNumber.toString()).replace(/\s+/g, "");
            phoneStr = (phoneStr[0] !== "+" && countryCallingCode !== "") ? `+${countryCallingCode}${phoneStr}` : phoneStr;
            return isValidPhoneNumber(phoneStr);
        }
    };

    return (
        <>
            <div>
                <MyBusinessButton direction="row" justifyContent="end">
                    {!isEditable && <EditButton onClick={ (e) => emitEditableEventHandler(true) }>Edit</EditButton>}

                    {isEditable && <EditButton style={{ marginRight: "5px" }} onClick={ (e) => cancelHandler(false) }>Cancel</EditButton>}
                    {isEditable && <SaveButton onClick={ (e) => handleFormSubmit() }>{formLoading ? "Saving..." : "Save"}</SaveButton>}
                </MyBusinessButton>
            </div>
            <ContainerGrid>

                { hasCompany() && 
                    <>
                        <BusinessName isEditable={!formLoading && isEditable} placeholderDisabled={!isEditable} formData={formData} onChangeFormData={handleInputChange} inputErrors={inputErrors}/>
                        <Country token={userContext.token} isEditable={!formLoading && isEditable} placeholderDisabled={!isEditable} formData={formData} onChangeFormData={handleInputChange} inputErrors={inputErrors}/>
                        <Website isEditable={!formLoading && isEditable} formData={formData} placeholderDisabled={!isEditable} onChangeFormData={handleInputChange} inputErrors={inputErrors} />
                        {formData.country === "AU" && <Abn isEditable={!formLoading && isEditable} placeholderDisabled={!isEditable} formData={formData} onChangeFormData={handleInputChange} inputErrors={inputErrors} />}
                    </>
                }
            </ContainerGrid>
            <ContainerGrid>   
                <FirstName isEditable={!formLoading && isEditable} placeholderDisabled={!isEditable} formData={formData} onChangeFormData={handleInputChange} inputErrors={inputErrors}/>
                <LastName isEditable={!formLoading && isEditable} placeholderDisabled={!isEditable} formData={formData} onChangeFormData={handleInputChange} inputErrors={inputErrors}/>
                <DateOfBirth isEditable={!formLoading && isEditable} placeholderDisabled={!isEditable} formData={formData} onChangeFormData={handleInputChange} inputErrors={inputErrors}/>
                <Mobile isEditable={!formLoading && isEditable} placeholderDisabled={!isEditable} formData={formData} handlePhoneNumber={handlePhoneNumber} defaultCountry={formData.country} inputErrors={inputErrors}/>
            </ContainerGrid>
        </>
        
    );
}

export default MyBusiness;