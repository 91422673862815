
import { Dispatch, SetStateAction } from "react"; // eslint-disable-line no-unused-vars
import { EventType, ClientEvent } from "matrix-js-sdk";

/**
 * @typedef {import('matrix-js-sdk').MatrixClient} MatrixClient
 * @param {any[]} data
 * @param {MatrixClient} matrixClient
 * @param {Dispatch<SetStateAction<{ id: string; name: string }[]>>} setMatrixChats
 */
class MatrixChat {
    constructor(data, matrixClient, setMatrixChats) {
        this.updateFromApiResponseData(data);
        this.matrixClient = matrixClient; // Store the matrixClient instance
        
        //will be populated when fetchMatrixRoom()
        this.matrixRoom = null;
        this.matrixRoomLatestEvent = null;
        this.matrixRoomLoading = true;
        this.matrixRoomUnreadNotificationCount = 0;
        this.matrixRoomRecentMessage = "";
        this.handleSetMatrixChat = () => {
            setMatrixChats([this]);
        };

        this.maxtrixRoomTimelineLoading = false;
        this.maxtrixRoomTimeline = [];
    }

    updateFromApiResponseData(data) {
        this.created_at = data.created_at;
        this.isClient = data.isClient;
        this.isGroupChat = data.isGroupChat;
        this.latestMessageAt = data.latestMessageAt || null; // Default to null if not present
        this.leadershipRoleUuid = data.leadershipRoleUuid || null;
        this.matrixRoomId = data.matrixRoomId;
        this.name = data.name;
        this.ownerUuid = data.ownerUuid;
        this.updated_at = data.updated_at;
        this.users = data.users;
        this.uuid = data.uuid;
    }

    getMyRoomLiveDetails = async () => {
        await this.getRoomWithTimeout(this.matrixClient, this.matrixRoomId);
        await this.fetchMatrixRoom(this.matrixClient);
        this.handleSetMatrixChat();
        return;
    };

    getRoomWithTimeout = (matrixClient, roomId, timeout = 500, retryDelay = 3000, maxRetries = 20) => {
        let retries = 0;
    
        return new Promise((resolve, reject) => {

            const checkRoom = async () => {
                console.log(`[MatrixChat] Room ${roomId} getRoom(), Retries: ${retries}`);
                const room = matrixClient.getRoom(roomId);
                if (room) {
                    resolve(room);
                    return;
                }
    
                if (retries < maxRetries) {
                    retries++;
                    console.log(`[MatrixChat] Room ${roomId} not found, retrying in ${retryDelay}ms (attempt ${retries}/${maxRetries}).`);
                    setTimeout(checkRoom, retryDelay);
                } else {
                    reject(new Error(`[MatrixChat] Room ${roomId} not found after ${maxRetries} retries.`));
                }
            };
            
            console.log(`[MatrixChat] Room ${roomId} is now syncing with MatrixClient`);
            setTimeout(checkRoom, timeout); // Initial check after timeout
        });
    };

    /**
     * @typedef {import('matrix-js-sdk').MatrixClient} MatrixClient
     * @param {MatrixClient} matrixClient
     * @param activeChatLists Array
     */
    async fetchMatrixRoom(matrixClient) {
        this.matrixRoomLoading = true;
        try {
            this.matrixRoom = matrixClient.getRoom(this.matrixRoomId);

            if (this.matrixRoom) {
                const latestMatrixEvent = this.matrixRoom.getLastLiveEvent();
                await this.matrixClient.decryptEventIfNeeded(latestMatrixEvent);
                this.matrixRoomLatestEvent = latestMatrixEvent;
                if (!latestMatrixEvent) {
                    console.warn("[MatrixChat] No latest event found in this room.", this.matrixRoom);
                }
               
                this.matrixRoomUnreadNotificationCount = this.matrixRoom.getUnreadNotificationCount("total"); 
                this.matrixRoomRecentMessage = this.getRecentMessageEventInTheRoom(this.matrixRoom);
                this.matrixRoomLoading = false;
            }
            //this.matrixRoom = await matrixClient.getRoomSummary(this.matrixRoomId);
        } catch (error) {
            console.error("[MatrixChat] Error getting matrix room:", this.matrixRoom, error);
            // Handle error appropriately, e.g., set an error flag on this
        } finally {
            console.info(`[MatrixChat] ${this.matrixRoomId} is done running fetchMatrixRoom`, this);
        }
    }

    getRecentMessageEventInTheRoom = (room) => {
        try {
            // Get or create a filtered timeline set using the filter
            const latestRoomMessageEvent = room.getLiveTimeline()
                .getEvents()
                .findLast(matrixEvent => {
                    const type = matrixEvent.getType();
                    return type === EventType.RoomMessage || type === EventType.RoomMessageEncrypted;
                });

            // Check if the filteredTimeline is empty
            if (!latestRoomMessageEvent) {
                return "No messages yet";
            }

            // Check if the type is reported as encrypted (hence decryption failed and the inner type could not be read)
            if (latestRoomMessageEvent.getType() === EventType.RoomMessageEncrypted) {
                return "(Encrypted message)";
            }

            return latestRoomMessageEvent.getContent().body;
        } catch (e) {
            console.warn("[MatrixChat] Error in `getRecentMessageEventInTheRoom`", e);
            return "Unable to retrieve recent message";
        }
    };
    
    /**
     * Deletes a Matrix room.
     * @param {function(): Promise<void>} onDeleteChat - Callback function to execute after successful Matrix room deletion.
     */
    deleteMatrixRoom = async (onDeleteChat) => {
        try {
            const matrixRoom = await this.getRoomWithTimeout(this.matrixClient, this.matrixRoomId);
            if (!matrixRoom) {
                throw new Error("Room not found", this.matrixRoomId);
            }

            await onDeleteChat();
            //forcefully running this event, spamming deleting of chat room fixes this.     
            this.matrixClient.emit(ClientEvent.DeleteRoom, this.matrixRoomId);
            console.log(`Room ${this.matrixRoomId} deleted successfully.`);    
        } catch (error) {
            //when 404 status, remove it from the MatrixChatList
            if (typeof error === "string" && error === "Chat not found") {
                this.matrixClient.emit(ClientEvent.DeleteRoom, this.matrixRoomId);
            }
            else 
            {
                console.warn("[MatrixChat] Room deletion encountered an error: ", error);
                throw error;
            }
        }
    };

    fetchMatrixRoomTimeline = async () => {
        const events = await this.getMatrixEvents();
        this.maxtrixRoomTimeline = events;
        return events;
    };

    getMatrixEvents = async () => {
        const matrixRoom = await this.getRoomWithTimeout(this.matrixClient, this.matrixRoomId);

        return new Promise((resolve, reject) => {
            this.maxtrixRoomTimelineLoading = true;
            let _maxtrixRoomTimeline = [];
            try {
                if (!matrixRoom) {
                    reject(new Error("Room not found", this.matrixRoomId));
                }

                matrixRoom.getLiveTimeline().getEvents().forEach(async (matrixEvent) => {
                    if (matrixEvent.getType() === EventType.RoomMessage) {
                        _maxtrixRoomTimeline.push(matrixEvent);
                    }
                });

                this.maxtrixRoomTimelineLoading = false;
                resolve(_maxtrixRoomTimeline);
                return;
            } catch (error) {
                console.warn("[MatrixChat] Room encountered an error when getting the events: ", error);
                this.maxtrixRoomTimelineLoading = false;
                reject(error);
            }
        });
    };
}

export default MatrixChat;