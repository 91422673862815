import React, { useState, useContext, useEffect } from "react";
import { ChatContext } from "src/scenes/Chat/ChatContext";
import ChatInputMessages from "src/scenes/Chat/components/ChatInputMessages";
import LeadershipEmployees from "src/scenes/Chat/components/LeadershipEmployees";
import {
    Container, ChatBox, Header, MessagesContainer, ChatInputContainer, EditChatButton, Dropdown, Dot
} from "src/scenes/Chat/components/styled/chatMessagesStyled";
import { FlexContainer, Text, LottieLoadingIndicator } from "src/components";
import DropdownActionsBox from "src/scenes/Chat/components/DropdownActionsBox";
import { UserContext } from "src/scenes/App/UserContext";
import { MESSAGE_CLIENTS, MESSAGE_EMPLOYEES } from "src/constants/permissions";
import NoActiveChats from "src/scenes/Chat/components/children/chatmessages/NoActiveChats";
import { IsTypingBox } from "src/scenes/Chat/components/children/chatmessages/IsTypingBox";
import ViewGroupChatModal from "src/scenes/Chat/components/ViewGroupChatModal";
import EditGroupChatModal from "src/scenes/Chat/components/EditGroupChatModal";
import ChatRoomTimeline from "src/scenes/Chat/components/children/ChatRoomTimeline";
import { isChatActionAllowed } from "src/constants/permissions";
import { USER_TYPE_CLIENTS, USER_TYPE_EMPLOYEES } from "src/constants/chat";

const ChatMessageContainer = () => {
    const [showViewGroupChatModal, setShowViewGroupChatModal] = useState(false);
    const [openedTimeline, setOpenedTimeline] = useState(null);
    const [isLookingForLeadershipToChat, setIsLookingForLeadershipToChat] = useState(false);

    const { 
        matrixClient,
        currentMatrixUserId,
        useChatMessage: ctxUseChatMessage,
        useDeleteChat: ctxUseDeleteChat,
        useEditChat: ctxUseEditChat,
        useTypingListener: ctxUseTypingListener,
        useActiveChats: ctxUseActiveChats,
        useCreateChatModal: ctxUseCreateChatModal
    } = useContext(ChatContext);

    const { 
        isFetchingActiveChats,
        selectedChatListTypeOrLeadershipUuid,
        lookingForLeadershipToChat,
        selectedChatListTypeOrLeadershipUuidLabel
    } = ctxUseActiveChats;

    const { 
        isTyping, 
        typingMember, 
        typingRoomId 
    } = ctxUseTypingListener;

    const { 
        openedMatrixChat,
        toggleDropdown,
        setToggleDropdown,
        displayOtherUserProfileModal
    } = ctxUseChatMessage;

    const { 
        handleShowDeleteConfirmationModal 
    } = ctxUseDeleteChat;

    const {
        showEditGroupChatModal,
        setShowEditGroupChatModal
    } = ctxUseEditChat;

    const { 
        showCreateChatModalOrShowLeadershipEmployees,
        createChatWithLeadership
    } = ctxUseCreateChatModal;
    
    useEffect(() => {
        if (!lookingForLeadershipToChat) {
            setIsLookingForLeadershipToChat(false);
            return;
        }

        const isNonLeadership = [USER_TYPE_CLIENTS, USER_TYPE_EMPLOYEES].includes(lookingForLeadershipToChat.uuid);
        setIsLookingForLeadershipToChat(isNonLeadership ? false : true);

    }, [lookingForLeadershipToChat]); //see useCreateChatModal::showCreateChatModalOrShowLeadershipEmployees()
    
    return (  
        <>
            {
                isLookingForLeadershipToChat  
                    ?
                    <LeadershipEmployees
                        createChatWithLeadership={createChatWithLeadership}
                        selectedChatListTypeOrLeadershipUuidLabel={selectedChatListTypeOrLeadershipUuidLabel}
                        isChatListEmpty={ (openedMatrixChat) && true }
                    /> 
                    :
                    <>
                        <Container >
                            <ChatBox>
                                <ChatBoxHeader
                                    currentChat={openedMatrixChat}
                                    setShowEditGroupChatModal={setShowEditGroupChatModal}
                                    setShowViewGroupChatModal={setShowViewGroupChatModal}
                                    setToggleDropdown={setToggleDropdown}
                                    toggleDropdown={toggleDropdown}
                                    showUserProfile={displayOtherUserProfileModal}
                                    handleMatrixChatShowDeleteConfirmationModal={ () => handleShowDeleteConfirmationModal(openedMatrixChat) }
                                />
                                <ChatBoxBody
                                    setOpenedTimeline={setOpenedTimeline} 
                                    currentChat={openedMatrixChat} 
                                    isChatActionAllowed={isChatActionAllowed} 
                                    selectedChatListTypeOrLeadershipUuid={selectedChatListTypeOrLeadershipUuid} 
                                    showCreateChatModalOrShowLeadershipEmployees={showCreateChatModalOrShowLeadershipEmployees} 
                                    isTyping={isTyping}
                                    isFetchingActiveChats={isFetchingActiveChats}
                                >
                                    <MessagesContainer>
                                        <IsTypingBox 
                                            currentChat={openedMatrixChat} 
                                            isTyping={isTyping} 
                                            typingRoomId={typingRoomId}  
                                            typingMember={typingMember} 
                                            matrixClient={matrixClient}
                                        />
                                        <ChatRoomTimeline 
                                            roomTimeline={openedTimeline} 
                                            currentChat={openedMatrixChat}
                                            currentMatrixUserId={currentMatrixUserId}
                                        />
                                    </MessagesContainer>
                                    <ChatInputContainer>
                                        <ChatInputMessages currentChat={openedMatrixChat} />
                                    </ChatInputContainer>
                                </ChatBoxBody>
                            </ChatBox>
                        </Container>
                        { showViewGroupChatModal &&
                            <ViewGroupChatModal 
                                currentChat={openedMatrixChat}
                                showDialog={showViewGroupChatModal}
                                handleClose={() => setShowViewGroupChatModal(false)}
                            />
                        }
                        { showEditGroupChatModal &&
                            <EditGroupChatModal />
                        }
                    </>
            }
        </>
    );
};

const ChatBoxHeader = ({ 
    currentChat,
    setShowEditGroupChatModal,
    setShowViewGroupChatModal, 
    setToggleDropdown, 
    toggleDropdown, 
    showUserProfile, 
    handleMatrixChatShowDeleteConfirmationModal 
}) => {
    const {
        currentMatrixUserId,
        useActiveChats: ctxUseActiveChats
    } = useContext(ChatContext);

    const {
        leadershipRoles
    } = ctxUseActiveChats;

    const renderChatType = () => {
        if (currentChat?.leadershipRoleUuid) {
            const lr = leadershipRoles.find((leadershipLabel) => leadershipLabel.uuid === currentChat.leadershipRoleUuid)?.name ?? "";
            return lr;
        }

        return currentChat.isClient ? currentChat.isGroupChat ? "Clients" : "Client"
            : currentChat.isGroupChat ? "Employees" : "Employee";
    };

    return (
        <Header> 
            { currentChat &&
                <React.Fragment>
                    <Text color="#4B5155" weight="700" size="1.125rem">{ currentChat.name }</Text>
                    { currentChat.isGroupChat ?
                        <React.Fragment>
                            { `@${currentChat.ownerUuid}:memotivationapp.com` === currentMatrixUserId ?
                                <EditChatButton onClick={() => setShowEditGroupChatModal(true) } enabled={true}>
                                    Edit Group
                                </EditChatButton>
                                :
                                <EditChatButton onClick={() => setShowViewGroupChatModal(true)} enabled={true}>
                                    View Group
                                </EditChatButton>
                            }
                        </React.Fragment>
                        
                        :   
                        <div className="flex-centered-content">
                            <Text color="#000000" weight="700" size="1.125rem">
                                { renderChatType() }
                            </Text>
                            <Dropdown className="flex-centered-content"
                                onClick={() => setToggleDropdown(!toggleDropdown)}
                            >
                                <Dot />
                                <Dot />
                                <Dot />
                                
                            </Dropdown>
                        </div>
                    }
                </React.Fragment>
            }
            
            { toggleDropdown &&
                <DropdownActionsBox
                    showUserProfile={showUserProfile}
                    deleteChat={handleMatrixChatShowDeleteConfirmationModal}
                    setToggleDropdown={setToggleDropdown}
                />
            }
        </Header>
    );
};

const ChatBoxBody = ({ children, 
    setOpenedTimeline, 
    currentChat, 
    isChatActionAllowed, 
    selectedChatListTypeOrLeadershipUuid, 
    showCreateChatModalOrShowLeadershipEmployees,
    isTyping,
    isFetchingActiveChats
}) => {
    
    const userContext = useContext(UserContext);
    const hasPermissionToInitiateChat = isChatActionAllowed(MESSAGE_CLIENTS, userContext.permissions) 
    || isChatActionAllowed(MESSAGE_EMPLOYEES, userContext.permissions);

    const { 
        useChatMessage: ctxUseChatMessage
    } = useContext(ChatContext);

    const { 
        isFetchingRoomTimeline,
        roomTimeline,
    } = ctxUseChatMessage;
    
    useEffect(() => {
        setOpenedTimeline(roomTimeline);
    }, [roomTimeline, isFetchingRoomTimeline, isTyping, isFetchingActiveChats]);
        
    const thereIsNoExistingChat = !currentChat?.length;

    if (isFetchingActiveChats || currentChat === undefined) { //this will only be true one time from onLoad
        return (
            <div style={{ height: "100%" }}>
                <LottieLoadingIndicator /> 
            </div> 
        );
    }

    return (
        <>
            { (!isFetchingActiveChats && currentChat === null) ? 
                <NoActiveChatContainer hasPermissionToInitiateChat={hasPermissionToInitiateChat} thereIsNoExistingChat={thereIsNoExistingChat}>
                    <NoActiveChats 
                        selectedChatListTypeOrLeadershipUuid={selectedChatListTypeOrLeadershipUuid}
                        permissions={userContext.permissions}
                        showCreateChatModalOrShowLeadershipEmployees={showCreateChatModalOrShowLeadershipEmployees }
                    />
                </NoActiveChatContainer>
                :
                <>{children}</>
            }
        </>
    );
};

const NoActiveChatContainer = ({ children, hasPermissionToInitiateChat, thereIsNoExistingChat }) => {
    return (
        <FlexContainer height="100%" justifyContent="center" alignItems="center">
            { !hasPermissionToInitiateChat && <div>No available chat</div> }

            { hasPermissionToInitiateChat && thereIsNoExistingChat && <>{children}</>
            }
            { hasPermissionToInitiateChat && !thereIsNoExistingChat &&
                <Text color="#8c8c8c">Select a chat to see its messages.</Text>
            }
        </FlexContainer>
    );
};

export default ChatMessageContainer; 