import React from "react";
import { ErrorList, ErrorStrings, surveyTypeOptions } from "../../../components/Enums";
import { ErrorText, Text } from "src/components";
import { customDisabledStyles, customStyles, IconOption, SingleValue } from "../DropdownCustomStyles";
import Skeleton from "src/scenes/Statistics/components/Skeleton";
import Select from "react-select";


export const TypeOfSurveyField = (props) => {
    const { survey, selectSurveyType, inputErrors, isEditSurveyForm, loading } = props;

    const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
    const surveyTypeError = getErrorStrings(ErrorList.EMPTY_SURVEY_TYPE);

    return (
        <>
            <Text align="left" size="16px" weight="700">
                Select The Survey Type
            </Text>
            { loading ?
                <Skeleton height="50px" width="100%" animation="wave" />
                :
                <Select
                    isSearchable={false}
                    isDisabled={isEditSurveyForm}
                    value={surveyTypeOptions.find(obj => obj.value == survey.surveyType)}
                    className="surveyTypeDropdown"
                    placeholder="Please select..."
                    options={ surveyTypeOptions }
                    onChange={(choice) => selectSurveyType(choice)}
                    styles={isEditSurveyForm ? customDisabledStyles : customStyles}
                    components={{ Option: IconOption, SingleValue: SingleValue }}
                />
            }

            { surveyTypeError.length > 0 &&
                <ErrorText errorMargin="15px 20px 15px">
                    {surveyTypeError}
                </ErrorText>
            }
        </>
    );
};