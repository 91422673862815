import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { API_URL } from "src/scenes/App";
import axios from "axios";
import {
    LottieLoadingIndicator, FlexContainer, CardHeadingLabel, ExplainParagraphLabel
} from "src/components";
import { SettingContext } from "../SettingContext";
import moment from "moment";
import MoreMenuContainer from "../components/MoreMenuContainerBilling";
import CalendarIcon from "src/img/billingcalendar.svg";
import DownArrow from "src/img/billingdownarrow.svg";

const InvoiceTable = styled.table`
    text-align: left;
    font-size: 14px;
    border-collapse: collapse;
    background-color: white;
    margin-top: 1em;
    width: 100%;
`;

const InvoiceTableHeaderRow = styled.tr`
    background: #F7F7F7;
    border: 1px solid #E9EBEF;
    font-size: 15px;
`;

const InvoiceTableHeaderCell = styled.td`
    color: #808080;
    padding: 0.3rem 0 0.3rem 0.3rem;
    width: ${ p => p.width || "auto" };
    text-align: ${ p => p.align || "left" };
`;

const InvoiceTableRow = styled.tr`
    border-bottom: 1px solid #E9EBEF;
`;

const InvoiceTableCell = styled.td`
    text-align: ${ p => p.align || "left" };
    padding: 0.3rem 5px 0.3rem 5px;
    
    width: ${ p => p.width || "auto" };
`;

const InvoiceNumber = styled.span`
    color: #5A2A7F;
`;

const layoutStyle = {
    paddingLeft: "40px"
};

const tdHeaderStyle = {
    paddingTop: "1em",
    paddingBottom: "1em",
    paddingRight: "1em",
};

const firstTdHeaderStyle = {
    ...layoutStyle,
    ...tdHeaderStyle
};

const firstTdStyle = {
    ...layoutStyle
};

const lastTdStyle = {
    padding: "1em 40px 1em 1em"
};

const DropdownSet = styled.div`
    border: 1px #E9EBEF solid;
    border-radius: 12px;
    min-width: 140px;
    max-width: 210px;
    line-height: 1em;
    display: flex;
    align-items: center;
    padding: 0px 15px;
    img{
        display: inline-block;
    }

    :active, :focus-within {
        border: 1px solid #000;
    }
`;

const Dropdown = styled.select`
    border: none;
    height: 40px;
    width:100%;
    padding-left: 20px;
    position:relative;
    font-family: Roboto, Helvetica, sans-serif;

    /* Customized Down Arrow Icon */

    -moz-appearance:none; /* Firefox */
    -webkit-appearance:none; /* Safari and Chrome */
    appearance:none;

    background-image: url("${DownArrow}");
    background-color: inherit;
    background-repeat: no-repeat;
    background-size: 14px;
    background-position-x: 100%;
    background-position-y: 17px;

    :focus {
        outline: none;
        width: 100%;
    }
`;

function BillingHistory(_props) {
    const { token } = useContext(SettingContext);
    const [invoices, setInvoices] = useState([]);
    const [invoicesLoading, setInvoicesLoading] = useState(true);

    const options = [
        { value: "All Time", label: "All Time" }, 
        { value: "This Year", label: "This Year" }, 
        { value: "Last Year", label: "Last Year" } 
    ];
    const [indexOfShownMenu, setIndexOfShownMenu] = useState(null);

    useEffect(() => {
        fetchInvoices();
    }, []);

    // Fetch Invoices
    const fetchInvoices = async () => {
        try {
            let invoicesRes = axios.get(`${API_URL}/company/getAllInvoices`, {
                headers: {
                    Authorization: "Bearer " + token
                }
            });

            const res = await invoicesRes;
            setInvoices(res.data);
            setInvoicesLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    const handleSubmit = async (filterValue) => {
        setInvoicesLoading(true);

        let invoicesRes = axios.get(`${API_URL}/company/getAllInvoices`, {
            params: {
                filterYear: filterValue
            },
            headers: {
                Authorization: "Bearer " + token
            }
        });

        const res = await invoicesRes;
        setInvoices(res.data);
        setInvoicesLoading(false);
    };

    return (
        <div style={{ paddingBottom: "2em", height: "100%" }}>
            <FlexContainer margin="0px 40px 20px 40px" style={{ flexDirection: "row" }}>

                <div >
                    <CardHeadingLabel>
                        Billing History
                    </CardHeadingLabel>
                    <ExplainParagraphLabel>
                        View invoices and refunds for your business user subscriptions.
                    </ExplainParagraphLabel>
                </div>
                <div style={{ marginLeft: "auto" }}>
                    <DropdownSet>
                        <img src={CalendarIcon} alt={CalendarIcon} />
                        <Dropdown onChange={(e) => handleSubmit(e.target.value)}>
                            {options.map((option) => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </Dropdown>
                    </DropdownSet>
                </div>
            </FlexContainer>
            {
                invoicesLoading 
                    ? 
                    <LottieLoadingIndicator/> :

                    <InvoiceTable>
                        <thead>
                            <InvoiceTableHeaderRow>
                                <InvoiceTableHeaderCell style={firstTdHeaderStyle}>Date</InvoiceTableHeaderCell>
                                <InvoiceTableHeaderCell>Invoice</InvoiceTableHeaderCell>
                                <InvoiceTableHeaderCell>Description</InvoiceTableHeaderCell>
                                <InvoiceTableHeaderCell>Amount Purchased</InvoiceTableHeaderCell>
                                <InvoiceTableHeaderCell>Payment Method</InvoiceTableHeaderCell>
                                <InvoiceTableHeaderCell>Status</InvoiceTableHeaderCell>
                                <InvoiceTableHeaderCell align="right">Total</InvoiceTableHeaderCell>
                                <InvoiceTableHeaderCell>&nbsp;</InvoiceTableHeaderCell>
                            </InvoiceTableHeaderRow>
                        </thead>
                        <tbody>
                            { invoices.map((invoice, key) => {
                                return (
                                    <InvoiceTableRow key={key}>
                                        <InvoiceTableCell style={firstTdStyle}>
                                            {moment(invoice.date).format("MMM D, YYYY")}
                                        </InvoiceTableCell>
                                        <InvoiceTableCell >
                                            <InvoiceNumber>{invoice.invoiceNo}</InvoiceNumber>
                                        </InvoiceTableCell>
                                        <InvoiceTableCell >
                                            {invoice.description}
                                        </InvoiceTableCell>
                                        <InvoiceTableCell >
                                            {invoice.numberOfUsers}
                                        </InvoiceTableCell>
                                        <InvoiceTableCell>
                                            {invoice.paymentMethod}
                                        </InvoiceTableCell>
                                        <InvoiceTableCell >
                                            {invoice.status}
                                        </InvoiceTableCell>
                                        <InvoiceTableCell align="right">
                                            {invoice.currency} {invoice.totalIncGST}
                                        </InvoiceTableCell>
                                        <InvoiceTableCell style={lastTdStyle} align="center">
                                            <MoreMenuContainer thisIndex={key} indexOfShownMenu={indexOfShownMenu} setIndexOfShownMenu={setIndexOfShownMenu} invoice={invoice} token={token} />
                                        </InvoiceTableCell>
                                    </InvoiceTableRow>);
                            })}
                        </tbody>
                    </InvoiceTable>
            }
        </div>
    );
}

export default BillingHistory;