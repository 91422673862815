import React, { useState, useEffect } from "react";
import { Button, Text, LottieLoadingIndicator } from "src/components";
import { Dialog, Box, Divider } from "@material-ui/core";
import axios from "axios";
import { API_URL } from "src/scenes/App";
import { generateNameInitials } from "src/utils/helpers";
import { FILTER_NOTICE } from "./../Noticeboard";
import styled from "styled-components";

const NoticeViewershipModal = ({ noticeData, showNoticeViewershipModal, handleCloseNoticeViewershipModal, user }) => {
    const readerType = noticeData.readerType === FILTER_NOTICE.EMPLOYEE ? "Employee" : "Client";
    const isReaderTypeEmployee = noticeData.readerType === FILTER_NOTICE.EMPLOYEE;
    const viewsCountFormatted = parseInt(noticeData.readCount).toLocaleString("en-us");
    const [viewersList, setViewersList] = useState([]);
    const [isFetching, setIsFetching] = useState(false);
    useEffect(() => {
        fetchViewerList();
    }, [user.token]);
    
    const fetchViewerList = async () => {
        const noticeboardApi = `${API_URL}/company/noticeboard/viewersList/${noticeData.uuid}`;
        
        setIsFetching(true);
        try {
            const res = await axios.get(noticeboardApi, {
                headers: {
                    Authorization: "Bearer " + user.token
                }
            });
            
            setViewersList(res.data.viewerDetails);
        } catch (error) {
            // Handle error
        } finally {
            setIsFetching(false);
        }
    };


    return (
        <Dialog
            open={showNoticeViewershipModal}
            maxWidth="md"
            scroll="body"
            PaperProps={{
                style: {
                    width: 980,
                    height: "auto",
                    borderRadius: 15,
                    overflow: "auto"
                }
            }}
        >
            <Box sx={{ padding: "1.563rem 3.125rem 0.625rem" }}>
                <Text size="2.313rem" weight="700" align="left" margin="0">
                    Post {readerType} Views
                </Text>
            </Box>
            <Divider />
            <Box sx={{ padding: "1.25rem 3.125rem" }}>
                <Text size="1.25rem" weight="700" align="left">Number of {readerType} Views</Text>
                <Text size="1rem" align="left">This is the number of {readerType}s who have seen the selected post on your business Noticeboard.</Text>
                <Text size="2.313rem" weight="700" align="left" margin="0">{viewsCountFormatted}</Text>
            </Box>
            <Box sx={{ padding: "1.25rem 3.125rem" }}>
                <>
                    { isFetching 
                        ? <LottieLoadingIndicator height="1.563rem" width="1.563rem" containerHeight="0" /> 
                        : <ViewersListPreview viewersList={viewersList} isReaderTypeEmployee={isReaderTypeEmployee} />
                    }   
                </>
            </Box>
            <Divider />
            <Box sx={{ padding: "1.25rem 3.125rem 2.188rem" }}>
                <div style={{ display: "flex", justifyContent: "end" }}>
                    <div style={{ textAlign: "right" }}>
                        <>
                            <Button
                                onClick={handleCloseNoticeViewershipModal}
                                size="0.875rem"
                                border="1px solid #000"
                                color="#000"
                                marginRight="15px"
                                width="8.75rem"
                                style={{ borderRadius: "7px", boxShadow: "none" }}
                            >
                                Cancel
                            </Button>
                        </>
                    </div>
                </div>
            </Box>
        </Dialog>
    );
};

const ContainerOverflow = styled.div`
    max-height: 400px;
    overflow-y: scroll;
    padding-right: 1em;

    ::-webkit-scrollbar {
        display: block;
        width: 8px;
        height: 8px;
    }

    ::-webkit-scrollbar-track {
        background: transparent;
    }

    ::-webkit-scrollbar-thumb {
        background-color: #174A84;
        border-radius: 6px;
        border-right: none;
        border-left: none;
    }

    ::-webkit-scrollbar-track-piece:end {
        background-color: #D1DBE6;
        border-radius: 6px;
        margin-right: 30px;
    }

    ::-webkit-scrollbar-track-piece:start {
        background-color: #D1DBE6;
        border-radius: 6px;
    }
`;

const TablePreview = styled.div`
    .thead * {
        font-weight: 700;
    }
    .tbody * {
        font-weight: 400;
    }
    .tbody, .thead{
        display: table;
        width: 100%;
    }

    .tbody > *, .thead > * {
        display: table-row;
    }

    .tbody .col, .thead .col {
        display: table-cell;
        padding: 1em;
        width: calc(100% / 4); /* Assuming 3 columns */
    }

    .col-fullname-width {
        width: calc(100% / 2) !important;
    }

    .tbody .col {
        border-left: 1px solid #8291B2;
        border-top: 1px solid #8291B2;
    }

    .tbody .lastcol {
        border-right: 1px solid #8291B2;
    }

    .tbody .lastrow > .col {
        border-bottom: 1px solid #8291B2;
    }
`;

const CircledInitials = styled.div`
    font-family: SofiaPro;
    font-size: 16px;
    font-weight: 600;
    width: 42px;
    height: 42px;
    background-color: #D6D6D6;
    color: #6F6F6F;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 0.5em;
`;

const UserRow = ({ index, maxIndex, uuid, nameInitials, fullName, department, jobTitle, isReaderTypeEmployee }) => {
    const isFirstRow = index === 0;
    const isLastRow = index === maxIndex;
    const isOnlyOneRow = isFirstRow && isLastRow;

    const topLeftRadius = "8px 0px 0px 0px";
    const topRightRadius = "0px 8px 0px 0px";
    const bottomLeftRadius = "0px 0px 0px 8px";
    const bottomRightRadius = "0px 0px 8px 0px";

    let divStyleFirstColumn = isFirstRow ? { borderRadius: topLeftRadius } : isLastRow ? { borderRadius: bottomLeftRadius } : {};
    let divStyleLastColumn = isFirstRow ? { borderRadius: topRightRadius } : isLastRow ? { borderRadius: bottomRightRadius } : {};

    if (isOnlyOneRow) {
        divStyleFirstColumn = { borderRadius: "8px 0px 0px 8px" };
        divStyleLastColumn = { borderRadius: "0px 8px 8px 0px" };
    }

    return (
        <div className={`row ${isLastRow ? "lastrow" : ""}`} key={uuid + "_" + index}>
            <div className="col col-fullname-width" style={ divStyleFirstColumn }>
                <div style={{ display: "inline-block" }}><CircledInitials>{nameInitials}</CircledInitials></div> {fullName}
            </div>
            {isReaderTypeEmployee && <div className="col">{department}</div>}
            <div className={"col lastcol"} style={ divStyleLastColumn }>{jobTitle}</div>
        </div>
    );
};

const ViewersListPreview = ({ viewersList, isReaderTypeEmployee }) => {
    if (!viewersList || viewersList.length === 0) {
        return ("No views found");
    }

    return (
        <TablePreview>
            <div className="thead ">
                <div className="row" key="theadrow">
                    <div className="col col-fullname-width">Name</div>
                    {isReaderTypeEmployee && <div className="col">Department / Group</div>}
                    <div className="col">Job Title</div>
                </div>
            </div>

            <ContainerOverflow>
                <div className="tbody">
                    {
                        viewersList.map((employee, index) => {
                            const nameInitials = generateNameInitials(employee.firstName, employee.lastName);
                            const fullName = employee.firstName + " " + employee.lastName;
                            return (
                                <UserRow 
                                    index={index}
                                    maxIndex={viewersList.length - 1}
                                    uuid={employee.uuid}
                                    nameInitials={nameInitials}
                                    fullName={fullName}
                                    department={employee.department} 
                                    jobTitle={employee.employmentPosition} 
                                    isReaderTypeEmployee={isReaderTypeEmployee}
                                />
                            );
                        })
                    }
                </div>
            </ContainerOverflow>
        </TablePreview>
    );
};

export default NoticeViewershipModal;