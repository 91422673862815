import React from "react";
import styled from "styled-components";
import { FlexContainer, 
    mobile, desktop, tablet, ExplainParagraphLabel, PageHeadingLabel, Text 
} from "src/components";

import QrCode from "src/img/new/qr.svg";

const QrContainer = styled.div`
    background-color: #FFF;
    color: #000;
    width: 65%;
    border: 1px solid #8291B2;
    border-radius: 12px;
    padding: 1em 1em 0.5em 1em;

    ${tablet`
        width: unset;
    `};

    ${mobile`
        width: unset;
    `};
    
`;

const Card = styled.div`
    background-color: #FFF;
    color: #000;
    border: 1px solid #8291B2;
    width: 35%;
    height: auto;
    min-height: ${p => p.minHeight || "6.875rem"};
    border-radius: 12px;
    padding: 1.4375rem 1.5625rem 1.4375rem 1.8125rem;
    box-sizing: border-box;
    position: relative;

    ${tablet`
        width: unset;
    `};

    ${mobile`
        width: unset;
    `};
`;

const Label = styled.div`
    font-weight: 500;
    font-size: 0.875rem;
`;

const Number = styled.div`
    font-size: 2rem;
    font-weight: 700;
    margin: 0;
    text-align: left;
`;

const GetQRCodeButton = styled.button`
    background: #000000 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 10px;
    font-weight: 700;
    color: #FFFFFF;
    margin-right: 1em;
    padding: 1em 0em;
    width: 162px;
    cursor: pointer;
`;

export const SALES_AND_PROMOTION_HEADING = "Sales & Promotions";

function SalesAndPromotionHeader(props) {
    const { handleShowGetQrModal } = props;
    return (
        <FlexContainer dGap="6rem" direction="row" justifyContent="space-between" marginBottom="1.625rem"
            style={{ maxWidth: "98.625rem" }}
        >
            <div>
                <PageHeadingLabel>{ SALES_AND_PROMOTION_HEADING }</PageHeadingLabel>
                <ExplainParagraphLabel width="100%" size="0.875rem" maxWidth="44rem">
                        Sales & Promotions allows you to provide short term access to your Me Business app to clients. Client Trial Licenses are different to your main business users as they are short term licenses and provide a limited period of access to your Me Business app.
                </ExplainParagraphLabel>
            </div>

            <FlexContainer direction="row" margin="2rem 0 0.5rem"
                width="49.375rem"
                justifyContent="space-between"
                gap="1em"
                tDirection="row"
            >
                <QrContainer>
                    <Text size="1.25rem" weight="800" align="left" margin="0 0 1em 0">
                        Easy Access QR Code 
                        <img src={QrCode} alt="QR Code" height="30px" style={{ marginLeft: "1em", top: "8px", position: "relative" }}/>
                    </Text>
                    <FlexContainer direction="row" tDirection="column">
                        <Text size="0.875rem" weight="500" align="left" margin="0 0.5em 1em 0">
                            Easily grant your clients access to your branded 
                            Me Business app using your unique QR code.
                        </Text>
                        <div>
                            <GetQRCodeButton onClick={ () => handleShowGetQrModal()} >Get QR Code</GetQRCodeButton>
                        </div>
                    </FlexContainer>
                </QrContainer>

                <Card>
                    <Label>Client Licenses Available</Label>
                    <Number>{ props.unusedLicensesAvailable }</Number>
                </Card>
            </FlexContainer>
        </FlexContainer>
    );
}

export default SalesAndPromotionHeader;