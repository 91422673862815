import React, { Component } from "react";
import { API_URL } from "src/scenes/App";
import axios from "axios";
import styled from "styled-components";
import {
    Toast, Text, LottieLoadingIndicator, ErrorText, Form, Button,
    BankCountry, AccountHolderName, BankName, AccountNumber, SwiftCode,
    BsbOrIban, InvoiceOrStatementEmail, StreetAddress, StreetAddress2nd, 
    City, State, AddressCountry, PostcodeOrZip, ErrorList, ContainerGrid
} from "src/components";

import BankDetailsModal from "../components/BankDetailsModal";
import { AUSTRALIA } from "src/constants/subscriptions";

const ActionButtonContainer = styled.div`
    margin: 1rem 0px;
`;

const ActionButton = styled(Button)`
    color: ${ p => (p.color) || "#000" };
    background-color: ${ p => (p.backgroundColor) || "#fff" };
    margin: 5px 5px;
    font-size:14px;
    padding: ${ p => (p.padding) || "9px 45px 9px !important" };
    border: ${ p => (p.border) || "2px solid #000" };
    border-radius: 10px !important;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
`;

const OverflowContainerWithCustomScrollBar = styled.div`
    overflow: auto;
    height: 26em;
    padding-right: 1em;
    margin-top: 1em;
    
`; //TO DO Modify Scrollbar UI

export const PaddingLeftForOverFlowIssue = {
    paddingLeft: "5px"
};

class BankDetailsForm extends Component {
    state = {
        bankAccountDetails: {
            country: "",
            accountHolderName: "",
            bankName: "",
            accountNumber: "",
            swiftCode: "",
            bsbOrIban: "",
            invoiceOrStatementEmail: "",
            streetAddress: "",
            streetAddress2: "",
            city: "",
            state: "",
            addressCountry: "",
            postcodeOrZip: "",
        },
        message: "",
        inputErrors: [],
        loadingSubmit: false,
        isLoading: false,
        isSwiftCodeDisabled: false,
    };
    
    componentDidMount() {
        this.populateBankAccountDetailsForm();
    }

    populateBankAccountDetailsForm = () => {
        this.setState(prevState => ({
            isLoading: true
        }));

        axios.get(`${API_URL}/company/getBankAccountDetails`, {
            headers: {
                Authorization: "Bearer " + this.props.token
            }
        }).then(res => {
            const bankAccount = res.data.bankAccountDetails;

            if (Array.isArray(bankAccount) && bankAccount.length === 0) {
                /* No record found */
            }
            else { /* bankAccount - as Object */
                this.setState(prevState => ({
                    bankAccountDetails: {
                        country: bankAccount.countryCode,
                        accountHolderName: bankAccount.accountHolderName,
                        bankName: bankAccount.bankName,
                        accountNumber: bankAccount.accountNumber,
                        swiftCode: bankAccount.swiftCode,
                        bsbOrIban: bankAccount.bsbOrIban,
                        invoiceOrStatementEmail: bankAccount.email,
                        streetAddress: bankAccount.street,
                        streetAddress2: bankAccount.street2,
                        city: bankAccount.city,
                        state: bankAccount.state,
                        addressCountry: bankAccount.countryCodeAddress,
                        postcodeOrZip: bankAccount.postcodeOrZip,
                    },
                    isSwiftCodeDisabled: bankAccount.countryCode === AUSTRALIA
                }));
            }
        }).catch(error => {
            if (!error.response)
                return Toast.error(error.message);
        }).finally(error => {
            this.setState(prevState => ({
                isLoading: false
            }));
        });
    };

    getErrors() {
        let bankAccountDetails = this.state.bankAccountDetails;
        let errors = [];

        if (bankAccountDetails.country === "" || bankAccountDetails.country === null) {
            errors.push(ErrorList.EMPTY_COUNTRY);
        }

        if (bankAccountDetails.accountHolderName === "" || bankAccountDetails.accountHolderName === null) {
            errors.push(ErrorList.EMPTY_ACCOUNT_HOLDER_NAME);
        }

        if (bankAccountDetails.bankName === "" || bankAccountDetails.bankName === null) {
            errors.push(ErrorList.EMPTY_BANK_NAME);
        }

        if (bankAccountDetails.accountNumber === "" || bankAccountDetails.accountNumber === null) {
            errors.push(ErrorList.EMPTY_ACCOUNT_NUMBER);
        } else if (bankAccountDetails.country === AUSTRALIA && !(bankAccountDetails.accountNumber.length >= 7 && bankAccountDetails.accountNumber.length <= 12)) {
            errors.push(ErrorList.AUS_INVALID_ACCOUNT_NUMBER);
        } 

        if (bankAccountDetails.country !== AUSTRALIA) {
            if (bankAccountDetails.swiftCode === "" || bankAccountDetails.swiftCode === null) {
                errors.push(ErrorList.EMPTY_SWIFT_CODE);
            }
        }

        if (bankAccountDetails.bsbOrIban === "" || bankAccountDetails.bsbOrIban === null) {
            errors.push(ErrorList.EMPTY_BSB_OR_IBAN);
        } else if (bankAccountDetails.country === AUSTRALIA && bankAccountDetails.bsbOrIban.length !== 6) {
            errors.push(ErrorList.AUS_BSB_LENGTH);
        }
      
        if (bankAccountDetails.invoiceOrStatementEmail === "" || bankAccountDetails.invoiceOrStatementEmail === null) {
            errors.push(ErrorList.EMPTY_INVOICE_OR_STATEMENT_EMAIL);
        }
        else {
            /* regex to validate email */
            if (/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(bankAccountDetails.invoiceOrStatementEmail) === false) {
                errors.push(ErrorList.EMAIL_INVALID);
            }
        }

        /** ADDRESS */
        if (bankAccountDetails.streetAddress === "" || bankAccountDetails.streetAddress === null) {
            errors.push(ErrorList.EMPTY_STREET_ADDRESS);
        }

        if (bankAccountDetails.city === "" || bankAccountDetails.city === null) {
            errors.push(ErrorList.EMPTY_CITY);
        }

        if (bankAccountDetails.state === "" || bankAccountDetails.state === null) {
            errors.push(ErrorList.EMPTY_STATE);
        }

        if (bankAccountDetails.addressCountry === "" || bankAccountDetails.addressCountry === null) {
            errors.push(ErrorList.EMPTY_ADDRESS_COUNTRY);
        }

        if (bankAccountDetails.postcodeOrZip === "" || bankAccountDetails.postcodeOrZip === null) {
            errors.push(ErrorList.EMPTY_POST_CODE_OR_ZIP);
        }

        return errors;
    }

    submitEarningBankAccountDetailsForm = () => {
        this.setState({
            loadingSubmit: true,
            inputErrors: [],
            responseErrors: "",
        });

        const bankAccountDetailsData = this.state.bankAccountDetails;

        let errors = this.getErrors();

        if (errors.length > 0) {
            this.setState({
                loadingSubmit: false,
                inputErrors: errors,
                responseErrors: "Please check the fields again for any errors. The form cannot be submitted until all fields are valid.",
            });
        }
        else {
            this.postClient(bankAccountDetailsData);
        }
    };

    postClient = (bankAccountDetailsData) => {
        axios.post(`${API_URL}/company/upsertBankAccountDetails`, {
            countryCode: bankAccountDetailsData.country,
            accountHolderName: bankAccountDetailsData.accountHolderName,
            bankName: bankAccountDetailsData.bankName,
            accountNumber: bankAccountDetailsData.accountNumber,
            swiftCode: bankAccountDetailsData.swiftCode,
            bsbOrIban: bankAccountDetailsData.bsbOrIban,
            email: bankAccountDetailsData.invoiceOrStatementEmail,

            street: bankAccountDetailsData.streetAddress,
            street2: bankAccountDetailsData.streetAddress2,
            city: bankAccountDetailsData.city,
            state: bankAccountDetailsData.state,
            countryCodeAddress: bankAccountDetailsData.addressCountry,
            postcodeOrZip: bankAccountDetailsData.postcodeOrZip,
        },
        {
            headers: {
                Authorization: "Bearer " + this.props.token
            }
        }).then(res => {
            Toast.info("Your bank account details have been updated.");
            BankDetailsModal.close();
        }).catch(message => {
            this.setState({
                responseErrors: message,
            });
        }).finally(error => {
            this.setState({
                loadingSubmit: false,
            });
        });
    };

    cancel = () => {
        BankDetailsModal.close();
    };

    inputBankAccountDetailsValue = (event, propertyName) => {
        const propertyNameValue = event.target.value;
        
        this.setState(prevState => ({
            bankAccountDetails: { // object that we want to update
                ...prevState.bankAccountDetails, // keep all other key-value pairs
                [propertyName]: propertyNameValue // update the value of specific key
            }
        }));

        //Disable Swift Code Field when Bank Account Info.Country is AU
        if (propertyName === "country") {
            this.setState(prevState => ({
                bankAccountDetails: {         
                    ...prevState.bankAccountDetails,   
                    "swiftCode": propertyNameValue === AUSTRALIA ? "" : prevState.swiftCode 
                },
                isSwiftCodeDisabled: propertyNameValue === AUSTRALIA
            }));
        }
    };

    render() {
        const { inputErrors, responseErrors, bankAccountDetails, loadingSubmit, isLoading, isSwiftCodeDisabled } = this.state;

        if (isLoading)
            return <div style={{ width: "100%" }}><LottieLoadingIndicator height="4em" width="4em" /></div>;
        else {
            return (
                <>
                    <Form width="100%" onSubmit={(e) => {e.preventDefault(); }} >
                        { 
                            responseErrors && <ErrorText margin="0" style={PaddingLeftForOverFlowIssue}>
                                { responseErrors}  
                            </ErrorText>
                        }

                        <OverflowContainerWithCustomScrollBar style={PaddingLeftForOverFlowIssue}>
                            <Text size="1.25em" color="#612684" weight="700" align="left" margin="0">
                                Bank Account Information
                            </Text>
                            <ContainerGrid>
                                <BankCountry bankAccountDetails={bankAccountDetails} inputBankAccountDetailsValue={this.inputBankAccountDetailsValue} inputErrors={inputErrors}/>
                                <AccountHolderName bankAccountDetails={bankAccountDetails} inputBankAccountDetailsValue={this.inputBankAccountDetailsValue} inputErrors={inputErrors} />
                                <BankName bankAccountDetails={bankAccountDetails} inputBankAccountDetailsValue={this.inputBankAccountDetailsValue} inputErrors={inputErrors} />
                                <AccountNumber bankAccountDetails={bankAccountDetails} inputBankAccountDetailsValue={this.inputBankAccountDetailsValue} inputErrors={inputErrors} />
                                <SwiftCode bankAccountDetails={bankAccountDetails} inputBankAccountDetailsValue={this.inputBankAccountDetailsValue} inputErrors={inputErrors} isSwiftCodeDisabled={isSwiftCodeDisabled} />
                                <BsbOrIban bankAccountDetails={bankAccountDetails} inputBankAccountDetailsValue={this.inputBankAccountDetailsValue} inputErrors={inputErrors} />
                            </ContainerGrid>
                            
                            <InvoiceOrStatementEmail bankAccountDetails={bankAccountDetails} inputBankAccountDetailsValue={this.inputBankAccountDetailsValue} inputErrors={inputErrors} />

                            <Text size="1.25em" color="#612684" weight="700" align="left">
                                Address
                            </Text>
                            <ContainerGrid>
                                <StreetAddress bankAccountDetails={bankAccountDetails} inputBankAccountDetailsValue={this.inputBankAccountDetailsValue} inputErrors={inputErrors} />
                                <StreetAddress2nd bankAccountDetails={bankAccountDetails} inputBankAccountDetailsValue={this.inputBankAccountDetailsValue} inputErrors={inputErrors} />
                                <City bankAccountDetails={bankAccountDetails} inputBankAccountDetailsValue={this.inputBankAccountDetailsValue} inputErrors={inputErrors} />
                                <State bankAccountDetails={bankAccountDetails} inputBankAccountDetailsValue={this.inputBankAccountDetailsValue} inputErrors={inputErrors} />
                                <AddressCountry bankAccountDetails={bankAccountDetails} inputBankAccountDetailsValue={this.inputBankAccountDetailsValue} inputErrors={inputErrors}/>
                                <PostcodeOrZip bankAccountDetails={bankAccountDetails} inputBankAccountDetailsValue={this.inputBankAccountDetailsValue} inputErrors={inputErrors} />
                            </ContainerGrid>
                        </OverflowContainerWithCustomScrollBar>
                    </Form>

                    <ActionButtonContainer>
                        <hr/>
                        {
                            loadingSubmit 
                                ?
                                <div style={{ width: "100%" }}><LottieLoadingIndicator height="4em" width="4em" /></div>
                                :
                                <ContainerGrid>
                                    <div></div>
                                    <div style={{ textAlign: "right" }}>
                                        <ActionButton style={{ marginLeft: "auto" }} backgroundColor="#FFFFFF" color="#000" onClick={this.cancel}>
                                        Cancel
                                        </ActionButton>
                                        <ActionButton color="#FFF" backgroundColor="#000000 " onClick={this.submitEarningBankAccountDetailsForm}>
                                        Save
                                        </ActionButton>
                                    </div>
                                </ContainerGrid>
                        }
                    </ActionButtonContainer>
                </>
            );
        }    
    }
}

export default BankDetailsForm;
