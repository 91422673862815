import React from "react";
import { Grid } from "@material-ui/core";
import LinkedSocials from "./NoticeboardLinkedSocialItems";
import { LottieLoadingIndicator } from "src/components";

const LinkedSocialsGroup = (linkedPlatformItems, onToggleSocialItem) => {
    return (
        <Grid container direction="row" spacing={1}>
            {
                linkedPlatformItems.map((platformItem) => {
                    const platformType = platformItem.pageAdmin.socialMediaType;
                    return platformItem.pages.map((pageItem) => {
                        return (
                            <Grid item>
                                <LinkedSocials
                                    pageName={pageItem.pageName}
                                    pagePlatformType={platformType}
                                    pageUuid={pageItem.uuid}
                                    onToggle={onToggleSocialItem}
                                />
                            </Grid>
                        );
                    });
                })
            }
        </Grid>
    );
};

const NoticeModalSocialPlatform = ({
    linkedAccounts,
    linkedAccountsFetched,
    onToggleSocialItem
}) => {
    return (
        <>
            {
                linkedAccountsFetched ?
                    <>
                        {LinkedSocialsGroup(linkedAccounts.Facebook, onToggleSocialItem)}
                        {LinkedSocialsGroup(linkedAccounts.Instagram, onToggleSocialItem)}
                        {LinkedSocialsGroup(linkedAccounts.LinkedIn, onToggleSocialItem)}
                    </>
                    : <LottieLoadingIndicator />
            }
        </>
    );
};

export default NoticeModalSocialPlatform;