import { useState, useEffect } from "react";
import { Toast } from "src/components";
import axios from "axios";
import { API_URL } from "src/scenes/App";

const useGetHeroImages = (token) => {
    const controller = new AbortController();
    const [businessHeroImages, setBusinessHeroImages] = useState(null);
    const [meHeroImages, setMeHeroImages] = useState(null);
    const [isFetchingHeroImages, setIsFetchingHeroImages] = useState(null);
    
    const fetchHeroImages = async () => {
        setIsFetchingHeroImages(true);
        try {

            const response = await axios.get(`${API_URL}/membershipCard/company/templates`, {
                signal: controller.signal,
                headers: {
                    Authorization: "Bearer " + token
                }
            });

            setBusinessHeroImages(response.data.cardTemplates);
            setMeHeroImages(response.data.fromTheMeTeam);
        } catch (error) {
            if (error === "canceled") { //AbortController
                return;
            }

            console.error("Error occurred when fetching hero images: ", error);
            Toast.error("Unable to fetch digital membership hero images. Please try again later or contact support if the issue persists.");
        } finally {
            setIsFetchingHeroImages(false);
        }
    };

    useEffect(() => {
        fetchHeroImages();

        return () => {
            controller.abort();
        };
    }, [token]);

    return { 
        businessHeroImages, 
        setBusinessHeroImages,
        meHeroImages,
        setMeHeroImages,
        isFetchingHeroImages,
        fetchHeroImages
    };
};

export default useGetHeroImages;