import { useState, useEffect } from "react";
import axios from "axios";
import { Toast } from "src/components";
import { API_URL } from "src/scenes/App";
import { UPLOADED_CUSTOM_UUID } from "src/scenes/DigitalMemberCard/utils/const";

const useSaveCustomisation = (token) => {
    const controller = new AbortController();

    useEffect(() => {
        return () => {
            controller.abort();
        };
    }, [token]);

    const [isSavingCustomisation, setIsSavingCustomisation] = useState(false);

    const saveCustomCardSettings = async (formData) => {
        const { customCardColor, customCardHeroData, forClient } = formData;
        const companyMembershipCardRequest = {
            "customBackgroundColor": customCardColor,
            "membershipCardTemplateUuid": customCardHeroData && customCardHeroData.uuid,
            "forClient": forClient,
        };

        const isNewlyUploadedImage = customCardHeroData && customCardHeroData.uuid === UPLOADED_CUSTOM_UUID;
        const uploadedImageSrc = customCardHeroData && customCardHeroData.imageUrl;

        try {
            await saveMembershipCardCustomisation(companyMembershipCardRequest, isNewlyUploadedImage, uploadedImageSrc);
        } catch (error) {
            console.error("Error in member card settings: ", error);
        }
    };

    const createBusinessTemplate = async (imageUrl) => {
        try {
            const uploadedData = {
                imageUrl: imageUrl
            };

            const response = await axios.post(`${API_URL}/membershipCard/company/businessTemplate`, uploadedData, {
                signal: controller.signal,
                headers: {
                    Authorization: "Bearer " + token
                }
            });
            return response.data.businessTemplate.uuid;

        } catch (error) {
            console.error("Creating business template failed:", error);
            Toast.error("Unable to use the newly uploaded image. Please try again later or contact support if the issue persists.");
            throw error; // Re-throw the error to be caught by the calling function
        }
    };
    
    const saveCustomisationDetails = async (customisationPayloadRequest) => {
        try {
            const response = await axios.put(`${API_URL}/membershipCard/company/cardTemplate`, customisationPayloadRequest, {
                signal: controller.signal,
                headers: {
                    Authorization: "Bearer " + token,
                }
            });
            Toast.success("Membership Card customisation has been saved.");
            return response.data; // You can return data from this function if needed
        } catch (error) {
            Toast.error("Unable to save digital membership cards. Please try again later or contact support if the issue persists.");
            throw error; // Re-throw the error
        }
    };
    
    const saveMembershipCardCustomisation = async (companyMembershipCardRequest, isNewlyUploadedImage, imageUrl) => {
        setIsSavingCustomisation(true);
        try {
            let cardFormDataRequest = { ...companyMembershipCardRequest };
            if (isNewlyUploadedImage) {
                const uuid = await createBusinessTemplate(imageUrl);
                cardFormDataRequest.membershipCardTemplateUuid = uuid;
            }
            await saveCustomisationDetails(cardFormDataRequest);
        } catch (error) {
            // This catch block will catch errors thrown by either createBusinessTemplate or saveImageDetails
            console.error("Error in saving membership card: ", error);
            // No need to display another toast here if the individual functions already do it
        } finally {
            setIsSavingCustomisation(false);
        }
    };

    return {
        saveCustomCardSettings,
        isSavingCustomisation
    };
};

export default useSaveCustomisation;