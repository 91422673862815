import styled from "styled-components";
import { Button } from "src/components";

export const PanelContainer = styled.div`
    flex: 1;
    display: flex;
    width: 100%;

    & div.spacing-x {
        padding-right: 40px;
        padding-left: 40px;
    }

    & div.spacing-y {
        padding-top: 30px;
        padding-bottom: 40px;
    }
`;

export const LeftPanel = styled.div`
    border-right:1px solid #8291B2;
    flex: 1;
    
    & div.borderBottom {
        border-bottom: 1px solid #8291B2
    }

    & div.maxWidth {
        max-width: 550px;
    }

    & div.maxWidth-680 {
        max-width: 680px;
    }
        
`;

export const RightPanel = styled.div`
    text-align: center;
`;

export const CustomizeButton = styled(Button)`
    padding: 10px 0 !important;
    border-radius: 10px !important;
    background-color: ${p => p.backgroundColor || "#FFF" };
    color: ${p => p.color || "#000" };
    font-size: 0.875rem;
    text-align: center;
    border: 2px solid #000000;
    width: 112px;
`;

export const Heading = styled.div`
    color: #612684;
    font-size: 1.563rem; //25px
    font-weight: 700;
    margin: 5px 0px;
`;
export const Description = styled.div`
    color: #808080;
    font-size: 0.875rem;
    font-weight: 500;
`;

export const FormLabel = styled.div`
    font-size: ${ p => p.fontSize || "1rem" };
    color: #000000;
    font-weight: 700;
    margin-bottom: 10px;
`;

export const FormDescription = styled.div`
    font-size: 0.875rem;
    font-weight: 500;
    color: #8291B2;
`;

export const HeaderTwoColumns = styled.div`
    display: flex;
    justify-content: space-between;
    max-width: 94.5rem;
    margin: 0 0 1.4rem 0 !important;
`;

export const DisclaimerContainer = styled.div`
    margin-top: 2rem; //should be the same marginTop of PageHeadingLabel
    width: 485px;
    border: 1px solid #8291B2;
    border-radius: 12px;
    background-color: #FFFFFF;
    color: #000000;
    

    & div.container {
        width: unset;
        margin: 15px 20px;
    }
    & div.title {
        font-size: 1.25rem;
        font-weight: 700;
        margin-bottom: 0.5rem;
    }
    & div.desc {
        font-size: 0.875rem;
        font-weight: 500;
    }
`;