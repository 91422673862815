import React from "react";
import { Grid } from "@material-ui/core/";
import Permission from "./PermissionType";
import { ACCOUNT_OWNER, MULTIPLE_PERMISSIONS } from "src/constants/roles";
import { IsFeatureReady } from "src/constants/features";

const RolesForm = (props) => {
    const { isClient, roles, userRole, isUserRole, clickRole, disableInput, currentSelectedRole,
        permissions, currentSelectedPermissions,
        disableChatPermission, hasLeadershipRole, setPermissionIsChecked } = props;

    return (
        <>
            { 
                <form className="permission-modal-form">
                    <>
                        <hr />
                        <RolesContainer 
                            isClient={isClient} 
                            roles={roles}
                            userRole={userRole} 
                            isUserRole={isUserRole} 
                            clickRole={clickRole} 
                            disableInput={disableInput} 
                            currentSelectedRole={currentSelectedRole} 
                            hasLeadershipRole={hasLeadershipRole}
                        />
                    </>
                
                    { userRole !== ACCOUNT_OWNER && 
                        <>
                            <hr style={{ marginRight: 30 }}/>
                            <div className="custom-scroll" 
                                style={{ 
                                    display: "grid", gridTemplateColumns: "repeat(2, 1fr)"
                                }}
                            >
                                <div className="col">
                                    { permissions.app && <AppPermission
                                        permissions={permissions}
                                        currentSelectedPermissions={currentSelectedPermissions}
                                        setPermissionIsChecked={setPermissionIsChecked}
                                        disableInput={disableInput}
                                    />
                                    }
                                    <ChatPermission
                                        permissions={permissions}
                                        currentSelectedPermissions={currentSelectedPermissions}
                                        setPermissionIsChecked={setPermissionIsChecked}
                                        disableChatPermission={disableChatPermission}
                                    />
                                </div>

                                <div className="col">
                                    <WebPermission
                                        permissions={permissions}
                                        currentSelectedPermissions={currentSelectedPermissions}
                                        setPermissionIsChecked={setPermissionIsChecked}
                                        disableInput={disableInput}
                                    />
                                </div>
                            </div>
                        </>
                    }
                </form>
            }
        </>
    );
};

const Checkbox = (props) => {
    const { item, isChecked, setPermissionIsChecked } = props;

    return (
        <div className="check-field" style={{ display: props.hidden ? "none" : "inline-block" }}>
            <input type="checkbox" className="permissions-checkbox" value={item.id}
                checked={ isChecked }
                onChange={() => setPermissionIsChecked(item.id, !isChecked)}
                disabled={props.disabled}
            />
            <label>{ item.name }</label>
            <div className="sub-text">
                { item.description }
            </div>
        </div>
    );
};

const RolesContainer = (props) => {
    const { isClient, roles, userRole, isUserRole, clickRole, disableInput, currentSelectedRole, hasLeadershipRole } = props;

    return (
        <div className="item-row" style={{ marginRight: 30 }}>
            <div className="row-label">Roles</div>
            <div className="row" >

                { (!isClient && userRole !== ACCOUNT_OWNER) && 
                    <Grid className="grid grid-col-2" >
                        {   
                            roles && roles.map((role, index) => {
                                return (
                                // hide the Account Owner role if selected user is not an account owner
                                // also hide "Multiple Permissions" role
                                    (userRole !== ACCOUNT_OWNER && role.id === ACCOUNT_OWNER) || role.id === MULTIPLE_PERMISSIONS ? ""
                                        :
                                        <div key={index} className="radio-field">
                                            <input type="radio" name="role" value={role.id} 
                                                checked={ isUserRole(role.id) }
                                                onChange={() => clickRole(role.id)}
                                                disabled={disableInput}
                                            />
                                            <label htmlFor="">{ role.name }</label>
                                        </div>    
                                );
                            })
                        }
                    </Grid>
                }
                <div className={hasLeadershipRole ? "grid grid-col-2" : undefined} >
                    <Permission role={currentSelectedRole} isClient={isClient} hasLeadershipRole={hasLeadershipRole}></Permission>
                </div>
            </div>
        </div>
    );
};

const AppPermission = (props) => {
    const { permissions, currentSelectedPermissions, setPermissionIsChecked, disableInput } = props;

    return (
        <>
            <div className="row-label">App Permissions</div>
            {
                permissions.app && permissions.app.map((item, i) => {
                    const hidden = !IsFeatureReady.accountLinking() && i === 3;
                    return (
                        <Checkbox
                            key={item.id}
                            item={item}
                            hidden={hidden}
                            setPermissionIsChecked={setPermissionIsChecked}
                            isChecked={currentSelectedPermissions.includes(item.id)}
                            disabled={disableInput}
                        />
                    );
                })
            }
        </>
    );
};

const ChatPermission = (props) => {
    const { permissions, currentSelectedPermissions, setPermissionIsChecked, disableChatPermission } = props;
    return (
        <div>
            <div className="row-label">Chat Permissions (App & Web)</div>
            {
                permissions.chat && permissions.chat.map(item => {
                    return (
                        <Checkbox
                            key={item.id}
                            item={item}
                            setPermissionIsChecked={setPermissionIsChecked}
                            hidden={false}
                            isChecked={currentSelectedPermissions.includes(item.id)}
                            disabled={disableChatPermission(item.id)}
                        />
                    );
                })
            }
        </div>
    );
};

const WebPermission = (props) => {
    const { permissions, currentSelectedPermissions, setPermissionIsChecked, disableInput } = props;
    return (
        <>
            <div className="row-label">Web Dashboard Permissions</div>
            {
                permissions.web && permissions.web.map((item, i) => {
                    const hidden = !IsFeatureReady.accountLinking() && (i === 10 || i === 11);
                    return (
                        <Checkbox
                            key={item.id}
                            hidden={hidden}
                            item={item}
                            setPermissionIsChecked={setPermissionIsChecked}
                            isChecked={currentSelectedPermissions.includes(item.id)}
                            disabled={disableInput}
                        />
                    );
                })
            }
        </>
    );
};

export default RolesForm;
