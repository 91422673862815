import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { Box, Dialog, Divider } from "@material-ui/core/";
import {
    Text, FlexContainer, Button, FormField, LottieLoadingIndicator
} from "src/components";
import { LeadershipContext } from "./LeadershipContext";
import OneImg from "src/img/new/chats/leadership/one.png";
import TwoImg from "src/img/new/chats/leadership/two.png";

const InputField = styled(FormField)`
    border: 1px solid #000000;
    border-radius: 0.75rem;
    background: #FFF;
    font-size: 0.875rem;
    height: 2.4375rem;
    width: 100%;
    padding: 0.625rem 1.8125rem;
    box-sizing: border-box;
    max-width: 18.875rem;
    text-align: left;
`;

const StyledButton = styled(Button)`
    border-radius: 0.4375rem !important;
    height: 2.3125rem;
    line-height: 100%;
    font-size: 0.875rem;
`;

const LeadershipRoleModal = (props) => {
    const { showDialog, handleClose } = props;
    const [ isLoading, setIsLoading ] = useState(true);
    const { createLeadershipRole,
        updateLeadershipRole,
        leadershipRoleToEdit, 
        deleteLeadershipRole,
        isFormCreate 
    } = useContext(LeadershipContext);
    const [ roleName, setRoleName ] = useState("");

    const handleInputChange = (e) => {
        setRoleName(e.target.value);
    };

    const submitForm = () => {
        setIsLoading(true);
        isFormCreate ? submitCreate() : submitUpdate();
    };

    const submitCreate = () => {
        const formData = {
            name: roleName,
            isVisibleToClients: false,
            isVisibleToEmployees: false
        };
        createLeadershipRole(formData);
    };

    const submitUpdate = () => {
        const formData = leadershipRoleToEdit;
        formData.name = roleName;
        updateLeadershipRole(formData);
    };

    const handleDelete = () => {
        const isConfirmed = window.confirm("Are you sure you want to remove the this leadership role?");
        if (!isConfirmed) {
            return false;
        }
        deleteLeadershipRole(leadershipRoleToEdit);
    };

    useEffect(() => {
        if (!isFormCreate) {
            setRoleName(leadershipRoleToEdit.name);
        }
        setIsLoading(false);
    }, [isFormCreate]);

    return (
        <React.Fragment>
            <Dialog open={showDialog} onClose={handleClose}
                maxWidth={false}
                scroll="body"
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
                PaperProps={{
                    style: {
                        borderRadius: 15,
                        height: "43.1875rem",
                        maxWidth: "56.875rem",
                        width: "100%"
                    },
                }}
            >
                <Box sx={{ padding: "2.6875rem 3.8125rem 2.1875rem 3.8125rem" }}>
                    { isLoading ? <LottieLoadingIndicator /> :
                        <React.Fragment>
                            <Text align="left" size="2.5rem"
                                weight="700" margin="0"
                            >
                                { isFormCreate ? "Create" : "Edit" } Leadership Role
                            </Text>
                            <Divider />
                            <FlexContainer direction="row" justifyContent="space-between" marginTop="1.25rem">
                                <div style={{ maxWidth: "18.875rem", width: "100%" }}>
                                    <Text align="left" weight="700"
                                        size="1.25rem" margin="0 0 0.75rem 0"
                                    >
                                        { isFormCreate ? "Create" : "Edit" } Leadership Role Title
                                    </Text>
                                    <InputField value={roleName}
                                        onChange={(e) => handleInputChange(e)}
                                        placeholder="Leadership Role Title"
                                        placeholderAlignment="left"
                                    />
                                    <Text align="left" margin="1.3125rem 0 0 0" size="0.875rem">
                                        Leadership Role Titles appear in your business chat menu in the app and web interface. When you assign a leadership role to a user, other users will be able to see them as such. Depending on your selection, employees or clients can contact and chat with them.
                                    </Text>
                                </div>
                                <FlexContainer direction="row">
                                    <img src={OneImg} alt="one" />
                                    <img src={TwoImg} alt="two" />
                                </FlexContainer>
                            </FlexContainer>
                            <Divider style={{ marginTop: 30 }} />
                            <FlexContainer direction="row" justifyContent="space-between" marginTop="0.9375rem">
                                { !isFormCreate ?
                                    <StyledButton backgroundColor="#FF5353"
                                        color="#FFF"
                                        onClick={() => handleDelete()}
                                    >  
                                        Permanently Delete
                                    </StyledButton>
                                    : <div>&nbsp;</div>
                                }
                                <FlexContainer direction="row" justifyContent="end">
                                    <StyledButton backgroundColor="#FFF"
                                        marginRight="1.125rem"
                                        color="#000000"
                                        width="8.75rem"
                                        border="0.0625rem solid #000"
                                        lineHeight="100%"
                                        onClick={() => handleClose()}
                                    >Cancel</StyledButton>
                                    <StyledButton backgroundColor="#006CFF"
                                        color="#FFF"
                                        width="8.75rem"
                                        border="1px solid #006CFF"
                                        onClick={() => submitForm()}
                                    >Save</StyledButton>
                                </FlexContainer>
                            </FlexContainer>
                        </React.Fragment>
                    }
                </Box>
            </Dialog>
        </React.Fragment>
    );
};

export default LeadershipRoleModal;