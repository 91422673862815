import React from "react";
import { EnumUserGroup, ErrorList, ErrorStrings } from "../../../components/Enums";
import { ErrorText, FlexContainer, Text } from "src/components";
import Skeleton from "src/scenes/Statistics/components/Skeleton";
import styled from "styled-components";
import UserGroup from "src/img/survey/updatedIcons/users.svg";
import VisibilityModal from "../VisibilityModal";
import VisibilitySection from "../VisibilitySection";
import { TableType } from "src/constants/survey";


const SelectedPeopleIcon = styled.div`
    width: 30px;
    height: 30px;
    background: #D6D6D6 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 2px solid #FFFFFF;
    opacity: 1;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    margin-top: auto;
    margin-bottom: auto;
`;

const SelectedPeopleItem = styled.div`
    width: 247px;
    height: 49px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 5px 5px #0000001A;
    border: 1px solid #E0E0E0;
    border-radius: 15px;
    opacity: 1;
    display: flex;
    flex-direction: row;
    padding-left: 14px;
    margin-bottom: 9px;
    align-items: center;
    cursor: pointer;
    justify-content: space-between;
`;

const renderSelectedPeopleItem = (label, count, onClick, isAll) => (
    <SelectedPeopleItem onClick={onClick}>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
            <SelectedPeopleIcon>
                <img src={UserGroup} alt="peopleIcon" height="14px" style={{ margin: "auto" }} />
            </SelectedPeopleIcon>
            <Text size="16px" weight="500" style={{ marginLeft: "0.5rem" }}>
                {isAll ? `All ${label}` : `${count} ${count === 1 ? label.slice(0, -1) : label}`}
            </Text>
        </div>
        <Text size="16px" color="#006CFF" weight="400" style={{ marginRight: "1rem" }}>
            Edit
        </Text>
    </SelectedPeopleItem>
);

const renderSelectedPeopleSection = (
    label, peopleInPoll, countInPoll, isEditSurveyForm,
    surveyCount, totalSurveyCount, userGroup, toggleModal
) => {
    const isNotTargetedSurvey = (
        userGroup === EnumUserGroup.ALL_USERS ||
        userGroup === EnumUserGroup.EMPLOYEES ||
        userGroup === EnumUserGroup.CLIENTS
    );
    if (peopleInPoll || countInPoll > 0) {
        return renderSelectedPeopleItem(label, countInPoll, toggleModal, peopleInPoll);
    } else if (isEditSurveyForm && surveyCount > 0 && !isNotTargetedSurvey) {
        const isTotalMatch = totalSurveyCount === surveyCount;
        return renderSelectedPeopleItem(label, surveyCount, toggleModal, isTotalMatch);
    } else if (isEditSurveyForm && isNotTargetedSurvey && userGroup === label) {
        return renderSelectedPeopleItem(label, 0, toggleModal, true);
    } else if (isEditSurveyForm && isNotTargetedSurvey && userGroup === EnumUserGroup.ALL_USERS) {
        return renderSelectedPeopleItem(label, 0, toggleModal, true);
    } else {
        return null;
    }
};

export const UserGroupField = (props) => {
    const {
        inputErrors,
        isEditSurveyForm,
        survey,
        loading,
        togglePollVisibilityModal,
        showPollVisibilityModal,
        handleSaveSelectedUsers,
        selectedUsers,
        clientsInPoll,
        employeesInPoll,
        employeesCountInPoll,
        clientsCountInPoll,
        isEditForm,
        isShowInitialUserGroupAddButton
    } = props;

    const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
    const userGroupError = getErrorStrings(ErrorList.EMPTY_USER_GROUP);
    const selectedUsersError = getErrorStrings(ErrorList.EMPTY_SELECTED_USERS);

    return (
        <>
            <Text align="left" size="16px" weight="700" margin="16px 0px 0px 0px">
                Select User Group or Specific Users
            </Text>
            <Text align="left" size="14px" weight="400" color="#8291B2" margin="0">
                Select to send this survey to All Employees, All Clients, or selected users.
            </Text>

            { loading ?
                <Skeleton height="50px" width="100%" animation="wave" />
                :
                <>
                    <div style={{ marginBlockStart: "1em", marginBlockEnd: "1em", display: "flex", flexDirection: "column", gap: "1rem" }}>
                        <VisibilitySection
                            togglePollVisibilityModal={togglePollVisibilityModal}
                            isEditForm={isEditForm}
                            pollVisibilityError={selectedUsersError}
                            label="Select Users"
                            buttonWidth="17.5rem"
                            buttonHeight="3.125rem"
                            showButton={isShowInitialUserGroupAddButton}
                        />
                        <FlexContainer direction="row" gap="1rem">
                            {renderSelectedPeopleSection(
                                "Employees",
                                employeesInPoll,
                                employeesCountInPoll,
                                isEditSurveyForm,
                                survey.employeeCount,
                                survey.totalEmployeeCount,
                                survey.userGroup,
                                togglePollVisibilityModal
                            )}
                            {renderSelectedPeopleSection(
                                "Clients",
                                clientsInPoll,
                                clientsCountInPoll,
                                isEditSurveyForm,
                                survey.clientCount,
                                survey.totalClientCount,
                                survey.userGroup,
                                togglePollVisibilityModal
                            )}
                        </FlexContainer>
                    </div>

                    <VisibilityModal
                        togglePollVisibilityModal={togglePollVisibilityModal}
                        showPollVisibilityModal={showPollVisibilityModal}
                        handleSave={handleSaveSelectedUsers}
                        pollParticipantUuids={isEditForm ? survey.usersSelectedUuid : selectedUsers}
                        clientsInPoll={isEditForm ? survey.totalClientCount === survey.clientCount : clientsInPoll}
                        employeesInPoll={isEditForm ? survey.totalEmployeeCount === survey.employeeCount : employeesInPoll}
                        employeesCountInPoll={isEditForm ? survey.employeeCount : employeesCountInPoll}
                        clientsCountInPoll={isEditForm ? survey.clientCount : clientsCountInPoll}
                        modalTitle="Choose Specific Survey Participants"
                        formType={TableType.Surveys}
                        userGroupType={survey.userGroup}
                    />
                </>
            }

            { userGroupError.length > 0 &&
                <ErrorText errorMargin="15px 20px 15px">
                    {userGroupError.length > 0 ? userGroupError : null}
                </ErrorText>
            }
        </>
    );
};