import { useState } from "react";
import { Toast } from "src/components";
import axios from "axios";
import { API_URL } from "src/scenes/App";

const useDeleteMembershipCard = (token) => {
    const controller = new AbortController();

    const [isDeletingMembershipCard, setIsDeletingMembershipCard] = useState(false);
    
    const deleteHeroImage = async (uuid) => {
        setIsDeletingMembershipCard(true);
        try {
            await axios.delete(`${API_URL}/membershipCard/company/businessTemplate/${uuid}`, {
                signal: controller.signal,
                headers: {
                    Authorization: "Bearer " + token
                }
            });
        } catch (error) {
            if (error === "canceled") { //AbortController
                return;
            }

            console.error("Error occurred when deleting hero images: ", error);
            Toast.error("Unable to delete hero image.");
        } finally {
            setIsDeletingMembershipCard(false);
        }
    };

    return { 
        deleteHeroImage,
        isDeletingMembershipCard
    };
};

export default useDeleteMembershipCard;