/**
 * @param {string} hex
 * @returns {number[]} - 4-element array with RGBA values in the range [0.0, 1.0]
 */
export const hexToRgba = hex => {

    hex = hex.replace("#", "");
    if (hex.length !== 3 && hex.length !== 6) {
        return [1.0, 1.0, 1.0, 1.0];
    }

    const stringComponents = hex.length === 3 ?
        [hex.slice(0, 1).repeat(2), hex.slice(1, 2).repeat(2), hex.slice(2, 3).repeat(2), "ff"] :
        [hex.slice(0, 2), hex.slice(2, 4), hex.slice(4, 6), "ff"];
    return stringComponents.map(str => parseInt(str, 16) / 255);
};
