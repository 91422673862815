import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { SketchPicker } from "react-color";
import plusSignColorPickerImg from "src/img/PlusSignColorPicker.png";
import greenCheckImg from "src/img/check@2x.png";

const ColorPop = styled.div`
    position: absolute;
    z-index: 2;
`;

const ColorCover = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`;

export const ColorCircle = styled.span`
    width: ${p => p.width || "49px"};
    height: ${p => p.height || "49px"};
    display: inline-block;
    border-radius: 50%;
    background-color: ${ p => p.color};
    background-image: ${p => p.showGreenCheck ? `url(${greenCheckImg})` : "none"};
    background-size: 65%;
    background-repeat: no-repeat;
    background-position: center;
    border: ${ p => p.border || "1px solid #8291B2"};
    flex-shrink: 0;
    cursor: pointer;
`;

const ColorPickerCircle = styled(ColorCircle)`
    box-shadow: 0px 3px 6px #00000029;
    background-image: url(${plusSignColorPickerImg});
    background-size: 100%;
    border: none;
`;

const MemberCardColorPicker = ({ hexColor, onColorPickerChange }) => {
    const [isColorClicked, setIsColorClicked] = useState(false);
    const [pickerColor, setPickerColor] = useState(hexColor);
    const timeoutRef = useRef(null); // Ref to store the timeout ID

    const handleClick = () => {
        setIsColorClicked(prevBoolValue => !prevBoolValue);
    };

    const handleClose = () => {
        setIsColorClicked(false);
    };

    useEffect(() => {

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current); // Clear previous timeout
        }

        timeoutRef.current = setTimeout(() => {
            onColorPickerChange(pickerColor);
        }, 50);

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current); // Cleanup timeout on unmount
            }
        };
    }, [pickerColor]);

    const handleColorPickerChange = (color) => {
        setPickerColor(color.hex);
    }; 

    return (
        <span style={{ position: "relative", top: "2px" }}>
            <ColorPickerCircle
                color={"#FFF"}
                onClick={handleClick}
            />

            {isColorClicked && (
                <ColorPop>
                    <ColorCover onClick={handleClose} />
                    <SketchPicker color={pickerColor} onChange={handleColorPickerChange} />
                </ColorPop>
            )}
        </span>
    );
};

export default MemberCardColorPicker;