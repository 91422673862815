import React, { Component } from "react";
import styled from "styled-components";
import { Forms, Screens } from "../Customise";
import DropdownIcon from "src/img/new/chevron-down.png";
import {
    TextLabel,
    Text,
    Button,
    FlexContainer,
    LottieLoadingIndicator
} from "src/components";
import { CustomiseContext } from "../CustomiseContext";

const Container = styled.div`
    display: flex;
    flex-direction: row;
    font-family: Roboto, Helvetica, sans-serif;
    width: 100%;
`;

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    margin-top: 40px;
`;

const DropdownSelect = styled.select`
    height: 50px;
    width: 407px;
    padding: 0.3em 1.1em;
    border: 1px solid #8291B2;
    border-radius: 12px;
    font-family: Roboto, Helvetica, sans-serif;
    font-weight: 400;
    font-size: 0.938em;
    color: #000;
    box-shadow: 0px 3px 6px #0000001A;
    cursor: pointer;
    background-image: url(${DropdownIcon});
    background-repeat: no-repeat;
    background-position-x: 97%;
    background-position-y: 50%;
    background-color: unset;
    appearance: none;

    &:focus {
        border: 1px solid #000;
        outline: none;
    }
`;

const InputLabel = styled.label`
    font-family: Roboto, Helvetica, sans-serif;
    font-weight: 700;
    font-size: 1em;
    color: #000;
    margin-top: 15px;
`;

const InputDescription = styled.p`
    font-family: Roboto, Helvetica, sans-serif;
    font-weight: normal;
    font-size: 0.938em;
    color: #612684;
    margin: 9px 0;
`;

export const AppScreens = {
    QUOTES: "Quotes Screen",
    NOTICEBOARD: "Noticeboard Screen",
    BODY_AND_MIND: "Body & Mind Screen",
    PLAYLISTS: "Playlists Screen",
    GOALS: "Goals Screen",
    HABITS: "Habits Screen"
};

class StartScreen extends Component {
    static contextType = CustomiseContext;

    clickNext = () => {
        if (this.context.isNextClickable) {
            this.context.nextClick();
        }
    };

    handleScreenChange = (userStartScreenType, e) => {
        this.context.updateUserStartScreen(userStartScreenType, e.target.value);
    };

    render() {
        return (
            <React.Fragment>
                <Container>
                    <div>
                        <TextLabel
                            weight="700"
                            size="1.875em"
                            color="#2A2B2A"
                            flexGrow="1"
                        >
                            Step 4: Set Your App&rsquo;s Starting Screen (Optional)
                        </TextLabel>

                        <FlexContainer>
                            <Text size="1em" align="left">
                                Customise the default screen that employees and clients see when they <br />
                                open your app. Select the feature that best fits their needs to ensure a <br />
                                seamless and personalised experience.
                            </Text>

                            <Text
                                color="#2A2B2A"
                                size="1.25em"
                                align="left"
                                weight="700"
                                margin="0"
                            >
                                Selection Options:
                            </Text>

                            <InputLabel>
                                Employees
                            </InputLabel>
                            <InputDescription>
                                Select what employees will see when they first open the app.
                            </InputDescription>
                            <DropdownSelect
                                disabled={this.context.isFinishing}
                                value={this.context.employeeStartScreen}
                                onChange={(e) => this.handleScreenChange("employeeStartScreen", e)}
                            >
                                {Object.values(AppScreens).map((screen) => (
                                    <option key={screen} value={screen}>
                                        {screen === AppScreens.QUOTES ? `${screen} (Default)` : screen}
                                    </option>
                                ))}
                            </DropdownSelect>

                            <InputLabel>
                                Clients
                            </InputLabel>
                            <InputDescription>
                                Select what clients will see when they first open the app.
                            </InputDescription>
                            <DropdownSelect
                                disabled={this.context.isFinishing}
                                value={this.context.clientStartScreen}
                                onChange={(e) => this.handleScreenChange("clientStartScreen", e)}
                            >
                                {Object.values(AppScreens).map((screen) => (
                                    <option key={screen} value={screen}>
                                        {screen === AppScreens.QUOTES ? `${screen} (Default)` : screen}
                                    </option>
                                ))}
                            </DropdownSelect>
                        </FlexContainer>

                        <hr style={{
                            width: "100%",
                            marginTop: "56px",
                            marginBottom: "6px",
                            backgroundColor: "#E9EBEF"
                        }} />

                        <Text
                            align="left"
                            size="1em"
                            color="#23262D"
                            margin="0"
                        >
                            Click <b>Finish</b> to complete customisation. You can make additional <br />
                            changes as required by selecting <b>‘My Me App’</b> on the side menu.
                        </Text>

                        <ButtonsContainer>
                            <div>
                                <Button
                                    disabled={this.context.isFinishing}
                                    color="#000"
                                    backgroundColor="transparent"
                                    borderRadius="10px !important"
                                    border="1px solid #000"
                                    width="112px"
                                    onClick={() => this.context.previousClick(Forms.ICON, Screens.MAIN)}
                                >
                                    Back
                                </Button>
                            </div>

                            {this.context.isFinishing &&
                                <div style={{ width: "112px" }}>
                                    <LottieLoadingIndicator
                                        containerHeight="0rem"
                                        height="2em"
                                        width="2em"
                                        margin="5px 0 0 0"
                                    />
                                </div>
                            }
                                

                            { !this.context.isFinishing &&
                                <div>
                                    <Button
                                        color="#fff"
                                        backgroundColor="#000"
                                        borderRadius="10px !important"
                                        border="1px solid #000"
                                        marginLeft="20px"
                                        width="112px"
                                        onClick={this.clickNext}
                                    >
                                        Finish
                                    </Button>
                                </div>
                            }
                        </ButtonsContainer>
                    </div>
                </Container>
            </React.Fragment>
        );
    }
}

export default StartScreen;