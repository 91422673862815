import styled from "styled-components";
import { Button, mobile, tablet, desktop } from "src/components";

export const NextStepButton = styled(Button)`
    background: #51315D;
    color: #ffffff;
`;

export const CancelButton = styled(Button)`
    background: #ffffff;
    color: #000000;
    border: 1px solid #000000;
    height: ${ p => p.height || "38px"};
    border-radius: ${ p => p.borderRadius || ""};
`;

export const SaveButton = styled(Button)`
    background: ${ p => p.backgroundColor || "#ffffff"};
    color: ${ p => p.color || "#000000"};
    border: ${ p => p.border || "1px solid #000000"};
    height: ${ p => p.height || "38px"};
    border-radius: ${ p => p.borderRadius || ""};
`;

export const FormBackground = styled.div`
    position: relative;
    width: 100%;
    max-width: 1512px;
    background: #FFF;
    height: auto;
    min-height: 5.625rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 7px;
    border: 1px solid #8291B2;
    box-sizing: border-box;
    margin-bottom: 2.5rem;
    
    ${tablet`
        padding: 1rem 0;
        flex-direction: column;
    `};

    ${mobile`
        padding: 1rem 0;
        flex-direction: column;
    `};

    ${desktop`
        padding: 0;
        flex-direction: row;
    `};
`;

export const FormSectionHeader = styled.div`
    font-size: 25px;
    color: #612684;
    letter-spacing: 0px;
`;

export const DropdownSelector = styled.select`
    width: 100%;
    border-radius: 12px;
    text-decoration: none;
    color: white;
    height: 50px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    padding: 0.3em 1.1em;
    color: #000000;
    font-size: 16px;
    font-family: Roboto, Helvetica, sans-serif;
    background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='45' viewBox='0 0 24 24' width='35' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
    background-repeat: no-repeat;
    background-position-x: 97%;
    background-position-y: 50%;
    appearance: none;
`;

export const LoadingIndicatorButton = styled.div`
    background: #ffffff;
    color: #000000;
    border: 1px solid #000000;
    height: ${ p => p.height || "38px"};
    width: ${ p => p.width || "150px"};
    display: flex;
    border-radius: ${ p => p.borderRadius || "12px"};
`;

export const StyledCheckbox = styled.div`
background-clip: content-box;
background-color: ${props => (props.checked ? props.color || "#85b336" : "white")};
border: 1px solid #2D3037;
box-sizing: border-box;
height: ${p => p.height || "20px"};
padding: ${p => p.padding || "5px"};
border-radius: ${ p => p.radius || "0"};
transition: all 150ms;
width: ${p => p.width || "20px"};
`;
