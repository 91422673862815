import React from "react";
import styled from "styled-components";
import { PageHeadingLabel, ExplainParagraphLabel } from "src/components";
import PerksTable from "./components/PerksTable";
import { PERK_TYPE } from "src/constants/perks";

const SelectInput = styled.select`
    height: 3.188rem !important;
    width: 19.7rem !important;

    &:focus {
        outline: none;
    }
`;

const HeaderTwoColumns = styled.div`
    display: flex;
    justify-content: space-between;
    max-width: 94.5rem;
    margin: 0 0 1.4rem 0 !important;
`;

const FilterContainer = styled.div`
    display: flex;
    align-items: end;
`;

const TablePage = ({
    user,
    perks,
    onHandleFilterByType,
    handleSearchChange,
    handleFilterByStatus,
    filterType,
    searchQuery,
    filterStatus,
    isFetching,
    allPerksChecked,
    handleCheckAllPerks,
    checkedPerks,
    handleCheckPerk,
    handleShowDeleteDialog,
    handleFormVisible,
    handleEditPerk
}) => {
    return (
        <>
            <PageHeadingLabel>
                Perks
            </PageHeadingLabel>

            <HeaderTwoColumns className="row">
                <ExplainParagraphLabel size="0.875rem" width="100%" maxWidth="44rem">
                    Easily create and manage exclusive perks for employees or clients. Use this feature to offer <br />
                    special rewards and gift perks, and customise benefits that align with your business. Track and <br />
                    update perks seamlessly to enhance user engagement and satisfaction.
                </ExplainParagraphLabel>

                { !isFetching && (
                    <FilterContainer className="field">
                        <label>Filter:</label>

                        <SelectInput value={filterType} onChange={onHandleFilterByType}>
                            <option value="all">All Perks</option>
                            <option value={PERK_TYPE.GENERAL}>General Perks</option>
                            <option value={PERK_TYPE.GIFT}>Gift Perks</option>
                        </SelectInput>
                    </FilterContainer>
                )}
            </HeaderTwoColumns>

            <PerksTable
                user={user}
                perks={perks}
                handleSearchChange={handleSearchChange}
                handleFilterByStatus={handleFilterByStatus}
                searchQuery={searchQuery}
                filterStatus={filterStatus}
                isFetching={isFetching}
                allPerksChecked={allPerksChecked}
                handleCheckAllPerks={handleCheckAllPerks}
                checkedPerks={checkedPerks}
                handleCheckPerk={handleCheckPerk}
                handleShowDeleteDialog={handleShowDeleteDialog}
                handleFormVisible={handleFormVisible}
                handleEditPerk={handleEditPerk}
            />
        </>
    );
};

export default TablePage;