import React, { useEffect, useState } from "react";
import { useRef } from "react";
import { ErrorText, Text, Toast } from "src/components";
import styled from "styled-components";
import { ErrorList, ErrorStrings, validImageTypes, validVideoTypes } from "../../components/Enums";

const ChangeAsset = styled.div`
    margin-bottom: 20px;
    background-color: black;
    border: none;
    border-radius: 7px;
    cursor: pointer;
    box-shadow: 0px 3px 6px #00000029;
    width: 166px;
    height: 38px;
    font-size: 0.875rem;
    font-family: "Roboto", "Helvetica", sans-serif;
    font-weight: 400;
    letter-spacing: 0px;
    color: white;
    z-index: 1;
    position: absolute;
    align-items: center;
    text-align: center;
    justify-content: center;
    display: flex;
`;

const ChangeVideoAssetButton = styled.div`
    margin-top: 10px;
    margin-bottom: 20px;
    background-color: black;
    border: none;
    border-radius: 7px;
    cursor: pointer;
    box-shadow: 0px 3px 6px #00000029;
    width: 166px;
    height: 38px;
    font-size: 0.875rem;
    font-family: "Roboto", "Helvetica", sans-serif;
    font-weight: 400;
    letter-spacing: 0px;
    color: white;
    align-items: center;
    text-align: center;
    justify-content: center;
    display: flex;
`;

const BrowseFileButton = styled.div`
    width: 140px;
    height: 37.5px;
    background-color: #000;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 7px;
    color: #fff;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    font-weight: 400;
    font-size: 0.875rem;
    font-family: "Roboto", "Helvetica", sans-serif;
`;

const ClickableDivBox = styled.div`
    width: 364px;
    height: 205px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #E9EBEF;
    border-radius: 15px;
    background-color: #F8F8F8;
    cursor: pointer;
    @media (max-width: 600px) {
        max-width: 220px;
        height: 150px;
    };
`;

const ImageBoxParent = styled.div`
    display: flex;
    position: relative;
    align-items: end;
    justify-content: center;
    @media (max-width: 600px) {
        max-width: 220px;
        height: 150px;
    };
`;

const ImageBox = styled.img`
    width: 364px;
    height: 205px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #E0E0E0;
    border-radius: 15px;
    background-color: #E8E8E8;
    cursor: pointer;
    object-fit: cover;
    object-position: center;
    @media (max-width: 600px) {
        max-width: 220px;
        height: 150px;
    };
`;

const VideoBox = styled.video`
    width: 364px;
    height: 205px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #E0E0E0;
    border-radius: 15px;
    background-color: #E8E8E8;
    cursor: pointer;
    object-fit: cover;
    object-position: center;
    @media (max-width: 600px) {
        max-width: 220px;
        height: 150px;
    };
`;

const renderAssetFieldPreview = (primaryAsset, assetType) => {
    if (assetType === "image" || validImageTypes.includes(assetType)) {
        return (
            <ImageBoxParent>
                <ImageBox
                    src={primaryAsset}
                    alt="Preview"
                />
                <ChangeAsset>
                    Change Image/Video
                </ChangeAsset>
            </ImageBoxParent> 
        );
    }

    if (assetType === "video" || validVideoTypes.includes(assetType)) {
        return (
            <div style={{ display: "flex", flexDirection: "column" }}>
                <ImageBoxParent>
                    <VideoBox
                        src={primaryAsset}
                        alt="Preview"
                        controls={true}
                        muted={true}
                    />
                </ImageBoxParent>
            </div>
        );
    }

    return (<>Unsupported Asset Type</>);
};

const AdPrimaryImageUpload = (props) => {
    const { setFormPrimaryAsset, setImageChanged, inputErrors, asset, setFormPrimaryAssetType, assetType } = props;
    const [primaryAsset, setPrimaryAsset] = useState(asset || null);
    const fileInputRef = useRef(null);

    const getErrorStrings = (...errs) => inputErrors.filter(err => errs.includes(err)).map(err => ErrorStrings[err]);
    const primaryAssetError = getErrorStrings(ErrorList.EMPTY_PRIMARY_ASSET);

    useEffect(() => {
        if (asset) {
            setPrimaryAsset(asset);
        }
    }, [asset]);

    const handleFiles = (files) => {
        const maxSizeInBytes = 20 * 1024 * 1024; // 20MB
        const targetAspectRatio = 16 / 9;

        if (primaryAsset) {
            setPrimaryAsset(null);
            setFormPrimaryAsset(null);
        }

        if (files && files.length > 0) {
            const file = files[0];
            const reader = new FileReader();

            if (file.size > maxSizeInBytes) {
                Toast.error("File size exceeds 20MB. Please select a smaller file.");
                return;
            }

            const validateAspectRatio = (width, height) => {
                const actualAspectRatio = width / height;
                return Math.abs(actualAspectRatio - targetAspectRatio) < 0.01; // Allow slight tolerance
            };

            reader.onload = () => {
                if (validImageTypes.includes(file.type)) {
                    const img = new Image();
                    img.onload = () => {
                        if (!validateAspectRatio(img.width, img.height)) {
                            Toast.error("Invalid aspect ratio. Media should have a 16:9 aspect ratio.");
                            return;
                        }
                        setPrimaryAsset(reader.result);
                        setFormPrimaryAsset(reader.result);
                        setImageChanged(true);
                        setFormPrimaryAssetType("image");
                    };
                    img.src = reader.result;
                } else if (validVideoTypes.includes(file.type)) {
                    const video = document.createElement("video");
                    video.className = "videoPreview";
                    video.src = URL.createObjectURL(file);
                    video.crossOrigin = "anonymous";
                    video.muted = true;
    
                    video.addEventListener("loadeddata", () => {
                        if (!validateAspectRatio(video.videoWidth, video.videoHeight)) {
                            Toast.error("Invalid aspect ratio. Media should have a 16:9 aspect ratio.");
                            return;
                        }
                        setFormPrimaryAssetType("video");
                        setFormPrimaryAsset(reader.result);
                        setPrimaryAsset(video.src);
                        setImageChanged(true);
                    }, { once: true });
                } else {
                    Toast.error("Unsupported file type. Images may be JPEG or PNG format, and videos may be MP4 format.");
                }
            };
            reader.readAsDataURL(file);
        }
    };

    const handleDropzoneClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleDragOver = (event) => {
        event.preventDefault();
    };

    const handleDrop = (event) => {
        event.preventDefault();
        handleFiles(event.dataTransfer.files);
    };

    const handleInputChange = (event) => {
        handleFiles(event.target.files);
    };

    return (
        <div>
            <Text size="16px" align="left" weight="700">
                Upload Primary Asset
            </Text>
            <Text size="0.938rem" color="#612684" align="left" style={{ wrap: "auto" }}>
                Click browse file to upload your image or video.
            </Text>
            <Text size="0.875rem" color="#808080" align="left" style={{ wrap: "auto", maxHeight: "48px", maxWidth: "290px" }}>
                Supported formats: JPG, PNG, MP4. Max size: 20MB. Aspect Ratio 16:9 - 1920px x 1080px
            </Text>
            
            <input
                type="file"
                accept=".png, .jpg, .jpeg, .mp4"
                ref={fileInputRef}
                onChange={handleInputChange}
                style={{ display: "none" }}
            />
            <ClickableDivBox
                onClick={handleDropzoneClick}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
            >
                {primaryAsset ? (
                    renderAssetFieldPreview(primaryAsset, assetType)
                ) : (
                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        <BrowseFileButton>
                            Browse File
                        </BrowseFileButton>
                    </div>
                )}
            </ClickableDivBox>
            {
                assetType === "video" &&
                <ChangeVideoAssetButton onClick={() => handleDropzoneClick()}>
                    Change Image/Video
                </ChangeVideoAssetButton>
            }
            
            { primaryAssetError.length > 0 && <ErrorText errorTextAlign="left">{primaryAssetError}</ErrorText> }
        </div>
    );
};

export default AdPrimaryImageUpload;
