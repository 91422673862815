import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { API_URL } from "src/scenes/App";
import { SocialMediaPlatform } from "../utils/Enums";
import { Toast } from "src/components";

const useAccountLinks = (token) => {
    const controller = new AbortController();
    const isMountedRef = useRef(true);
    const config = {
        signal: controller.signal,
        headers: {
            Authorization: "Bearer " + token
        }
    };

    useEffect(() => {
        return () => {
            controller.abort();
            isMountedRef.current = false;
        };
    }, []);

    const [showAccountsLinked, setShowAccountsLinked] = useState(false);
    const [linkedAccounts, setLinkedAccounts] = useState([]);
    const [linkedAccountsFetched, setLinkedAccountsFetched] = useState(false);

    const retrieveLinkedAccounts = () => {
        axios.get(`${API_URL}/companySocials/connectedAccounts`, config)
            .then((response) => {
                setLinkedAccounts((response.data));
                setShowAccountsLinked(true);
                setLinkedAccountsFetched(true);
            });
    };

    const saveFacebookDetails = (data) => {
        setShowAccountsLinked(false);
        setLinkedAccountsFetched(false);
    
        axios.post(`${API_URL}/companySocials/facebook/link`, data, config)
            .then((response) => {
                setLinkedAccounts(response.data);
                setShowAccountsLinked(true);
                setLinkedAccountsFetched(true);
            }).catch((err) => {
                setShowAccountsLinked(true);
                setLinkedAccountsFetched(true);
                const message = typeof(err) === "string" ? err : "Something went wrong when processing your request.";
                return Toast.error(message);
            });
    };

    const revokeSocialMediaAccount = (socialMediaAdminUuid) => {
        setLinkedAccountsFetched(false);
        axios.delete(`${API_URL}/companySocials/${socialMediaAdminUuid}/revoke`, config)
            .then((response) => {
                Toast.success("Selected account has been disconnected.");
            }).catch((err) => {
                const message = typeof(err) === "string" ? err : "Something went wrong while disconnecting your account.";
                Toast.error(message);
            }).finally(() => {
                retrieveLinkedAccounts();
            });
    };

    /**
     * passed down into the button, once clicked
     * we'll be identifying which functions we'll be using based on
     * provided platformType.
     * @param {string} socialMediaAdminUuid 
     * @param {string} platformType 
     * @returns 
     */
    const reconnectSocialMediaPlatform = (socialMediaAdminUuid, platformType) => {
        setShowAccountsLinked(false);
        setLinkedAccountsFetched(false);
        switch (platformType) {
            case SocialMediaPlatform.FACEBOOK:
                handleFacebookReconnect(socialMediaAdminUuid);
                break;
        
            default:
                return Toast.error("Selected platform is currently unsupported.");
        }
    };

    /**
     * this is called from reconnectedSocialMediaPlatform and fires the Facebook
     * login window, and on success will call @see reconnectFacebookDetails
     * and send necessary tokens to api
     * @param {string} socialMediaAdminUuid 
     */
    const handleFacebookReconnect = (socialMediaAdminUuid) => {
        if (!window.FB) {
            console.error("Facebook SDK is still loading...");
        }
        window.FB.login(
            function(response) {
                if (response.authResponse) {
                    const authResponse = response.authResponse; // short lived access token
                    let data = {
                        accessToken: authResponse.accessToken,
                        expiryDate: authResponse.expiresIn
                    };
                    reconnectFacebookDetails(data, socialMediaAdminUuid);
                } else {
                    console.error("Facebook login failed. Please try again.");
                }
            },
            { scope: "pages_manage_posts,pages_read_engagement" } // Ensure permissions are requested
        );
    };
    
    /**
     * Sends a request to API to update provided Social Media Page Admin which perform
     * necessary checks 
     * @param {object{string accessToken, integer expiryDate}} data 
     * @param {string} socialMediaPageAdminUuid 
     */
    const reconnectFacebookDetails = (data, socialMediaPageAdminUuid) => {
        setShowAccountsLinked(false);
        setLinkedAccountsFetched(false);
    
        axios.post(`${API_URL}/companySocials/${socialMediaPageAdminUuid}/reconnect`, data, config)
            .then((response) => {
                setLinkedAccounts(response.data);
                setShowAccountsLinked(true);
                setLinkedAccountsFetched(true);
            }).catch((err) => {
                setShowAccountsLinked(true);
                setLinkedAccountsFetched(true);
                const message = typeof(err) === "string" ? err : "Something went wrong while reconnecting your account.";
                return Toast.error(message);
            });
    };

    return { 
        retrieveLinkedAccounts,
        saveFacebookDetails,
        revokeSocialMediaAccount,
        reconnectSocialMediaPlatform,
        showAccountsLinked,
        linkedAccounts,
        linkedAccountsFetched,
    };
};
export default useAccountLinks;