import React from "react";
import { Text, Button, LottieLoadingIndicator } from "src/components";
import { Dialog, Box, Divider } from "@material-ui/core";
import styled from "styled-components";

const SpinnerContainer = styled.div`
    width: 2.188rem;
`;

const DeleteDialog = ({ showDeleteDialog, handleCloseDeleteDialog, handleMultipleDelete, isDeleting }) => {
    return (
        <Dialog
            open={showDeleteDialog}
            maxWidth="md"
            PaperProps={{
                style: {
                    width: 720,
                    height: "auto",
                    borderRadius: 7,
                    overflow: "auto"
                }
            }}
        >
            <Box sx={{ padding: "65px 0" }}>
                <Text size="2.313rem" weight="700" align="center" margin="0 0 20px 0">
                    Delete Selected Perk
                </Text>
                <Text size="0.875rem" weight="500" align="center" margin="0">
                    Are you sure you want to delete the selected perk or <br /> perks from this list?
                </Text>
            </Box>
            <Divider />
            <Box sx={{ padding: "25px" }} style={{ display: "flex", justifyContent: "end" }}>
                {isDeleting ?
                    <SpinnerContainer>
                        <LottieLoadingIndicator height="1.563rem" width="1.563rem" containerHeight="0" />
                    </SpinnerContainer>
                    :
                    <>
                        <Button
                            onClick={handleCloseDeleteDialog}
                            size="0.875rem"
                            border="1px solid #000"
                            color="#000"
                            width="8.75rem"
                            style={{ borderRadius: "7px", boxShadow: "none" }}
                        >
                            Cancel
                        </Button>
                        <Button
                            onClick={handleMultipleDelete}
                            size="0.875rem"
                            backgroundColor="#ff7171"
                            border="1px solid #ff7171"
                            color="#fff"
                            width="8.75rem"
                            style={{ borderRadius: "7px", boxShadow: "none", marginLeft: "25px" }}
                        >
                            Delete
                        </Button>
                    </>
                }
            </Box>
        </Dialog>
    );
};

export default DeleteDialog;