import React, { useState, useContext } from "react";
import { API_URL } from "src/scenes/App";
import axios from "axios";
import CancellationModal from "../components/CancellationModal";
import PreSubscriptionActionPrompt from "../components/PreSubscriptionActionPrompt";
import {
    FlexContainer, Toast, LottieLoadingIndicator, CardHeadingLabel, ExplainParagraphLabel
} from "src/components";

import { SettingContext } from "../SettingContext";
import { Hr, SubscriptionContainer, SubscriptionHeaderContainer, SubscriptionTitle, SubscriptionStatus,
    SubscriptionBadgeContainer, SubscriptionBadge, SubscriptionAction, SubscriptionContentContainer, 
    CardGray, CardTitle, CardContent, RedBackgroundButton } from "src/components/SubscriptionElements";
import MoreMenuContainer from "../components/MoreMenuContainerSubscription";
import { UserContext } from "src/scenes/App/UserContext";
import { UserKeys } from "src/constants/userDetails";
import { withRouter } from "react-router-dom";

function Subscription({ ...props }) {
    const { getCompanyDetails, subscriptionStart, subscriptionEnd, subscriptionStatus, subscriptionNextPaymentDate, totalNumber, employees, clients, token } = useContext(SettingContext);
    const userContext = useContext(UserContext);
    const activeUsers = (employees + clients);
    const [loading, setLoading] = useState(false);
    const [showCancellationModal, setShowCancellationModal] = useState(false);
    const [showReactivationModal, setShowReactivationModal] = useState(false);
    const [showRenewalModal, setShowRenewalModal] = useState(false);

    const companyDetails = {
        companyName: userContext[UserKeys.COMPANY_NAME],
        subscriptionEnd: subscriptionEnd
    };

    const cancelSubscription = () => {
        setShowCancellationModal(false);
        setLoading(true);

        axios.put(`${API_URL}/company/cancel`, {}, {
            headers: {
                Authorization: "Bearer " + token
            }
        }).then (async _ => {
            await getCompanyDetails();
            setLoading(false);

            Toast.info("Your subscription has been cancelled.");    
        }).catch(error => {
            Toast.error(error.message);
        });
    };

    const reactivationSubscription = () => {
        setShowReactivationModal(false);
        setLoading(true);
        axios.put(`${API_URL}/company/reactivate`, {}, {
            headers: {
                Authorization: "Bearer " + token
            }
        }).then (async _ => {
            await getCompanyDetails();
            setLoading(false);

            Toast.info("Your subscription has been reactivated.");    
        }).catch(error => {
            Toast.error(error.message);
        });
    };

    const renewalSubscription = () => {
        props.history.push("/renewal");
    };

    const handleCloseCancellationModal = () => setShowCancellationModal(false);
    const handleCloseReactivationModal = () => setShowReactivationModal(false);
    const handleCloseRenewalModal = () => setShowRenewalModal(false);

    if (loading) {
        return <LottieLoadingIndicator />;
    }

    return (
        <>
            <CardHeadingLabel>
                Subscriptions
            </CardHeadingLabel>
            <ExplainParagraphLabel>
                View, manage or cancel your license subscriptions.
            </ExplainParagraphLabel>
            
            <Hr />

            <SubscriptionContainer>
                <SubscriptionHeaderContainer>
                    <SubscriptionTitle>Yearly Subscription</SubscriptionTitle>
                    <SubscriptionBadgeContainer><SubscriptionBadge status={subscriptionStatus}>{subscriptionStatus}</SubscriptionBadge></SubscriptionBadgeContainer>
                    <SubscriptionAction>
                        <MoreMenuContainer 
                            status={subscriptionStatus} 
                            cancelSubscriptionEvent={setShowCancellationModal} 
                            reactivationSubscriptionEvent={setShowReactivationModal} 
                            renewalSubscriptionEvent={setShowRenewalModal} 
                        />
                    </SubscriptionAction>
                </SubscriptionHeaderContainer>
                <SubscriptionContentContainer>
                    <FlexContainer display="flex" direction="row" marginLeft="0.5em" marginRight="0.5em">
                        <CardGray>
                            <CardTitle>Start Date</CardTitle>
                            <CardContent size="20px" weight="900">{subscriptionStart}</CardContent>
                        </CardGray>
                        <CardGray>
                            <CardTitle>End Date</CardTitle>
                            <CardContent size="20px" weight="900">{subscriptionEnd}</CardContent>
                        </CardGray>
                        <CardGray>
                            <CardTitle>Number of Licenses</CardTitle>
                            <CardContent size="20px" weight="900">{totalNumber}</CardContent>
                        </CardGray>
                        <CardGray>
                            <CardTitle>Active Users</CardTitle>
                            <CardContent size="20px" weight="900">{activeUsers}</CardContent>
                        </CardGray>
                    </FlexContainer>

                    <FlexContainer display="flex" direction="row" marginLeft="0.5em" marginRight="0.5em">
                        <CardGray backgroundColor="inherit">
                            <CardTitle weight="700">Last Payment</CardTitle>
                            <CardContent>{subscriptionStart}</CardContent>
                        </CardGray>
                        <CardGray backgroundColor="inherit">
                            <CardTitle weight="700">Next Payment</CardTitle>
                            <CardContent>{subscriptionNextPaymentDate}</CardContent>
                        </CardGray>
                        <CardGray backgroundColor="inherit">
                            <CardTitle weight="700">Employee Users</CardTitle>
                            <CardContent size="20px" color="#5A2A7F">{employees}</CardContent>
                        </CardGray>
                        <CardGray backgroundColor="inherit">
                            <CardTitle weight="700">Client Users</CardTitle>
                            <CardContent size="20px" color="#5A2A7F">{clients}</CardContent>
                        </CardGray>
                    </FlexContainer>

                    {subscriptionStatus === SubscriptionStatus.REACTIVATE && <Reactivate setShowReactivationModal={setShowReactivationModal}/> }
                    {subscriptionStatus === SubscriptionStatus.EXPIRED && <Expired setShowRenewalModal={setShowRenewalModal} /> }
                </SubscriptionContentContainer>
            </SubscriptionContainer>

            {showCancellationModal &&
                <CancellationModal 
                    showDialog={showCancellationModal}
                    handleClose={() => handleCloseCancellationModal()}
                    companyDetails={companyDetails}
                    confirmCallback={() => cancelSubscription()}
                />
            }

            {showReactivationModal &&
                <PreSubscriptionActionPrompt
                    actionName="Reactivate"
                    showDialog={showReactivationModal}
                    handleClose={() => handleCloseReactivationModal()}
                    companyDetails={companyDetails}
                    confirmCallback={() => reactivationSubscription()}
                />
            }

            {showRenewalModal &&
                <PreSubscriptionActionPrompt
                    actionName="Renew"
                    showDialog={showRenewalModal}
                    handleClose={() => handleCloseRenewalModal()}
                    companyDetails={companyDetails}
                    confirmCallback={() => renewalSubscription()}
                />
            }
        </>
    );
}

function Reactivate({ setShowReactivationModal }) {
    return (
        <FlexContainer direction="row" padding="0 0 0 1.75em">
            <div style={{ color: "#FF5353", fontSize: "0.9 rem", lineHeight: "1.9rem" }}>Your subscription will not renew after the end date. Select to reactivate this subscription if you wish to continue.</div>
            <div style={{ flexGrow: "1", paddingLeft: "2em" }} onClick={setShowReactivationModal}><RedBackgroundButton>Reactivate</RedBackgroundButton></div>
        </FlexContainer>
    );
}

function Expired({ setShowRenewalModal }) {
    return (
        <FlexContainer direction="row" padding="0 0 0 1.75em">
            <div style={{ color: "#FF5353", fontSize: "0.9 rem", lineHeight: "1.9rem" }}>Your subscription has expired. Please make a payment immediately to prevent cancellation.</div>
            <div style={{ flexGrow: "1", paddingLeft: "2em" }} onClick={() => setShowRenewalModal(true) }><RedBackgroundButton>Renew</RedBackgroundButton></div>
        </FlexContainer>
    );
}

export default withRouter(Subscription);