import React, { useContext } from "react";
import styled from "styled-components";
import DigitalCompanyCardContext from "src/scenes/DigitalMemberCard/DigitalCompanyCardContext";

const CardContainer = styled.div`
    background-color: ${ p => p.backgroundColor };
    box-shadow: 0px 5px 10px #00000033;
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 10px;
    width: 270px;
    text-align: left;
    overflow: hidden; /* hides image cover excess to follow the rounded border */
`;

const CardHeader = styled.div`
    display: flex;
    flex-direction: row;
    color: #FFF;
    padding: 10px;

    & > div {
        width: 50%;
    }
`;

const GoogleCardHeroContainer = styled.div`
    height: 99px;
    border-color: rgba(255, 255, 255, 0.4);
    border-style: solid;
    border-width: 1px 0px 0px 0px;

    /** image settings */
    background-image: url("${ p => p.cardHeroImage }");
    background-position: center center;
    background-size: cover;
    background-repeat: no-repeat;
`;

const CardInformation = styled.div`
    height: 85px; //+10 padding-top +10 padding-bottom = 105px
    padding: 10px 10px 10px 10px;

    display: flex;
    flex-wrap: wrap; /* Allows items to wrap onto new lines */

    //direct div container
    > div:nth-child(1) {
        width: 100%;
    }

    > div:nth-child(2) {
        width: 60%;
    }

    > div:nth-child(3) {
        width: 40%;
    }
    
    border-color: rgba(255, 255, 255, 0.4);
    border-style: solid;
    border-width: 1px 0px 0px 0px;
`;

const QRCodeContainer = styled.div`
    padding-top: 26px;
    padding-bottom: 26px;
    display: grid;
    justify-items: center;

    border-color: rgba(255, 255, 255, 0.4);
    border-style: solid;
    border-width: 1px 0px 0px 0px;
`;

const QrCode = styled.div`
    width: 108px;
    height: 108px;
    border: 1px solid #FFF;
    border-radius: 5px;
`;

const GoogleDesignationGrid = styled.div`
    text-align: right;
`;

const Designation = styled.div`
    font-size: 0.625rem;
    color: ${p => p.color || "#FFFFFF" };
    font-weight: 500;
    text-transform: capitalize;
`;

const AssignedValue = styled(Designation)`
    font-weight: 300;
    text-transform: capitalize;
    margin-top: 5px;
`;

const GoogleDesignation = styled(Designation)`
    font-weight: 400;
`;

const GoogleAssignedValue = styled(AssignedValue)`
    font-size: 0.813rem; //13px
    font-weight: 500;
`;

const GoogleImageContainer = styled.div`
    height: 31px;
    width: 79px;
    display: flex; 
    justify-content: flex-start; 
    align-items: center;

    > img {
        max-width: 100%;
        max-height: 100%;
    }
`;

const EmployeeGoogleCard = ({ isTemplateForClientPreview, cardPreviewBackgroundColor, cardPreviewHeroImage, cardPreviewTextColor }) => {

    const { 
        getCardTemplateByUserType,
        employeeCardInfo,
        clientCardInfo,
        digitalMembershipCards
    } = useContext(DigitalCompanyCardContext);

    const cardTemplate = getCardTemplateByUserType(isTemplateForClientPreview);
    const textColor = cardPreviewTextColor ? cardPreviewTextColor : cardTemplate.textColor;
    const hasCardHeroImage = cardPreviewHeroImage === null ? false : (cardPreviewHeroImage || cardTemplate.cardHeroImage);
    const userCardInfo = isTemplateForClientPreview ? clientCardInfo : employeeCardInfo;

    return (
        <CardContainer backgroundColor={ cardPreviewBackgroundColor ? cardPreviewBackgroundColor : cardTemplate.backgroundColor}>
            <CardHeader>
                <GoogleImageContainer>
                    <img src={cardTemplate.logo} alt="Business Logo" />
                </GoogleImageContainer>
            </CardHeader>
            
            <CardInformation>
                <div>
                    <div>
                        <GoogleDesignation color={textColor}>{ isTemplateForClientPreview ? "Client #" : "Employee #"}{userCardInfo.cardId}</GoogleDesignation>
                        <GoogleAssignedValue color={textColor}>{ userCardInfo.fname } { userCardInfo.lname }</GoogleAssignedValue>
                    </div>
                </div>
                
                <div style={{ alignContent: "flex-end" }}>
                    <Designation color={textColor}>{ isTemplateForClientPreview ? "Client Type" : "Title" }</Designation>
                    <AssignedValue color={textColor}>{ userCardInfo.titleOrClientType }</AssignedValue>
                </div>
                <GoogleDesignationGrid style={{ alignContent: "flex-end" }}>
                    <Designation color={textColor}>Member Since</Designation>
                    <AssignedValue color={textColor}>{ userCardInfo.memberSince }</AssignedValue>
                </GoogleDesignationGrid>
            </CardInformation>
            <QRCodeContainer>
                <QrCode><img src={digitalMembershipCards.qrImage} alt="Public Contract QR Code" style={{ width: "100%" }}/></QrCode>
            </QRCodeContainer>
            { hasCardHeroImage && <GoogleCardHeroContainer cardHeroImage={cardPreviewHeroImage ? cardPreviewHeroImage : cardTemplate.cardHeroImage} /> }
        </CardContainer>
    );
};
export default EmployeeGoogleCard;